<template>
  <div :class="['relative h-14 mt-px']">
    <label :class="[isLabel ? 'label' : 'placeholder']">
      {{ label }}
    </label>

    <div
      :class="[
        'w-full absolute left-0 bottom-0 border-b',
        isLabel ? 'border-border-alternate-5' : 'border-border-primary',
      ]"
      v-if="isGender"
    >
      <div class="flex absolute -top-8 right-0">
        <label
          class="gender mx-4 font-semibold text-xs flex items-center text-text-primary"
        >
          <input class="mr-4" type="radio" value="Male" v-model="inputValue" />
          Male
        </label>
        <label
          class="gender mx-4 font-semibold text-xs flex items-center text-text-primary"
        >
          <input class="mr-4" type="radio" value="Female" v-model="inputValue" />
          Female
        </label>
      </div>
    </div>

    <div
      :class="[
        'w-full absolute left-0 bottom-0 border-b flex justify-between',
        isLabel ? 'border-border-alternate-5' : 'border-border-primary',
      ]"
      v-else
    >
      <input
        :type="inputType"
        :required="required"
        v-model.trim="inputValue"
        @focus="inputFocused"
        @blur="inputBlurred"
        :max="maxlength"
        :maxlength="maxlength"
      />
      <div
        v-if="isPassword"
        class="cursor-pointer pl-3.5"
        @click="hidePassword = !hidePassword"
      >
        <PasswordShownIcon v-if="hidePassword" />
        <PasswordHiddenIcon v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
export default {
  name: 'AuthInput',

  props: ['modelValue', 'label', 'type', 'required', 'maxlength', 'nospace'],

  setup(props, { emit }) {
    const hidePassword = ref(false);
    const isFocused = ref(false);

    const inputFocused = () => (isFocused.value = true);

    const inputBlurred = () => (isFocused.value = false);

    const inputValue = computed({
      get: () => props.modelValue,
      set: (value) => {
        const data = props.nospace ? `${value}`.replace(/\s+/g, '') : value.trim();
        emit('update:modelValue', data);
      }
    });

    const isLabel = computed(() => isDate.value ? true : isFocused.value || props.modelValue);

    const isPassword = computed(() => props.type === 'password');

    const isGender = computed(() => props.type === 'gender');

    const isDate = computed(() => props.type === 'date');

    const inputType = computed(() => {
      // if (isDate.value) return isFocused.value ? 'date' : 'text';
      if (!isPassword.value) return props.type || 'text';
      else if (hidePassword.value) return 'text';
      return 'password';
    });

    return {
      isLabel,
      isGender,
      isPassword,
      inputType,
      inputFocused,
      inputBlurred,
      inputValue,
      hidePassword,
    };
  },
};
</script>

<style lang="scss" scoped>
label:not(.gender) {
  @apply absolute top-0 left-0 duration-300 ease-linear;
}

label.placeholder {
  @apply text-sm mt-6 text-text-alternate-3;
}

label.label {
  @apply text-text-alternate-1 font-medium text-2xs mt-3;
}

input:not([type='radio']) {
  @apply w-full text-sm outline-none pb-2;
  background-color: transparent;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
</style>
