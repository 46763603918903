<template>
  <transition
    enter-active-class="ease-linear duration-300 transition-all"
    enter-from-class="opacity-0"
    leave-active-class="ease-linear duration-300 transition-all"
    leave-to-class="opacity-0"
    leave-class="opacity-100"
    appear
  >
    <div
      class="fixed z-10 top-0 left-0 w-screen h-screen flex justify-center"
      style="background: rgba(22, 23, 34, 0.6)"
      v-if="isOpen"
    ></div>
  </transition>
  <transition
    enter-active-class="ease-linear duration-300 transition-all"
    enter-from-class="top-1/2 opacity-0"
    leave-active-class="ease-linear duration-300 transition-all"
    leave-to-class="top-1/2 opacity-0"
    leave-class="top-0 opacity-100"
    appear
  >
    <div
      class="
        bg-bg-primary
        pb-16
        z-10
        fixed
        flex flex-col
        left-0
        right-0
        top-0
        bottom-0
        overflow-y-scroll
        m-max-width
        mx-auto
      "
      v-if="isOpen"
    >
      <main class="flex-grow -mt-6">
        <button
          class="
            focus:outline-none
            text-2xl
            sticky
            top-4
            left-6
            bg-bg-primary
            rounded-full
            w-6
            h-6
            flex
            items-center
            justify-center
            z-30
          "
          @click="$emit('close')"
          style="box-shadow: 0 0 5px #888"
        >
          &times;
        </button>
        <div v-if="isOneTime" class="relative rounded-t-lg overflow-hidden">
          <Carousel :autoplay="9000" :wrapAround="true">
            <Slide v-for="{ imageurl, youTubeLink, id } in slides" :key="id">
              <div class="w-full relative" style="padding-top: 66.66%">
                <img
                  v-if="imageurl"
                  class="w-full h-full absolute top-0"
                  :src="imageurl"
                  alt="gallery"
                />
                <iframe
                  v-else-if="youTubeLink"
                  class="w-full h-full absolute top-0"
                  :src="`https://www.youtube.com/embed/${id}?ps=docs&controls=1`"
                  width="100%"
                  height="100%"
                />
              </div>
            </Slide>
            <template #addons>
              <Pagination class="w-1/2 transform translate-x-1/2" />
            </template>
          </Carousel>
          <router-link
            v-if="authUser?.id === fundraiserResponse.userId"
            :class="[
              'absolute right-4 top-4 bg-bg-primary px-5 py-2.5 text-center rounded text-text-alternate-1 font-semibold text-sm',
              { 'link-disabled': isOneTime && !hasNotExpired },
            ]"
            :to="{
              name: 'EditFundraiser',
              params: { id: fundraiserResponse.id },
            }"
          >
            Edit
          </router-link>
          <div
            class="
              flex
              items-center
              justify-between
              px-6
              bg-bg-alternate-6
              text-text-alternate-1
              font-semibold
              text-xs
              xs:text-sm
              py-2
            "
            v-if="!isOneTime"
          >
            <router-link
              class="ml-16 xs:ml-20 truncate"
              :to="{
                name: 'Profile',
                params: { username: fundraiserResponse.userdetail.username },
              }"
            >
              @{{ fundraiserResponse.userdetail.username }}
            </router-link>
            <VerifiedIcon
              class="h-3 pl-1 mr-auto flex-shrink-0"
              v-if="
                fundraiserResponse.userdetail.verificationStatus === 'VERIFIED'
              "
            />
            <VIPIcon
              class="h-4 pl-1 mr-auto flex-shrink-0"
              v-else-if="fundraiserResponse.verificationStatus === 'VIP'"
            />
            <div
              class="
                rounded-full
                border-2 border-border-secondary
                w-14
                xs:w-20
                h-14
                xs:h-20
                absolute
                bottom-1
                left-6
                overflow-hidden
                flex
                items-center
                justify-center
                text-xl
                xs:text-2xl
                font-bold
                z-20
              "
              :style="{
                backgroundImage: `linear-gradient(144.46deg, ${randomColor({
                  luminosity: 'light',
                  seed: `${fundraiserResponse.userdetail.firstname[0]}${fundraiserResponse.userdetail.lastname[0]}`,
                })} 17.06%, ${randomColor({
                  luminosity: 'dark',
                  seed: `${fundraiserResponse.userdetail.firstname[0]}${fundraiserResponse.userdetail.lastname[0]}`,
                })} 103.78%)`,
                color: '#fff',
              }"
            >
              <img
                v-if="fundraiserResponse.userdetail.profilePhoto"
                :src="fundraiserResponse.userdetail.profilePhoto"
                alt="profile_image"
              />
              <p v-else>
                {{ fundraiserResponse.userdetail.firstname[0]
                }}{{ fundraiserResponse.userdetail.lastname[0] }}
              </p>
            </div>
          </div>
        </div>
        <div v-else class="relative lg:hidden">
          <div class="relative">
            <img
              style="margin-top: -8px"
              :src="fundraiserResponse.coverImage"
              class="object-cover lg:h-48 h-32 w-full"
              alt=""
            />
            <div
              class="
                rounded-full
                border-2 border-border-secondary
                w-20
                h-20
                absolute
                top-24
                left-6
                overflow-hidden
                flex
                items-center
                justify-center
                text-xl
                xs:text-2xl
                font-bold
                z-1
              "
              :style="{
                backgroundImage: `linear-gradient(144.46deg, ${randomColor({
                  luminosity: 'light',
                  seed: `${fundraiserResponse.userdetail.firstname[0]}${fundraiserResponse.userdetail.lastname[0]}`,
                })} 17.06%, ${randomColor({
                  luminosity: 'dark',
                  seed: `${fundraiserResponse.userdetail.firstname[0]}${fundraiserResponse.userdetail.lastname[0]}`,
                })} 103.78%)`,
                color: '#fff',
              }"
              style="margin-top: -8px"
            >
              <img
                v-if="fundraiserResponse.userdetail.profilePhoto"
                :src="fundraiserResponse.userdetail.profilePhoto"
                alt="profile_image"
                class="w-20 h-20 rounded-full"
              />
              <p v-else>
                {{ fundraiserResponse.userdetail.firstname[0]
                }}{{ fundraiserResponse.userdetail.lastname[0] }}
              </p>
            </div>
          </div>
          <router-link
            v-if="authUser?.id === fundraiserResponse.userdetail.userid"
            class=""
            :class="[
              'absolute right-4 top-4 bg-bg-primary px-5 py-2.5 text-center rounded text-text-alternate-1 font-semibold text-sm',
              { 'link-disabled': isOneTime && !hasNotExpired },
            ]"
            :to="{
              name: 'EditFundraiser',
              params: { id: fundraiserResponse.id },
            }"
          >
            Edit
          </router-link>
          <div
            class="
              flex
              items-center
              justify-between
              px-6
              bg-bg-alternate-69
              text-text-alternate-1
              font-semibold
              text-xs
              xs:text-sm
              py-2
            "
          ></div>
          <div
            class="
              text-center
              bg-bg-alternate-7
              text-text-alternate-1
              font-semibold
              text-2xs
              xs:text-xs
              py-2
            "
            v-if="fundraiserResponse.status === 'PENDING'"
          >
            Pending admin review
          </div>
          <!-- {{ fundraiser.organisationWebsite }} -->
          <div class="text-right flex mr-2 justify-end lg:mt-3">
            <a
              v-for="social in socialmedialinks"
              :key="social.id"
              :href="`//${social.link.replace(/^https?:\/\//, '')}`"
              target="_blank"
              class="mr-3"
            >
              <div
                class="
                  lg:h-10 lg:w-10
                  w-6
                  h-6
                  bg-bg-alternate-3
                  rounded-full
                  flex
                  justify-center
                  items-center
                "
              >
                <FBIcon
                  class="lg:h-6 lg:w-6 w-4 h-4"
                  v-if="social.smname === 'Facebook'"
                />
                <IGIcon
                  class="lg:h-6 lg:w-6 w-4 h-4"
                  v-if="social.smname === 'Instagram'"
                />
                <TWIcon
                  class="lg:h-6 lg:w-6 w-4 h-4"
                  v-if="social.smname === 'Twitter'"
                />
                <YTIcon
                  class="lg:h-6 lg:w-6 w-4 h-4"
                  v-if="social.smname === 'YouTube'"
                />
              </div>
            </a>
            <a
              :href="`//${fundraiserResponse.organisationWebsite.replace(
                /^https?:\/\//,
                ''
              )}`"
              target="_blank"
              class="mr-3"
            >
              <div
                class="
                  lg:h-10 lg:w-10
                  w-6
                  h-6
                  bg-bg-alternate-3
                  rounded-full
                  flex
                  justify-center
                  items-center
                "
              >
                <WEBIcon class="lg:h-6 lg:w-6 w-4 h-4" />
              </div>
            </a>
          </div>
        </div>
        <div
          class="
            text-center
            bg-bg-alternate-7
            text-text-alternate-1
            font-semibold
            text-2xs
            xs:text-xs
            py-2
          "
          v-if="fundraiserResponse.status === 'PENDING'"
        >
          Pending admin review
        </div>

        <OneOffFundraiser
          v-if="isOneTime"
          :fundraiser="fundraiserResponse"
          :donations="[]"
          :accounts="accounts"
          :targetCoverage="targetCoverage"
          :isExpired="!hasNotExpired"
          :isModal="true"
        />

        <RecurringFundraiser
          v-else
          :fundraiser="fundraiserResponse"
          :donations="[]"
          :accounts="accounts"
          :targetCoverage="targetCoverage"
          :isModal="true"
        />

        <section class="flex-grow mx-6">
          <Overview
            class="border-t border-border-primary"
            v-if="isOneTime"
            :fundraiser="fundraiserResponse"
            :authUser="authUser"
            :isModal="true"
            :isExpired="isOneTime && !hasNotExpired"
            @addUpdate="handleAddUpdate"
            @allBlessors="isBlessModalOpen = true"
          />
          <router-link
            class="
              bg-btn-bg-primary
              block
              mt-5
              mb-4
              text-btn-text-primary
              px-4
              py-3.5
              xs:py-4
              w-full
              rounded
              text-center text-sm
              xs:text-base
              font-bold
            "
            :to="{ name: 'Overview', params: { slug } }"
          >
            Go To Fundraiser
          </router-link>
        </section>
        <BlessorModal
          :isOpen="isBlessModalOpen"
          :fundraiserId="fundraiserResponse.id"
          @close="isBlessModalOpen = false"
        />
      </main>
    </div>
  </transition>
</template>

<script>
import { computed, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { Carousel, Slide, Pagination } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import Overview from '@/views/fundraiser/Overview';
import { useToast } from 'vue-toastification';
import randomColor from 'randomcolor';
import RecurringFundraiser from '@/views/fundraiser/RecurringFundraiser';
import OneOffFundraiser from '@/views/fundraiser/OneOffFundraiser';
import dayjs from 'dayjs';
import { getYouTubeVideoId } from '@/utils';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import minMax from 'dayjs/plugin/minMax';
import BlessorModal from './BlessorModal.vue';
dayjs.extend(isSameOrAfter);
dayjs.extend(minMax);

export default {
  name: 'FundraiserModal',

  emits: ['close'],

  props: ['isOpen', 'fundraiser'],

  components: {
    Overview,
    Carousel,
    Slide,
    Pagination,
    RecurringFundraiser,
    OneOffFundraiser,
    BlessorModal,
  },

  setup(props) {
    const isBlessModalOpen = ref(false);
    const toast = useToast();

    // eslint-disable-next-line vue/no-setup-props-destructure
    const fundraiserResponse = props.fundraiser;

    const slug = computed(() => {
      const { id, title } = fundraiserResponse;
      return `${id}-${title
        .trim()
        .toLowerCase()
        .replace(/[^a-z\d\s:]/g, '')
        .replace(/\s+/g, '-')}`;
    });

    const handleCopy = async (value) => {
      try {
        navigator.clipboard.writeText(value);
        toast.success('Copied to clipboard!');
      } catch (error) {
        console.log(error);
      }
    };

    const targetCoverage = computed(() => {
      const { raisedAmount, targetAmount } = fundraiserResponse;

      return `${Math.min(raisedAmount / targetAmount, 1) * 100}%`;
    });

    const store = useStore();
    const authUser = store.state.auth.authData;

    const handleAddUpdate = (update) => {
      fundraiserResponse.fundraiserupdates.splice(0, 0, update);
    };

    const hasNotExpired = computed(() => {
      // const { createdAt, duration } = fundraiserResponse;
      // const dueDay = dayjs(createdAt).add(duration, 'day');
      // return dayjs(dueDay).isSameOrAfter(dayjs());
      const { status } = fundraiserResponse;
      if (
        status !== 'EXPIRED' ||
        status !== 'DELETED' ||
        status !== 'SUSPENDED' ||
        status !== 'ENDED'
      ) {
        return true;
      } else {
        return false;
      }
    });

    const isOneTime = computed(
      () => fundraiserResponse?.fundraiserType !== 'PERMANENT'
    );

    const socialmedialinks = computed(() =>
      fundraiserResponse.socialmedialinks.filter(({ link }) => !!link?.trim())
    );

    watch(props, ({ isOpen }) => {
      if (isOpen) document.body.classList.add('modal-open');
      else document.body.classList.remove('modal-open');
    });

    const formatNumber = (num) => {
      if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
      }
      if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
      }
      if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
      }
      return num;
    };

    const accounts = computed(() => {
      const result = [];
      fundraiserResponse.reservedaccounts.forEach((acc) =>
        acc.bankCode == '035' ? result.unshift(acc) : result.push(acc)
      );
      return result;
    });

    const slides = computed(() => {
      const { images, youTubeLink } = fundraiserResponse;
      return youTubeLink
        ? [
            ...images.slice().reverse(),
            { id: embededYoutubeId.value, youTubeLink },
          ]
        : images.slice().reverse();
    });

    const embededYoutubeId = computed(() =>
      getYouTubeVideoId(fundraiserResponse?.youTubeLink)
    );

    return {
      accounts,
      isOneTime,
      formatNumber,
      slides,
      fundraiserResponse,
      hasNotExpired,
      targetCoverage,
      randomColor,
      handleAddUpdate,
      RecurringFundraiser,
      dayjs,
      handleCopy,
      authUser,
      slug,
      isBlessModalOpen,
      socialmedialinks,
    };
  },
};
</script>

<style lang="scss" scoped>
.link-disabled {
  pointer-events: none;
  background-color: #dcdcdc;
  color: #c4c4c4;
}
</style>

<style lang="scss">
.carousel {
  &__pagination {
    @apply absolute bottom-2 w-1/2;
  }
  &__pagination-button {
    @apply rounded-full w-1.5 xs:w-2 h-1.5 xs:h-2;
  }
}

.nav-border {
  border: 4px solid transparent;
}

.nav-active {
  border-bottom-color: #577387;
}
//
</style>
