// import { createStore, createLogger } from 'vuex';
import { createStore } from 'vuex';
import auth from './modules/auth';

export default createStore({
  // plugins: [createLogger()],
  state: {},
  mutations: {},
  actions: {},
  modules: { auth },
});
