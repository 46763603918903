<template>
  <ProjectImageCrop
    v-if="cropImage"
    :file="file"
    @save="handleSave"
    @cancel="handleCancel"
    :freeAspect="true"
  />

  <div v-else class="" id="form">
    <div class="lg:hidden">
      <div class="text-sm">
        <p class="px-6 text-lg font-semibold text-text-primary mb-5">
          Sign up now as an Organisation.
        </p>
        <BStepperOrg :step="2" />
      </div>
      <div class="px-6">
        <form @submit.prevent="handleContinue">
          <div
            class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
          >
            <label>Contact Person*</label>

            <input
              class="
                border
                rounded
                border-border-primary
                mt-2
                mb-1
                p-3.75
                text-sm
                font-medium
                focus:outline-none
                input-shadow
              "
              v-model="contactperson"
              type="text"
            />
            <span
              v-for="error in v$.contactperson.$errors"
              :key="error.$uid"
              class="text-xs text-text-danger"
              >{{ error.$message }}</span
            >
          </div>

          <div
            class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
          >
            <label>Contact Phone Number*</label>
            <input
              class="
                border
                rounded
                border-border-primary
                mt-2
                mb-1
                p-3.75
                text-sm
                font-medium
                focus:outline-none
                input-shadow
              "
              type="number"
              placeholder="+234"
              v-model="phoneNumber"
            />
            <span
              v-for="error in v$.phoneNumber.$errors"
              :key="error.$uid"
              class="text-xs text-text-danger"
              >{{ error.$message }}</span
            >
          </div>

          <div
            class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
          >
            <label>Email*</label>
            <input
              class="
                border
                rounded
                border-border-primary
                mt-2
                mb-1
                p-3.75
                text-sm
                font-medium
                focus:outline-none
                input-shadow
              "
              type="email"
              placeholder=""
              v-model="email"
            />
            <span
              v-for="error in v$.email.$errors"
              :key="error.$uid"
              class="text-xs text-text-danger"
              >{{ error.$message }}</span
            >
          </div>

          <div
            class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
          >
            <label class="text-sm font-bold">Password*</label>
            <div class="w-full relative">
              <input
                class="
                  w-full
                  relative
                  border
                  rounded
                  border-border-primary
                  mt-2
                  mb-1
                  p-3.75
                  text-sm
                  font-medium
                  focus:outline-none
                  input-shadow
                "
                v-model="password"
                :type="show ? 'text' : 'password'"
              />

              <PasswordHiddenIcon
                v-if="!show"
                @click="show = true"
                class="absolute right-4 bottom-5"
              />
              <PasswordShownIcon
                v-else
                @click="show = false"
                class="absolute right-4 bottom-5"
              />
            </div>
            <span
              v-for="error in v$.password.$errors"
              :key="error.$uid"
              class="text-xs text-text-danger"
              >{{ error.$message }}</span
            >
          </div>

          <div
            class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
          >
            <label class="text-sm font-bold">Confirm Password*</label>
            <div class="w-full relative">
              <input
                class="
                  w-full
                  relative
                  border
                  rounded
                  border-border-primary
                  mt-2
                  mb-1
                  p-3.75
                  text-sm
                  font-medium
                  focus:outline-none
                  input-shadow
                "
                v-model="confirm_password"
                :type="show2 ? 'text' : 'password'"
              />
              <PasswordHiddenIcon
                v-if="!show2"
                @click="show2 = true"
                class="absolute right-4 bottom-5"
              />
              <PasswordShownIcon
                v-else
                @click="show2 = false"
                class="absolute right-4 bottom-5"
              />
            </div>
          </div>
          <p
            v-for="error in v$.confirm_password.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger font-semibold"
            >{{ error.$message }}</p
          >

          <div
            class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
          >
            <label class="mb-2">Means of Identification*</label>
            <BModalSelect v-model="idType" :options="idTypes" />
            <span
              v-for="error in v$.idType.$errors"
              :key="error.$uid"
              class="text-xs text-text-danger"
              >{{ error.$message }}</span
            >
          </div>

          <BButtonLoader class="mx-auto h-7 w-7 my-24" v-if="isCompressing" />
          <label
            class="
              flex flex-col
              justify-center
              items-center
              border border-border-primary
              rounded
              h-48
              my-4
              cursor-pointer
              font-semibold
              text-text-primary text-sm
            "
            v-else-if="!idFile"
          >
            <IDCardIcon class="my-4" />
            Click here to upload ID
            <input
              type="file"
              hidden
              accept="image/png,image/jpeg,image/jpg"
              @change="handleFileInput"
            />
          </label>
          <div
            class="
              flex flex-col
              justify-center
              items-center
              border border-border-primary
              rounded
              my-4
              p-4
            "
            v-else
          >
            <img class="h-40 sm:h-56 mb-4" :src="idFile.base64" alt="id_card" />
            <button
              class="text-sm font-semibold text-text-primary"
              @click="idFile = ''"
            >
              Remove
            </button>
          </div>
          <span
            v-for="error in v$.idFile.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger font-semibold"
            >{{ error.$message }}</span
          >

          <div
            class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
          >
            <label>Organisation Website*</label>

            <input
              class="
                border
                rounded
                border-border-primary
                mt-2
                mb-1
                p-3.75
                text-sm
                font-medium
                focus:outline-none
                input-shadow
              "
              placeholder="https://"
              v-model="organisationwebsite"
              type="text"
            />
            <span
              v-for="error in v$.organisationwebsite.$errors"
              :key="error.$uid"
              class="text-xs text-text-danger"
              >{{ error.$message }}</span
            >
          </div>

          <div
            class="flex flex-col my-5 text-text-primary font-semibold text-sm"
          >
            <div class="flex">
              <label>Organisation Social Media (optional)</label>
              <BToolTipBtn :details="infoData.socials" />
            </div>
            <div
              class="
                border
                rounded
                border-border-primary
                flex
                items-center
                overflow-hidden
                w-full
                my-1
                mt-2
              "
            >
              <FBIcon class="mx-3.5 h-6.5" />
              <input
                class="
                  p-4
                  w-full
                  border-l border-border-primary
                  text-sm
                  font-semibold
                  focus:outline-none
                "
                type="text"
                placeholder="https://"
                v-model.trim="facebook"
              />
            </div>
            <div
              class="
                border
                rounded
                border-border-primary
                flex
                items-center
                overflow-hidden
                w-full
                my-1
              "
            >
              <IGIcon class="mx-3.5 h-6.5" />
              <input
                class="
                  p-4
                  w-full
                  border-l border-border-primary
                  text-sm
                  font-semibold
                  focus:outline-none
                "
                type="text"
                placeholder="https://"
                v-model.trim="instagram"
              />
            </div>
            <div
              class="
                border
                rounded
                border-border-primary
                flex
                items-center
                overflow-hidden
                w-full
                my-1
              "
            >
              <TWIcon class="mx-3.5 h-6.5" />
              <input
                class="
                  p-4
                  w-full
                  border-l border-border-primary
                  text-sm
                  font-semibold
                  focus:outline-none
                "
                type="text"
                placeholder="https://"
                v-model.trim="twitter"
              />
            </div>

            <div
              class="
                border
                rounded
                border-border-primary
                flex
                items-center
                overflow-hidden
                w-full
                my-1
              "
            >
              <TKKIcon class="mx-3.5 h-6.5" />
              <input
                class="
                  p-4
                  w-full
                  border-l border-border-primary
                  text-sm
                  font-semibold
                  focus:outline-none
                "
                type="text"
                placeholder="https://"
                v-model.trim="tiktok"
              />
            </div>
          </div>

          <div class="flex flex-col pt-6">
            <button
              class="
                bg-btn-bg-primary
                text-btn-text-primary
                my-2
                py-4
                rounded
                text-sm
                font-bold
              "
              type="submit"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="hidden lg:flex mt-2 justify-center">
      <div class="b-card p-6">
        <div class="text-sm">
          <div class="flex mb-8">
            <button @click="handlePrevious">
              <LeftArrowIcon class="h-6 fill-current" />
            </button>
            <p class="px-6 text-lg font-semibold text-text-primary">
              Sign up now as an Organisation.
            </p>
          </div>
          <BStepperOrgLG :step="2" />
        </div>
        <div class="px-26 mt-12">
          <form @submit.prevent="handleContinue">
            <div
              class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
            >
              <label>Contact Person*</label>

              <input
                class="
                  border
                  rounded
                  border-border-primary
                  mt-2
                  mb-1
                  p-3.75
                  text-sm
                  font-medium
                  focus:outline-none
                  input-shadow
                "
                v-model="contactperson"
                type="text"
              />
              <span
                v-for="error in v$.contactperson.$errors"
                :key="error.$uid"
                class="text-xs text-text-danger"
                >{{ error.$message }}</span
              >
            </div>

            <div
              class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
            >
              <label>Contact Phone Number*</label>
              <input
                class="
                  border
                  rounded
                  border-border-primary
                  mt-2
                  mb-1
                  p-3.75
                  text-sm
                  font-medium
                  focus:outline-none
                  input-shadow
                "
                type="number"
                placeholder="+234"
                v-model="phoneNumber"
              />
              <span
                v-for="error in v$.phoneNumber.$errors"
                :key="error.$uid"
                class="text-xs text-text-danger"
                >{{ error.$message }}</span
              >
            </div>

            <div
              class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
            >
              <label>Email*</label>
              <input
                class="
                  border
                  rounded
                  border-border-primary
                  mt-2
                  mb-1
                  p-3.75
                  text-sm
                  font-medium
                  focus:outline-none
                  input-shadow
                "
                type="email"
                placeholder=""
                v-model="email"
              />
              <span
                v-for="error in v$.email.$errors"
                :key="error.$uid"
                class="text-xs text-text-danger"
                >{{ error.$message }}</span
              >
            </div>

            <div
              class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
            >
              <label class="text-sm font-bold">Password*</label>
              <div class="w-full relative">
                <input
                  class="
                    w-full
                    relative
                    border
                    rounded
                    border-border-primary
                    mt-2
                    mb-1
                    p-3.75
                    text-sm
                    font-medium
                    focus:outline-none
                    input-shadow
                  "
                  v-model="password"
                  :type="show ? 'text' : 'password'"
                />

                <PasswordHiddenIcon
                  v-if="!show"
                  @click="show = true"
                  class="absolute right-4 bottom-5"
                />
                <PasswordShownIcon
                  v-else
                  @click="show = false"
                  class="absolute right-4 bottom-5"
                />
              </div>
              <span
                v-for="error in v$.password.$errors"
                :key="error.$uid"
                class="text-xs text-text-danger"
                >{{ error.$message }}</span
              >
            </div>

            <div
              class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
            >
              <label class="text-sm font-bold">Confirm Password*</label>
              <div class="w-full relative">
                <input
                  class="
                    w-full
                    relative
                    border
                    rounded
                    border-border-primary
                    mt-2
                    mb-1
                    p-3.75
                    text-sm
                    font-medium
                    focus:outline-none
                    input-shadow
                  "
                  v-model="confirm_password"
                  :type="show2 ? 'text' : 'password'"
                />
                <PasswordHiddenIcon
                  v-if="!show2"
                  @click="show2 = true"
                  class="absolute right-4 bottom-5"
                />
                <PasswordShownIcon
                  v-else
                  @click="show2 = false"
                  class="absolute right-4 bottom-5"
                />
              </div>
            </div>
            <p
              v-for="error in v$.confirm_password.$errors"
              :key="error.$uid"
              class="text-xs text-text-danger font-semibold"
              >{{ error.$message }}</p
            >

            <div
              class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
            >
              <label class="mb-2">Means of Identification*</label>

              <BSelectInput v-model="idType" :options="idTypes" />
              <span
                v-for="error in v$.idType.$errors"
                :key="error.$uid"
                class="text-xs text-text-danger"
                >{{ error.$message }}</span
              >
            </div>

            <BButtonLoader class="mx-auto h-7 w-7 my-24" v-if="isCompressing" />
            <label
              class="
                flex flex-col
                justify-center
                items-center
                border border-border-primary
                rounded
                h-48
                my-4
                cursor-pointer
                font-semibold
                text-text-primary text-sm
              "
              v-else-if="!idFile"
            >
              <IDCardIcon class="my-4" />
              Click here to upload ID
              <input
                type="file"
                hidden
                accept="image/png,image/jpeg,image/jpg"
                @change="handleFileInput"
              />
            </label>
            <div
              class="
                flex flex-col
                justify-center
                items-center
                border border-border-primary
                rounded
                my-4
                p-4
              "
              v-else
            >
              <img
                class="h-40 sm:h-56 mb-4"
                :src="idFile.base64"
                alt="id_card"
              />
              <button
                class="text-sm font-semibold text-text-primary"
                @click="idFile = ''"
              >
                Remove
              </button>
            </div>
            <span
              v-for="error in v$.idFile.$errors"
              :key="error.$uid"
              class="text-xs text-text-danger font-semibold"
              >{{ error.$message }}</span
            >

            <div
              class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
            >
              <label>Organisation Website*</label>

              <input
                class="
                  border
                  rounded
                  border-border-primary
                  mt-2
                  mb-1
                  p-3.75
                  text-sm
                  font-medium
                  focus:outline-none
                  input-shadow
                "
                placeholder="https://"
                v-model="organisationwebsite"
                type="text"
              />
              <span
                v-for="error in v$.organisationwebsite.$errors"
                :key="error.$uid"
                class="text-xs text-text-danger"
                >{{ error.$message }}</span
              >
            </div>

            <div
              class="flex flex-col my-5 text-text-primary font-semibold text-sm"
            >
              <div class="flex">
                <label>Organisation Social Media (optional)</label>
                <BToolTipBtn :details="infoData.socials" />
              </div>
              <div
                class="
                  border
                  rounded
                  border-border-primary
                  flex
                  items-center
                  overflow-hidden
                  w-full
                  my-1
                  mt-2
                "
              >
                <FBIcon class="mx-3.5 h-6.5" />
                <input
                  class="
                    p-4
                    w-full
                    border-l border-border-primary
                    text-sm
                    font-semibold
                    focus:outline-none
                  "
                  type="text"
                  placeholder="https://"
                  v-model.trim="facebook"
                />
              </div>
              <div
                class="
                  border
                  rounded
                  border-border-primary
                  flex
                  items-center
                  overflow-hidden
                  w-full
                  my-1
                "
              >
                <IGIcon class="mx-3.5 h-6.5" />
                <input
                  class="
                    p-4
                    w-full
                    border-l border-border-primary
                    text-sm
                    font-semibold
                    focus:outline-none
                  "
                  type="text"
                  placeholder="https://"
                  v-model.trim="instagram"
                />
              </div>
              <div
                class="
                  border
                  rounded
                  border-border-primary
                  flex
                  items-center
                  overflow-hidden
                  w-full
                  my-1
                "
              >
                <TWIcon class="mx-3.5 h-6.5" />
                <input
                  class="
                    p-4
                    w-full
                    border-l border-border-primary
                    text-sm
                    font-semibold
                    focus:outline-none
                  "
                  type="text"
                  placeholder="https://"
                  v-model.trim="twitter"
                />
              </div>

              <div
                class="
                  border
                  rounded
                  border-border-primary
                  flex
                  items-center
                  overflow-hidden
                  w-full
                  my-1
                "
              >
                <TKKIcon class="mx-3.5 h-6.5" />
                <input
                  class="
                    p-4
                    w-full
                    border-l border-border-primary
                    text-sm
                    font-semibold
                    focus:outline-none
                  "
                  type="text"
                  placeholder="https://"
                  v-model.trim="tiktok"
                />
              </div>
            </div>

            <div class="grid grid-cols-3 items-center">
              <div class="col-span-1 pt-4">
                <a
                  href="javascript:void(0)"
                  @click="handlePrevious"
                  class="font-bold text-text-primary"
                  >Previous</a
                >
              </div>
              <div class="col-span-2">
                <div class="flex flex-col pt-6">
                  <button
                    class="
                      bg-btn-bg-primary
                      text-btn-text-primary
                      my-2
                      py-4
                      rounded
                      text-sm
                      font-bold
                    "
                    type="submit"
                    
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, reactive, computed } from 'vue';
import BStepperOrg from '../../../components/ui/BStepperOrg.vue';
import BModalSelect from '../../../components/ui/BModalSelect.vue';
import imageCompression from 'browser-image-compression';
import { useToast } from 'vue-toastification';
import BButtonLoader from '../../../components/ui/BButtonLoader.vue';
import ProjectImageCrop from '../../createProject/ProjectImageCrop.vue';
import BToolTipBtn from '../../../components/ui/BToolTipBtn.vue';
import infoData from '@/data/info.js';
import BStepperOrgLG from '../../../components/ui/BStepperOrgLG.vue';
import BSelectInput from '../../../components/ui/BSelectInput.vue';
import useVuelidate from '@vuelidate/core';
import {
  required,
  helpers,
  email,
  sameAs,
  minLength,
} from '@vuelidate/validators';

export default {
  name: 'StepOne',

  components: {
    BStepperOrg,
    BModalSelect,
    BButtonLoader,
    ProjectImageCrop,
    BToolTipBtn,
    BStepperOrgLG,
    BSelectInput,
  },

  props: ['user'],

  emits: ['next', 'reset'],

  setup(props, { emit }) {
    const steps = ref([{}]);
    const show = ref(false);
    const show2 = ref(false);
    const cropImage = ref(false);
    const file = ref('');
    const toast = useToast();

    const required$ = helpers.withMessage('This field is required', required);
    const formInput = reactive({
      idType: '',
      idFile: '',
      contactperson: '',
      phoneNumber: '',
      email: '',
      password: '',
      confirm_password: '',
      facebook: '',
      instagram: '',
      tiktok: '',
      twitter: '',
      organisationwebsite: '',
    });

    const rules = {
      idType: { required$ },
      idFile: { required$ },
      contactperson: { required$ },
      phoneNumber: { required$ },
      email: { required$, email },
      password: { required$, minLength: minLength(8) },
      confirm_password: {
        required$,
        sameAsPassword: sameAs(computed(() => formInput.password)),
      },
      facebook: {},
      instagram: {},
      tiktok: {},
      twitter: {},
      organisationwebsite: { required$ },
    };

    const v$ = useVuelidate(rules, formInput);

    const idTypes = [
      'International Passport',
      'Driver License',
      'National ID',
      'Voter’s Card',
    ];

    const isCompressing = ref(false);

    const isFilled = computed(() => {
      if (
        formInput.idFile &&
        formInput.idType &&
        formInput.contactperson &&
        formInput.phoneNumber &&
        formInput.email &&
        formInput.organisationwebsite &&
        formInput.password == formInput.confirm_password
      ) {
        return true;
      } else {
        return false;
      }
    });

    const toggleCropImage = () => {
      cropImage.value = !cropImage.value;
    };

    const handleFileInput = async ({ target }) => {
      try {
        isCompressing.value = true;
        let targetFile = target.files[0];
        file.value = await imageCompression(targetFile, { maxSizeMB: 0.3 });
        toggleCropImage();
      } catch (error) {
        console.log(error);
      } finally {
        isCompressing.value = false;
      }
    };

    const handleSave = (base64) => {
      const imageSize = Math.round(
        Buffer.from(base64.substring(base64.indexOf(',') + 1)).length / 1e3
      );
      if (imageSize > 1000) {
        toast.error(
          'Image size is too large, Maximum image size should be 1MB.'
        );
      } else {
        const { name } = file.value;
        formInput.idFile = { base64, name, mimetype: 'image/jpeg' };
        file.value = null;
      }
      toggleCropImage();
    };

    const handleCancel = () => {
      file.value = null;
      toggleCropImage();
    };
    const handleContinue = async () => {
      const result = await v$.value.$validate();
      if (!result) {
        document.getElementById('form').scrollIntoView({
          behavior: 'smooth',
        });
        return;
      }
      emit('next', formInput);
    };

    const handlePrevious = () => {
      emit('previous');
    };

    return {
      steps,
      ...toRefs(formInput),
      isFilled,
      show,
      show2,
      idTypes,
      handleContinue,
      handleFileInput,
      isCompressing,
      cropImage,
      file,
      handleSave,
      handleCancel,
      infoData,
      handlePrevious,
      v$,
    };
  },
};
</script>
<style lang="scss" scoped>
.decorated {
  &::after {
    background: #000;
    width: 100px;
  }
  // after:block
  //           after:bg-text-primary
  //           after:w-1
  //           after:h-10
  //           after:mx-auto
  //           after:my-2
}
</style>
