<template>
  <div class="">
    <div class="text-sm">
      <p class="px-6 text-lg font-semibold text-text-primary mb-5">
        Sign up now as an Individual.
      </p>
      <BStepper :step="2" />
    </div>
    <div class="px-6" id="form">
      <form @submit.prevent="handleContinue">
        <div class="flex flex-col mt-5 text-text-primary font-semibold text-sm">
          <label>Username/Nickname*</label>

          <input
            class="
              border
              rounded
              border-border-primary
              mt-2
              mb-1
              p-3.75
              text-sm
              font-medium
              focus:outline-none
              input-shadow
            "
            v-model="username"
            type="text"
          />
          <span
            v-for="error in v$.username.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
        </div>

        <div class="flex flex-col mt-5 text-text-primary font-semibold text-sm">
          <label>Email*</label>
          <input
            class="
              border
              rounded
              border-border-primary
              mt-2
              mb-1
              p-3.75
              text-sm
              font-medium
              focus:outline-none
              input-shadow
            "
            type="email"
            v-model="email"
          />
          <span
            v-for="error in v$.email.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
        </div>

        <div class="flex flex-col mt-5 text-text-primary font-semibold text-sm">
          <label>Password*</label>
          <div class="w-full relative">
            <input
              class="
                w-full
                relative
                border
                rounded
                border-border-primary
                mt-2
                mb-1
                p-3.75
                text-sm
                font-medium
                focus:outline-none
                input-shadow
              "
              v-model="password"
              :type="show ? 'text' : 'password'"
            />

            <PasswordHiddenIcon
              v-if="!show"
              @click="show = true"
              class="absolute right-4 bottom-5"
            />
            <PasswordShownIcon
              v-else
              @click="show = false"
              class="absolute right-4 bottom-5"
            />
          </div>
          <span
            v-for="error in v$.password.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
        </div>

        <div class="flex flex-col mt-5 text-text-primary font-semibold text-sm">
          <label>Confirm Password*</label>
          <div class="w-full relative">
            <input
              class="
                w-full
                relative
                border
                rounded
                border-border-primary
                mt-2
                mb-1
                p-3.75
                text-sm
                font-medium
                focus:outline-none
                input-shadow
              "
              v-model="confirm_password"
              :type="show2 ? 'text' : 'password'"
            />
            <PasswordHiddenIcon
              v-if="!show2"
              @click="show2 = true"
              class="absolute right-4 bottom-5"
            />
            <PasswordShownIcon
              v-else
              @click="show2 = false"
              class="absolute right-4 bottom-5"
            />
          </div>
          <span
            v-for="error in v$.confirm_password.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger font-semibold"
            >{{ error.$message }}</span
          >
        </div>

        <div class="flex flex-col mt-5 text-text-primary font-semibold text-sm">
          <label>Referral Code (optional)</label>

          <input
            class="
              border
              rounded
              border-border-primary
              mt-2
              mb-1
              p-3.75
              text-sm
              font-medium
              focus:outline-none
              input-shadow
            "
            v-model="referralCode"
            type="number"
          />
        </div>

        <div class="flex flex-col pt-6">
          <button
            class="
              bg-btn-bg-primary
              text-btn-text-primary
              my-2
              py-4
              rounded
              text-sm
              font-bold
            "
            type="submit"
          >
            Continue
          </button>
        </div>
      </form>
    </div>

    <!-- <BSpinner v-if="isLoading" text="Verifying" />
  
      <BSpinner v-else-if="bvnResponse.isLoading" text="Verifying BVN" /> -->
  </div>
</template>

<script>
import { ref, toRefs, reactive, computed } from 'vue';

// import BSpinner from '@/components/ui/BSpinner';

import BStepper from '../../../components/ui/BStepper.vue';
import useVuelidate from '@vuelidate/core';
import {
  required,
  helpers,
  email,
  minLength,
  sameAs,
} from '@vuelidate/validators';

export default {
  name: 'StepOne',

  components: {
    // BSpinner,
    BStepper,
  },

  props: ['user'],

  emits: ['next', 'reset'],

  setup(props, { emit }) {
    const steps = ref([{}]);
    const show = ref(false);
    const show2 = ref(false);
    const required$ = helpers.withMessage('This field is required', required);
    const formInput = reactive({
      username: '',
      email: '',
      password: '',
      confirm_password: '',
      referralCode: '',
    });

    const rules = () => {
      return {
        username: { required$ },
        email: { required$, email },
        password: { required$, minLength: minLength(8) },
        confirm_password: {
          required$,
          sameAsPassword: sameAs(computed(() => formInput.password)),
        },
      };
    };

    const v$ = useVuelidate(rules, formInput);

    const isFilled = computed(() => {
      if (
        formInput.username &&
        formInput.email &&
        formInput.password &&
        formInput.password == formInput.confirm_password
      ) {
        return true;
      } else {
        return false;
      }
    });

    const handleContinue = async () => {
      const result = await v$.value.$validate();
      if (!result) {
        document.getElementById('form').scrollIntoView({
          behavior: 'smooth',
        });
        return;
      }
      emit('next', formInput);
    };

    return {
      steps,
      ...toRefs(formInput),
      isFilled,
      show,
      show2,
      handleContinue,
      v$,
    };
  },
};
</script>
<style lang="scss" scoped>
.decorated {
  &::after {
    background: #000;
    width: 100px;
  }
  // after:block
  //           after:bg-text-primary
  //           after:w-1
  //           after:h-10
  //           after:mx-auto
  //           after:my-2
}
</style>
