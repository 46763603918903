<template>
  <div class="px-6 py-8">
   
    <div class=" lg:flex justify-center">
      <div class="b-card lg:px-26 lg:mt-20 lg:pt-10">
        <h2 class="font-semibold text-lg text-center">Forgot Password</h2>
        <form @submit.prevent="handleSubmit">
          <!-- <AuthInput label="Email" v-model="email" type="email" :required="true" /> -->

          <div class="flex flex-col my-6">
            <label class="text-text-primary font-semibold" for="email"
              >Email</label
            >
            <input
              class="
                border
                rounded
                my-2
                border-border-primary
                p-3.5
                text-sm
                font-medium
                focus:outline-none
                input-shadow
              "
              placeholder="Email"
              v-model.trim="email"
              required
              type="email"
              id="email"
            />
          </div>

          <button
            class="
              bg-btn-bg-primary
              text-btn-text-primary
              rounded
              w-full
              mt-8
              py-4
              font-bold
              text-sm
              focus:outline-none
            "
            type="submit"
            :disabled="isLoading"
          >
            <BButtonLoader class="mx-auto h-5 w-5" v-if="isLoading" />
            <span v-else>Reset Password</span>
          </button>
        </form>
      </div>
    </div>
  </div>
  <BModal :isOpen="isSuccess" @close="closeSuccessModal">
    <div class="text-center flex flex-col items-center pb-6">
      <SuccessIcon class="mb-6 mt-4 mx-auto" />
      <p class="text-lg max-w-sm text-text-primary font-semibold mt-4 mb-2">
        A password reset link has been sent to your email.
      </p>
      <p class="text-xs text-text-alternate-3 font-medium">
        Please check your email and follow the instructions.
      </p>

      <button
        class="
          bg-btn-bg-primary
          max-w-sm
          text-btn-text-primary
          rounded
          w-full
          mt-4
          py-4
          font-bold
          text-sm
          focus:outline-none
        "
        @click="closeSuccessModal"
      >
        Done
      </button>
    </div>
  </BModal>
  <BModal :isOpen="isFailure" @close="closeFailureModal">
    <div class="text-center flex flex-col items-center pb-4">
      <FailureIcon class="my-4 mx-auto" />
      <h2 class="text-xl text-text-primary font-semibold my-4">Sorry!</h2>
      <p class="text-xs text-text-alternate-3">
        {{ error }}
      </p>
      <button
        class="
          bg-btn-bg-primary
          max-w-sm
          text-btn-text-primary
          rounded
          w-full
          mt-8
          py-4
          font-bold
          text-sm
          focus:outline-none
        "
        @click="closeFailureModal"
      >
        Close
      </button>
    </div>
  </BModal>
</template>

<script>
import { ref, toRefs } from 'vue';
// import AuthInput from '@/components/ui/AuthInput';
import { useApi } from '@/cmp-functions/useApi';
import { forgetPassword } from '@/services/api';
import BModal from '@/components/ui/BModal';

export default {
  name: 'ForgotPassword',

  components: { BModal },

  setup() {
    const email = ref('');
    const [response, postForgetPassword, { setIsSuccess, setIsFailure }] =
      useApi(forgetPassword);

    const handleSubmit = () => {
      postForgetPassword({ email: email.value });
    };

    const closeSuccessModal = () => {
      setIsSuccess(false);
    };

    const closeFailureModal = () => {
      setIsFailure(false);
    };

    return {
      email,
      handleSubmit,
      ...toRefs(response),
      closeSuccessModal,
      closeFailureModal,
    };
  },
};
</script>
