<template>
  <div class="px-6 border-b pb-8 border-text-alternate-8">
    <!-- {{step}} -->
    <div class="flex justify-center">
      <div class="flex w-full justify-between items-center text-center">
        <span
          class="
            h-6
            w-6
            rounded-full
            bg-text-primary
            flex
            items-center
            justify-center
            font-bold
            text-text-secondary text-sm
          "
          >{{ step > 1 ? '&#10003;' : '1' }}</span
        >
        <p class="text-text-primary font-bold text-sm ml-2">Personal Information</p>
        <div class="flex-grow border-t mx-2 border-text-alternate-8"></div>
        <span
          class="
            h-6
            w-6
            rounded-full
            flex
            items-center
            justify-center
            font-bold
            text-text-secondary text-sm
          "
          :class="step >= 2 ? 'bg-text-primary' : 'bg-text-alternate-3'"
          >{{ step > 2 ? '&#10003;' : '2' }}</span
        >
        <p :class="step >= 2 ? 'text-text-primary' : 'text-text-alternate-3'" class=" font-bold text-sm ml-2">Proof of identity</p>
        <div class="flex-grow border-t mx-2 border-text-alternate-8"></div>
        <span
          :class="step >= 3 ? 'bg-text-primary' : 'bg-text-alternate-3'"
          class="
            h-6
            w-6
            rounded-full
            flex
            items-center
            justify-center
            font-bold
            text-text-secondary text-sm
          "
          >{{ step > 3 ? '&#10003;' : '3' }}</span
        >
        <p :class="step >= 2 ? 'text-text-primary' : 'text-text-alternate-3'" class=" font-bold text-sm ml-2">Bank Details</p>
        
      </div>
    </div>
 
  </div>
</template>

<script>
export default {
  props: ['step'],
};
</script>

<style></style>
