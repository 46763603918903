<template>
  <div
    class="bg-bg-primary rounded py-12 px-16 flex items-center justify-center h-80"
  >
    <span
      class="h-10 w-10 border-4 border-border-alternate-3 rounded-full loader"
    />
  </div>
</template>

<script>
export default {
  name: 'BPageLoader',
};
</script>

<style lang="scss" scoped>
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border-top-color: transparent;
  animation: spinner 0.8s linear infinite;
}
</style>
