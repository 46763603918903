<template>
  <div>
    <div class="lg:hidden">
      <div class="px-6 py-4 flex flex-col lg:mt-32">
        <header
          class="
            flex
            mb-4
            items-center
            font-semibold
            text-lg
            flex
            text-text-alternate-1
            pr-6
          "
        >
          <button @click="$router.go(-1)">
            <LeftArrowIcon class="h-6 fill-current" />
          </button>
          <h2 class="mx-auto">Filter</h2>
        </header>

        <BPageLoader v-if="isLoading" class="flex-grow" />

        <div class="flex-grow" v-else>
          <div
            class="flex flex-col mb-4 text-text-primary font-semibold text-sm"
          >
            <label>Country</label>
            <BModalSelect
              class="my-2"
              :options="countryList"
              v-model="filterOptions.country"
            />
          </div>
          <div
            class="flex flex-col my-2 text-text-primary font-semibold text-sm"
          >
            <label>Minimum Raised Amount</label>
            <input
              class="my-6 rounded h-1 border border-border-primary slider"
              type="range"
              :min="0"
              :max="5000000"
              :step="50000"
              v-model.number="filterOptions.amount"
            />
            <span
              class="
                rounded
                border border-border-primary
                px-4
                py-3.5
                min-w-36
                max-w-min
                font-medium
              "
            >
              ₦{{ filterOptions.amount.toLocaleString() }}
            </span>
          </div>
          <div
            class="
              flex flex-col
              mt-6
              mb-4
              text-text-primary
              font-semibold
              text-sm
            "
          >
            <label>Category</label>
            <BModalSelect
              class="my-2"
              :options="categoryList"
              v-model="filterOptions.category"
            />
          </div>
          <div
            class="flex flex-col my-4 text-text-primary font-semibold text-sm"
          >
            <label>Fundraiser Type</label>
            <BModalSelect
              class="my-2"
              :options="typeList"
              v-model="filterOptions.type"
            />
          </div>
          <div class="flex flex-col py-2">
            <button
              class="
                bg-btn-bg-primary
                text-btn-text-primary
                rounded
                py-4
                font-bold
                text-sm
                my-2
              "
              @click="applyFilter"
            >
              Apply Filter
            </button>
            <button
              class="
                font-bold
                text-sm text-text-primary
                rounded
                border
                py-4
                my-2
              "
              @click="resetFilter"
            >
              Reset Filter
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="lg:flex justify-center hidden">
      <div class="b-card mt-28 py-8">
        <div class="px-26 py-4 flex flex-col">
          <header
            class="
              flex
              mb-4
              items-center
              font-semibold
              text-lg
              flex
              text-text-alternate-1
              pr-6
            "
          >
            <button @click="$router.go(-1)">
              <LeftArrowIcon class="h-6 fill-current" />
            </button>
            <h2 class="mx-auto">Filter</h2>
          </header>

          <BPageLoader v-if="isLoading" class="flex-grow" />

          <div class="flex-grow" v-else>
            <div
              class="flex flex-col mb-4 text-text-primary font-semibold text-sm"
            >
              <label>Country</label>

              <BSelectInput
                class="my-2"
                :options="countryList"
                v-model="filterOptions.country"
              />
            </div>
            <div
              class="flex flex-col my-2 text-text-primary font-semibold text-sm"
            >
              <label>Minimum Raised Amount</label>
              <input
                class="my-6 rounded h-1 border border-border-primary slider"
                type="range"
                :min="0"
                :max="5000000"
                :step="50000"
                v-model.number="filterOptions.amount"
              />
              <span
                class="
                  rounded
                  border border-border-primary
                  px-4
                  py-3.5
                  min-w-36
                  max-w-min
                  font-medium
                "
              >
                ₦{{ filterOptions.amount.toLocaleString() }}
              </span>
            </div>
            <div
              class="
                flex flex-col
                mt-6
                mb-4
                text-text-primary
                font-semibold
                text-sm
              "
            >
              <label>Category</label>

              <BSelectInput
                class="my-2"
                :options="categoryList"
                v-model="filterOptions.category"
              />
            </div>
            <div
              class="flex flex-col my-4 text-text-primary font-semibold text-sm"
            >
              <label>Fundraiser Type</label>

              <BSelectInput
                class="my-2"
                :options="typeList"
                v-model="filterOptions.type"
              />
            </div>
            <div class="flex flex-col py-2">
              <button
                class="
                  bg-btn-bg-primary
                  text-btn-text-primary
                  rounded
                  py-4
                  font-bold
                  text-sm
                  my-2
                "
                @click="applyFilter"
              >
                Apply Filter
              </button>
              <button
                class="
                  font-bold
                  text-sm text-text-primary
                  rounded
                  border
                  py-4
                  my-2
                "
                @click="resetFilter"
              >
                Reset Filter
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, toRefs } from 'vue';
import BModalSelect from '@/components/ui/BModalSelect';
import { useAppState } from '@/cmp-functions/appState.js';
import { useRouter } from 'vue-router';
import BSelectInput from '../components/ui/BSelectInput.vue';
const typeList = ['Any', 'One-Time', 'Recurring'];
const defaultOptions = {
  amount: 0,
  category: 'All Categories',
  type: 'Any',
  country: 'Nigeria',
};
const countryList = ['Nigeria'];
const countryMap = { Nigeria: 'ng' };

export default {
  name: 'Filter',

  components: { BModalSelect, BSelectInput },

  setup() {
    const filterOptions = ref({ ...defaultOptions });
    const router = useRouter();
    const { categories: response } = useAppState();

    const categoryList = computed(() => {
      const list = ['All Categories'];
      response.data?.forEach(({ categoryname }) => list.push(categoryname));
      return list;
    });

    const categoryId = computed(() => {
      const { id = '' } =
        response.data?.find(
          ({ categoryname }) => categoryname === filterOptions.value.category
        ) || {};
      if (id) return id;
      return '';
    });

    const resetFilter = () => {
      filterOptions.value = { ...defaultOptions };
    };

    const applyFilter = () => {
      const { amount, type, country } = filterOptions.value;
      const query = {};
      const params = {};
      if (amount) query.amount = amount;
      if (type !== 'Any') query.type = type;
      if (categoryId.value) {
        query.category = categoryId.value;
      } else {
        params.filter = 'latest';
      }
      if (country) query.country = countryMap[country];

      router.push({ name: 'Feeds', query, params });
    };

    return {
      ...toRefs(response),
      resetFilter,
      applyFilter,
      countryList,
      filterOptions,
      typeList,
      categoryList,
    };
  },
};
</script>
