<template>
  <ProjectImageCrop
    v-if="cropImage"
    :file="file"
    @save="handleSave"
    @cancel="handleCancel"
    :freeAspect="true"
    class="lg:mx-24"
  />

  <ProjectImageCrop
    v-else-if="cropImageLogo"
    :file="file"
    @save="handleSaveLogo"
    @cancel="handleCancelLogo"
    :freeAspect="true"
  />
  <div v-else class="" id="form">
    <div class="lg:hidden">
      <div class="text-sm">
        <p class="px-6 text-lg font-semibold text-text-primary mb-5">
          Sign up now as an Organisation.
        </p>
        <BStepperOrg :step="1" />
      </div>
      <div class="px-6">
        <form @submit.prevent="handleContinue">
          <div
            class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
          >
            <label>Name of Organisation*</label>

            <input
              class="
                border
                rounded
                border-border-primary
                mt-2
                mb-1
                p-3.75
                text-sm
                font-medium
                focus:outline-none
                input-shadow
              "
              v-model="nameoforg"
              type="text"
            />
            <span
            v-for="error in v$.nameoforg.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
          </div>

          <div
            class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
          >
            <label>RC Number*</label>
            <input
              class="
                border
                rounded
                border-border-primary
                mt-2
                mb-1
                p-3.75
                text-sm
                font-medium
                focus:outline-none
                input-shadow
              "
              type="number"
              v-model="rcnumber"
            />
            <span
            v-for="error in v$.rcnumber.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
          </div>

          <div
            class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
          >
            <label>Brand Name*</label>

            <input
              class="
                border
                rounded
                border-border-primary
                mt-2
                mb-1
                p-3.75
                text-sm
                font-medium
                focus:outline-none
                input-shadow
              "
              v-model="username"
              type="text"
            />
            <span
            v-for="error in v$.username.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
          </div>

          <div
            class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
          >
            <label class="">CAC Certificate*</label>
            <BButtonLoader class="mx-auto h-7 w-7 my-24" v-if="isCompressing" />
            <label
              class="
                flex flex-col
                justify-center
                items-center
                border border-border-primary
                rounded
                h-48
                my-4
                cursor-pointer
                font-semibold
                text-text-primary text-sm
              "
              v-else-if="!cacCertificate"
            >
              <IDCardIcon class="my-4" />
              Click here to upload
              <input
                type="file"
                hidden
                accept="image/png,image/jpeg,image/jpg"
                @change="handleFileInput"
              />
            </label>
            <div
              class="
                flex flex-col
                justify-center
                items-center
                border border-border-primary
                rounded
                my-4
                p-4
              "
              v-else
            >
              <img
                class="h-40 sm:h-56 mb-4"
                :src="cacCertificate.base64"
                alt="id_card"
              />
              <button
                class="text-sm font-semibold text-text-primary"
                @click="cacCertificate = ''"
              >
                Remove
              </button>
            </div>
          </div>
          <span
            v-for="error in v$.cacCertificate.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger font-semibold"
            >{{ error.$message }}</span
          >

          <div
            class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
          >
            <label class="">Logo*</label>
            <BButtonLoader
              class="mx-auto h-7 w-7 my-24"
              v-if="isCompressingLogo"
            />
            <label
              class="
                flex flex-col
                justify-center
                items-center
                border border-border-primary
                rounded
                h-48
                my-4
                cursor-pointer
                font-semibold
                text-text-primary text-sm
              "
              v-else-if="!logo"
            >
              <IDCardIcon class="my-4" />
              Click here to upload
              <input
                type="file"
                hidden
                accept="image/png,image/jpeg,image/jpg"
                @change="handleFileInputLogo"
              />
            </label>
            <div
              class="
                flex flex-col
                justify-center
                items-center
                border border-border-primary
                rounded
                my-4
                p-4
              "
              v-else
            >
              <img class="h-40 sm:h-56 mb-4" :src="logo.base64" alt="logo" />
              <button
                class="text-sm font-semibold text-text-primary"
                @click="logo = ''"
              >
                Remove
              </button>
            </div>
          </div>
          <span
            v-for="error in v$.logo.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger font-semibold"
            >{{ error.$message }}</span
          >

          <div
            class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
          >
            <label>Full Address*</label>

            <input
              class="
                border
                rounded
                border-border-primary
                mt-2
                mb-1
                p-3.75
                text-sm
                font-medium
                focus:outline-none
                input-shadow
              "
              v-model="address"
              type="text"
            />
            <span
            v-for="error in v$.address.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
          </div>

          <div
            class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
          >
            <label class="mb-2">State/Province*</label>

            <BModalSelect
              v-model="state"
              placeholder="Choose state"
              :options="states"
            />
            <span
            v-for="error in v$.state.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
          </div>

          <div
            class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
          >
            <label class="mb-2">Country*</label>

            <BModalSelect
              v-model="country"
              placeholder="Choose country"
              :options="countryList"
            />
            <span
            v-for="error in v$.country.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
          </div>

          <div class="flex flex-col pt-6">
            <button
              class="
                bg-btn-bg-primary
                text-btn-text-primary
                my-2
                py-4
                rounded
                text-sm
                font-bold
              "
              type="submit"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>

    <div class="lg:flex mt-2 justify-center hidden">
      <div class="b-card p-6">
        <div class="text-sm">
          <div class="flex mb-8">
            <button @click="handlePrevious">
              <LeftArrowIcon class="h-6 fill-current" />
            </button>
            <p class="px-6 text-lg font-semibold text-text-primary">
              Sign up now as an Organisation.
            </p>
          </div>
          <BStepperOrgLG :step="1" />
        </div>
        <div class="px-26 mt-12">
          <form @submit.prevent="handleContinue">
            <div
              class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
            >
              <label>Name of Organisation*</label>

              <input
                class="
                  border
                  rounded
                  border-border-primary
                  mt-2
                  mb-1
                  p-3.75
                  text-sm
                  font-medium
                  focus:outline-none
                  input-shadow
                "
                v-model="nameoforg"
                type="text"
              />
              <span
            v-for="error in v$.nameoforg.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
            </div>

            <div
              class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
            >
              <label>RC Number*</label>
              <input
                class="
                  border
                  rounded
                  border-border-primary
                  mt-2
                  mb-1
                  p-3.75
                  text-sm
                  font-medium
                  focus:outline-none
                  input-shadow
                "
                type="number"
                v-model="rcnumber"
              />
              <span
            v-for="error in v$.rcnumber.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
            </div>

            <div
              class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
            >
              <label>Brand Name*</label>

              <input
                class="
                  border
                  rounded
                  border-border-primary
                  mt-2
                  mb-1
                  p-3.75
                  text-sm
                  font-medium
                  focus:outline-none
                  input-shadow
                "
                v-model="username"
                type="text"
              />
              <span
            v-for="error in v$.username.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
            </div>

            <div
              class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
            >
              <label class="">CAC Certificate*</label>
              <BButtonLoader
                class="mx-auto h-7 w-7 my-24"
                v-if="isCompressing"
              />
              <label
                class="
                  flex flex-col
                  justify-center
                  items-center
                  border border-border-primary
                  rounded
                  h-48
                  my-4
                  cursor-pointer
                  font-semibold
                  text-text-primary text-sm
                "
                v-else-if="!cacCertificate"
              >
                <IDCardIcon class="my-4" />
                Click here to upload
                <input
                  type="file"
                  hidden
                  accept="image/png,image/jpeg,image/jpg"
                  @change="handleFileInput"
                />
              </label>
              <div
                class="
                  flex flex-col
                  justify-center
                  items-center
                  border border-border-primary
                  rounded
                  my-4
                  p-4
                "
                v-else
              >
                <img
                  class="h-40 sm:h-56 mb-4"
                  :src="cacCertificate.base64"
                  alt="id_card"
                />
                <button
                  class="text-sm font-semibold text-text-primary"
                  @click="cacCertificate = ''"
                >
                  Remove
                </button>
              </div>
            </div>
            <span
            v-for="error in v$.cacCertificate.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger font-semibold"
            >{{ error.$message }}</span
          >

            <div
              class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
            >
              <label class="">Logo*</label>
              <BButtonLoader
                class="mx-auto h-7 w-7 my-24"
                v-if="isCompressingLogo"
              />
              <label
                class="
                  flex flex-col
                  justify-center
                  items-center
                  border border-border-primary
                  rounded
                  h-48
                  my-4
                  cursor-pointer
                  font-semibold
                  text-text-primary text-sm
                "
                v-else-if="!logo"
              >
                <IDCardIcon class="my-4" />
                Click here to upload
                <input
                  type="file"
                  hidden
                  accept="image/png,image/jpeg,image/jpg"
                  @change="handleFileInputLogo"
                />
              </label>
              <div
                class="
                  flex flex-col
                  justify-center
                  items-center
                  border border-border-primary
                  rounded
                  my-4
                  p-4
                "
                v-else
              >
                <img class="h-40 sm:h-56 mb-4" :src="logo.base64" alt="logo" />
                <button
                  class="text-sm font-semibold text-text-primary"
                  @click="logo = ''"
                >
                  Remove
                </button>
              </div>
            </div>
            <span
            v-for="error in v$.logo.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger font-semibold"
            >{{ error.$message }}</span
          >

            <div
              class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
            >
              <label>Full Address*</label>

              <input
                class="
                  border
                  rounded
                  border-border-primary
                  mt-2
                  mb-1
                  p-3.75
                  text-sm
                  font-medium
                  focus:outline-none
                  input-shadow
                "
                v-model="address"
                type="text"
              />
              <span
            v-for="error in v$.address.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
            </div>

            <div
              class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
            >
              <label class="mb-2">State/Province*</label>

              <BSelectInput
                v-model="state"
                placeholder="Choose state"
                :options="states"
              />
              <span
            v-for="error in v$.state.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
            </div>

            <div
              class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
            >
              <label class="mb-2">Country*</label>

              <BSelectInput
                v-model="country"
                placeholder="Choose country"
                :options="countryList"
              />
              <span
            v-for="error in v$.country.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
            </div>

            <div class="grid grid-cols-3 items-center">
              <div class="col-span-1 pt-4">
                <a
                  href="javascript:void(0)"
                  @click="handlePrevious"
                  class="font-bold text-text-primary"
                  >Previous</a
                >
              </div>
              <div class="col-span-2">
                <div class="flex flex-col pt-6">
                  <button
                    class="
                      bg-btn-bg-primary
                      text-btn-text-primary
                      my-2
                      py-4
                      rounded
                      text-sm
                      font-bold
                    "
                    type="submit"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, reactive, computed } from 'vue';
import BStepperOrg from '../../../components/ui/BStepperOrg.vue';
import BButtonLoader from '../../../components/ui/BButtonLoader.vue';
import BModalSelect from '../../../components/ui/BModalSelect.vue';
import ProjectImageCrop from '../../createProject/ProjectImageCrop.vue';
import imageCompression from 'browser-image-compression';
import { useToast } from 'vue-toastification';
import BStepperOrgLG from '../../../components/ui/BStepperOrgLG.vue';
import BSelectInput from '../../../components/ui/BSelectInput.vue';

import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

// import BSpinner from '@/components/ui/BSpinner';

export default {
  name: 'StepOne',

  components: {
    BStepperOrg,
    BButtonLoader,
    BModalSelect,
    ProjectImageCrop,
    BStepperOrgLG,
    BSelectInput,
  },

  emits: ['next', 'reset'],

  setup(props, { emit }) {
    const steps = ref([{}]);
    const cropImage = ref(false);
    const cropImageLogo = ref(false);
    const file = ref('');
    const isCompressing = ref(false);
    const isCompressingLogo = ref(false);

    const required$ = helpers.withMessage('This field is required', required);

    const formInput = reactive({
      nameoforg: '',
      rcnumber: '',
      username: '',
      address: '',
      state: '',
      country: '',
      cacCertificate: '',
      logo: '',
    });

    const rules = {
      nameoforg: { required$ },
      rcnumber: { required$ },
      username: { required$ },
      address: { required$ },
      state: { required$ },
      country: { required$ },
      cacCertificate: { required$ },
      logo: { required$ },
    };
    const v$ = useVuelidate(rules, formInput);
    const states = [
      'Abia',
      'Adamawa',
      'Akwa Ibom',
      'Anambra',
      'Bauchi',
      'Bayelsa',
      'Benue',
      'Borno',
      'Cross River',
      'Delta',
      'Ebonyi',
      'Edo',
      'Ekiti',
      'Enugu',
      'FCT - Abuja',
      'Gombe',
      'Imo',
      'Jigawa',
      'Kaduna',
      'Kano',
      'Katsina',
      'Kebbi',
      'Kogi',
      'Kwara',
      'Lagos',
      'Nasarawa',
      'Niger',
      'Ogun',
      'Ondo',
      'Osun',
      'Oyo',
      'Plateau',
      'Rivers',
      'Sokoto',
      'Taraba',
      'Yobe',
      'Zamfara',
    ];

    const toast = useToast();

    const countryList = ['Nigeria'];

    const isFilled = computed(() => {
      if (
        formInput.nameoforg &&
        formInput.rcnumber &&
        formInput.username &&
        formInput.address &&
        formInput.state &&
        formInput.country &&
        formInput.cacCertificate &&
        formInput.logo
      ) {
        return true;
      } else {
        return false;
      }
    });

    const toggleCropImage = () => {
      cropImage.value = !cropImage.value;
    };

    const toggleCropImageLogo = () => {
      cropImageLogo.value = !cropImageLogo.value;
    };

    const handleFileInput = async ({ target }) => {
      try {
        isCompressing.value = true;
        let targetFile = target.files[0];
        file.value = await imageCompression(targetFile, { maxSizeMB: 0.3 });
        toggleCropImage();
      } catch (error) {
        console.log(error);
      } finally {
        isCompressing.value = false;
      }
    };

    const handleFileInputLogo = async ({ target }) => {
      try {
        isCompressingLogo.value = true;
        let targetFile = target.files[0];
        file.value = await imageCompression(targetFile, { maxSizeMB: 0.3 });
        toggleCropImageLogo();
      } catch (error) {
        console.log(error);
      } finally {
        isCompressingLogo.value = false;
      }
    };

    const handleSave = (base64) => {
      const imageSize = Math.round(
        Buffer.from(base64.substring(base64.indexOf(',') + 1)).length / 1e3
      );
      if (imageSize > 1000) {
        toast.error(
          'Image size is too large, Maximum image size should be 1MB.'
        );
      } else {
        const { name } = file.value;
        formInput.cacCertificate = { base64, name, mimetype: 'image/jpeg' };
        file.value = null;
      }
      toggleCropImage();
    };

    const handleSaveLogo = (base64) => {
      const imageSize = Math.round(
        Buffer.from(base64.substring(base64.indexOf(',') + 1)).length / 1e3
      );
      if (imageSize > 1000) {
        toast.error(
          'Image size is too large, Maximum image size should be 1MB.'
        );
      } else {
        const { name } = file.value;
        formInput.logo = { base64, name, mimetype: 'image/jpeg' };
        file.value = null;
      }
      toggleCropImageLogo();
    };

    const handleCancel = () => {
      file.value = null;
      toggleCropImage();
    };

    const handleCancelLogo = () => {
      file.value = null;
      toggleCropImageLogo();
    };

    const handleContinue = async () => {
      const result = await v$.value.$validate();
      if (!result) {
        document.getElementById('form').scrollIntoView({
          behavior: 'smooth',
        });
        return;
      }
      emit('next', formInput);
    };

    const handlePrevious = () => {
      emit('previous');
    };

    return {
      steps,
      ...toRefs(formInput),
      isFilled,
      handleContinue,
      handleFileInput,
      isCompressing,
      isCompressingLogo,
      cropImage,
      cropImageLogo,
      file,
      states,
      countryList,
      handleSave,
      handleCancel,
      handleFileInputLogo,
      handleSaveLogo,
      handlePrevious,
      handleCancelLogo,
      v$
    };
  },
};
</script>
<style lang="scss" scoped>
.decorated {
  &::after {
    background: #000;
    width: 100px;
  }
  // after:block
  //           after:bg-text-primary
  //           after:w-1
  //           after:h-10
  //           after:mx-auto
  //           after:my-2
}
</style>
