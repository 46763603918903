<template>
  <div class="">
    <div class="text-sm">
      <BStepper :step="4" />
    </div>
    <div class="px-6">
      <p class="text-primary mt-4 text-sm font-normal text-text-primary">
        Welcome
        <span class="font-semibold">
          {{ user.firstname }} {{ user.lastname }}</span
        >
      </p>
      <p class="text-sm font-medium text-text-alternate-7 mt-4">
        Provide your bank account details where we will send
        donations/blessings. Note: This must be a personal bank account
      </p>
      <form @submit.prevent="handleSubmit">
        <div class="flex flex-col mt-5 text-text-primary font-semibold text-sm">
          <!-- {{bvn}} -->
          <label>BVN</label>

          <input
            class="
              border
              rounded
              border-border-primary
              mt-2
              mb-1
              p-3.75
              text-sm
              font-medium
              focus:outline-none
              input-shadow
            "
            maxlength="11"
            v-model="bvn"
            type="number"
          />
        </div>

        <div class="flex flex-col mt-5 text-text-primary font-semibold text-sm">
          <label>Bank Account Number</label>
          <input
            maxlength="10"
            class="
              border
              rounded
              border-border-primary
              mt-2
              mb-1
              p-3.75
              text-sm
              font-medium
              focus:outline-none
              input-shadow
            "
            v-model="banknumber"
            type=""
          />
        </div>

        <div class="flex flex-col mt-5 text-text-primary font-semibold text-sm">
          <label class="mb-2">Bank</label>
          <BModalSelect v-model="bank" :options="bankNames" />
        </div>

        <div class="flex flex-col pt-6">
          <button
            class="
              bg-btn-bg-primary
              text-btn-text-primary
              my-2
              py-4
              rounded
              text-sm
              font-bold
            "
            type="submit"
            :disabled="!canGoNext"
          >
            Continue
          </button>
        </div>
      </form>
    </div>

    <BSpinner v-if="isLoading" text="Verifying" />

    <BSpinner v-else-if="bvnResponse.isLoading" text="Verifying BVN" />

    <BModal @close="closeVerificationModal" :isOpen="isFailure || isSuccess">
      <div class="text-center flex flex-col items-center" v-if="isFailure">
        <FailureIcon class="my-4 mx-auto" />
        <h2 class="text-xl text-text-primary font-semibold my-4">Sorry!</h2>
        <p class="text-xs text-text-alternate-3">
          We couldn’t verify your bank details, please try again
        </p>
        <button
          class="
            bg-btn-bg-primary
            max-w-sm
            text-btn-text-primary
            rounded
            w-full
            mt-8
            py-4
            font-bold
            text-sm
            focus:outline-none
          "
          @click="closeVerificationModal"
        >
          Retry
        </button>
      </div>
      <div v-else-if="isSuccess">
        <p class="text-text-alternate-3 text-xs font-medium my-4">
          Please confirm that this is your withdrawal account details
        </p>
        <div class="border rounded border-border-primary p-4 text-xs flex">
          <BankIcon class="h-6 fill-current" style="fill: #323232" />
          <div class="flex flex-col justify-between pl-3">
            <p class="font-semibold text-text-primary truncate uppercase">
              {{ data.account_name }}
            </p>
            <p class="text-text-alternate-3 font-semibold my-1">
              {{ data.account_number }}
            </p>
            <p class="text-text-alternate-3 font-medium uppercase">
              {{ bank.value }}
            </p>
          </div>
        </div>
        <div class="text-center">
          <button
            class="
              bg-btn-bg-primary
              focus:outline-none
              text-btn-text-primary
              p-4
              w-full
              rounded
              text-center text-sm
              font-bold
              my-6
            "
            @click="isSuccess = false"
          >
            Confirm
          </button>
          <button
            class="
              focus:outline-none
              rounded
              text-center text-sm
              font-semibold
              mx-auto
            "
            @click="closeVerificationModal"
          >
            Change Bank Details
          </button>
        </div>
      </div>
    </BModal>
    <BModal
      @close="bvnResponse.isFailure = false"
      :isOpen="bvnResponse.isFailure"
    >
      <div class="text-center flex flex-col items-center">
        <FailureIcon class="my-4 mx-auto" />
        <h2 class="text-xl text-text-primary font-semibold my-4">Sorry!</h2>
        <p class="text-xs text-text-alternate-3">
          The BVN does not match your bank details, please try again
        </p>
        <button
          class="
            bg-btn-bg-primary
            max-w-sm
            text-btn-text-primary
            rounded
            w-full
            mt-8
            py-4
            font-bold
            text-sm
            focus:outline-none
          "
          @click="bvnResponse.isFailure = false"
        >
          Retry
        </button>
      </div>
    </BModal>

    <BSpinner v-if="signingUp" text="Signing you up" />

    <BModal @close="handleLogin" :isOpen="isOpen">
      <div class="text-center flex flex-col items-center pb-6">
        <SuccessIcon class="mb-8 mt-4 mx-auto" />
        <p class="text-lg max-w-sm text-text-primary font-semibold my-4">
          Registration complete.
        </p>
        <p class="text-xs text-text-alternate-3 font-medium">
          Please check your email to verify your account
        </p>

        <button
          class="
            bg-btn-bg-primary
            max-w-sm
            text-btn-text-primary
            rounded
            w-full
            mt-8
            py-4
            font-bold
            text-sm
            focus:outline-none
          "
          @click="handleLogin"
        >
          Done
        </button>
      </div>
    </BModal>
  </div>
</template>

<script>
import { ref, toRefs, reactive, computed, watch } from 'vue';

// import BSpinner from '@/components/ui/BSpinner';

import BStepper from '../../../components/ui/BStepper.vue';
import BModalSelect from '../../../components/ui/BModalSelect.vue';
import { banks } from '@/data/banks';
import { useRouter } from 'vue-router';
import { useApi } from '@/cmp-functions/useApi';
import { verifyAccount, verifyBVN, registerUser } from '@/services/api';
import BSpinner from '../../../components/ui/BSpinner.vue';
import BModal from '../../../components/ui/BModal.vue';

export default {
  name: 'StepOne',

  components: {
    // BSpinner,
    BStepper,
    BModalSelect,
    BSpinner,
    BModal,
  },

  props: ['user'],

  emits: ['next', 'reset'],

  setup(props, { emit }) {
    const steps = ref([{}]);
    const show = ref(false);
    const show2 = ref(false);
    const bankNames = computed(() => banks.map(({ name }) => name));
    const router = useRouter();
    const signingUp = ref(false);
    const formInput = reactive({
      bank: '',
      banknumber: '',
    });
    const bvn = ref('');

    const isFilled = computed(() => {
      if (
        formInput.username &&
        formInput.email &&
        formInput.password &&
        formInput.password == formInput.confirm_password
      ) {
        return true;
      } else {
        return false;
      }
    });

    const handleContinue = () => {
      emit('next');
    };

    watch(formInput, ({ bank, banknumber }) => {
      if (banknumber.length === 10 && bank) {
        handleVerification();
      } else {
        closeVerificationModal();
      }
    });
    const bankDetails = computed(() =>
      banks.find(({ name }) => name === formInput.bank)
    );

    const handleSubmit = () => {
      const { firstname, lastname } = props.user;
      const { code: bankcode } = bankDetails.value;
      const payload = {
        bvn: bvn.value,
        accountNumber: verificationResponse.data.account_number,
        firstName: firstname,
        lastName: lastname,
        bankCode: bankcode,
      };
      verifyUserBVN(payload);
    };

    const closeVerificationModal = () => {
      verificationResponse.data = null;
      verificationResponse.isFailure = false;
      verificationResponse.isSuccess = false;
    };
    const [bvnResponse, verifyUserBVN] = useApi(verifyBVN);

    watch(bvnResponse, ({ isSuccess }) => {
      if (isSuccess) {
        const { code: bankcode, name: bankname } = bankDetails.value;
        const payload = {
          bvn: bvn.value,
          bankcode,
          bankname,
          accountnumber: verificationResponse.data.account_number,
          accountname: verificationResponse.data.account_name,
        };
        console.log(props.user);
        console.log(payload);
        signingUp.value = true;

        let phonenumber = props.user.phoneNumber;
        phonenumber =
          phonenumber[0] === '+'
            ? phonenumber
            : phonenumber[0] === '0'
            ? `+234${phonenumber.slice(1)}`
            : `+234${phonenumber}`;
        signupUser({
          ...props.user,
          ...formInput,
          phonenumber,
        });
      }
    });
    const closeModal = () => {
      signupResponse.isSuccess = false;
      emit('reset');
    };
    const handleLogin = () => {
      closeModal();
      router.push({ name: 'Feeds' });
    };
    const [signupResponse, signupUser] = useApi(registerUser);

    const canGoNext = computed(() => verificationResponse.data && bvn.value);

    const [
      verificationResponse,
      verifyBankAccount,
      { setIsFailure: setIsVerificationFailure },
    ] = useApi(verifyAccount);
    const handleVerification = () => {
      setIsVerificationFailure(false);
      const { code: bankcode } = bankDetails.value;
      verifyBankAccount({
        account_bank: bankcode,
        account_number: formInput.banknumber,
      });
    };

    return {
      steps,
      ...toRefs(formInput),
      ...toRefs(verificationResponse),
      bvnResponse,
      isFilled,
      bvn,
      show,
      show2,
      bankNames,
      handleContinue,
      handleSubmit,
      closeVerificationModal,
      canGoNext,
      handleLogin,
      signingUp,
    };
  },
};
</script>
<style lang="scss" scoped>
.decorated {
  &::after {
    background: #000;
    width: 100px;
  }
  // after:block
  //           after:bg-text-primary
  //           after:w-1
  //           after:h-10
  //           after:mx-auto
  //           after:my-2
}
</style>
