export default {
  title: {
    title: 'Title',
    text: 'Provide a title that best defines your fundraiser. Remember that the title is the first thing potential donors will look at so make it concise',
  },
  desc: {
    title: 'Description ',
    text: 'Provide a detailed description of your fundraiser. Explain why you are trying to raise money, exactly what the money will be used for and why people should donate to your cause.<br/><br/>Remember, it is always better to provide more information than less.',
  },
  about: {
    title: 'About us ',
    text: 'Provide a detailed description of your organisation. Explain why you are trying to raise money, exactly what the money will be used for and why people should donate to your cause.<br/><br/>Remember, it is always better to provide more information than less.',
  },
  cycle: {
    title: 'Fundraiser Cycle',
    text: 'Choose your fundraiser cycle. It can be monthly or quarterly.<br/><br/>Your fundraiser cycle determines when your fundraiser will reset and all donations during that cycle will be paid out to you.<br/><br/>Fundraiser Cycles are only relevant to Recurring Fundraisers.',
  },
  category: {
    title: 'Category',
    text: 'Choose the nearest category under which your fundraiser falls.<br/><br/>Make sure you choose the most appropriate category to ensure approval of your fundraiser.',
  },
  target: {
    title: 'Target Amount',
    text: 'Set a target amount for your fundraiser.<br/><br/>Keep your target amount honest, realistic, and appropriate to your target audience and potential donors.',
  },
  targetCycle: {
    title: 'Cycle Target Amount',
    text: 'Use the slider to set your cycle target amount.<br/><br/>Note that your cycle target amount does not have to be made public.',
  },
  hideCycle: {
    title: 'Hide Cycle',
    text: 'Check the box to hide your cycle target amount from the public.<br/><br/>As the owner of the fundraiser, you may still see your cycle target amount in your dashboard.',
  },
  duration: {
    title: 'Duration',
    text: 'Set a duration for which you want this fundraiser to be active.<br/><br/>Note that you will not receive the funds from this fundraiser until the end of your set duration.',
  },
  images: {
    title: 'Images',
    text: 'Add relevant images to your fundraiser. You may add up to 3 images and images must be jpeg or png format.<br/><br/>It is important to note that images are one of the most important characteristics of your fundraiser. Ensure that the images your upload are appropriate and relevant to your fundraiser, otherwise your fundraiser will be rejected.',
  },
  coverImage: {
    title: 'Cover Image',
    text: 'Add a cover image to your fundraiser. This is the image that will be displayed on the fundraiser page.',
  },
  youtube: {
    title: 'Youtube Video',
    text: 'You may add a link to a youtube video.<br/><br/>If there is a youtube video that helps explain your fundraiser or persuade your donors, then it can be a good idea to add it here. Simply paste the link in the space provided.',
  },
  isBeneficiary: {
    title: 'Beneficiary',
    text: 'If the ultimate beneficiary of the money you will raise from this fundraiser is someone other than yourself, then select this option.<br/><br/>By selecting this option, you are admitting that you will be raising this money for someone else, and Blessmi will carry out further checks to verify your claim.',
  },
  beneficiaryName: {
    title: 'Beneficiary Name',
    text: 'Provide the name of the beneficiary of this fundraiser.<br/><br/>Please provide full legal name before adding any aliases.',
  },
  beneficiaryLink: {
    title: 'Link To Beneficiary',
    text: 'Provide a link to an online profile of the beneficiary.<br/><br/>This link can be a social media profile, new article, or related webpage. Note that Blessmi will carry out further checks to verify all information provided.',
  },
  observers: {
    title: 'Observer',
    text: 'You may invite an observer to your fundraiser, as long as the person is a registered on Blessmi and accepts your request.<br/><br/>Inviting a person of integrity as an observer to your fundraiser can help boost donor confidence. However, please note that this may delay the approval process of the fundraiser.',
  },
  socials: {
    title: 'Social Media',
    text: 'You may add relevant social media links directly related to this particular fundraiser.<br/><br/>Make sure any social media links you supply are of direct consequence to this particular fundraiser.',
  },
  orgWebsite: {
    title: 'Organisation Website',
    text: 'Please update your organisation website.',
  },
  contact: {
    title: 'Contact Information',
    text: 'You may add your contact information such as address, email and/or phone number',
  },
  impactStory: {
    title: 'Impact Story',
    text: 'Add relevant past impact stories to your fundraiser page.',
  },
  team: {
    title: 'Team Members',
    text: 'Add your team members information.',
  },
  commissionPercentage: {
    title: 'Promote & Earn Commission Percentage',
    text: 'Promote & Earn helps you reward your promoters with a percentage of the total value they help you raise. You can set your desired percentage here.<br/><br/>',
  },
  promoteEarn: {
    title: 'Promote and Earn',
    text: 'Promote & Earn is a feature that allows other people help promote your fundraiser in return for a commission, by generating their unique link which they can share with their friends and family network. Promoters are then rewarded based on the total funds they were able to help you generate via their link. You are require to set the commission percentage with which your promoters will be rewarded.',
  },
  advertPromo: {
    title: 'Request Media Package',
    text: 'Blessmi Media Package allows Blessmi to advertise and promote your fundraiser through various media platforms, in return for a special percentage commission (Terms & Conditions Apply).<br/><br/>Not all fundraisers will qualify for this scheme. If you request this package, we will assess your fundraiser to determine if it qualifies. If your fundraiser qualifies, you will make yourself available for video and audio interviews and adverts.',
  },
};
