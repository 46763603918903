<template>
  <div>
    <BHeader />
    <div class="flex pt-26 pb-20 flex-col justify-center items-center">
      <div class="b-card">
        <!-- {{active}} -->
        <div class="py-4 flex flex-col p-6 py-9">
          <header class="mb-3 px-6">
            <div class="flex">
              <button @click="handlePrev">
                <LeftArrowIcon class="h-6 fill-current" />
              </button>
              <p class="text-text-primary text-lg ml-6 font-bold">
                Sign up now as an Individual.
              </p>
            </div>
          </header>

          <keep-alive>
            <component
              :is="componentName"
              :user="user"
              @previous="handlePrev"
              @next="handleNext"
              @reset="handleReset"
            />
          </keep-alive>
        </div>
      </div>
    </div>

    <BFooterLG class="hidden z-10 bottom-0 mt-10 lg:block" />
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import { useRouter, useRoute } from 'vue-router';
import StepThree from './StepThree.vue';
import BHeader from '../../../components/ui/BHeader.vue';
import BFooterLG from '../../../components/ui/BFooterLG.vue';

export default {
  name: 'Signup',

  components: {
    StepOne,
    BHeader,
    BFooterLG,
  },

  setup() {
    let active = ref(1);
    const componentName = computed(() => {
      switch (active.value) {
        case 1:
          return StepOne;
        case 2:
          return StepTwo;
        case 3:
          return StepThree;
        default:
          throw Error('Invalid active component');
      }
    });

    const route = useRoute();
    const user = ref({
      accounttype: 'INDIVIDUAL',
      referredBy: route.query.referral ?? '',
    });
    const router = useRouter();

    const handleNext = (userData) => {
      if (userData) {
        user.value = { ...user.value, ...userData };
      }
      active.value += 1;
    };

    const handlePrev = () => {
      if (active.value === 1) router.go(-1);
      else active.value -= 1;
    };

    const handleReset = () => {
      user.value = {};
      active.value = 1;
    };

    return { componentName, handleNext, handlePrev, user, handleReset, active };
  },
};
</script>
