<template>
  <BHeader class="hidden lg:block" />
  <div class="py-4 flex lg:pt-24 flex-col min-h-screen">
    <header class="mb-3 lg:hidden px-6">
      <div class="flex justify-between">
        <button @click="handlePrev">
          <LeftArrowIcon class="h-6 fill-current" />
        </button>
      </div>
    </header>

    <keep-alive class="mb-20 pb- pb-48">
      <component
        :is="componentName"
        :step="step"
        @previous="handlePrev"
        :user="user"
        @next="handleNext"
        @reset="handleReset"
      />
    </keep-alive>
    <BFooterLG class="hidden z-10 bottom-0 absolute mt-0 lg:block" />
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import { useRouter, useRoute } from 'vue-router';
import StepThree from './StepThree.vue';
import StepFour from './StepFour.vue';
import BHeader from '../../../components/ui/BHeader.vue';
import BFooterLG from '../../../components/ui/BFooterLG.vue';

export default {
  name: 'Signup',

  components: {
    StepOne,
    BHeader,
    BFooterLG,
  },

  setup() {
    let active = ref(1);
    const componentName = computed(() => {
      switch (active.value) {
        case 1:
          return StepOne;
        case 2:
          return StepTwo;
        case 3:
          return StepThree;
        case 4:
          return StepFour;
        default:
          throw Error('Invalid active component');
      }
    });

    const route = useRoute();
    const user = ref({
      accounttype: '',
      referredBy: route.query.referral ?? '',
    });
    const router = useRouter();

    const handleNext = (userData) => {
      if (userData) {
        user.value = { ...user.value, ...userData };
      }
      active.value += 1;
    };

    const handlePrev = () => {
      if (active.value === 1) router.go(-1);
      else active.value -= 1;
    };

    const handleReset = () => {
      user.value = {};
      active.value = 1;
    };

    return { componentName, handleNext, handlePrev, user, handleReset, active };
  },
};
</script>
