<template>
  <div class="relative">
    <div
      class="flex cursor-pointer justify-between w-full items-center h-full rounded border border-border-primary overflow-hidden input-shadow"
      @click="toggleOpenState"
    >
      <input
        class="p-4 w-full text-sm font-medium focus:outline-none bg-bg-primary"
        disabled
        :placeholder="placeholder"
        :value="modelValue"
      />
      <div class="px-4">
        <ChevronDownIcon
          :class="['transform fill-current h-6', { 'rotate-180': openState }]"
        />
      </div>
    </div>
    <BModal :isOpen="openState" @close="toggleOpenState">
      <ul
        class="w-full bg-bg-secondary text-sm left-0 pb-16 rounded max-h-60 text-text-primary font-semibold overflow-y-auto"
      >
        <li class="py-2 px-4 w-full" v-if="!options.length">Empty</li>
        <li
          :class="[
            'py-2 my-2 xs:my-3 w-full cursor-pointer relative hover:bg-bg-alternate-5',
          ]"
          v-for="(item, index) in options"
          :key="`${item}-${index}`"
          @click="handleSelect(item)"
        >
          <TickIcon
            v-if="modelValue === item"
            class="absolute right-0 h-5 fill-current"
            style="fill: #037ED8"
          />
          {{ item }}
        </li>
      </ul>
    </BModal>
  </div>
</template>

<script>
import { ref } from 'vue';
import BModal from './BModal';

export default {
  name: 'BModalSelect',

  components: { BModal },

  props: {
    options: {
      required: true,
    },

    placeholder: {
      default: '',
    },

    modelValue: [Number, String],
  },

  setup(props, { emit }) {
    const openState = ref(false);
    const select = ref(null);

    const toggleOpenState = () => {
      openState.value = !openState.value;
    };

    const handleSelect = (value) => {
      toggleOpenState();
      emit('update:modelValue', value);
    };

    return {
      openState,
      select,
      toggleOpenState,
      handleSelect,
    };
  },
};
</script>
