<template>
  <div class="lg:flex justify-center gap-6 grid-cols-">
    <div class="col-span-2">
      <div
        class="lg:rounded-lg lg:border lg:border-text-alternate-14 lg:h-full w-fff"
      >
        <div class="relative hidden rounded-lg lg:block">
          <Carousel
            :autoplay="false"
            :wrapAround="true"
            class="hidden rounded-lg lg:block"
          >
            <Slide v-for="{ imageurl, youTubeLink, id } in slides" :key="id">
              <div class="w-full relative" style="padding-top: 66.66%">
                <img
                  v-if="imageurl"
                  class="w-full h-full absolute top-0 rounded-t-lg"
                  :src="imageurl"
                  alt="gallery"
                />
                <iframe
                  v-else-if="youTubeLink"
                  class="w-full h-full absolute top-0"
                  :src="`https://www.youtube.com/embed/${id}?ps=docs&controls=1`"
                  width="100%"
                  height="100%"
                />
              </div>
            </Slide>
            <template #addons>
              <Pagination class="w-1/2 transform translate-x-1/2" />
            </template>
          </Carousel>

          <router-link
            v-if="authUser && authUser?.id === fundraiser.userid"
            :class="[
              'absolute right-4 top-4 bg-bg-primary px-5 py-2.5 text-center rounded text-text-alternate-1 font-semibold text-sm',
              { 'link-disabled': isOneTime && !hasNotExpired },
            ]"
            :to="{ name: 'EditFundraiser', params: { id: fundraiser.id } }"
          >
            Edit
          </router-link>
          <div
            class="text-center bg-bg-alternate-7 text-text-alternate-1 font-semibold text-2xs xs:text-xs py-2"
            v-if="fundraiser.status === 'PENDING'"
          >
            Pending admin review
          </div>
        </div>

        <div
          class="flex justify-between px-6 bg-bg-alternate-3 text-text-alternate-1 font-semibold text-xs xs:text-sm py-2"
        >
          <p class="flex items-center">
            <span class="text-text-alternate-3 pr-1">By:</span>
            <router-link
              class="truncate"
              :to="{
                name: 'Profile',
                params: { username: fundraiser.userdetail.username },
              }"
            >
              <span class="lg:hidden">
                {{
                  truncateWord(
                    `${fundraiser.userdetail.firstname} ${fundraiser.userdetail.lastname}`,
                    30
                  )
                }}
              </span>
              <span class="hidden lg:block">
                {{
                  truncateWord(
                    `${fundraiser.userdetail.firstname} ${fundraiser.userdetail.lastname}`,
                    55
                  )
                }}
              </span>
            </router-link>
            <VerifiedIcon
              class="h-3 pl-1 pr-5 flex-shrink-0"
              v-if="fundraiser.userdetail.verificationStatus === 'VERIFIED'"
            />
            <VIPIcon
              class="h-4 pl-1 pr-5 flex-shrink-0"
              v-else-if="fundraiser.verificationStatus === 'VIP'"
            />
          </p>
          <p>One-Off</p>
        </div>

        <section class="px-6">
          <div
            v-if="fundraiser.components.includes('1')"
            class="border-b border-border-primary flex justify-between py-3 text-xs text-text-alternate-3 font-semibold"
          >
            <p class="flex text-text-alternate-1">
              <TagIcon class="h-4 stroke-0 stroke-current fill-current" />
              <span class="ml-1">{{ fundraiser.category.categoryname }}</span>
            </p>
            <vue-countdown
              v-if="showCountdown"
              :time="timeLeft"
              v-slot="{ hours, minutes, seconds }"
            >
              {{ hours }} hrs, {{ minutes }} mins, {{ seconds }} secs.
            </vue-countdown>
            <!-- <span>Time Remaining：1 days, 23 hours, 59 minutes, 59 seconds.</span> -->
            <p :class="{ 'text-text-alternate-14': isExpired }" v-else>
              {{ getTime() }}
            </p>
          </div>

          <div class="text-text-alternate-1 py-3">
            <h3 class="py-2 text-2xl font-semibold">
              {{ fundraiser.title }}
            </h3>
            <span
              class="text-xs xs:text-sm py-1 leading-4-1/2 xs:leading-5-1/2"
            >
              <!-- {{ description }} -->
              <div v-html="description"></div>
              <template v-if="isLenghtExceeded">
                <button
                  class="font-semibold focus:outline-none"
                  v-if="isDescTruncated"
                  @click="shouldTruncateDesc = false"
                >
                  ...See&nbsp;more
                </button>
                <button
                  class="font-semibold focus:outline-none"
                  v-else
                  @click="shouldTruncateDesc = true"
                >
                  ...See&nbsp;less
                </button>
              </template>
            </span>
          </div>
          <div
            class="py-3 lg:hidden border-t border-border-primary"
            v-if="
              !fundraiser.hideCycleTargetAmount &&
              fundraiser.components.includes('2')
            "
          >
            <p
              class="flex justify-between font-semibold text-xs xs:text-sm py-1"
            >
              <span>₦{{ fundraiser.raisedAmount.toLocaleString() }}</span>
              <span>₦{{ fundraiser.targetAmount.toLocaleString() }}</span>
            </p>
            <div
              class="bg-bg-alternate-3 h-2 rounded-full my-2 overflow-hidden"
            >
              <div
                class="bg-bg-alternate-8 rounded-full h-2"
                :style="{ width: targetCoverage }"
              ></div>
            </div>
            <p
              class="flex justify-between font-medium text-xs text-text-alternate-3 pb-1"
            >
              <span>Raised</span>
              <span>Target</span>
            </p>
          </div>
          <div
            class="lg:hidden border-border-primary py-4"
            :class="fundraiser.components.includes('2') ? 'border-t' : null"
          >
            <p class="text-xs font-medium pt-2">
              Bless this Fundraiser by making a payment using Bank Transfer,
              Card or USSD.
            </p>
            <div
              class="rounded bg-bg-alternate-8 my-2 mb-0 flex flex-col justify-center items-center text-text-secondary font-semibold"
              :class="{
                'py-4':
                  isExpired ||
                  fundraiser.status == 'PENDING' ||
                  fundraiser.supportedPayments.split(',').includes('1'),
              }"
            >
              <template v-if="isExpired">
                <Fundraiser2Icon style="stroke: #e0f2ff" class="h-6 stroke-2" />
                <p class="text-xs mt-3">This fundraiser has expired</p>
              </template>
              <template v-else-if="fundraiser.status == 'PENDING'">
                <FundraiserIcon />
                <p class="text-xs mt-3">
                  This fundraiser is still awaiting approval
                </p>
              </template>
              <template
                v-else-if="
                  fundraiser.supportedPayments.split(',').includes('1')
                "
              >
                <h3 class="uppercase text-text-alternate-9 text-xs">
                  <span v-if="isWishList">
                    {{ wishListAccounts[0].bankName }}
                  </span>
                  <span v-else-if="isPromoteAndEarn">
                    {{ promoteAndEarnAccounts[0].bankName }}
                  </span>
                  <span v-else>
                    {{ accounts[0].bankName }}
                  </span>
                </h3>
                <p class="text-lg my-2 flex">
                  <span class="mr-2">
                    <span v-if="isWishList">
                      {{ wishListAccounts[0].accountNumber }}
                    </span>
                    <span v-else-if="isPromoteAndEarn">
                      {{ promoteAndEarnAccounts[0].accountNumber }}
                    </span>
                    <span v-else>
                      {{ accounts[0].accountNumber }}
                    </span></span
                  >
                  <button
                    @click="
                      handleCopy(
                        isWishList
                          ? wishListAccounts[0].accountNumber
                          : isPromoteAndEarn
                          ? promoteAndEarnAccounts[0].accountNumber
                          : accounts[0].accountNumber
                      )
                    "
                  >
                    <CopyIcon
                      class="h-4 stroke-0 stroke-current fill-current"
                    />
                  </button>
                </p>
                <p class="text-xs">
                  {{ accounts[0].accountName }}
                </p>
              </template>
            </div>

            <div
              v-if="fundraiser.supportedPayments.split(',').includes('8')"
              class="mt-4 py-4 border border-border-primary flex justify-center rounded-md flex-col items-center"
            >
              <p class="text-xs mb-2 font-semibold text-text-primary">
                NQR - Scan to pay
              </p>
              <qrcode-vue
                foreground="#000000"
                :value="fundraiser.emvcoCode"
                :size="200"
                level="H"
              />
            </div>
          </div>
          <template
            v-if="!isExpired && fundraiser.status !== 'PENDING' && !isModal"
          >
            <!-- <p class="decorated">Or</p> -->
            <div class="pb-4 pt-0">
              <!-- <p class="font-medium text-xs my-2">
              You can also bless this fundraiser from anywhere in the world using a
              Credit/Debit Card or ApplePay.
            </p> -->

              <div class="flex flex-col items-center gap-4 mb-2 lg:hidden">
                <button
                  v-if="
                    fundraiser.supportedPayments.split(',').includes('3') ||
                    fundraiser.supportedPayments.split(',').includes('10') ||
                    fundraiser.supportedPayments.split(',').includes('12')
                  "
                  class="border border-btn-border-alternate-1 btn-shadow w-full py-4 flex justify-center rounded"
                  @click="
                    $emit('bless', {
                      option: fundraiser.supportedPayments
                        .split(',')
                        .includes('3')
                        ? 'PAYPAL'
                        : fundraiser.supportedPayments.split(',').includes('10')
                        ? 'FLUTTERWAVEAPPLENGN'
                        : 'FLUTTERWAVEAPPLEUSD',
                      currency: 'USD',
                    })
                  "
                >
                  <ApplePayIcon class="h-10" />
                </button>
                <button
                  v-if="
                    fundraiser.supportedPayments.split(',').includes('2') ||
                    fundraiser.supportedPayments.split(',').includes('5') ||
                    fundraiser.supportedPayments.split(',').includes('9')
                  "
                  class="border border-btn-border-alternate-1 btn-shadow w-full py-6 flex justify-center gap-3 rounded"
                  @click="
                    fundraiser.supportedPayments.split(',').includes('2') ||
                    fundraiser.supportedPayments.split(',').includes('9')
                      ? (showCurrencyModal = true)
                      : $emit('bless', { option: 'CARD', currency })
                  "
                >
                  <MasterCardIcon class="h-6" />
                  <VisaIcon class="h-6" />
                  <VerveIcon class="h-6" />
                  <!-- <UnionPayIcon class="h-6" /> -->
                </button>

                <button
                  v-if="
                    fundraiser.supportedPayments.split(',').includes('6') ||
                    fundraiser.supportedPayments.split(',').includes('12')
                  "
                  class="border border-btn-border-alternate-1 btn-shadow w-full py-4 btn-ussd flex justify-center rounded"
                  @click="isOpen = true"
                >
                  <p class="ussd-text">*USSD#</p>
                </button>
                <!-- <GooglePay /> -->
              </div>
            </div>
            <button
              class="lg:hidden bg-btn-bg-primary text-btn-text-primary px-4 py-4 w-full rounded text-center text-sm xs:text-base font-bold mb-4"
              @click="$emit('share')"
            >
              Share this Fundraiser
            </button>
          </template>
        </section>

        <section class="flex-grow mt-2.5" v-if="!isModal">
          <header class="overflow-scroll bg-bg-alternate-3 px-6">
            <ul class="inline-flex font-semibold text-xs xs:text-sm w-full">
              <li v-for="(nav, index) in sections" :key="index">
                <router-link
                  :class="[
                    'px-4 py-3 nav-border inline-block',
                    $route.name === nav
                      ? 'nav-active text-text-alternate-1'
                      : 'text-text-alternate-10',
                  ]"
                  :to="{
                    name: nav,
                    query: isWishList
                      ? { wishlist_id: wishListId }
                      : isPromoteAndEarn
                      ? { promotion_id: promoteAndEarnId }
                      : null,
                  }"
                >
                  {{ nav }}
                </router-link>
              </li>
            </ul>
          </header>
          <router-view
            class="px-6"
            :fundraiser="fundraiser"
            :authUser="authUser"
            :isExpired="isExpired"
            @update="$emit('update')"
            @bless="$emit('bless')"
            @addUpdate="$emit('addUpdate', $event)"
            @share="$emit('share')"
            @verify="isVerify = true"
            @allBlessors="isBlessModalOpen = true"
          />
        </section>

        <BModal :isOpen="showCurrencyModal" @close="showCurrencyModal = false">
          <p class="text-text-alternate-7 text-sm my-8">
            Select Currency Below
          </p>

          <form
            @submit.prevent="
              $emit('bless', { option: 'CARD', currency }),
                (showCurrencyModal = false)
            "
            class="lg:px-28"
          >
            <label
              :class="[
                'block mt-4 border-text-alternate-12 rounded h-28 bg-bg-alternate-6 p-4 xs:p-5 my-4 xs:my-5 relative border-box cursor-pointer',
                currency === 'NGN' ? ' border-2' : '',
              ]"
            >
              <TickIcon
                v-if="currency == 'NGN'"
                class="h-5 xs:h-6 absolute right-4 top-4 z-10 fill-current"
                style="fill: #017622"
              />

              <p class="text-4xl font-bold text-center mt-4">NGN (&#8358;)</p>

              <input
                type="radio"
                name="Naira"
                value="NGN"
                hidden
                v-model="currency"
              />
            </label>
            <label
              :class="[
                'block border-text-alternate-12 rounded h-28 bg-bg-alternate-9 p-4 xs:p-5 mt-8 mb-4 xs:my-5 relative border-box cursor-pointer',
                currency === 'USD' ? ' border-2' : '',
              ]"
            >
              <TickIcon
                v-if="currency == 'USD'"
                class="h-5 xs:h-6 absolute right-4 top-4 z-10 fill-current"
                style="fill: #017622"
              />

              <p class="text-4xl font-bold text-center mt-4">USD ($)</p>

              <input
                type="radio"
                name="Dollar"
                value="USD"
                hidden
                v-model="currency"
              />
            </label>
            <button
              class="bg-btn-bg-primary text-btn-text-primary rounded w-full py-4 my-4 mt-10 font-bold text-sm xs:text-base"
              type="submit"
              :disabled="!currency"
            >
              Proceed
            </button>
          </form>
        </BModal>

        <BlesserChecker
          :fundraiserId="fundraiser.id"
          v-model="isVerify"
          @bless="$emit('bless', { option: 'CARD' })"
        />

        <BlessorModal
          :isOpen="isBlessModalOpen"
          :fundraiserId="fundraiser.id"
          @close="isBlessModalOpen = false"
        />

        <BModal class="lg:hidden" :isOpen="showCode" @close="showCode = false">
          <p class="form-label">
            The USSD code generated below is only valid for the previously
            selected bank.
          </p>
          <div class="ussd-card">
            <p class="font-bold text-center text-text-primary">
              {{ ussdData.paymentCode }}
            </p>
            <p class="copy-text text-center flex mt-2">
              Tap to copy code
              <CopyIcon
                @click="handleCopy(ussdData.paymentCode)"
                class="h-5 ml-2 stroke-0 stroke-current fill-current"
                style="fill: #577387"
              />
            </p>
          </div>
          <div
            class="px-6 py-4 flex gap-4 z-10 bg-bg-alternate-3 absolute left-0 w-full bottom-13"
          >
            <a
              :href="'tel:' + ussdData.paymentCode"
              class="bg-btn-bg-primary text-btn-text-primary px-4 py-3.5 xs:py-4 w-full rounded text-center text-sm xs:text-base font-bold"
            >
              Dial Code
            </a>
          </div>
        </BModal>

        <BModal
          :heading="'Bless Fundraiser using USSD'"
          :isOpen="isOpen"
          @close="isOpen = showCode = false"
        >
          <form @submit.prevent="generateCode">
            <div class="lg:hidden">
              <div>
                <p class="form-label">
                  Use the form below to generate a USSD code for your bank to
                  bless this fundraiser
                </p>
              </div>
              <!-- {{ bankLists }} -->
              <div
                class="flex flex-col mt-2 text-text-primary font-semibold text-sm"
              >
                <label>Amount (Naira)*</label>
                <!-- <input
                  class="
                    border
                    rounded
                    border-border-primary
                    mt-2
                    mb-1
                    p-3.75
                    text-sm
                    font-medium
                    focus:outline-none
                    input-shadow
                  "
                  type="number"
                  v-model.number="amount"
                  pattern="\d*"
                  inputmode="decimal"
                  min="0"
                /> -->
                <BCurrencyInput
                  name="amount"
                  placeholder="0.00"
                  @input="handleAmountInput"
                />
              </div>
              <template v-if="!authUser">
                <div
                  class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
                >
                  <label>First Name*</label>
                  <input
                    class="border rounded border-border-primary mt-2 mb-1 p-3.75 text-sm font-medium focus:outline-none input-shadow"
                    type="text"
                    v-model.trim="firstname"
                  />
                </div>
                <div
                  class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
                >
                  <label>Last Name*</label>
                  <input
                    class="border rounded border-border-primary mt-2 mb-1 p-3.75 text-sm font-medium focus:outline-none input-shadow"
                    type="text"
                    v-model.trim="lastname"
                  />
                </div>
              </template>
              <div
                class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
              >
                <label>Choose Your Bank *</label>
                <BModalSelect
                  class="my-2 pb-5"
                  :options="bankLists"
                  v-model="bank"
                ></BModalSelect>
              </div>
              <div class="mb-24 flex items-center mt-4">
                <input type="checkbox" v-model="anonymous" />
                <label class="font-medium text-xs ml-2">
                  Bless this fundraiser anonymously.
                </label>
              </div>
              <div
                class="px-6 py-4 flex gap-4 z-10 bg-bg-alternate-3 absolute left-0 w-full bottom-13"
              >
                <button
                  class="bg-btn-bg-primary text-btn-text-primary px-4 py-3.5 xs:py-4 w-full rounded text-center text-sm xs:text-base font-bold"
                  :disabled="!isFilled"
                  type="submit"
                >
                  Generate Code
                </button>
              </div>
            </div>
            <div class="hidden lg:block mt-6">
              <p class="text-sm text-text-primary font-medium">
                Use the form below to generate a USSD code for your bank to
                bless this fundraiser
              </p>
              <div class="grid grid-cols-2 mt-6 gap-6">
                <div class="col-span-1">
                  <div
                    class="flex flex-col mt-2 text-text-primary font-semibold text-sm"
                  >
                    <label>Amount (Naira)*</label>
                    <!-- <input
                      class="
                        border
                        rounded
                        border-border-primary
                        mt-2
                        mb-1
                        p-3.75
                        text-sm
                        font-medium
                        focus:outline-none
                        input-shadow
                      "
                      type="number"
                      v-model.number="amount"
                      pattern="\d*"
                      inputmode="decimal"
                      min="0"
                    /> -->
                    <BCurrencyInput
                      name="amount"
                      placeholder="0.00"
                      @input="handleAmountInput"
                    />
                  </div>
                  <template v-if="!authUser">
                    <div
                      class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
                    >
                      <label>First Name*</label>
                      <input
                        class="border rounded border-border-primary mt-2 mb-1 p-3.75 text-sm font-medium focus:outline-none input-shadow"
                        type="text"
                        v-model.trim="firstname"
                      />
                    </div>
                  </template>
                  <div class="mb-8 flex items-center mt-4">
                    <input type="checkbox" v-model="anonymous" />
                    <label class="font-medium text-xs ml-2">
                      Bless this fundraiser anonymously.
                    </label>
                  </div>
                  <button
                    class="bg-btn-bg-primary text-btn-text-primary px-4 py-3.5 xs:py-4 w-full h-14 rounded text-center text-sm xs:text-base font-bold"
                    :disabled="!isFilled"
                    type="submit"
                  >
                    Generate Code
                  </button>
                </div>
                <div class="col-span-1">
                  <div
                    class="flex flex-col mt-2 text-text-primary font-semibold text-sm"
                  >
                    <label>Choose Your Bank *</label>

                    <BSelectInput
                      :options="bankLists"
                      v-model="bank"
                      class="mt-2 input-shadow focus:outline-none"
                    />
                  </div>

                  <div
                    v-if="!authUser"
                    class="flex flex-col mt-6 text-text-primary font-semibold text-sm"
                  >
                    <label>Last Name*</label>
                    <input
                      class="border rounded border-border-primary mt-2 mb-1 p-3.75 text-sm font-medium focus:outline-none input-shadow"
                      type="text"
                      v-model.trim="lastname"
                    />
                  </div>

                  <div
                    v-if="showCode"
                    class="flex flex-col items-center justify-center h-14 bg-text-alternate-9 rounded-md mt-16 text-center"
                  >
                    <p class="text-sm text-border-alternate-4">
                      Dial the code below on your phone
                    </p>
                    <p class="text-base mt-1 font-bold text-text-primary">
                      {{ ussdData.paymentCode }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </BModal>
        <BSpinner
          class="flex-grow"
          v-if="ussdResponse.isLoading"
          text="Generating code..."
        ></BSpinner>

        <FundraiserBottom
          :isModal="isModal"
          :fundraiser="fundraiser"
          @setSection="handleSetSection"
          @makeawish="$emit('makeawish')"
          @promoteFundraiser="$emit('promoteFundraiser')"
          @updateLikeData="updateLike"
          :authUser="authUser"
          :isOwner="isOwner"
          :searchResponse="searchResponse"
          v-if="!isExpired"
        />
      </div>
    </div>
    <div class="col-span-1 hidden lg:block">
      <div class="h- p-6 pt-4 border rounded-lg border-text-alternate-14 w-ff">
        <div
          class=""
          v-if="
            !fundraiser.hideCycleTargetAmount &&
            fundraiser.components.includes('2')
          "
        >
          <p class="flex justify-between font-semibold text-xs xs:text-sm py-1">
            <span>₦{{ fundraiser.raisedAmount.toLocaleString() }}</span>
            <span>₦{{ fundraiser.targetAmount.toLocaleString() }}</span>
          </p>
          <div class="bg-bg-alternate-3 h-2 rounded-full my-2 overflow-hidden">
            <div
              class="bg-bg-alternate-8 rounded-full h-2"
              :style="{ width: targetCoverage }"
            ></div>
          </div>
          <p
            class="flex justify-between font-medium text-xs text-text-alternate-3 pb-1"
          >
            <span>Raised</span>
            <span>Target</span>
          </p>
        </div>
        <div
          class="border-border-primary py-4"
          :class="fundraiser.components.includes('2') ? 'border-t mt-5' : null"
        >
          <p class="text-xs font-medium pt-2">
            Bless this Fundraiser by making a payment using Bank Transfer, Card
            or USSD.
          </p>
          <div
            class="rounded bg-bg-alternate-8 my-2 mb-0 flex flex-col justify-center items-center text-text-secondary font-semibold"
            :class="{
              'py-4':
                isExpired ||
                fundraiser.status == 'PENDING' ||
                fundraiser.supportedPayments.split(',').includes('1'),
            }"
          >
            <template v-if="isExpired">
              <Fundraiser2Icon style="stroke: #e0f2ff" class="h-6 stroke-2" />
              <p class="text-xs mt-3">This fundraiser has expired</p>
            </template>
            <template v-else-if="fundraiser.status == 'PENDING'">
              <FundraiserIcon />
              <p class="text-xs mt-3">
                This fundraiser is still awaiting approval
              </p>
            </template>
            <template
              v-else-if="fundraiser.supportedPayments.split(',').includes('1')"
            >
              <h3 class="uppercase text-text-alternate-9 text-xs">
                <span v-if="isWishList">
                  {{ wishListAccounts[0].bankName }}
                </span>
                <span v-else-if="isPromoteAndEarn">
                  {{ promoteAndEarnAccounts[0].bankName }}
                </span>
                <span v-else>
                  {{ accounts[0].bankName }}
                </span>
              </h3>
              <p class="text-lg my-2 flex">
                <span class="mr-2">
                  <span v-if="isWishList">
                    {{ wishListAccounts[0].accountNumber }}
                  </span>
                  <span v-else-if="isPromoteAndEarn">
                    {{ promoteAndEarnAccounts[0].accountNumber }}
                  </span>
                  <span v-else>
                    {{ accounts[0].accountNumber }}
                  </span>
                </span>
                <button
                  @click="
                    handleCopy(
                      isWishList
                        ? wishListAccounts[0].accountNumber
                        : isPromoteAndEarn
                        ? promoteAndEarnAccounts[0].accountNumber
                        : accounts[0].accountNumber
                    )
                  "
                >
                  <CopyIcon class="h-4 stroke-0 stroke-current fill-current" />
                </button>
              </p>
              <p class="text-xs">
                {{ accounts[0].accountName }}
              </p>
            </template>
          </div>
          <div
            v-if="fundraiser.supportedPayments.split(',').includes('8')"
            class="mt-4 py-4 border border-border-primary flex justify-center rounded-md flex-col items-center"
          >
            <p class="text-xs mb-2 font-semibold text-text-primary">
              NQR - Scan to pay
            </p>
            <qrcode-vue
              foreground="#000000"
              :value="fundraiser.emvcoCode"
              :size="200"
              level="H"
            />
          </div>
        </div>
        <template
          v-if="!isExpired && fundraiser.status !== 'PENDING' && !isModal"
        >
          <!-- <p class="decorated">Or</p> -->
          <div class="pb-4 pt-0">
            <!-- <p class="font-medium text-xs my-2">
              You can also bless this fundraiser from anywhere in the world using a
              Credit/Debit Card or ApplePay.
            </p> -->

            <div class="flex flex-col items-center gap-4 mb-2">
              <button
                v-if="
                  fundraiser.supportedPayments.split(',').includes('3') ||
                  fundraiser.supportedPayments.split(',').includes('10') ||
                  fundraiser.supportedPayments.split(',').includes('12')
                "
                class="border border-btn-border-alternate-1 btn-shadow w-full py-4 flex justify-center rounded"
                @click="
                  $emit('bless', {
                    option: fundraiser.supportedPayments
                      .split(',')
                      .includes('3')
                      ? 'PAYPAL'
                      : fundraiser.supportedPayments.split(',').includes('10')
                      ? 'FLUTTERWAVEAPPLENGN'
                      : 'FLUTTERWAVEAPPLEUSD',
                    currency: 'USD',
                  })
                "
              >
                <ApplePayIcon class="h-10" />
              </button>
              <button
                v-if="
                  fundraiser.supportedPayments.split(',').includes('2') ||
                  fundraiser.supportedPayments.split(',').includes('5') ||
                  fundraiser.supportedPayments.split(',').includes('9')
                "
                class="border border-btn-border-alternate-1 btn-shadow w-full py-6 flex justify-center gap-3 rounded"
                @click="
                  fundraiser.supportedPayments.split(',').includes('2') ||
                  fundraiser.supportedPayments.split(',').includes('9')
                    ? (showCurrencyModal = true)
                    : $emit('bless', { option: 'CARD', currency })
                "
              >
                <MasterCardIcon class="h-6" />
                <VisaIcon class="h-6" />
                <VerveIcon class="h-6" />
                <!-- <UnionPayIcon class="h-6" /> -->
              </button>

              <button
                v-if="
                  fundraiser.supportedPayments.split(',').includes('6') ||
                  fundraiser.supportedPayments.split(',').includes('12')
                "
                class="border border-btn-border-alternate-1 btn-shadow w-full py-4 btn-ussd flex justify-center rounded"
                @click="isOpen = true"
              >
                <p class="ussd-text">*USSD#</p>
              </button>
            </div>
          </div>

          <!-- <GooglePay /> -->
          <button
            class="bg-btn-bg-primary text-btn-text-primary px-4 py-4 w-full rounded text-center text-sm xs:text-base font-bold mb-4"
            @click="$emit('share')"
          >
            Share this Fundraiser
          </button>
        </template>

        <div
          class="border-t border-border-primary py-3"
          v-if="fundraiser.blessings?.length"
        >
          <span v-if="fundraiser.components.includes('3')">
            <div
              class="flex justify-between font-semibold text-lg py-3 text-text-alternate-1"
            >
              <h2>Top Blessors</h2>
              <span v-if="isModal" class="underline"
                >({{ fundraiser.totalBlessingsCount }})</span
              >
              <button
                v-else
                class="font-semibold"
                @click="isBlessModalOpen = true"
              >
                ({{ fundraiser.totalBlessingsCount }})
              </button>
            </div>

            <BBlessor
              v-for="blessor in fundraiser.blessings"
              :key="blessor.id"
              :blessor="blessor"
            />
          </span>

          <template v-if="!isModal">
            <button
              v-if="fundraiser.components.includes('3')"
              class="border border-btn-border-secondary items-center w-full px-4 py-3.75 text-center rounded text-text-alternate-1 font-bold text-sm mt-4 mb-2"
              @click="isBlessModalOpen = true"
            >
              Show more
            </button>
            <button
              class="bg-btn-bg-alternate-1 text-btn-text-alternate-1 w-full py-4 rounded text-center text-sm font-bold mt-2 mb-1"
              @click="isVerify = true"
            >
              Verify Your Donation
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { useRouter } from 'vue-router';
import QrcodeVue from "qrcode.vue";
import { reactive, toRefs, computed, watch, ref } from "vue";
import randomColor from "randomcolor";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import BlesserChecker from "./BlesserChecker";
import BlessorModal from "@/components/ui/BlessorModal";
import BSpinner from "@/components/ui/BSpinner";
import BModal from "@/components/ui/BModal";
import dayjs from "dayjs";
import { useUtils } from "@/cmp-functions/useUtils";
import relativeTime from "dayjs/plugin/relativeTime";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import minMax from "dayjs/plugin/minMax";
import BModalSelect from "@/components/ui/BModalSelect.vue";
import FundraiserBottom from "./FundraiserBottom.vue";
import { useApi } from "@/cmp-functions/useApi";
import { getUssdBanks, generateUssdCode } from "@/services/api";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel";
import { getYouTubeVideoId, formatNumber } from "@/utils";
import BBlessor from "../../components/ui/BBlessor.vue";
import BSelectInput from "../../components/ui/BSelectInput.vue";
import BCurrencyInput from "../../components/ui/BCurrencyInput.vue";
import moment from "moment";

// import GooglePay from '../../components/ui/GooglePay.vue';
// import BFundraiserCardSM from '../../components/ui/BFundraiserCardSM.vue';
dayjs.extend(relativeTime);
dayjs.extend(isSameOrAfter);
dayjs.extend(minMax);

export default {
  name: "OneOffFundraiser",

  props: [
    "fundraiser",
    "donations",
    "accounts",
    "targetCoverage",
    "authUser",
    "isModal",
    "isExpired",
    "searchResponse",
    "isWishList",
    "wishListId",
    "isPromoteAndEarn",
    "promoteAndEarnId",
  ],

  components: {
    BlesserChecker,
    BlessorModal,
    BModal,
    BModalSelect,
    BSpinner,
    Carousel,
    Slide,
    Pagination,
    BBlessor,
    BSelectInput,
    QrcodeVue,
    FundraiserBottom,
    BCurrencyInput,
    // GooglePay,
    // BFundraiserCardSM,
  },

  emits: [
    "share",
    "bless",
    "addUpdate",
    "update",
    "makeawish",
    "updateLikeData",
  ],

  setup(props, context) {
    const { handleCopy } = useUtils();
    const isVerify = ref(false);
    const verify = ref(false);
    const isOpen = ref(false);
    const showCode = ref(false);
    const isBlessModalOpen = ref(false);
    const store = useStore();
    const authUser = computed(() => store.state.auth.authData);
    const { firstname = "", lastname = "", bank = "" } = authUser.value || {};
    const showCurrencyModal = ref(false);
    const currency = ref("NGN");
    const ussdData = ref({});
    // const router = useRouter();
    const toast = useToast();
    const showCountdown = ref(false);

    const recurBless = reactive({
      monthlyId: "",
    });

    const userDetails = reactive({
      firstname,
      lastname,
      bank,
      amount: "",
      bankCode: "0",
      anonymous: false,
      isPromoteAndEarn: props.isPromoteAndEarn,
      promoteAndEarnId: props.promoteAndEarnId,
    });
    const embededYoutubeId = computed(() =>
      getYouTubeVideoId(props.fundraiser?.youTubeLink)
    );

    const slides = computed(() => {
      const { images, youTubeLink } = props.fundraiser;
      // Separate images into true and false arrays
      let featuredImages = images.filter((image) => image.isFeatured);
      let nonFeaturedImages = images
        .filter((image) => !image.isFeatured)
        .slice()
        .reverse();

      // Concatenate the two arrays, placing featured images first
      let rearrangedImages = featuredImages.concat(nonFeaturedImages);
      return youTubeLink
        ? [...rearrangedImages, { id: embededYoutubeId.value, youTubeLink }]
        : rearrangedImages;
    });

    const isFilled = computed(() =>
      Object.values(userDetails).every(
        (value) => typeof value === "boolean" || value
      )
    );

    const shouldTruncateDesc = ref(true);

    const [bankListResponse, fetchUssdBanks] = useApi(getUssdBanks);
    const [ussdResponse, generateUssd] = useApi(generateUssdCode);
    fetchUssdBanks();
    const bankList = computed(() => bankListResponse.data);
    const bankLists = computed(
      () =>
        bankListResponse.data
          ?.filter((item) => item.baseUssdCode)
          .map(({ name }) => name) || []
    );

    const isMobile = () => {
      if (screen.width <= 768) {
        return true;
      } else {
        return false;
      }
    };

    const generateCode = () => {
      const payload = {
        ...userDetails,
        fundraiserId: props.fundraiser.id,
      };
      let obj = bankList.value.filter((item) => item.name === userDetails.bank);
      let code = obj[0].baseUssdCode;
      let ussdCode = code.substring(1, code.length - 1);
      payload.bankCode = ussdCode;
      delete payload.bank;
      // delete payload.anonymous;
      generateUssd(payload);
    };

    const truncateWord = (word, length) => {
      return word.length <= length
        ? word.substr(0, length)
        : `${word.substr(0, length)}...`;
    };

    watch(ussdResponse, ({ data, error, isSuccess, isFailure }) => {
      if (isSuccess) {
        isMobile() ? (isOpen.value = false) : null;
        showCode.value = true;
        ussdData.value = data;
      }
      if (isFailure) {
        const toast = useToast();
        toast.error(error);
      }
    });

    const updateLike = (value) => {
      context.emit("updateLikeData", value);
    };

    const handleSetSection = (value) => {
      context.emit("setSection", value);
    };

    const isLenghtExceeded = computed(
      () => props.fundraiser.fundraiserDesc.length > 280
    );

    const isDescTruncated = computed(
      () => shouldTruncateDesc.value && isLenghtExceeded.value
    );

    const description = computed(() => {
      if (isDescTruncated.value)
        return props.fundraiser.fundraiserDesc.slice(0, isMobile() ? 180 : 400);
      return props.fundraiser.fundraiserDesc;
    });

    const getTime = () => {
      const { /*createdAt, duration,*/ nextPayoutDate } = props.fundraiser;
      // if (dayjs().isAfter(dayjs(nextPayoutDate))) return "Expired";
      if (props.isExpired) return "Expired";
      let daysLeft = Math.abs(
        // dayjs().diff(dayjs(createdAt).add(duration, 'day'), 'day')
        // dayjs().diff(dayjs(nextPayoutDate), "day");

        moment(new Date(nextPayoutDate).toISOString().substring(0,10)).diff(moment(new Date().toISOString().substring(0,10)), "days")
      );
      // daysLeft += 1;

      if (daysLeft === 0) {
        // return `Few hours left`;
        showCountdown.value = true;
      }
      return `${daysLeft} ${daysLeft == 1 ? "day" : "days"} left`;
    };

    const handleAmountInput = (value) => {
      console.log(value);
      userDetails.amount = value;
    };

    const wishListAccounts = computed(() => {
      if (props.isWishList) {
        const account = props.accounts.filter(
          (item) => item.wishListId === props.wishListId
        );
        return account;
      } else {
        return null;
      }
    });

    const promoteAndEarnAccounts = computed(() => {
      if (props.isPromoteAndEarn) {
        const account = props.accounts.filter(
          (item) => item.promoteAndEarnId === props.promoteAndEarnId
        );
        return account;
      } else {
        return null;
      }
    });

    const sections = computed(() => {
      const sectionList = ["Overview"];
      if (props.fundraiser.rewards.length) {
        sectionList.push("Rewards");
      }

      if (props.fundraiser.turnOnComments) {
        sectionList.push("Comments");
      }

      return sectionList;
    });

    const timeLeft = computed(() => {
      // Get current UTC time
      const currentUTC = new Date();
      const currentUTCHours = currentUTC.getUTCHours();
      const currentUTCMinutes = currentUTC.getUTCMinutes();
      // const currentUTCSeconds = currentUTC.getUTCSeconds();
      // const currentUTCMilliseconds = currentUTC.getUTCMilliseconds();

      // Set the target time to 22:59 UTC of the current day
      const targetTime = new Date();
      targetTime.setUTCHours(22);
      targetTime.setUTCMinutes(59);
      targetTime.setUTCSeconds(0);
      targetTime.setUTCMilliseconds(0);
      let diffInMs;
      // If current time is before 22:59 UTC
      if (
        currentUTCHours < 22 ||
        (currentUTCHours === 22 && currentUTCMinutes < 59)
      ) {
        diffInMs = targetTime - currentUTC;
      } else {
        // If current time is after 22:59 UTC, calculate for the next day
        const nextDayTarget = new Date(targetTime);
        nextDayTarget.setUTCDate(targetTime.getUTCDate() + 1); // Increment to next day
        diffInMs = nextDayTarget - currentUTC;
      }
      return diffInMs;
    });

    return {
      ...toRefs(recurBless),
      ...toRefs(userDetails),
      randomColor,
      getTime,
      generateCode,
      isBlessModalOpen,
      isVerify,
      isOpen,
      sections,
      handleCopy,
      dayjs,
      shouldTruncateDesc,
      isDescTruncated,
      isLenghtExceeded,
      description,
      bankLists,
      isFilled,
      ussdResponse,
      bankList,
      ussdData,
      showCode,
      slides,
      toast,
      embededYoutubeId,
      formatNumber,
      wishListAccounts,
      promoteAndEarnAccounts,
      isMobile,
      verify,
      showCurrencyModal,
      currency,
      truncateWord,
      handleSetSection,
      handleAmountInput,
      showCountdown,
      timeLeft,
      updateLike,
    };
  },
};
</script>

<style lang="scss" scoped>
.nav-border {
  border: 4px solid transparent;
}

.nav-active {
  border-bottom-color: #577387;
}

.decorated {
  @apply relative overflow-hidden text-center text-text-alternate-3 font-semibold;
  &::before,
  &::after {
    content: "\a0";
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 46%;
    height: 1px;
    background-color: #e5e5e5;
  }
  &::before {
    margin-left: -48%;
    text-align: right;
  }
  &::after {
    margin-left: 2%;
  }
}
// Send
</style>
