/* eslint-disable no-unused-vars */
import axiosInstance from './axiosInstance';
import { getAuthData } from '@/utils';

export const verifyBVN = (payload) => axiosInstance.post(`verify-bvn`, payload);

// const fileHeader = {
//   headers: {
//     Accept: 'application/json',
//     'Content-Type': 'multipart/form-data',
//   },
// };

const getQuery = (queryObject) => {
  let query = '';
  Object.entries(queryObject).forEach(([key, value]) => {
    if (value !== undefined) {
      query += `&${key}=${value}`;
    }
  });
  return query.slice(1);
};

export const verifyOTP = (opt) => {
  return new Promise((resolve, reject) => {
    if (opt === '1212') {
      setTimeout(() => resolve({ data: { opt } }), 1000);
    } else {
      setTimeout(() => reject({ data: { message: 'Wrong number' } }), 1000);
    }
  });
};

export const resendOTP = () => {
  return new Promise((resolve) => {
    setTimeout(() => resolve({ data: {} }), 1000);
  });
};

export const registerCategory = (payload) =>
  axiosInstance.post(`reg/preferences`, payload);

export const getCategories = () => axiosInstance.get(`categories`);

export const getUserPreference = (userId) =>
  axiosInstance.get(`preferences/${userId}`);

export const updateUserPreference = ({ preferenceId, userId, categoryids }) =>
  axiosInstance.put(`preferences/${preferenceId}/update/${userId}`, {
    categoryids,
  });

export const registerUser = (payload) => axiosInstance.post(`signup`, payload);

export const loginUser = (payload) => axiosInstance.post(`signin`, payload);
export const registerOrganisation = (payload) =>
  axiosInstance.post(`signup/corporate`, payload);

export const registerCompany = (payload) =>
  axiosInstance.post(`company/registration`, payload);

export const registerBank = (payload) =>
  axiosInstance.post(`bank/create`, payload);

export const updateUserBank = ({ bankId, ...payload }) =>
  axiosInstance.put(`bank/${bankId}/update`, payload);

export const postFundraiser = (payload) =>
  axiosInstance.post(`fundraiser/create`, payload);

export const postFundraiserComment = (payload) =>
  axiosInstance.post(`comments/create`, payload);

export const postFundraiserUpdate = (payload) =>
  axiosInstance.post(`fundraiser/updates`, payload);

export const deleteFundraiserUpdate = ({ fundraiserId, updateId }) =>
  axiosInstance.delete(`fundraiser/updates/${fundraiserId}/delete/${updateId}`);

export const postFundraiserGallery = (payload) =>
  axiosInstance.post(`gallery/upload`, payload);

export const postAnImage = (payload) =>
  axiosInstance.post(`gallery/singleupload`, payload);

export const deleteAnImage = (fundraiserId, id) =>
  axiosInstance.delete(`gallery/${fundraiserId}/delete/${id}`)

export const reportFundraiser = (payload) =>
  axiosInstance.post(`/fundraisers/report`, payload);

export const skipRegisterBank = (userId) =>
  axiosInstance.get(`reg/banksetup/${userId}`);

export const getBanks = (country) =>
  axiosInstance.get(`bank/lov?country=${country}`);

export const getUssdBanks = () => axiosInstance.get(`/payments/banks`);

export const generateUssdCode = (payload) =>
  axiosInstance.post(`/payments/ussd`, payload);

export const getPromoPlans = () => axiosInstance.get(`promo/plans`);

export const verifyAccount = (payload) =>
  axiosInstance.post(`bank/account-verification`, payload);

export const verifyTransfer = (id, payload) =>
  axiosInstance.post(`payments/verification/${id}`, payload);

export const makePayment = (payload, query) =>
  axiosInstance.post(`payments?${getQuery(query)}`, payload);

export const verifyPayment = ({ type, reference }) =>
  axiosInstance.get(`payments/${type}/${reference}/verify`);

export const searchUsers = (search) =>
  axiosInstance.get(`members/autocomplete?query=${search}`);

export const getFundraiserUpdates = (id) =>
  axiosInstance.get(`fundraiser/updates/${id}`);

export const searchFundraiser = ({ offset, ...searchQuery }) =>
  axiosInstance.get(
    `fundraiser/search?offset=${offset}&${getQuery(searchQuery)}`
  );

export const updateFundraiser = ({ id, payload }) =>
  axiosInstance.put(`fundraiser/update/${id}`, payload);

export const getFundraiser = (id) => {
  let url = `/fundraiser/findById/${id}`;
  const user = getAuthData();
  if (user) {
    url += `?userId=${user.id}`;
  }
  return axiosInstance.get(url);
};

export const getUserProfile = (accountId) => {
  let url = `/accounts/profile/${accountId}`;
  const user = getAuthData();
  if (user) {
    url += `?userId=${user.id}`;
  }
  return axiosInstance.get(url);
};

export const getUserProfileByUsername = (username) => {
  let url = `/accounts/profile/findByUsername/${username}`;
  const user = getAuthData();
  if (user) {
    url += `?userId=${user.id}`;
  }
  return axiosInstance.get(url);
};

export const updateUserProfile = ({ id, payload }) =>
  axiosInstance.put(`/accounts/profile/${id}/update`, payload);

export const getAccountWallet = (id) =>
  axiosInstance.get(`/accounts/wallet/${id}`);

export const getFollowedFundraiser = (userId, offset) => {
  let url = `/fundraiser/findAllByFollowings/${userId}?limit=10&offset=${offset}`;
  const user = getAuthData();
  if (user) {
    url += `&userId=${user.id}`;
  }
  return axiosInstance.get(url);
};

export const getLikedFundraiser = (userId, offset) => {
  let url = `fundraiser/findAllByLikes/${userId}?limit=10&offset=${offset}`;
  const user = getAuthData();
  if (user) {
    url += `&userId=${user.id}`;
  }
  return axiosInstance.get(url);
};

export const likeFundraiser = ({ id, userId }) =>
  axiosInstance.post(`fundraisers/${id}/like/${userId}`);

export const getUserFundraisers = ({ userId, offset }) => {
  let url = `fundraiser/findAllByUser/${userId}?limit=10&offset=${offset}`;
  const user = getAuthData();
  if (user) {
    url += `&userId=${user.id}`;
  }
  return axiosInstance.get(url);
};

export const getUserAccountOverview = ({ userId, filter }) =>
  axiosInstance.get(`/accounts/${userId}/overview?filter=${filter}`);

export const getUserAccountWallet = (userId) =>
  axiosInstance.get(`wallets/${userId}`);

export const getFundraiserWallet = ({ fundraiserId, userId }) =>
  axiosInstance.get(`wallets/balance/${fundraiserId}/${userId}`);

export const postWalletWithdrawal = (payload) =>
  axiosInstance.post(`withdrawals/create`, payload);

export const getWalletHistory = (userId) =>
  axiosInstance.get(`accounts/transactions/${userId}`);

export const getFundraiserHistory = ({ fundraiserId, userId }) =>
  axiosInstance.get(`fundraisers/${fundraiserId}/history/${userId}`);

export const deleteFundraiser = (fundraiserId) =>
  axiosInstance.delete(`fundraisers/${fundraiserId}/delete`);

export const getFundraiserRewards = (id) =>
  axiosInstance.get(`rewards/findAllByFundraiser/${id}`);

export const getFundraiserComments = ({ fundraiserId, offset }) =>
  axiosInstance.get(
    `comments/findAllByFundraiser/${fundraiserId}?limit=5&offset=${offset}`
  );

export const getFundraiserBlessings = ({ fundraiserId, offset }) =>
  axiosInstance.get(
    `blessings/blessors/${fundraiserId}?limit=5&offset=${offset}`
  );

export const getAllBlessors = (fundraiserId) =>
  axiosInstance.get(`blessings/blessors/${fundraiserId}`);

export const getBlessings = ({ fundraiserId, ...searchQuery }) =>
  axiosInstance.get(`blessings/${fundraiserId}?${getQuery(searchQuery)}`);

export const getWithdrawals = (fundraiserId) =>
  axiosInstance.get(`withdrawals/findByFundraiser/${fundraiserId}`);

export const followUser = ({ accountId, userId }) =>
  axiosInstance.post(`/accounts/${accountId}/follow/${userId}`);

export const getUserBank = (userId) =>
  axiosInstance.get(`bank/findByUser/${userId}`);

export const unFollowUser = ({ accountId, userId }) =>
  axiosInstance.post(`/accounts/${accountId}/unfollow/${userId}`);

export const getSecurityQtn = () => axiosInstance.get(`/security/questions`);

export const getUserSecurity = (userId) =>
  axiosInstance.get(`/security/${userId}`);

export const postSecurityQtn = (payload) =>
  axiosInstance.post(`/security/questions`, payload);

export const updateSecurityQtn = ({ userId, payload }) =>
  axiosInstance.put(`/security/update/${userId}`, payload);

export const checkSecurity = ({ userId, ...payload }) =>
  axiosInstance.post(`/security/verify/${userId}`, payload);

export const updatePassword = ({ userId, payload }) =>
  axiosInstance.put(`/accounts/change-password/${userId}`, payload);

export const getUserReferal = (userId) =>
  axiosInstance.get(`referrals/findByUser/${userId}`);

export const postReferalWithdrawal = (payload) =>
  axiosInstance.post(`referrals/withdraw`, payload);

export const getFAQs = () => axiosInstance.get(`settings/faqs`);

export const postMessage = (payload) => axiosInstance.post('messages', payload);

export const getMessageSubjects = () => axiosInstance.get(`messages/subjects`);

export const postMessageReply = (payload) =>
  axiosInstance.post(`messages/reply`, payload);

export const getMessages = (userId) => axiosInstance.get(`messages/${userId}`);

export const getMessageThread = ({ userId, messageId }) =>
  axiosInstance.get(`messages/${userId}/thread/${messageId}`);

export const getFAQDetails = (id) => axiosInstance.get(`settings/faq/${id}`);

export const getUserNotifications = (userId) =>
  axiosInstance.get(`notifications/${userId}`);

export const getNotificationsSettings = (userId) =>
  axiosInstance.get(`notifications/settings/${userId}`);

export const updateNotificationsSettings = ({ userId, ...payload }) =>
  axiosInstance.put(`notifications/settings/${userId}`, payload);

export const getPrivacy = () => axiosInstance.get(`privacy`);

export const getAboutUs = () => axiosInstance.get(`about-us`);

export const getTerms = () => axiosInstance.get(`terms`);

export const getTopDonatedFundraiser = ({ offset, ...searchQuery }) =>
  axiosInstance.get(
    `fundraisers/top-donated?offset=${offset}&${getQuery(searchQuery)}`
  );

export const getTrendingFundraiser = ({ offset, ...searchQuery }) =>
  axiosInstance.get(
    `fundraisers/trending?offset=${offset}&${getQuery(searchQuery)}`
  );

export const forgetPassword = (payload) =>
  axiosInstance.post(`accounts/verify-email`, payload);

export const verifyPasswordToken = (payload) =>
  axiosInstance.post(`accounts/verify-token`, payload);

export const resetPassword = (payload) =>
  axiosInstance.put(`accounts/reset-password`, payload);

export const checkFundraiserPledge = ({ fundraiserId, email }) =>
  axiosInstance.get(`payments/subscriptions/${fundraiserId}/${email}`);

export const cancelFundraiserPledge = (payload) =>
  axiosInstance.post(`payments/subscriptions/cancel`, payload);

export const getUserDetails = (userId) =>
  axiosInstance.get(`userdetails/${userId}`);

export const topupWallet = (payload) =>
  axiosInstance.post(`payments/topup`, payload);

export const verifyEmail = (payload) =>
  axiosInstance.post(`accounts/verify-reg-token`, payload);

export const sendEmailVerification = (payload) =>
  axiosInstance.post(`accounts/resend-email-verification`, payload);

export const getFundraiserPledges = (id) =>
  axiosInstance.get(`fundraisers/pledges/${id}`);

export const getTopFundraisers = (query) =>
  axiosInstance.get(`fundraisers/top-fundraisers?limit=10&${getQuery(query)}`);

export const getTopBlesssors = (query) =>
  axiosInstance.get(`blessing/top-blessors?limit=10&${getQuery(query)}`);

export const getPledge = (fundraiserId, planCode) =>
  axiosInstance.post(`fundraisers/plans/pledgers/${fundraiserId}`, {
    planCode,
  });

export const makeWish = (payload) =>
  axiosInstance.post(`makeawish/create`, payload);

export const verifyWishList = (payload) =>
  axiosInstance.post(`makeawish/verify`, payload);

export const getPromotedFundraisers = (userId) =>
  axiosInstance.get(`promote-and-earn/${userId}`);

export const getWishListById = (id) =>
  axiosInstance.get(`promote-and-earn/findById/${id}`);

export const getPromotedFundraiserBlessings = (id) =>
  axiosInstance.get(`promote-and-earn/blessings/${id}`);

export const verifyPromoteAndEarn = (payload) =>
  axiosInstance.post(`promote-and-earn/verify`, payload);

export const promoteAndEarn = (payload) =>
  axiosInstance.post(`promote-and-earn/create`, payload);

export const promoteAndEarnWithdrawal = (payload) =>
  axiosInstance.post(`promote-and-earn/withdrawals/create`, payload);

export const getPromoteAndEarnRemittances = (id, fundraiserId) =>
  axiosInstance.get(`promote-and-earn/remittances/${id}/${fundraiserId}`);

export const getPromoteAndEarnWallet = (userId) =>
  axiosInstance.get(`promote-and-earn/wallet/${userId}`);

export const getPromoteAndEarnWithdrawals = (promoteAndEarnWalletId) =>
  axiosInstance.get(`promote-and-earn/withdrawals/${promoteAndEarnWalletId}`);
