<template>
  <div class="px-6 py-4 lg:pt-24 flex flex-col">
    <header class="font-semibold text-lg flex text-text-alternate-1 pr-6">
      <button @click="handleCancel">
        <LeftArrowIcon class="h-6 fill-current" />
      </button>
      <h2 class="mx-auto">Crop Photo</h2>
    </header>
    <div class="my-6 flex-grow cropper-container">
      <Cropper
        class="cropper"
        ref="cropper"
        :src="image.content"
        :minWidth="900"
        :stencil-props="{
          aspectRatio: freeAspect
            ? 0
            : type == 'cover'
            ? 592 / 192
            : type == 'profilepic'
            ? 2 / 2
            : 3 / 2,
        }"
      />
    </div>
    <div>
      <button
        class="
          bg-btn-bg-primary
          text-btn-text-primary
          rounded
          w-full
          py-4
          xs:py-5
          my-2
          font-bold
          text-sm
          xs:text-base
        "
        @click="handleSave"
      >
        Save
      </button>
      <button
        class="
          bg-btn-bg-alternate-1
          text-btn-text-alternate-1
          w-full
          py-4
          xs:py-5
          my-2
          rounded
          text-center text-sm
          xs:text-base
          font-bold
        "
        @click="handleCancel"
      >
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { getFileDetails } from '@/utils';

export default {
  name: 'ProjectImageCrop',

  props: ['file', 'freeAspect', 'type'],

  emits: ['save', 'cancel'],

  components: {
    Cropper,
  },

  setup(props, { emit }) {
    const image = ref('');
    const cropper = ref(null);

    const convertImage = async () => {
      image.value = await getFileDetails(props.file);
    };

    convertImage();

    const handleSave = () => {
      const { canvas } = cropper.value.getResult();
      emit('save', canvas.toDataURL('image/jpeg', 0.5));
    };

    const handleCancel = () => emit('cancel');

    return { handleSave, handleCancel, cropper, image };
  },
};
</script>

<style lang="scss" scoped>
.cropper {
  max-height: max(42vh, 40vw);
}

.cropper-container {
  height: max(42vh, 40vw);
}
</style>
