<template>
  <div class="">
    <BHeader />
    <div class="lg:pt-32 pt-12 flex flex-col justify-center pb-8">
      <h3
        class="lg:text-4xl text-xl font-bold text-center text-text-alternate-1"
      >
        Terms and Conditions
      </h3>
      <p class="text-sm text-center text-text-alternate-1 mt-3">
        If you have any questions about our terms do contact us.
      </p>
      <hr style="color: #d7d7d7" class="mt-8 mx-auto w-10/12" />

      <BPageLoader v-if="isLoading" />
      <div v-else class=" lg:px-52 mt-8 px-4 lg:flex justify-center">
        <div class="text-sm text-text-alternate-1 py-4" v-html="data.content" />
      </div>
    </div>
    <BFooterLG class="hidden z-10 bottom-0 mt-10 lg:block" />
  </div>
</template>
<script>
import { toRefs } from 'vue';
import { useApi } from '@/cmp-functions/useApi';
import { getTerms } from '@/services/api';
import BHeader from '../components/ui/BHeader.vue';
import BFooterLG from '../components/ui/BFooterLG.vue';
export default {
  setup() {
    const [response, fetchTerms] = useApi(getTerms);
    fetchTerms();
    return {
      ...toRefs(response),
    };
  },
  components: { BHeader, BFooterLG },
};
//iuyfty
</script>
