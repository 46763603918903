import { useApi } from '@/cmp-functions/useApi';
import { getCategories, getMessageSubjects, getFAQs } from '@/services/api';

const [categories, fetchCategories] = useApi(getCategories);
const [msgSubjects, fetchMessageSubjects] = useApi(getMessageSubjects);
const [faqs, fetchFAQs] = useApi(getFAQs);

const isNative = navigator.userAgent.indexOf('gonative') > -1;

const sourceObject = {
  GoNativeIOS: 'ios',
  GoNativeAndroid: 'android',
};

const getSource = () => {
  if (!isNative) return 'web';
  const nativeUser = navigator.userAgent.match(/GoNativeIOS|GoNativeAndroid/)[0]
  return sourceObject[nativeUser];
};

export const initAppState = () => {
  fetchFAQs();
  fetchCategories();
  fetchMessageSubjects();
};

export const useAppState = () => {
  return { categories, msgSubjects, faqs, isNative, getSource };
};
