<template>
  <div class="min-h-screen flex flex-col pb-14">
    <BHeader />
    <div class="lg:grid grid-cols-6 gap-4 lg:mb-4">
      <!-- <div class="hidden lg:block col-span-1">
        <BSideMenuLG class="fixed" />
      </div> -->
      <div class="col-span-6 lg:z- lg:px-6">
        <slot />
      </div>
    </div>
    <BFooter class="fixed lg:hidden bottom-0 left-0 right-0 z-10" />
    <BFooterLG class="hidden lg:block" />
  </div>
</template>

<script>
import BFooter from '@/components/ui/BFooter';
import BHeader from '@/components/ui/BHeader';
import BFooterLG from '@/components/ui/BFooterLG';
// import BSideMenuLG from '@/components/ui/BSideMenuLG';

export default {
  name: 'BaseLayout',

  components: {
    BHeader,
    BFooter,
    BFooterLG,
    // BSideMenuLG,
  },
};
</script>
