export const fundraiser = {
  categoryId: null,
  title: '',
  fundraiserDesc: '',
  targetAmount: 0,
  fundraiserType: '',
  youTubeLink: '',
  fundraiserCycle: '',
  duration: 0,
  makePrivate: false,
  hideCycleTargetAmount: false,
  turnOnComments: true,
  monthlyDonations: [],
  images: [],
  members: [],
  smlinks: [
    { smname: 'Twitter', link: '' },
    { smname: 'Facebook', link: '' },
    { smname: 'Instagram', link: '' },
    { smname: 'YouTube', link: '' },
    { smname: 'TikTok', link: '' },
  ],
  rewards: [],
};
