/* eslint-disable no-useless-escape */
import store from '@/store/index';
import { fundraiser } from '@/data/createFundraiser.js';
import jwtDecode from 'jwt-decode';

const cycleMapping = {
  weekly: 7,
  monthly: 30,
  quarterly: 90,
};

const youtubeRegex =
  /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;

export const getAuthToken = () => store.state.auth.authData?.token;

export const getCycleDays = (cycle) => cycleMapping[cycle.toLowerCase()];

export const setAuth = (authData) => {
  localStorage.setItem('authData', JSON.stringify(authData));
  const decoded = jwtDecode(authData.token);
  if (parseInt(Date.now() / 1000) >= decoded.exp) {
    return localStorage.removeItem('authData');
  }

  store.commit('auth/SET_AUTH', { ...authData, ...decoded });
  store.dispatch('auth/fetchUser', decoded.id);
  store.dispatch('auth/fetchUserWallet', decoded.id);
};

export const getAuthData = () => store.state.auth.authData;

export const getUserDetails = () => store.state.auth.userDetails;

export const clearAuth = () => store.commit('auth/UNSET_AUTH');

const getFileExtension = (fileName) => {
  return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
};

export const getDataUrlFromImage = (img) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = img.width;
  canvas.height = img.height;
  ctx.drawImage(img, 0, 0);
  return canvas.toDataURL('image/png');
};

export const formatNumber = (num) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return num;
};

const getDataURL = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = ({ target }) => resolve(target.result);
    reader.onerror = (error) => reject(error);
  });
};

export const getFileDetails = async (file) => {
  const name = file.name;
  const fileExtension = getFileExtension(file.name);
  const contentType = file.type;
  const content = await getDataURL(file);
  return { name, fileExtension, contentType, content };
};

export const getProjectData = (data, userId) => {
  const result = { ...fundraiser };
  const isOneTime = data.fundraiserType !== 'PERMANENT';

  result.categoryId = data.categoryId;
  result.title = data.title;
  result.fundraiserDesc = data.description;
  result.targetAmount = data.targetAmount;
  result.fundraiserType = data.fundraiserType;
  result.images = data.images;
  result.turnOnComments = data.canComment;
  result.makePrivate = data.isPrivate;
  result.allowMakeAWish = data.allowMakeAWish;
  result.youTubeLink = data.youTubeLink;
  result.userId = userId;
  result.allowPromoteAndEarn = data.allowPromoteAndEarn;
  result.requestAdvertPromo = data.requestAdvertPromo;
  result.promoteAndEarnCommPercent = data.promoteAndEarnCommPercent;

  result.smlinks = [
    { smname: 'Twitter', link: data.twitter },
    { smname: 'Facebook', link: data.facebook },
    { smname: 'Instagram', link: data.instagram },
    { smname: 'YouTube', link: data.youtube },
    { smname: 'TikTok', link: data.tiktok },
  ];

  result.rewards = data.rewards.map(({ amount, reward }) => ({
    blessingAmount: amount,
    reward,
  }));

  result.isOrganiserBeneficiary = data.isOrganiserBeneficiary;

  if (isOneTime) {
    result.duration = parseInt(data.duration);
    result.members = data.members.map(({ userdetail, memberType }) => ({
      memberId: userdetail.userid,
      memberType: memberType.toUpperCase(),
    }));
    if (!data.isOrganiserBeneficiary) {
      result.members.push({ ...data.beneficiary, memberType: 'BENEFICIARY' });
    } else {
      result.members.push({ memberId: userId, memberType: 'BENEFICIARY' });
    }
    result.monthlyDonations = [];
  } else {
    result.fundraiserCycle = data.cycle.toUpperCase();
    result.monthlyDonations = [...data.monthlyDonations];
    result.hideCycleTargetAmount = data.shouldHideCycleAmount;
    result.coverImage = data.coverImage;
    result.organisationWebsite = data.organisationWebsite;
    result.contactEmail = data.contactEmail;
    result.contactAddress = data.contactAddress;
    result.contactPhoneNumber = data.contactPhoneNumber;
    result.impactStories = data.impactStories;
    result.teamMembers = data.teamMembers;
  }

  return result;
};

const socials = {
  Instagram: 'instagram',
  Facebook: 'facebook',
  Twitter: 'twitter',
  YouTube: 'youtube',
  TikTok: 'tiktok',
};

export const getEditProjectData = (data) => {
  console.log('Project DATA', data);
  const result = { ...fundraiser };
  const isOneTime = data.fundraiserType !== 'PERMANENT';

  result.categoryId = data.categoryId;
  result.title = data.title;
  result.fundraiserDesc = data.fundraiserDesc;
  result.targetAmount = data.targetAmount;
  result.fundraiserType = data.fundraiserType;
  result.images = data.images;
  result.turnOnComments = data.turnOnComments;
  result.makePrivate = data.makePrivate;
  result.youTubeLink = data.youTubeLink;
  result.monthlyDonations = undefined;
  result.allowPromoteAndEarn = data.allowPromoteAndEarn;
  result.promoteAndEarnCommPercent = data.promoteAndEarnCommPercent;

  result.smlinks = data.socialmedialinks.map((social) => ({
    ...social,
    link: data[socials[social.smname]],
  }));

  result.smlinks = Object.entries(socials).map(([smname, link]) => {
    const social = data.socialmedialinks.find((social) => social.smname === smname) || { smname };
    return { ...social, link: data[link] };
  });

  result.rewards = data.rewards;
  result.isOrganiserBeneficiary = data.isOrganiserBeneficiary;

  if (isOneTime) {
    result.duration = parseInt(data.duration);
    result.members = [];
    data.members.forEach(({ memberType, userdetail, id, fullname, link }) => {
      const type = memberType.toUpperCase();
      const isBeneficiary = type === 'BENEFICIARY';
      const memberId = !isBeneficiary
        ? userdetail.userid
        : data.isOrganiserBeneficiary
        ? data.userId
        : null;
      const mem = {
        id,
        fullname: data.isOrganiserBeneficiary ? '' : fullname,
        link: data.isOrganiserBeneficiary ? '' : link,
        memberId,
        memberType: type,
      };
      result.members.push(mem);
    });
    result.monthlyDonations = [];
  } else {
    result.fundraiserCycle = data.cycle?.toUpperCase() || data.fundraiserCycle;
    result.monthlyDonations = [...data.monthlyDonations];
    result.hideCycleTargetAmount = data.shouldHideCycleAmount;
    result.coverImage = data.coverImage;
    result.organisationWebsite = data.organisationWebsite;
    result.contactEmail = data.contactEmail;
    result.contactAddress = data.contactAddress;
    result.contactPhoneNumber = data.contactPhoneNumber;
    result.impactStories = data.impactStories;
    result.teamMembers = data.teamMembers;
  }

  return result;
};

export const getYouTubeVideoId = (link = '') => {
  const [, id] = link?.match(youtubeRegex) || [];
  if (id?.length === 11) return id;
};
