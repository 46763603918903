<template>
  <div
    class=" border-t border-border-primary footer-lg
      bg-btn-text-primary
      text-text-secondary
      p-8 pt-6 mt-4
      text-sm
    "
  >
    <div
      class="
        lg:grid lg:grid-cols-12
        items-center
        md:items-start md:flex-row
        gap-2
        max-w-xl
        md:max-w-screen-2xl
    
        mx-auto
      "
    >
      <div class="pt-1 md:pr-4 lg:col-span-4 col-span-12">
        <router-link to="/">
          <Brand2Icon class="h-12" />
        </router-link>
        <p class="text-text-primary font-medium mt-4">
          Blessmi is a platform designed and <br class="hidden lg:block" />
          built to enable organisations and <br class="hidden lg:block" />
          individuals raise money for good <br class="hidden lg:block" />causes
        </p>
      </div>

      <div class="lg:col-span-3 col-span-12 mt-10 lg:mt-0">
        <h4 class="font-bold text-sm text-text-primary">COMPANY</h4>
        <router-link :to="{ name: 'About' }">
          <p class="text-text-primary my-4 font-medium">About Us</p>
        </router-link>
        <router-link :to="{ name: 'TermsAndConditions' }">
          <p class="text-text-primary my-4 font-medium">Terms & Conditions</p>
        </router-link>
        <router-link :to="{ name: 'Privacy' }">
          <p class="text-text-primary my-4 font-medium">Privacy Policy</p>
        </router-link>
        <router-link :to="{ name: 'FAQs' }">
          <p class="text-text-primary my-4 font-medium">FAQs</p>
        </router-link>
      </div>

      <div class="lg:col-span-3 col-span-12 mt-10 lg:mt-0">
        <h4 class="font-bold text-sm text-text-primary">CONTACT US</h4>
        <a href="tel:+2347033237954">
          <p class="text-text-primary my-4 font-medium">234 703 323 7954</p>
        </a>
        <a href="mailto:hello@blessmi.com">
          <p class="text-text-primary my-4 font-medium">hello@blessmi.com</p>
        </a>
      </div>

      <div class="lg:col-span-2 col-span-12 mt-10 lg:mt-0">
        <h4 class="font-bold text-sm text-text-primary">CONNECT WITH US</h4>
        <ul class="flex py-4 gap-6">
          <li>
            <a
              href="https://www.youtube.com/channel/UC3ImUaab7xA66fBPF6JUAqA"
              target="_blank"
            >
              <YouTubeIcon class="h-5" />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/_blessmi" target="_blank">
              <TWIcon class="h-5" />
            </a>
          </li>
          <li>
            <a href="https://www.tiktok.com/@_blessmi_" target="_blank">
              <TKKI2con class="h-5" />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/_blessmi/" target="_blank">
              <InstagramIcon class="h-5" />
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="flex flex-col lg:flex-row justify-center mt-6">
      <p class="text-center font-medium text-sm text-text-primary">
        &copy;{{ new Date().getFullYear() }} Blessmi. GXT Blessmi Ltd. All
        Rights Reserved
      </p>
    </div>
  </div>
  <!-- <div class="footer-lg w-full flex justify-center items-center flex-col p-12">
    <div class="flex justify-between items-center">
      <router-link :to="{ name: 'Home' }">
        <BrandIcon2 />
      </router-link>
      <div class="flex">
        <button
          class="
            store-btn
            flex
            mr-4
            items-center
            justify-center
            text-sm
            font-bold
          "
        >
          <AppleIcon class="mr-3" /> App Store
        </button>
        <button
          class="store-btn flex items-center justify-center text-sm font-bold"
        >
          <PlayIcon class="mr-3" /> Play Store
        </button>
      </div>
      <div class="text-center">
        <p class="text-sm font-bold text-text-primary mb-2">CONNECT WITH US</p>
        <div class="flex justify-center">
          <a
            href="https://www.youtube.com/channel/UC3ImUaab7xA66fBPF6JUAqA"
            target="_blank"
          >
            <YouTubeIcon class="h-5 mr-6" />
          </a>
          <a href="https://twitter.com/_blessmi" target="_blank">
            <TwitterIcon class="h-5 mr-6" />
          </a>
          <a href="https://www.tiktok.com/@_blessmi_" target="_blank">
                <TKKI2con class="h-5 mr-6" />
              </a>
          <a href="https://www.instagram.com/_blessmi/" target="_blank">
            <InstagramIcon class="h-5" />
          </a>
        </div>
      </div>
    </div>
    <div class="flex mt-14 justify-center items-center">
      <p class="text-text-primary font-medium text-sm mr-8">©{{ new Date().getFullYear() }} Blessmi</p>
      <router-link to="/about">
        <p class="text-text-primary font-medium text-sm mr-8">About Us</p>
      </router-link>
      <router-link to="/terms-and-conditions">
        <p class="text-text-primary font-medium text-sm mr-8">
          Terms & Conditions
        </p>
      </router-link>
      <router-link to="/advisory-board">
        <p class="text-text-primary font-medium text-sm">Advisory Board</p>
      </router-link>
    </div>
  </div> -->
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.footer-lg {
  width: 100% !important;
  height: 202px;
  background: #ffffff;
  border-top: 1px solid #e5e5e5;
}
.store-btn {
  width: 148px;
  height: 48px;
  border: 1px solid #577387;
  border-radius: 4px;
  color: #577387;
}
</style>
