<template>
  <div class="flex items-center">
    <button @click.prevent="handleClick">
      <ToolTipInfoIcon class="h-3.5 ml-1" />
    </button>
    <transition
      enter-active-class="ease-linear duration-300 transition-all"
      enter-from-class="opacity-0"
      leave-active-class="ease-linear duration-300 transition-all"
      leave-to-class="opacity-0"
      leave-class="opacity-100"
      appear
    >
      <div
        class="fixed z-20 top-0 left-0 w-screen h-screen flex justify-center"
        style="background: rgba(22, 23, 34, 0.6)"
        v-if="isOpen"
      />
    </transition>
    <transition
      enter-active-class="ease-linear duration-300 transition-all"
      enter-from-class="opacity-0"
      leave-active-class="ease-linear duration-300 transition-all"
      leave-to-class="opacity-0"
      leave-class="opacity-100"
      appear
    >
      <div
        :class="[
          'fixed bg-bg-primary z-20 rounded-md p-4 max-w-lg w-10/12 md:w-9/12 text-text-alternate-1 text-xs left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2',
        ]"
        v-if="isOpen"
      >
        <div class="flex justify-between font-semibold">
          <span>{{ details.title }}</span>
          <button
            class="text-3xl h-5 focus:outline-none overflow-hidden flex items-center ml-auto"
            @click.self.prevent="handleClick"
          >
            &times;
          </button>
        </div>
        <div class="mt-3 font-medium" v-html="details.text" />
      </div>
    </transition>
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
  name: 'BToolTipBtn',

  props: ['details'],

  setup() {
    const isOpen = ref(false);
    const handleClick = () => {
      isOpen.value = !isOpen.value;
    };

    return {
      isOpen,
      handleClick,
    };
  },
};
</script>
