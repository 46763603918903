<template>
  <div
    class="relative"
    :ref="
      (el) => {
        if (el) select = el;
      }
    "
  >
    <div
      class="
        flex
        cursor-pointer
        justify-between
        items-center
        rounded
        border border-border-primary
        overflow-hidden
      "
      :class="dense ? ' h-7 w-36' : 'h-full w-full'"
      @click="toggleOpenState"
    >
      <input
        class="
          p-3.75
          w-full
          text-sm
          font-medium
          focus:outline-none
          input-shadow
          bg-bg-primary
        "
        disabled
        :id="id"
        placeholder="Choose"
        :value="modelValue"
      />
      <div class="px-3.5">
        <ChevronDownIcon
          :class="['transform fill-current h-6', { 'rotate-180': openState }]"
        />
      </div>
    </div>
    <ul
      class="
        absolute
        z-10
        w-full
        bg-bg-secondary
        border border-border-primary
        z-2
        text-xs
        xs:text-sm
        left-0
        rounded
        max-h-44
        pt-2
        text-text-primary
        font-medium
        overflow-y-auto
      "
      v-if="openState"
    >
      <li class="py-2 px-4 w-full" v-if="!options.length">Empty</li>
      <li
        :class="[
          'py-2 xs:py-2.5 px-4 w-full cursor-pointer',
          modelValue == item ? 'bg-bg-alternate-3' : 'hover:bg-bg-alternate-5',
        ]"
        v-for="(item, index) in options"
        :key="`${item}-${index}`"
        @click="handleSelect(item)"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, onBeforeUnmount } from 'vue';

export default {
  name: 'BSelectInput',

  props: {
    options: {
      type: Array,
      required: true,
    },

    dense: {
      type: Boolean,
    },

    id: {
      type: String,
    },

    modelValue: [Number, String],
  },

  setup(props, { emit }) {
    const openState = ref(false);
    const select = ref(null);

    const toggleOpenState = () => {
      openState.value = !openState.value;
    };

    const handleSelect = (value) => {
      toggleOpenState();
      emit('update:modelValue', value);
    };

    const close = ({ target }) => {
      if (!select.value.contains(target)) {
        openState.value = false;
      }
    };

    document.addEventListener('click', close);
    onBeforeUnmount(() => {
      document.removeEventListener('click', close);
    });

    return {
      openState,
      select,
      toggleOpenState,
      handleSelect,
    };
  },
};
</script>
