<template>
  <transition
    enter-active-class="ease-linear duration-300 transition-all"
    enter-from-class="opacity-0"
    leave-active-class="ease-linear duration-300 transition-all"
    leave-to-class="opacity-0"
    leave-class="opacity-100"
    appear
  >
    <div
      :class="[
        'rounded-lg overflow-hidden card-sm-shadow flex flex-col cursor-pointer relative',
        { 'bg-bg-alternate-3': fundraiser.isPromoted },
      ]"
      @click="openFundRaiser"
    >
      <!-- isMobile() ? (isOpen = true) : openNewTab() -->
      <div class="w-full relative" style="padding-top: 66.66%">
        <!-- {{fundraiser.fundraiserType}} -->

        <img
          v-if="isOneTime"
          class="w-full h-full absolute top-0 text-2xs"
          :src="featuredImage"
          alt="fundraiser_gallery"
        />

        <div
          v-else
          class="
            absolute
            top-0
            bg-bg-alternate-14
            h-full
            w-full
            flex
            justify-center
            items-center
          "
        >
          <div
            class="
              h-20
              w-20
              rounded-full
              border border-bg-alternate-3
              bg-bg-primary
              flex
              items-center
              justify-center
            "
          >
            <img
              class="h-18 w-18 rounded-full"
              :src="fundraiser.userdetail.profilePhoto"
              alt=""
            />
          </div>
        </div>

        <button
          class="focus:outline-none absolute top-2.5 right-2.5"
          v-if="canLike"
          @click.stop.prevent="handleLike"
        >
          <BButtonLoader class="h-5 w-5" v-if="isLoading" />
          <Heart2Icon
            class="h-4.5 xs:h-5"
            v-else-if="fundraiser.likes?.isLiked"
          />
          <HeartIcon
            class="h-5 xs:h-6 fill-current text-text-secondary"
            v-else
          />
        </button>
      </div>
      <div class="px-3 py-2 font-semibold text-2xs flex-grow">
        <h2 class="text-text-primary lg:text-text-alternate-1 my-1">
          {{ fundraiser.title }}
        </h2>
        <!-- lg:text-sm lg:mt-4 lg:flex -->
        <p class="my-1 justify-between">
          <span>
            <span class="mr-2 text-text-alternate-3 font-medium">Raised</span>
            <span style="color: #017622">
              ₦{{ fundraiser.raisedAmount.toLocaleString() }}
            </span>
          </span>
          <!-- <span class="font-medium lg:block hidden text-text-alternate-3">{{
            dayjs().to(fundraiser.createdAt)
          }}</span> -->
        </p>
      </div>
      <div
        class="absolute right-0 bottom-0"
        v-if="authUser && fundraiser.allowPromoteAndEarn"
      >
        <!-- ribbon -->

        <div
          style="right: -74px; bottom: 32px; width: 145px; background: #ff0000"
          class="
            absolute
            transform
            -rotate-45
            text-text-secondary
            font-semibold
            text-xs
            py-0
            pl-8
          "
        >
          {{ fundraiser.promoteAndEarnCommPercent }}%
        </div>

        <!-- end ribbon -->
      </div>
    </div>
  </transition>
  <!-- {{fundraiser}} -->
  <FundraiserModal
    :isOpen="isOpen"
    @close="isOpen = false"
    :fundraiser="fundraiser"
  />
</template>

<script>
import { computed, ref, watch, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import FundraiserModal from './FundraiserModal';
import { useApi } from '@/cmp-functions/useApi';
import { likeFundraiser } from '@/services/api';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

export default {
  name: 'BFundraiserCardSM',

  components: { FundraiserModal },

  props: ['fundraiser'],

  emits: ['like'],

  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const authUser = computed(() => store.state.auth.authData);
    const isOpen = ref(false);

    const isOwner = computed(
      () => props.fundraiser.userId == authUser.value?.id
    );

    const isOneTime = computed(
      () => props.fundraiser.fundraiserType !== 'PERMANENT'
    );
    const isMobile = () => {
      if (screen.width <= 768) {
        return true;
      } else {
        return false;
      }
    };

    // const isMobile = () => {
    //   let result = window.matchMedia(
    //     'only screen and (max-width: 768px)'
    //   ).matches;
    //   console.log(result);
    //   return result;
    // };

    const openFundRaiser = () => {
      // isMobile() ? (isOpen.value = true) : openNewTab();
      if (isMobile()) {
        isOpen.value = true;
      } else {
        openNewTab();
      }
    };

    const openNewTab = () => {
      let link = document.createElement('a');
      link.href = `/fundraiser/${slug.value}`;
      link.target = '_blank';
      link.click();
    };

    const canLike = computed(() => authUser.value && !isOwner.value);

    const [likeResponse, postLikeFundraiser] = useApi(likeFundraiser);

    const handleLike = () => {
      if (!canLike.value) return;
      postLikeFundraiser({
        userId: authUser.value.id,
        id: props.fundraiser.id,
      });
    };

    watch(likeResponse, ({ isSuccess, data }) => {
      if (isSuccess) {
        emit('like', data);
      }
    });

    const slug = computed(() => {
      const { id, title } = props.fundraiser;
      return `${id}-${title
        .trim()
        .toLowerCase()
        .replace(/[^a-z\d\s:]/g, '')
        .replace(/\s+/g, '-')}`;
    });

    const featuredImage = computed(() => {
      const imgObj = props.fundraiser.images.filter((item) => item.isFeatured);
      if (imgObj.length) {
        return imgObj[0].imageurl;
      } else {
        return props.fundraiser.images[props.fundraiser.images.length - 1]
          .imageurl;
      }
    });

    return {
      isOwner,
      router,
      slug,
      dayjs,
      isOpen,
      canLike,
      isOneTime,
      authUser,
      handleLike,
      ...toRefs(likeResponse),
      isMobile,
      openFundRaiser,
      openNewTab,
      featuredImage,
    };
  },
};
</script>
