<template>
  <div v-if="cropImage" class="pb-16">
    <ProjectImageCrop
      :file="file"
      @save="handleSave"
      @cancel="handleCancel"
      :freeAspect="true"
    />
  </div>
  <div v-else class="mt-10">
    <div class="text-sm">
      <BStepperLG :step="2" />
    </div>
    <div class="px-26" id="form">
      <p class="text-lg font-semibold mt-8 text-text-primary mt-4">
        We would like to know you better.
      </p>
      <p class="mt-2 text-text-alternate-7 text-sm mb-9">
        Please provide your address and a means of identification
      </p>
      <form @submit.prevent="handleContinue">
        <div class="flex flex-col mt-5 text-text-primary font-semibold text-sm">
          <label>Address*</label>

          <input
            class="
              border
              rounded
              border-border-primary
              mt-2
              mb-1
              p-3.75
              text-sm
              font-medium
              focus:outline-none
              input-shadow
            "
            v-model="address"
            type="text"
          />
          <span
            v-for="error in v$.address.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
        </div>

        <div class="flex flex-col mt-5 text-text-primary font-semibold text-sm">
          <label class="mb-2">State of residence*</label>
          <!-- <BModalSelect v-model="state" :options="states" /> -->
          <BSelectInput v-model="state" :options="states" />
          <span
            v-for="error in v$.state.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
        </div>

        <div class="flex flex-col mt-5 text-text-primary font-semibold text-sm">
          <label class="mb-2">Means of Identification*</label>
          <BSelectInput v-model="idType" :options="idTypes" />
          <span
            v-for="error in v$.idType.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
        </div>

        <BButtonLoader class="mx-auto h-7 w-7 my-24" v-if="isCompressing" />
        <label
          class="
            flex flex-col
            justify-center
            items-center
            border border-border-primary
            rounded
            h-48
            my-4
            cursor-pointer
            font-semibold
            text-text-primary text-sm
          "
          v-else-if="!idFile"
        >
          <IDCardIcon class="my-4" />
          Click here to upload ID
          <input
            type="file"
            hidden
            accept="image/png,image/jpeg,image/jpg"
            @change="handleFileInput"
          />
        </label>
        <div
          class="
            flex flex-col
            justify-center
            items-center
            border border-border-primary
            rounded
            my-4
            p-4
          "
          v-else
        >
          <img class="h-40 sm:h-56 mb-4" :src="idFile.base64" alt="id_card" />
          <button
            class="text-sm font-semibold text-text-primary"
            @click="idFile = ''"
          >
            Remove
          </button>

        </div>
        <span
          v-for="error in v$.idFile.$errors"
          :key="error.$uid"
          class="text-xs text-text-danger font-semibold -mt-4"
          >{{ error.$message }}</span
        >

        <div class="grid grid-cols-3 items-center">
          <div class="col-span-1 pt-4">
            <a
              href="javascript:void(0)"
              @click="handlePrevious"
              class="font-bold text-text-primary"
              >Previous</a
            >
          </div>
          <div class="col-span-2">
            <div class="flex flex-col pt-6">
              <button
                class="
                  bg-btn-bg-primary
                  text-btn-text-primary
                  my-2
                  py-4
                  rounded
                  text-sm
                  font-bold
                "
                type="submit"
             
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- <BSpinner v-if="isLoading" text="Verifying" />
    
        <BSpinner v-else-if="bvnResponse.isLoading" text="Verifying BVN" /> -->
  </div>
</template>

<script>
import { ref, toRefs, reactive, computed } from 'vue';

// import BSpinner from '@/components/ui/BSpinner';

import BButtonLoader from '../../../components/ui/BButtonLoader.vue';
import imageCompression from 'browser-image-compression';
import { useToast } from 'vue-toastification';
import ProjectImageCrop from '../../createProject/ProjectImageCrop.vue';
import BStepperLG from '../../../components/ui/BStepperLG.vue';
import BSelectInput from '../../../components/ui/BSelectInput.vue';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

export default {
  name: 'StepOne',

  components: {
    // BSpinner,

    BButtonLoader,
    ProjectImageCrop,
    BStepperLG,
    BSelectInput,
  },

  props: ['user'],

  emits: ['next', 'reset'],

  setup(props, { emit }) {
    const steps = ref([{}]);
    const show = ref(false);
    const show2 = ref(false);
    const cropImage = ref(false);
    const file = ref('');
    const toast = useToast();

    const states = [
      'Abia',
      'Adamawa',
      'Akwa Ibom',
      'Anambra',
      'Bauchi',
      'Bayelsa',
      'Benue',
      'Borno',
      'Cross River',
      'Delta',
      'Ebonyi',
      'Edo',
      'Ekiti',
      'Enugu',
      'FCT - Abuja',
      'Gombe',
      'Imo',
      'Jigawa',
      'Kaduna',
      'Kano',
      'Katsina',
      'Kebbi',
      'Kogi',
      'Kwara',
      'Lagos',
      'Nasarawa',
      'Niger',
      'Ogun',
      'Ondo',
      'Osun',
      'Oyo',
      'Plateau',
      'Rivers',
      'Sokoto',
      'Taraba',
      'Yobe',
      'Zamfara',
    ];
    const idTypes = [
      'International Passport',
      'Driver License',
      'National ID',
      'Voter’s Card',
    ];
    const required$ = helpers.withMessage('This field is required', required);
    const formInput = reactive({
      address: '',
      state: '',
      idType: '',
      idFile: '',
    });

    const rules = {
      address: { required$ },
      state: { required$ },
      idType: { required$ },
      idFile: { required$ },
    };

    const v$ = useVuelidate(rules, formInput);

    const isFilled = computed(() => {
      if (
        formInput.address &&
        formInput.state &&
        formInput.idType &&
        formInput.idFile
      ) {
        return true;
      } else {
        return false;
      }
    });
    const handleContinue = async () => {
      const result = await v$.value.$validate();
      if (!result) {
        document.getElementById('form').scrollIntoView({
          behavior: 'smooth',
        });
        return;
      }
      emit('next', formInput);
    };
    const isCompressing = ref(false);

    const toggleCropImage = () => {
      cropImage.value = !cropImage.value;
    };

    const handleFileInput = async ({ target }) => {
      try {
        isCompressing.value = true;
        let targetFile = target.files[0];
        file.value = await imageCompression(targetFile, { maxSizeMB: 0.3 });
        toggleCropImage();
      } catch (error) {
        console.log(error);
      } finally {
        isCompressing.value = false;
      }
    };

    const handleSave = (base64) => {
      const imageSize = Math.round(
        Buffer.from(base64.substring(base64.indexOf(',') + 1)).length / 1e3
      );
      if (imageSize > 1000) {
        toast.error(
          'Image size is too large, Maximum image size should be 1MB.'
        );
      } else {
        const { name } = file.value;
        formInput.idFile = { base64, name, mimetype: 'image/jpeg' };
        file.value = null;
      }
      toggleCropImage();
    };

    const handleCancel = () => {
      file.value = null;
      toggleCropImage();
    };

    const handlePrevious = () => {
      emit('previous');
    };

    return {
      steps,
      ...toRefs(formInput),
      isFilled,
      show,
      show2,
      states,
      idTypes,
      handleFileInput,

      isCompressing,
      cropImage,
      file,
      handleSave,
      handleCancel,
      handleContinue,
      handlePrevious,
      v$,
    };
  },
};
</script>
<style lang="scss" scoped>
.decorated {
  &::after {
    background: #000;
    width: 100px;
  }
}
</style>
