<template>
  <BModal :isOpen="modelValue" :heading="'Verify Donations'" @close="closeModal" >
    <div class="lg:pt-6">
      <BPageLoader v-if="isLoading" class="flex-grow" />
      <div v-else-if="stage === 2">
        <div class="flex flex-col my-3 text-text-primary font-semibold text-sm sticky-20">
          <input
            class="
              border
              rounded
              border-border-primary
              mt-2
              mb-1
              px-4
              py-3.75
              text-sm
              font-semibold
              focus:outline-none
              input-shadow
            "
            placeholder="Search banks"
            type="text"
            v-model="filter"
          />
        </div>
        <ul class="h-60 overflow-y-scroll text-text-primary">
          <li class="my-4" v-for="bank in searchBanks" :key="bank.id">
            <button
              class="py-1 font-semibold text-sm w-full text-left focus:outline-none"
              @click="choseBank(bank)"
            >
              {{ bank.name }}
            </button>
          </li>
        </ul>
      </div>
      <template v-else-if="stage === 3">
        <div class="text-center flex flex-col items-center" v-if="isFailure">
          <FailureIcon class="my-4 mx-auto" />
          <h2 class="text-lg text-text-primary font-semibold my-4">Donation Not Received</h2>
          <p class="text-xs xs:text-sm text-text-primary">
            We have not received your donation yet. If you have made a transfer, please note that it
            can take up to 24 hours for transfers to reflect.
          </p>
          <button
            class="
              bg-btn-bg-primary
              max-w-sm
              text-btn-text-primary
              rounded
              w-full
              mt-10
              py-4
              font-bold
              text-sm
              xs:text-base
              focus:outline-none
            "
            @click="
              () => {
                closeModal();
                $emit('bless');
              }
            "
          >
            Bless Fundraiser
          </button>
          <button
            class="
              text-text-primary
              focus:outline-none
              py-4
              xs:py-5
              my-2
              rounded
              text-center text-sm
              xs:text-base
              font-bold
            "
            @click="closeModal"
          >
            Cancel
          </button>
        </div>
        <ul v-else-if="isSuccess">
          <li
            class="border-b border-border-primary py-2.5"
            v-for="payment in data"
            :key="payment.id"
          >
            <header>
              <h2 class="flex justify-between text-sm">
                <span class="text-text-alternate-1 font-semibold">
                  {{ payment.accountName }}
                </span>
                <span class="font-bold" style="color: #017622">
                  ₦{{ payment.amount.toLocaleString() }}
                </span>
              </h2>
              <div class="flex justify-between items-center">
                <div class="text-text-alternate-7 font-medium">
                  <span class="text-2xs mr-2">{{
                    dayjs(payment.createdAt).format('DD MMM YYYY')
                  }}</span>
                  <span class="text-2xs">{{ dayjs(payment.createdAt).format('HH:mm:ss') }}</span>
                </div>
                <button class="focus:outline-none" @click="toggleOpen(payment.id)">
                  <ChevronDownIcon
                    :class="['transform fill-current h-4', { 'rotate-180': openIds[payment.id] }]"
                  />
                </button>
              </div>
            </header>
            <div class="text-2xs" v-if="openIds[payment.id]">
              <div class="py-2" v-if="payment.bankCode">
                <h4 class="text-text-alternate-7 font-medium">Bank</h4>
                <p class="py-2 font-semibold text-text-alternate-1">
                  {{ getBankName(payment.bankCode) || 'N/A' }}
                </p>
              </div>
              <div class="py-2" v-else>
                <h4 class="text-text-alternate-7 font-medium">Card Type</h4>
                <p class="py-2 font-semibold text-text-alternate-1">
                  {{ payment.cardType || 'N/A' }}
                </p>
              </div>
              <div class="py-2" v-if="payment.accountNumber">
                <h4 class="text-text-alternate-7 font-medium">Account No</h4>
                <p class="py-2 font-semibold text-text-alternate-1">
                  {{ payment.accountNumber }}
                </p>
              </div>
              <div class="py-2" v-else>
                <h4 class="text-text-alternate-7 font-medium">Card Number</h4>
                <p class="py-2 font-semibold text-text-alternate-1">
                  {{ payment.cardLast4Digits ? `**********${payment.cardLast4Digits}` : 'N/A' }}
                </p>
              </div>
              <div class="py-2">
                <h4 class="text-text-alternate-7 font-medium">Remarks</h4>
                <p class="py-2 font-semibold text-text-alternate-1">
                  {{ payment.desc || 'N/A' }}
                </p>
              </div>
              <div class="pt-2">
                <h4 class="text-text-alternate-7 font-medium">Transaction Reference</h4>
                <p class="py-2 font-semibold text-text-alternate-1">
                  {{ payment.transactionReference || 'N/A' }}
                </p>
              </div>
            </div>
          </li>
        </ul>
      </template>
      <form
        class="pb-14"
        v-else-if="verificationType === 'card'"
        @submit.prevent="handleCardSubmit"
      >
        <div class="flex flex-col mt-6 text-text-primary font-semibold text-sm mb-8">
          <label>Email Address</label>
          <input
            class="
              border
              rounded
              border-border-primary
              mt-2
              mb-1
              px-4
              py-3.75
              text-sm
              font-medium
              focus:outline-none
              input-shadow
            "
            placeholder="Email Address"
            type="email"
            v-model.trim="email"
          />
        </div>
        <div class="px-6 py-4 flex gap-4 z-10 bg-bg-alternate-3 lg:bg-text-secondary absolute left-0 w-full  bottom-13">
          <button
            class="
              bg-btn-bg-primary
              text-btn-text-primary
              px-4
              py-3.5
              xs:py-4
              w-full
              rounded
              text-center text-sm
              xs:text-base
              font-bold
            "
            :disabled="!email"
            type="submit"
          >
            Continue
          </button>
        </div>
      </form>
      <form class="pb-14" v-else-if="verificationType === 'transfer'" @submit.prevent="stage = 2">
        <div class="flex flex-col mt-6 text-text-primary font-semibold text-sm mb-8">
          <label>Bank Account Number</label>
          <input
            class="
              border
              rounded
              border-border-primary
              mt-2
              mb-1
              px-4
              py-3.75
              text-sm
              font-medium
              focus:outline-none
              input-shadow
            "
            placeholder="Account Number"
            type="text"
            :maxlength="10"
            v-model="bankNumber"
          />
        </div>
        <div class="px-6 py-4 flex gap-4 z-10 bg-bg-alternate-3 lg:bg-text-secondary absolute left-0 w-full bottom-13">
          <button
            class="
              bg-btn-bg-primary
              text-btn-text-primary
              px-4
              py-3.5
              xs:py-4
              w-full
              rounded
              text-center text-sm
              xs:text-base
              font-bold
            "
            :disabled="!bankNumber"
            type="submit"
          >
            Continue
          </button>
        </div>
      </form>
      <div v-else>
        <button
          class="
            border border-btn-border-alternate-1 
            btn-shadow
            w-full
            rounded
            p-6
            flex
            items-center
            my-4
          "
          @click="verificationType = 'transfer'"
        >
          <BankIcon class="h-6 fill-current" style="fill: #577387" />
          <p class="text-xs ml-4 font-semibold text-text-alternate-1">Verify Bank Transfers</p>
        </button>
        <button
          class="
            border border-btn-border-alternate-1
            btn-shadow
            w-full
            rounded
            p-6
            flex
            items-center
            my-4
          "
          @click="verificationType = 'card'"
        >
          <CreditCardIcon class="h-6 fill-current" style="fill: #577387" />
          <p class="text-xs ml-4 font-semibold text-text-alternate-1">Verify Card Payment</p>
        </button>
      </div>
    </div>
  </BModal>
</template>

<script>
import { ref, reactive, computed, watch, toRefs } from 'vue';
import BModal from '@/components/ui/BModal';
import { banks } from '@/data/banks';
import { useApi } from '@/cmp-functions/useApi';
import { verifyTransfer } from '@/services/api';
import dayjs from 'dayjs';

export default {
  name: 'BlesserChecker',

  components: { BModal },

  props: {
    fundraiserId: {
      required: true,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['bless', 'update:modelValue'],

  setup(props, { emit }) {
    const filter = ref('');
    const verifyData = reactive({
      verificationType: '',
      email: '',
      bankNumber: '',
    });

    const stage = ref(1);
    const openIds = ref({});
    const searchBanks = computed(() => {
      return banks.filter(({ name }) => name.toLowerCase().includes(filter.value.toLowerCase()));
    });

    const [response, checkTransfer] = useApi(verifyTransfer);

    const choseBank = (selectedBank) => {
      checkTransfer(props.fundraiserId, {
        accountNumber: verifyData.bankNumber,
        bankCode: selectedBank.nipCode,
        verificationType: verifyData.verificationType,
      });
      stage.value = 3;
    };

    const handleCardSubmit = () => {
      checkTransfer(props.fundraiserId, {
        email: verifyData.email,
        verificationType: verifyData.verificationType,
      });
      stage.value = 3;
    };

    const getBankName = (bankCode) => {
      return banks.find(({ nipCode }) => nipCode === bankCode)?.name;
    };

    const toggleOpen = (id) => {
      openIds.value = { [id]: !openIds.value[id] };
    };

    const closeModal = () => {
      verifyData.verificationType = '';
      verifyData.email = '';
      verifyData.bankNumber = '';
      emit('update:modelValue', false);
      stage.value = 1;
    };

    watch(response, ({ data, isSuccess }) => {
      if (isSuccess) {
        openIds.value = { [data[0]?.id]: true };
      }
    });

    return {
      ...toRefs(response),
      ...toRefs(verifyData),
      getBankName,
      stage,
      closeModal,
      handleCardSubmit,
      openIds,
      toggleOpen,
      dayjs,
      choseBank,
      filter,
      searchBanks,
    };
  },
};
</script>
