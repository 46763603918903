<template>
  <div>
    <div v-if="fundraiser.fundraiserType !== 'PERMANENT'">
      <section
        class="py-2 text-text-alternate-1"
        v-if="
          (!isModal &&
            !isExpired &&
            authUser?.id === fundraiser.userdetail.userid) ||
          fundraiser.fundraiserupdates?.length
        "
      >
        <header class="flex justify-between py-2 font-semibold items-center">
          <h2 class="text-lg">Updates</h2>
          <button
            class="font-medium flex items-center"
            @click="$emit('update')"
            v-if="fundraiser.fundraiserupdates?.length"
          >
            <span class="text-xs pr-1">View all</span>
            <span class="text-lg">&#8250;</span>
          </button>
        </header>
        <div
          class="py-2 text-xs xs:text-sm leading-4-1/2 xs:leading-5"
          v-if="fundraiser.fundraiserupdates.length"
        >
          <p>
            {{ fundraiser.fundraiserupdates[0].message }}
          </p>
        </div>
        <form
          class="border-border-primary pt-2"
          @submit.prevent="handleSubmit"
          v-if="!isExpired && authUser?.id === fundraiser.userdetail.userid"
        >
          <label class="font-semibold text-sm">Add an update</label>
          <div
            class="
              border
              rounded
              border-border-primary
              flex
              w-full
              items-center
              overflow-hidden
              my-2
              input-shadow
            "
          >
            <input
              class="p-4 focus:outline-none flex-grow text-xs"
              type="text"
              placeholder="Update here..."
              v-model="update"
              :readonly="updateResponse.isLoading"
            />
            <BButtonLoader
              class="mx-3 h-5 w-5"
              v-if="updateResponse.isLoading"
            />
            <button class="focus:outline-none" type="submit" v-else>
              <SendIcon class="h-6 fill-current mx-3" />
            </button>
          </div>
        </form>
      </section>
      <section
        class="py-3 border-t border-border-primary lg:hidden"
        v-if="fundraiser.blessings?.length"
      >
        <span v-if="fundraiser.components.includes('3')">
          <div
            class="
              flex
              justify-between
              font-semibold
              text-lg
              py-3
              text-text-alternate-1
            "
          >
            <h2>Top Blessors</h2>
            <span
              v-if="isModal"
              class="underline"
              @click="$emit('allBlessors')"
            >
              ({{ fundraiser.totalBlessingsCount }})
            </span>
            <button
              v-else
              class="underline font-semibold"
              @click="$emit('allBlessors')"
            >
              ({{ fundraiser.totalBlessingsCount }})
            </button>
          </div>
          <BBlessor
            class=""
            v-for="blessor in fundraiser.blessings"
            :key="blessor.id"
            :blessor="blessor"
          />
        </span>
        <template v-if="!isModal">
          <button
            v-if="fundraiser.components.includes('3')"
            class="
              border border-btn-border-secondary
              items-center
              w-full
              px-4
              py-3.75
              text-center
              rounded
              text-text-alternate-1
              font-bold
              text-sm
              mt-4
              mb-2
            "
            @click="$emit('allBlessors')"
          >
            Show more
          </button>
          <button
            class="
              bg-btn-bg-alternate-1
              text-btn-text-alternate-1
              w-full
              py-4
              rounded
              text-center text-sm
              font-bold
              my-2
            "
            @click="$emit('verify')"
          >
            Verify Your Donation
          </button>
        </template>
      </section>
      <section
        class="py-2 border-t border-border-primary text-text-alternate-1"
        v-if="!isModal"
      >
        <h2 class="text-lg font-semibold mt-2">Fundraiser Members</h2>

        <!-- {{ fundraiser.id }} -->
        <div
          v-for="(member, index) in members"
          class="
            flex
            gap-2
            xs:gap-3
            rounded
            justify-between
            items-center
            text-xs
            my-4
            font-semibold
          "
          :key="member.userid"
        >
          <img
            class="
              rounded-full
              border border-border-primary
              bg-bg-alternate-3
              text-xs
              xs:text-sm
              h-8
              xs:h-10
              w-8
              xs:w-10
            "
            v-if="
              member.profilePhoto ||
              (members[0].profilePhoto &&
                index > 0 &&
                member.firstname == members[0].firstname &&
                member.lastname == members[0].lastname)
            "
            :src="members[0].profilePhoto"
            alt=""
          />
          <div
            v-else
            class="
              rounded-full
              border border-border-primary
              bg-bg-alternate-3
              text-xs
              xs:text-sm
              h-8
              xs:h-10
              w-8
              xs:w-10
              flex flex-shrink-0
              justify-center
              items-center
            "
            :style="{
              backgroundColor: randomColor({
                luminosity: 'light',
                seed: `${member.firstname[0]}${member.lastname[0]}`,
                format: 'rgba',
                alpha: 0.3,
              }),
              color: randomColor({
                luminosity: 'bright',
                seed: `${member.firstname[0]}${member.lastname[0]}`,
              }),
            }"
          >
            {{ member.firstname[0] }}{{ member.lastname[0] }}
          </div>

          <div class="w-full flex flex-col justify-between">
            <p class="capitalize text-text-alternate-3 my-0.5 font-medium">
              {{ member.memberType.toLowerCase() }}
            </p>
            <a
              v-if="member.link"
              class="flex xs:text-sm text-text-alternate-1 my-0.5 font-bold"
              :href="member.link"
              target="_blank"
            >
              <span class="flex items-center">
                {{ member.firstname }} {{ member.lastname }}
                <VerifiedIcon
                  v-if="
                    member.verificationStatus == 'VERIFIED' ||
                    (member.firstname == members[0].firstname &&
                      member.lastname == members[0].lastname)
                  "
                  class="h-3 pl-1 pr-5 flex-shrink-0"
                />
              </span>
            </a>
            <router-link
              v-else
              class="flex xs:text-sm text-text-alternate-1 my-0.5 font-bold"
              :to="{ name: 'Profile', params: { username: member.username } }"
            >
              {{ member.firstname }} {{ member.lastname }}
              <VerifiedIcon
                v-if="
                  fundraiser.id == '3499' ||
                  member.verificationStatus == 'VERIFIED' ||
                  (index > 0 &&
                    member.firstname == members[0].firstname &&
                    member.lastname == members[0].lastname)
                "
                class="h-3 pl-1 pr-5 flex-shrink-0"
              />
            </router-link>
          </div>
        </div>
      </section>
      <section
        class="py-2 text-text-alternate-1 border-t border-border-primary"
        v-if="!isModal && socialmedialinks?.length"
      >
        <header class="py-2 font-semibold">
          <h2 class="text-lg">Fundraiser on Social Media</h2>
        </header>
        <div class="py-2 text-xs flex">
          <a
            v-for="social in socialmedialinks"
            :key="social.id"
            :href="`//${social.link.replace(/^https?:\/\//, '')}`"
            target="_blank"
          >
            <FBIcon
              v-if="social.smname === 'Facebook'"
              class="h-6 stroke-0 stroke-current fill-current mr-5"
            />
            <IGIcon
              v-else-if="social.smname === 'Instagram'"
              class="h-6 stroke-0 stroke-current fill-current mr-5"
            />
            <TWIcon
              v-else-if="social.smname === 'Twitter'"
              class="h-6 stroke-0 stroke-current fill-current mr-5"
            />
            <YTIcon
              v-else-if="social.smname === 'YouTube'"
              class="h-6 stroke-0 stroke-current fill-current mr-5"
            />
            <TKKIcon
              v-else-if="social.smname === 'TikTok'"
              class="h-6 stroke-0 stroke-current fill-current mr-5"
            />
          </a>
        </div>
      </section>
    </div>
    <div v-else class="pt-8">
      <span class="text-xs xs:text-sm py-1 leading-4-1/2 xs:leading-5-1/2">
        <!-- {{ description }} -->
        <div v-html="description"></div>
        <template v-if="isLenghtExceeded">
          <button
            class="font-semibold focus:outline-none"
            v-if="isDescTruncated"
            @click="$emit('shouldTruncate', false)"
          >
            ...See&nbsp;more
          </button>
          <button
            class="font-semibold focus:outline-none"
            v-else
            @click="$emit('shouldTruncate', true)"
          >
            ...See&nbsp;less
          </button>
        </template>
      </span>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import BBlessor from '@/components/ui/BBlessor';
import randomColor from 'randomcolor';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { useApi } from '@/cmp-functions/useApi';
import { postFundraiserUpdate } from '@/services/api';

export default {
  name: 'Overview',

  components: { BBlessor },

  props: [
    'fundraiser',
    'authUser',
    'isExpired',
    'isModal',
    'description',
    'isLenghtExceeded',
    'shouldTruncateDesc',
    'isDescTruncated',
  ],

  emits: ['update', 'addUpdate', 'verify', 'allBlessors'],

  setup(props, { emit }) {
    const route = useRoute();
    const { id: fundraiserId } = route.params;

    const socialmedialinks = computed(() =>
      props.fundraiser.socialmedialinks.filter(({ link }) => !!link?.trim())
    );

    const update = ref('');

    const members = computed(() => {
      const members = [];

      props.fundraiser.members.forEach(
        ({ memberType, userdetail, link, profilePhoto, fullname }) => {
          let member;
          if (link) {
            const [firstname, lastname] = fullname.split(' ');
            member = {
              firstname,
              lastname: lastname || ' ',
              link,
              memberType,
              userid: fullname,
              profilePhoto,
            };
          } else if (userdetail) {
            member = { ...userdetail, memberType };
          }
          if (member) {
            if (member.memberType === 'BENEFICIARY') {
              members.unshift(member);
            } else {
              members.push(member);
            }
          }
        }
      );

      members.unshift({
        ...props.fundraiser.userdetail,
        memberType: 'ORGANIZER',
      });

      return members;
    });

    const [updateResponse, postUpdate] = useApi(postFundraiserUpdate);

    const handleSubmit = () => {
      if (!update.value.trim()) return;
      postUpdate({
        message: update.value.trim(),
        fundraiserId,
      });
    };

    const slug = computed(() => {
      const { id, title } = props.fundraiser;
      return `${id}-${title
        .trim()
        .toLowerCase()
        .replace(/[^a-z\d\s:]/g, '')
        .replace(/\s+/g, '-')}`;
    });

    watch(updateResponse, ({ isSuccess, data }) => {
      if (isSuccess) {
        emit('addUpdate', data);
        update.value = '';
      }
    });

    return {
      members,
      update,
      randomColor,
      socialmedialinks,
      updateResponse,
      handleSubmit,
      slug,
    };
  },
};
</script>
