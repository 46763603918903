<template>
  <BModal :heading="'Cancel Pledge'" :isOpen="modelValue" @close="closeModal">
    <div class="lg:pt-6">
      <BPageLoader v-if="isLoading" />
      <div
        class="text-center flex flex-col items-center"
        v-else-if="cancelPledgeResponse.isSuccess"
      >
        <SuccessIcon class="my-4 mx-auto" />
        <h2 class="text-lg text-text-primary font-semibold my-4">
          Pledge was cancelled successfully
        </h2>
        <button
          class="
            bg-btn-bg-primary
            text-btn-text-primary
            w-full
            focus:outline-none
            py-4
            mt-4
            mb-2
            rounded
            text-center text-sm
            font-bold
          "
          @click="closeModal"
        >
          Done
        </button>
      </div>
      <div class="text-xs" v-else-if="isSuccess">
        <p class="mb-6 text-text-primary">Please find details of your pledge below.</p>
        <ul>
          <li class="flex justify-between font-semibold my-4">
            <span class="text-text-alternate-3">Pledged on</span>
            <span class="text-text-alternate-1">
              {{ dayjs(data.pledgedOn).format(' DD MMM YYYY') }}
            </span>
          </li>
          <li class="flex justify-between font-semibold my-4">
            <span class="text-text-alternate-3">Pledge Amount</span>
            <span class="text-text-alternate-1">₦{{ data.pledgedAmount.toLocaleString() }}</span>
          </li>
          <li class="flex justify-between font-semibold my-4">
            <span class="text-text-alternate-3">Cycle</span>
            <span class="text-text-alternate-1 capitalize">{{ data.cycle.toLowerCase() }}</span>
          </li>
        </ul>
        <button
          class="
            bg-btn-bg-primary
            text-btn-text-primary
            p-4
            w-full
            rounded
            text-center text-sm
            font-bold
            mt-4
            mb-2
          "
          @click="cancelPledge"
          :disabled="cancelPledgeResponse.isLoading"
        >
          <BButtonLoader class="mx-auto h-4 w-4" v-if="cancelPledgeResponse.isLoading" />
          <span v-else>Cancel Pledge</span>
        </button>
      </div>
      <div class="text-center flex flex-col items-center" v-else-if="isFailure">
        <FailureIcon class="my-4 mx-auto" />
        <h2 class="text-lg text-text-primary font-semibold my-4">Pledge Not Found</h2>
        <p class="text-xs xs:text-sm text-text-primary px-2">
          We cannot find any pledges that you have made<br class="hidden lg:block"/> to this fundraiser.
        </p>
        <button
          class="
            bg-btn-bg-primary
            text-btn-text-primary
            w-full lg:w-1/2
            focus:outline-none
            py-4
            mt-8
            mb-2
            rounded
            text-center text-sm
            font-bold
          "
          @click="closeModal"
        >
          Done
        </button>
      </div>
      <form class="pb-14" v-else-if="!authUser" @submit.prevent="checkPledge(email)">
        <p class="text-xs mb-6 text-text-primary">
          Please enter the email address you used to pledge
        </p>
        <div class="flex flex-col mt-6 text-text-primary font-semibold text-sm mb-8">
          <label>Email</label>
          <input
            class="
              border
              rounded
              border-border-primary
              mt-2
              mb-1
              px-4
              py-3.75
              text-sm
              font-medium
              focus:outline-none
              input-shadow
            "
            type="email"
            v-model.trim="email"
          />
        </div>
        <div class="px-6 py-4 flex gap-4 z-10 bg-bg-alternate-3 lg:bg-text-secondary absolute left-0 w-full bottom-13">
          <button
            class="
              bg-btn-bg-primary
              text-btn-text-primary
              p-4
              w-full
              rounded
              text-center text-sm
              font-bold
            "
            :disabled="!email"
            type="submit"
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  </BModal>
</template>

<script>
import { ref, watch, toRefs } from 'vue';
import BModal from '@/components/ui/BModal';
import { useApi } from '@/cmp-functions/useApi';
import { checkFundraiserPledge, cancelFundraiserPledge } from '@/services/api';
import dayjs from 'dayjs';

export default {
  name: 'CancelPledge',

  components: { BModal },

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    authUser: {
      required: true,
    },
    fundraiserId: {
      required: true,
    },
  },

  emits: ['bless', 'update:modelValue'],

  setup(props, { emit }) {
    const email = ref('');

    const closeModal = () => {
      email.value = '';
      checkPledgeResponse.isSuccess = false;
      checkPledgeResponse.isFailure = false;
      emit('update:modelValue', false);
    };

    const [checkPledgeResponse, checkUserPledge] = useApi(checkFundraiserPledge);
    const [cancelPledgeResponse, cancelUserPledge] = useApi(cancelFundraiserPledge);

    const checkPledge = () => {
      checkUserPledge({ email: email.value, fundraiserId: props.fundraiserId });
    };

    const cancelPledge = () => {
      if (props.authUser) {
        cancelUserPledge({
          email: props.authUser.email,
          fundraiserId: props.fundraiserId,
        });
      } else {
        cancelUserPledge({
          email: email.value,
          fundraiserId: props.fundraiserId,
        });
      }
    };

    watch(
      () => props.modelValue,
      (value) => {
        if (value && props.authUser) {
          checkUserPledge({
            email: props.authUser.email,
            fundraiserId: props.fundraiserId,
          });
        }
      }
    );

    return {
      ...toRefs(checkPledgeResponse),
      cancelPledgeResponse,
      closeModal,
      checkPledge,
      cancelPledge,
      dayjs,
      email,
    };
  },
};
</script>
