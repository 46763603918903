<template>
  <div class="py-4 lg:pt-0 flex flex-col min-h-screen">
    <BHeader class="hidden lg:block lg:mb-24" />
    <header class="lg:hidden mb-3 px-6">
      <div class="flex justify-between">
        <button @click="handlePrev">
          <LeftArrowIcon class="h-6 fill-current" />
        </button>
        <p v-if="active == 1" class="text-lg font-semibold text-text-primary">
          Sign Up
        </p>
        <p></p>
      </div>
    </header>

    <keep-alive>
      <component
      :is="componentName"
     
      :user="user"
      @next="handleNext"
      @reset="handleReset"
      />
    </keep-alive>
  </div>
  <BFooterLG class="hidden z-10 bottom-0 mt-10 lg:block" />
</template>

<script>
import { ref, computed } from 'vue';
import StepOne from './Individual/StepOne';
import Welcome from './Welcome';
import StepTwo from './Individual/StepTwo';
import { useRouter, useRoute } from 'vue-router';
import StepThree from './Individual/StepThree.vue';
import StepFour from './Individual/StepFour.vue';
import BHeader from '../../components/ui/BHeader.vue';
import BFooterLG from '../../components/ui/BFooterLG.vue';

export default {
  name: 'Signup',

  components: {
    // UserDetails,
    Welcome,
    BHeader,
    BFooterLG,
  },

  setup() {
    let active = ref(1);
    const componentName = computed(() => {
      switch (active.value) {
        case 1:
          return Welcome;
        case 2:
          return StepOne;
        case 3:
          return StepTwo;
        case 4:
          return StepThree;
        case 5:
          return StepFour;
        default:
          throw Error('Invalid active component');
      }
    });

    const route = useRoute();
    const user = ref({
      accounttype: '',
      referredBy: route.query.referral ?? '',
    });
    const router = useRouter();

    const handleNext = (userData) => {
      if (userData) {
        user.value = { ...user.value, ...userData };
      }
      active.value += 1;
    };

    const handlePrev = () => {
      if (active.value === 1) router.go(-1);
      else active.value -= 1;
    };

    const handleReset = () => {
      user.value = {};
      active.value = 1;
    };

    return { componentName, handleNext, handlePrev, user, handleReset, active };
  },
};
</script>
