import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/scss/tailwind.scss';
import { setAuth } from '@/utils';
import BButtonLoader from '@/components/ui/BButtonLoader';
import BPageLoader from '@/components/ui/BPageLoader';
import Toast, { POSITION } from 'vue-toastification';
import BFunctional from '@/components/ui/BFunctional';
import 'vue-toastification/dist/index.css';
import { createMetaManager } from 'vue-meta';
import VueGtag from 'vue-gtag';
import { initAppState } from '@/cmp-functions/appState.js';
// importing AOS css style globally
import 'aos/dist/aos.css';
// import VueAnalytics from 'vue3-analytics';
import VueCountdown from '@chenfengyuan/vue-countdown';

import VueSweetAlert from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import YouTube from 'vue3-youtube';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

initAppState();

const options = {
  position: POSITION.BOTTOM_CENTER,
};

const authData = localStorage.getItem('authData');
if (authData) setAuth(JSON.parse(authData));

const app = createApp(App);

(async () => {
  const Icons = await import('../src/icons/index.js');

  Object.entries(Icons).forEach(([iconName, iconComponent]) => {
    app.component(iconName, iconComponent);
  });
  app.component('BButtonLoader', BButtonLoader);
  app.component('BPageLoader', BPageLoader);
  app.component('BFunctional', BFunctional);
  app.component('YouTube', YouTube);
  app.component(VueCountdown.name, VueCountdown);
  app.component('VueDatePicker', VueDatePicker);

  app
    .use(store)
    .use(router)
    .use(createMetaManager())
    .use(VueSweetAlert)
    .use(Toast, options)
    // .use(VueAnalytics, { id: '302704065', router })
    .use(
      VueGtag,
      {
        config: {
          id: 'G-HE657K2QPL',
        },
      },
      router
    )
    .mount('#app');
})();
