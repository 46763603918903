<template>
  <BPageLoader v-if="verifyResponse.isLoading" />
  <div class="px-6 py-8" v-else-if="verifyResponse.isSuccess">
    <div class="lg:hidden">
      <h2 class="font-semibold text-lg text-center">Create a new password</h2>
      <form @submit.prevent="handleSubmit">
        <div class="flex flex-col mt-6">
          <label class="text-text-primary font-semibold" for="password"
            >New Password</label
          >
          <div
            class="
              flex
              items-center
              border
              rounded
              my-2
              overflow-hidden
              input-shadow
              border-border-primary
            "
          >
            <input
              class="
                py-3.5
                pl-3.5
                text-sm
                font-medium
                flex-grow
                focus:outline-none
              "
              @keyup="errorMessage = ''"
              :type="show ? 'text' : 'password'"
              v-model="password"
              placeholder="******"
              required
              id="password"
            />
            <div class="cursor-pointer px-3.5" @click="toggleHidePassword">
              <PasswordShownIcon v-if="show" />
              <PasswordHiddenIcon v-else />
            </div>
          </div>
        </div>
        <div class="flex flex-col mt-6">
          <label class="text-text-primary font-semibold" for="password"
            >Confirm Password</label
          >
          <div
            class="
              flex
              items-center
              border
              rounded
              my-2
              overflow-hidden
              input-shadow
              border-border-primary
            "
          >
            <input
              class="
                py-3.5
                pl-3.5
                text-sm
                font-medium
                flex-grow
                focus:outline-none
              "
              @keyup="errorMessage = ''"
              :type="show2 ? 'text' : 'password'"
              v-model="password2"
              placeholder="******"
              required
              id="password"
            />
            <div class="cursor-pointer px-3.5" @click="toggleHidePassword2">
              <PasswordShownIcon v-if="show2" />
              <PasswordHiddenIcon v-else />
            </div>
          </div>
        </div>

        <button
          class="
            bg-btn-bg-primary
            text-btn-text-primary
            rounded
            w-full
            mt-8
            py-4
            font-bold
            text-sm
            focus:outline-none
          "
          type="submit"
          :disabled="isLoading || !isFilled"
        >
          <BButtonLoader class="mx-auto h-5 w-5" v-if="isLoading" />
          <span v-else>Submit</span>
        </button>
      </form>
    </div>

    <div class="hidden lg:flex justify-center mt-20">
      <div class="b-card px-26 pt-10">
        <h2 class="font-semibold text-lg text-center">Create a new password</h2>
        <form @submit.prevent="handleSubmit">
          <div class="flex flex-col mt-6">
            <label class="text-text-primary font-semibold" for="password"
              >New Password</label
            >
            <div
              class="
                flex
                items-center
                border
                rounded
                my-2
                overflow-hidden
                input-shadow
                border-border-primary
              "
            >
              <input
                class="
                  py-3.5
                  pl-3.5
                  text-sm
                  font-medium
                  flex-grow
                  focus:outline-none
                "
                @keyup="errorMessage = ''"
                :type="show ? 'text' : 'password'"
                v-model="password"
                placeholder="******"
                required
                id="password"
              />
              <div class="cursor-pointer px-3.5" @click="toggleHidePassword">
                <PasswordShownIcon v-if="show" />
                <PasswordHiddenIcon v-else />
              </div>
            </div>
          </div>
          <div class="flex flex-col mt-6">
            <label class="text-text-primary font-semibold" for="password"
              >Confirm Password</label
            >
            <div
              class="
                flex
                items-center
                border
                rounded
                my-2
                overflow-hidden
                input-shadow
                border-border-primary
              "
            >
              <input
                class="
                  py-3.5
                  pl-3.5
                  text-sm
                  font-medium
                  flex-grow
                  focus:outline-none
                "
                @keyup="errorMessage = ''"
                :type="show2 ? 'text' : 'password'"
                v-model="password2"
                placeholder="******"
                required
                id="password"
              />
              <div class="cursor-pointer px-3.5" @click="toggleHidePassword2">
                <PasswordShownIcon v-if="show2" />
                <PasswordHiddenIcon v-else />
              </div>
            </div>
          </div>

          <button
            class="
              bg-btn-bg-primary
              text-btn-text-primary
              rounded
              w-full
              mt-8
              py-4
              font-bold
              text-sm
              focus:outline-none
            "
            type="submit"
            :disabled="isLoading || !isFilled"
          >
            <BButtonLoader class="mx-auto h-5 w-5" v-if="isLoading" />
            <span v-else>Submit</span>
          </button>
        </form>
      </div>
    </div>
  </div>
  <div class="px-6 py-4 text-center flex flex-col items-center" v-else>
    <div class="lg:hidden flex justify-center flex-col items-center">
      <div
        class="
          flex
          items-center
          justify-center
          rounded-full
          my-6
          h-28
          w-28
          bg-bg-alternate-3
        "
      >
        <SearchOffIcon />
      </div>
      <h2 class="text-xl max-w-sm text-text-primary font-semibold my-4">
        Expired or Invalid Link
      </h2>
      <p class="text-xs text-text-alternate-3 font-medium my-4">
        Your password reset link is not valid or has expired, re-initiate the
        password reset with the button below or login if you have a new
        password.
      </p>
      <div class="flex flex-col py-6 gap-4 w-full">
        <router-link
          class="
            bg-btn-bg-primary
            text-btn-text-primary
            py-4
            rounded
            text-sm text-center
            font-bold
          "
          :to="{ name: 'ForgotPassword' }"
        >
          Reset Password
        </router-link>
        <!-- <router-link class="w-full" to="/login"> -->
        <button
          class="
            bg-btn-bg-alternate-1
            text-btn-text-alternate-1
            cursor-pointer
            focus:outline-none
            py-4
            rounded
            text-center text-sm
            font-bold
          "
          @click="goToLogin"
        >
          Login
        </button>
        <!-- </router-link> -->
      </div>
    </div>
    <div class="hidden lg:flex justify-center mt-20">
      <div class="b-card flex justify-center flex-col items-center pt-10 px-26">
        <div
          class="
            flex
            items-center
            justify-center
            rounded-full
            my-6
            h-28
            w-28
            bg-bg-alternate-3
          "
        >
          <SearchOffIcon />
        </div>
        <h2 class="text-xl max-w-sm text-text-primary font-semibold my-4">
          Expired or Invalid Link
        </h2>
        <p class="text-xs text-text-alternate-3 font-medium my-4">
          Your password reset link is not valid or has expired, re-initiate the
          password reset with the button below or login if you have a new
          password.
        </p>
        <div class="flex flex-col py-6 gap-4 w-full">
          <router-link
            class="
              bg-btn-bg-primary
              text-btn-text-primary
              py-4
              rounded
              text-sm text-center
              font-bold
            "
            :to="{ name: 'ForgotPassword' }"
          >
            Reset Password
          </router-link>
          
            <button
              class="
                bg-btn-bg-alternate-1
                text-btn-text-alternate-1
                cursor-pointer
                focus:outline-none
                py-4
                rounded
                text-center text-sm
                font-bold
              "
            >
              Login
            </button>
          
        </div>
      </div>
    </div>
  </div>
  <BModal :isOpen="isSuccess" @close="goToLogin">
    <div class="text-center flex flex-col items-center pb-6">
      <SuccessIcon class="mb-6 mt-4 mx-auto" />
      <p class="text-lg max-w-sm text-text-primary font-semibold mt-4 mb-2">
        Your password was changed successfully.
      </p>
      <p class="text-xs text-text-alternate-3 font-medium">Login to continue</p>
      <router-link class="w-full" to="/login">
        <button
          class="
            bg-btn-bg-primary
            max-w-sm
            text-btn-text-primary
            rounded
            w-full
            mt-8
            py-4
            font-bold
            text-sm
            focus:outline-none
          "
          @click="handleLogin"
        >
          Login
        </button>
      </router-link>
    </div>
  </BModal>
  <BModal :isOpen="isFailure" @close="closeFailureModal">
    <div class="text-center flex flex-col items-center pb-4">
      <FailureIcon class="my-4 mx-auto" />
      <h2 class="text-xl text-text-primary font-semibold my-4">Sorry!</h2>
      <p class="text-xs text-text-alternate-3">
        {{ error }}
      </p>
      <button
        class="
          bg-btn-bg-primary
          max-w-sm
          text-btn-text-primary
          rounded
          w-full
          mt-8
          py-4
          font-bold
          text-sm
          focus:outline-none
        "
        @click="closeFailureModal"
      >
        Close
      </button>
    </div>
  </BModal>
</template>

<script>
import { ref, toRefs, computed } from 'vue';

import BModal from '@/components/ui/BModal';
import { useApi } from '@/cmp-functions/useApi';
import { useRouter } from 'vue-router';
import { resetPassword, verifyPasswordToken } from '@/services/api';
import { useRoute } from 'vue-router';
import useSideMenuState from '@/cmp-functions/useSideMenuState.js';

export default {
  name: 'ResetPassword',

  components: { BModal },

  setup() {
    const password = ref('');
    const password2 = ref('');
    const show = ref(false);
    const show2 = ref(false);
    const showModal = ref(false);
    const router = useRouter();

    const route = useRoute();
    const { ID } = route.query;

    const [response, postPasswordReset, { setIsSuccess, setIsFailure }] =
      useApi(resetPassword);
    const [verifyResponse, postTokenVerification] = useApi(verifyPasswordToken);

    postTokenVerification({ token: ID });

    const handleSubmit = () => {
      postPasswordReset({ token: ID, password: password.value });
    };

    const isFilled = computed(() => {
      if (
        password.value &&
        password2.value &&
        password.value == password2.value
      ) {
        return true;
      } else {
        return false;
      }
    });

    const { state } = useSideMenuState();

    const handleLogin = () => {
      password.value = '';
      password2.value = '';
      closeSuccessModal();
      state.isLoginOpen = true;
    };

    const closeSuccessModal = () => {
      setIsSuccess(false);
    };

    const closeFailureModal = () => {
      setIsFailure(false);
    };

    const toggleHidePassword = () => {
      show.value = !show.value;
    };
    const toggleHidePassword2 = () => {
      show2.value = !show2.value;
    };

    const goToLogin = () => {
      router.push('/login');
    };

    return {
      showModal,
      password,
      password2,
      show,
      show2,
      handleSubmit,
      handleLogin,
      closeSuccessModal,
      closeFailureModal,
      ...toRefs(response),
      ...toRefs(state),
      verifyResponse,
      toggleHidePassword,
      toggleHidePassword2,
      isFilled,
      goToLogin,
    };
  },
};
</script>
