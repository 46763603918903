<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content}` : `${SITE_NAME}`
    }}</template>
  </metainfo>
  <!-- <div :class="['mx-auto overflow-x-hidden', { 'm-max-width': !isLarge }]"> -->
  <div :class="['mx-auto', { 'm-max-width': !isLarge }]">
    <BSplashScreen v-if="timer" />
    <!-- <transition name="slide-left" mode="out-in" appear v-else> -->
    <div class="flex" v-else>
      <BSideMenu />
      <!-- <div
        :class="[
          'bg-bg-primary relative',
          { 'overlay': isSideMenuOpen },
          { 'm-max-width': !isLarge },
        ]"
      > -->
      <!-- 'bg-bg-primary relative ease-in-out duration-200  transition-all', -->
      <div
        :class="[
          'bg-bg-primary relative ease-in-out mx-auto',
          isLarge ? 'w-full' : 'm-max-width',
        ]"
      >
        <component
          :is="this.$route.meta.layout || 'div'"
          style="width: inherit"
        >
          <router-view />
        </component>
      </div>
    </div>
    <!-- </transition> -->
    <div class="z-50">
      <!-- <LoginModal class="" :isOpen="isLoginOpen" @close="isLoginOpen = false" /> -->
    </div>
    <TermsModal :isOpen="isTermsOpen" @close="isTermsOpen = false" />
    <PrivacyModal :isOpen="isPrivacyOpen" @close="isPrivacyOpen = false" />
    <OrgRegModal :isOpen="isOrgRegOpen" @close="isOrgRegOpen = false" />
    <AboutUsModal :isOpen="isAboutUsOpen" @close="isAboutUsOpen = false" />
  </div>
</template>

<script>
import { ref, toRefs, readonly, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import BSplashScreen from '@/components/ui/BSplashScreen';
// import LoginModal from '@/components/ui/LoginModal';
import TermsModal from '@/components/ui/TermsModal';
import OrgRegModal from '@/components/ui/OrgRegModal';
import PrivacyModal from '@/components/ui/PrivacyModal';
import AboutUsModal from '@/components/ui/AboutUsModal';
import BSideMenu from '@/components/ui/BSideMenu';
import useSideMenuState from '@/cmp-functions/useSideMenuState.js';
import { useMeta } from 'vue-meta';
const SITE_NAME = 'Blessmi';

export default {
  components: {
    BSplashScreen,
    BSideMenu,
    // LoginModal,
    TermsModal,
    OrgRegModal,
    PrivacyModal,
    AboutUsModal,
  },

  setup() {
    useMeta({
      title: 'Blessmi | Supporting People',
      description:
        "Blessmi is Africa's #1 Fundraising platform. The most trusted name in crowdsourcing. Designed for African Creators, Youtubers, Charities, Comedians, Entertainers, Influencers, Communities, Religous Organisations, and Everyone!",
      og: {
        site_name: 'Blessmi',
        type: 'Website',
        title: 'Blessmi | Supporting People',
        description:
          "Blessmi is Africa's #1 Fundraising platform. The most trusted name in crowdsourcing. Designed for African Creators, Youtubers, Charities, Comedians, Entertainers, Influencers, Communities, Religous Organisations, and Everyone!",
        url: 'https://www.blessmi.com/',
      },
      htmlAttrs: { lang: 'en', amp: true },
    });
    const isLarge = ref(false);
    const router = useRouter();
    const timer = ref(5);
    let timerId;

    const startTimer = (startValue = 3) => {
      timer.value = startValue;
      timerId = setInterval(() => {
        timer.value -= 1;
        if (timer.value <= 0) stopTimer();
      }, 1000);
    };

    const stopTimer = () => clearInterval(timerId);

    onMounted(startTimer);

    onBeforeUnmount(stopTimer);

    router.beforeEach((to, from, next) => {
      isLarge.value = !!to.meta.isLarge;
      next();
    });

    const { state } = useSideMenuState();

    return {
      SITE_NAME,
      isLarge: readonly(isLarge),
      timer,
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss">
#app {
  font-family: 'Montserrat' Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ddd;
}

* {
  touch-action: manipulation;
}

.m-max-width {
  width: 100vw;
  @media screen and (min-width: 768px) {
    width: 768px;
  }
  @media screen and (min-width: 776px) {
    width: 100%;
  }

  @media screen and (min-width: 1024px) {
    width: 1024px;
  }
  // @media screen and (min-width: 1200px) {
  //   width: 1153px;
  // }
  // @media screen and (min-width: 1440px) {
  //   width: 1440px;
  // }
}

// .m-max-width {
//   width: 100vw;
//   @media screen and (min-width: 750px) {
//     width: 750px;
//   }

//   @media screen and (min-width: 1200px) {
//     width: 950px;
//   }
// }

.overlay::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 999;
  background: rgba(22, 23, 34, 0.6);
  // background: transparent;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.w-fff {
  @media only screen and (min-width: 1024px) {
    width: 592px;
  }
}
.w-ff {
  @media only screen and (min-width: 1024px) {
    width: 360px;
  }
  // .w-modal {
  //   @media only screen and (min-width: 1024px) {
  //     width: 696px !important;
  //   }
  // }
}
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media only screen and (min-width: 1024px){
  .b-card {
    background: #ffffff;
    border: 1px solid #e2e8f0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    width: 750px;
    min-height: 645px;
  }

}

body {
  @apply overflow-x-hidden;
  &.modal-open {
    overflow: hidden;
    height: 100%;
    // position: fixed;
    // left: 0 !important;
    // right: 0 !important;
    // top: 0 !important;
    // bottom: 0 !important;
  }

  * {
    @apply font-body;
    -webkit-tap-highlight-color: transparent;
  }
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  background: #e0f2ff;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background: #577387;
  cursor: pointer;
  border-radius: 50%;
}

.Vue-Toastification__toast-body {
  font-size: 14px !important;
}

.Vue-Toastification__toast {
  padding: 0 16px !important;
  min-height: 52px !important;
  border-radius: 5px !important;
  align-items: center;
  font-weight: 500 !important;
  width: 300px !important;

  &.bottom-center {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  &--success {
    background: #57b894 !important;
  }
}

.slide-left-leave-active {
  transition-duration: 0.5s;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  opacity: 0;
  transform: translate(-100vw, 0);
  overflow: hidden;
}

.slide-left-enter-active {
  transition-duration: 0.5s;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  opacity: 0;
  transform: translate(100vw, 0);
  overflow: hidden;
}

// .slide-left-enter-from,
// .slide-fade-leave-to {
//   opacity: 0;
//   transform: translate(100vw, 0);
// }

.ql-container.ql-snow {
  border: 0 !important;
}
.ql-toolbar.ql-snow {
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
}

.ussd-text {
  @apply mb-0 text-4xl font-semibold;
  color: #00579f;
}
.form-label {
  @apply mb-0 text-xs font-medium;
  color: #7e7b7b;
}
.ussd-card {
  @apply text-center flex flex-col justify-center items-center my-4 mb-28;
  height: 76px;
  border-radius: 4px;
  background-color: #e0f2ff;
  text-align: center !important;
}

.copy-text {
  @apply text-xs;
  color: #577387;
}
.btn-ussd {
  height: 74px !important;
}

.swal2-popup {
    font-size: 14px !important;
}

.swal2-styled {
    padding: 10px 32px 10px 32px !important;
    margin: 20px 10px 0px 10px !important;
    width: 170px;
    height: 45px;
}
.swal2-icon{
  height: 40px;
  width: 40px;
}
button.swal2-confirm.swal2-styled{
  background: #3e3c3c;
}
button.swal2-cancel.swal2-styled{
  background: #fff;
  color: #3e3c3c;
  border: 1px solid #3e3c3c;
}
.dp__pointer{
  border: 0 !important;
}
.dp__action_button{
  height: 38px !important;
  width: 150px !important;
}
</style>
