<template>
  <footer class="bg-bg-primary m-max-width mx-auto" v-if="!isSideMenuOpen">
  <!-- <footer class="bg-bg-primary m-max-width mx-auto"> -->
    <nav class="w-full font-extrabold text-text-alternate-3">
      <ul
        class="flex text-2xs text-center justify-between items-center pt-3 pb-5 px-5 xs:px-6 sm:px-8 nav-shadow m-max-width"
      >
        <li
          :class="[
            'flex flex-col item-center gap-1',
            { 'text-text-alternate-4': routeName === 'Feeds' },
          ]"
        >
          <router-link :to="{ name: 'Feeds', params: { filter: 'latest' } }">
            <HomeIcon class="mx-auto h-6 stroke-2 stroke-current" />
          </router-link>
        </li>
        <li
          :class="[
            'flex flex-col item-center gap-1',
            { 'text-text-alternate-4': routeName === 'Filter' },
          ]"
        >
          <router-link :to="{ name: 'Filter' }">
            <DiscoverIcon class="mx-auto h-6 stroke-2 stroke-current" />
          </router-link>
        </li>
        <li
        v-if="!authUser"
          :class="[
            'flex flex-col item-center gap-1',
            { 'text-text-alternate-4': routeName === 'FAQs' },
          ]"
        >
          <router-link :to="{ name: 'FAQs' }">
            <FAQIcon class="mx-auto h-6 fill-2 fill-current" />
          </router-link>
        </li>
        <template v-else>
          <li
            :class="[
              'flex flex-col item-center gap-1',
              { 'text-text-alternate-4': routeName === 'Notifications' },
            ]"
          >
            <router-link :to="{ name: 'Notifications' }">
              <NotificationIcon class="mx-auto h-6 stroke-2 stroke-current" />
            </router-link>
          </li>
          <li
            :class="[
              'flex flex-col item-center gap-1',
              { 'text-text-alternate-4': routeName === 'AccountsFundraiser' },
            ]"
          >
            <router-link :to="{ name: 'AccountsFundraiser' }">
              <Fundraiser2Icon class="mx-auto h-6 stroke-2 stroke-current" />
            </router-link>
          </li>
          <li
            :class="[
              'flex flex-col item-center gap-1',
              { 'text-text-alternate-4': routeName === 'Wallet' },
            ]"
          >
            <router-link :to="{ name: 'Wallet' }">
              <Wallet2Icon class="mx-auto h-6 stroke-2 stroke-current" />
            </router-link>
          </li>
        </template>
      </ul>
    </nav>
  </footer>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { toRefs } from 'vue';
import useSideMenuState from '@/cmp-functions/useSideMenuState.js';
import { useStore } from 'vuex';

export default {
  name: 'BFooter',
  setup() {
    const route = useRoute();

    const routeName = computed(() => route.name);
    const { state } = useSideMenuState();
    const store = useStore();
    const authUser = computed(() => store.state.auth.authData);

    // return { routeName };
    return { ...toRefs(state), authUser, routeName };
  },
};
</script>
