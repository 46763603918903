<template>
  <div>
    <div class="px-6 lg:hidden">
      <p class="text-sm text-text-alternate-7 font-medium mt-4">
        Choose an option below
      </p>
      <form @submit.prevent="handleContinue" class="">
        <label
          :class="[
            'block mt-4 border-text-alternate-12 rounded h-40 bg-bg-alternate-6 p-4 xs:p-5 my-4 xs:my-5 relative border-box cursor-pointer',
            type === 'INDIVIDUAL' ? ' border-2' : '',
          ]"
        >
          <TickIcon
            v-if="type === 'INDIVIDUAL'"
            class="h-5 xs:h-6 absolute right-4 top-4 z-10 fill-current"
            style="fill: #017622"
          />
          <div
            class="
              flex
              items-center
              justify-center
              h-10
              w-10
              bg-text-secondary
              rounded-full
              mb-4
            "
          >
            <IndividualIcon />
          </div>
          <h3 class="font-semibold mb-2 text-sm text-text-alternate-1">
            Individual
          </h3>
          <p class="text-text-alternate-3 font-medium text-xs xs:text-sm">
            Create a fundraiser for yourself or others who are in need of
            financial support for their projects, talent or work.
          </p>
          <input
            type="radio"
            name="Individual"
            value="INDIVIDUAL"
            hidden
            v-model="type"
          />
        </label>
        <label
          :class="[
            'block border-text-alternate-12 rounded h-40 bg-bg-alternate-9 p-4 xs:p-5 my-4 xs:my-5 relative border-box cursor-pointer',
            type === 'ORGANISATION' ? ' border-2' : '',
          ]"
        >
          <TickIcon
            v-if="type === 'ORGANISATION'"
            class="h-5 xs:h-6 absolute right-4 top-4 z-10 fill-current"
            style="fill: #017622"
          />
          <div
            class="
              flex
              items-center
              justify-center
              h-10
              w-10
              bg-text-secondary
              rounded-full
              mb-4
            "
          >
            <OrganisationIcon />
          </div>
          <h3 class="font-semibold mb-2 text-sm text-text-alternate-1">
            Organisation
          </h3>
          <p class="text-text-alternate-3 font-medium text-xs xs:text-sm">
            Create fundraisers easily for social, internal or public projects as
            a team, organisation or enterprise
          </p>
          <input
            type="radio"
            name="Organisation"
            value="ORGANISATION"
            hidden
            v-model="type"
          />
        </label>
        <p class="text-sm">
          By proceeding, you accept our
          <a
            target="_blank"
            href="/terms-and-conditions"
            class="text-text-alternate-4 font-semibold"
          >
            Terms & Conditions</a
          >
          .
        </p>

        <button
          class="mt-10
            bg-btn-bg-primary
            text-btn-text-primary
            rounded
            w-full
            py-4
            my-4
            font-bold
            text-sm
            xs:text-base
          "
          type="submit"
          :disabled="!type "
        >
          Start
        </button>
      </form>
    </div>

    <div class="hidden lg:flex mt-2 flex-col items-center">
      <div class="b-card flex flex-col justify-cente items-center">
        <div class="mt-6">
          <p class="text-2xl text-text-alternate-1 font-semibold">Sign Up</p>
          <p class="text-sm font-medium text-text-alternate-7 mt-8 mb-4">
            Choose an option below
          </p>

          <form @submit.prevent="handleProceed" class="">
            <label
              :class="[
                'block mt-4 border-text-alternate-12 rounded h-40 bg-bg-alternate-6 p-4 xs:p-5 my-4 xs:my-5 relative border-box cursor-pointer',
                type === 'INDIVIDUAL' ? ' border-2' : '',
              ]"
            >
              <TickIcon
                v-if="type === 'INDIVIDUAL'"
                class="h-5 xs:h-6 absolute right-4 top-4 z-10 fill-current"
                style="fill: #017622"
              />
              <div
                style="width: 40px !important"
                class="
                  flex
                  items-center
                  justify-center
                  h-10
                  w-10
                  bg-text-secondary
                  rounded-full
                  mb-4
                "
              >
                <IndividualIcon />
              </div>
              <h3 class="font-bold mb-2 text-sm text-text-alternate-1">
                Individual
              </h3>
              <p class="text-text-alternate-3 font-medium text-xs xs:text-sm">
                Create a fundraiser for yourself or others who are in need of
                financial support for their projects, talent or work.
              </p>
              <input
                type="radio"
                name="Individual"
                value="INDIVIDUAL"
                hidden
                v-model="type"
              />
            </label>
            <label
              :class="[
                'block border-text-alternate-12 rounded h-40 bg-bg-alternate-9 p-4 xs:p-5 my-4 xs:my-5 relative border-box cursor-pointer',
                type === 'ORGANISATION' ? ' border-2' : '',
              ]"
            >
              <TickIcon
                v-if="type === 'ORGANISATION'"
                class="h-5 xs:h-6 absolute right-4 top-4 z-10 fill-current"
                style="fill: #017622"
              />

              <div
                style="width: 40px !important"
                class="
                  flex
                  items-center
                  justify-center
                  h-10
                  w-10
                  bg-text-secondary
                  rounded-full
                  mb-4
                "
              >
                <OrganisationIcon />
              </div>

              <h3 class="font-bold mb-2 text-sm text-text-alternate-1">
                Organisation
              </h3>
              <p class="text-text-alternate-3 font-medium text-xs xs:text-sm">
                Create fundraisers easily for social, internal or public
                projects as a team, organisation or enterprise
              </p>
              <input
                type="radio"
                name="Organisation"
                value="ORGANISATION"
                hidden
                v-model="type"
              />
            </label>
            <p class="text-xs">
              By proceeding, you accept our
              <a
                target="_blank"
                href="/terms-and-conditions"
                class="text-text-alternate-4 font-semibold"
              >
                Terms & Conditions</a
              >
              .
            </p>

            <button
              class="
                bg-btn-bg-primary
                text-btn-text-primary
                rounded
                w-full
                py-4
                my-4
                mt-10
                font-bold
                text-sm
                xs:text-base
              "
              type="submit"
              :disabled="!type"
            >
              Start
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'Welcome',
  props: ['user'],
  components: {},

  setup() {
    const type = ref('');
    const router = useRouter();

    const handleContinue = () => {
      // emit('next');

      type.value == 'INDIVIDUAL'
        ? router.push('/signup/individual')
        : router.push('/signup/organisation');
    };

    const handleProceed = () => {
      // emit('next');

      type.value == 'INDIVIDUAL'
        ? router.push('/signup/individual')
        : router.push('/signup/organisation');
    };

    return { type, handleContinue, handleProceed };
  },
};
</script>

<style lang="scss" scoped>
.b-card {
  div {
    width: 480px;
  }
}
</style>
