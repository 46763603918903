<template>
  <transition
    enter-active-class="ease-linear duration-300 transition-all"
    enter-from-class="opacity-0"
    leave-active-class="ease-linear duration-300 transition-all"
    leave-to-class="opacity-0"
    leave-class="opacity-100"
    appear
  >
    <div @click.prevent="$emit('close')"
      class="fixed z-10 top-0 left-0 w-screen h-screen flex justify-center"
      style="background: rgba(22, 23, 34, 0.6)"
      v-if="isOpen"
    ></div>
  </transition>
  <transition
    enter-active-class="ease-linear duration-300 transition-all"
    enter-from-class="-bottom-40 opacity-0"
    leave-active-class="ease-linear duration-300 transition-all"
    leave-to-class="-bottom-40 opacity-0"
    leave-class="bottom-0 opacity-100"
    appear
  >
    <div
      :class="[
        'bg-bg-primary z-10 rounded-md fixed transform left-0 bottom-0 right-0 m-max-width mx-auto my-auto ',
      ]"
      class="xl:bottom-1/4 lg:bottom-20 w-modal"
      v-if="isOpen"
    >
      <div
        class="
          text-right
          lg:border-b
          border-bg-alternate-1
          lg:pb-8
          flex
          justify-between
          px-4
          py-4
          h-10
        "
      >
        <p
          class="text-base hidden lg:block font-semibold text-text-primary ml-2"
        >
          {{ heading }}
        </p>
        <button
          class="
            text-3xl
            h-5
            focus:outline-none
            overflow-hidden
            flex
            items-center
            ml-auto
          "
          @click.prevent="$emit('close')"
        >
          &times;
        </button>
      </div>
      <div class="px-6 pb-16 max-h-90vh overflow-y-scroll">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import { watch } from 'vue';

export default {
  name: 'BModal',

  emits: ['close'],

  props: ['isOpen', 'heading'],

  setup(props) {
    watch(props, ({ isOpen }) => {
      if (isOpen) document.body.classList.add('modal-open');
      else document.body.classList.remove('modal-open');
    });
  },
};
</script>

<style scoped>
@media only screen and (min-width: 1024px) {
  .w-modal {
    width: 696px !important;
  }
}
</style>
