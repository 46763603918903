<template>
  <!-- <transition
    enter-active-class="ease-linear duration-300 transition-all"
    enter-from-class="opacity-0"
    leave-active-class="ease-linear duration-300 transition-all"
    leave-to-class="opacity-0"
    leave-class="opacity-100"
    appear
  >
    <div
      class="fixed z-10 top-0 left-0 w-screen h-screen flex justify-center"
      style="background: rgba(22, 23, 34, 0.6)"
      v-if="isOpen"
    ></div>
  </transition> -->
  <transition
    enter-active-class="ease-linear duration-300 transition-all"
    enter-from-class="top-1/2 opacity-0"
    leave-active-class="ease-linear duration-300 transition-all"
    leave-to-class="top-1/2 opacity-0"
    leave-class="top-0 opacity-100"
    appear
  >
    <div
      class="
        bg-bg-primary
        z-10
        fixed
        left-0
        right-0
        top-0
        bottom-0
        overflow-y-scroll
        m-max-width
        mx-auto
      "
      v-if="isOpen"
    >
      <div class="px-6 pt-4 pb-12 flex flex-col min-h-screen text-sm">
        <div class="flex items-center mb-14">
          <button class="focus:outline-none" @click="$emit('close')">
            <LeftArrowIcon class="h-6 fill-current" />
          </button>
          <p class="text-center w-full font-semibold text-lg truncate pl-5 pr-10">
            Organisation Registration
          </p>
        </div>
        <OrganizationIcon class="h-24" />
        <form @submit.prevent="handleSubmit">
          <h2 class="mb-6 mt-10 text-center text-text-primary font-semibold text-lg">
            Are you an Organisation?
          </h2>
          <p class="text-center text-xs font-medium text-text-alternate-3">
            To register as an organisation, please send your application using the form below, we
            will contact you within 24 hours.
          </p>
          <div class="mt-8">
            <AuthInput
              label="Name of Organisation"
              v-model.trim="orgname"
              type="text"
              :required="true"
            />
            <AuthInput
              label="Contact Person"
              v-model.trim="contactperson"
              type="text"
              :required="true"
            />
            <AuthInput
              label="Contact Person’s Email"
              v-model.trim="contactpersonemail"
              type="email"
              :required="true"
            />

            <AuthInput
              label="Contact Person’s Phone"
              v-model="contactpersonphone"
              type="number"
              :required="true"
            />
            <AuthInput
              label="Website (Optional)"
              v-model.trim="website"
              type="text"
              :required="false"
            />
          </div>
          <button
            class="bg-btn-bg-primary text-btn-text-primary rounded w-full mt-8 py-4 font-bold"
            type="submit"
            :disabled="!isFilled || isLoading"
          >
            <BButtonLoader class="mx-auto h-4 w-4" v-if="isLoading" />
            <span v-else>Continue</span>
          </button>
        </form>
      </div>
    </div>
  </transition>
</template>

<script>
import { onBeforeUnmount, reactive, toRefs, watch, computed } from 'vue';
import AuthInput from '@/components/ui/AuthInput';
import { useApi } from '@/cmp-functions/useApi';
import { useToast } from 'vue-toastification';
// import { useRouter } from 'vue-router';
import { registerCompany } from '@/services/api';

export default {
  name: 'OrgRegModal',

  emits: ['close'],

  props: ['isOpen'],

  components: { AuthInput },

  setup(props, { emit }) {
    const toast = useToast();
    // const router = useRouter();

    const companyDetails = reactive({
      contactperson: '',
      contactpersonphone: '',
      orgname: '',
      website: '',
      contactpersonemail: '',
    });

    const [response, authCompany] = useApi(registerCompany);

    watch(response, ({ isFailure, isSuccess }) => {
      if (isSuccess) {
        toast.success('Application Sent!');
        emit('close');
      } else if (isFailure) {
        toast.error('Something went Wrong');
      }
    });

    const handleSubmit = () => {
      authCompany(companyDetails);
    };

    const isFilled = computed(() => {
      const { contactperson, contactpersonphone, orgname, contactpersonemail } = companyDetails;
      return (
        contactperson && contactpersonphone && orgname && /^\w+@\w+\.\w+$/.test(contactpersonemail)
      );
    });

    onBeforeUnmount(() => {
      document.body.classList.remove('modal-open');
    });

    watch(props, ({ isOpen }) => {
      if (isOpen) document.body.classList.add('modal-open');
      else document.body.classList.remove('modal-open');
    });

    return {
      ...toRefs(companyDetails),
      ...toRefs(response),
      isFilled,
      handleSubmit,
    };
  },
};
</script>
