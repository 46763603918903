<template>
  <div class="" id="form">
    <div class="lg:hidden">
      <div class="text-sm">
        <p class="px-6 text-lg font-semibold text-text-primary mb-5">
          Sign up now as an Organisation.
        </p>

        <BStepperOrg :step="3" />
      </div>
      <div class="px-6">
        <form @submit.prevent="handleSubmit">
          <div
            class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
          >
            <!-- {{ verificationResponse }} -->
            <label>BVN*</label>

            <input
              class="
                border
                rounded
                border-border-primary
                mt-2
                mb-1
                p-3.75
                text-sm
                font-medium
                focus:outline-none
                input-shadow
              "
              maxlength="11"
              v-model="bvn"
              type="number"
            />
            <span
              v-for="error in v$.bvn.$errors"
              :key="error.$uid"
              class="text-xs text-text-danger"
              >{{ error.$message }}</span
            >
          </div>

          <div
            class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
          >
            <label>Corporate Bank Account Number*</label>
            <input
              maxlength="10"
              class="
                border
                rounded
                border-border-primary
                mt-2
                mb-1
                p-3.75
                text-sm
                font-medium
                focus:outline-none
                input-shadow
              "
              v-model="banknumber"
              type=""
            />
            <span
              v-for="error in v$.banknumber.$errors"
              :key="error.$uid"
              class="text-xs text-text-danger"
              >{{ error.$message }}</span
            >
          </div>

          <div
            class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
          >
            <label class="mb-2">Bank*</label>
            <BModalSelect
              class="lg:hidden"
              v-model="bank"
              :options="bankNames"
            />
            <span
              v-for="error in v$.bank.$errors"
              :key="error.$uid"
              class="text-xs text-text-danger"
              >{{ error.$message }}</span
            >
          </div>

          <div class="flex flex-col pt-6">
            <button
              class="
                bg-btn-bg-primary
                text-btn-text-primary
                my-2
                py-4
                rounded
                text-sm
                font-bold
              "
              type="submit"
            >
              Continue
            </button>
          </div>
        </form>
      </div>

      <BSpinner v-if="verificationResponse.isLoading" text="Verifying" />

      <BSpinner v-else-if="bvnResponse.isLoading" text="Verifying BVN" />

      <BModal
        v-if="isMobile"
        @close="closeVerificationModal"
        :isOpen="
          verificationResponse.isFailure || verificationResponse.isSuccess
        "
      >
        <div class="text-center flex flex-col items-center" v-if="isFailure">
          <FailureIcon class="my-4 mx-auto" />
          <h2 class="text-xl text-text-primary font-semibold my-4">Sorry!</h2>
          <p class="text-xs text-text-alternate-3">
            We couldn’t verify your bank details, please try again
          </p>
          <button
            class="
              bg-btn-bg-primary
              max-w-sm
              text-btn-text-primary
              rounded
              w-full
              mt-8
              py-4
              font-bold
              text-sm
              focus:outline-none
            "
            @click="closeVerificationModal"
          >
            Retry
          </button>
        </div>
        <div v-else-if="verificationResponse.isSuccess">
          <p class="text-text-alternate-3 text-xs font-medium my-4">
            Please confirm that this is your withdrawal account details
          </p>
          <div class="border rounded border-border-primary p-4 text-xs flex">
            <BankIcon class="h-6 fill-current" style="fill: #323232" />
            <div class="flex flex-col justify-between pl-3">
              <p class="font-semibold text-text-primary truncate uppercase">
                {{ verificationResponse.data.account_name }}
              </p>
              <p class="text-text-alternate-3 font-semibold my-1">
                {{ verificationResponse.data.account_number }}
              </p>
              <p class="text-text-alternate-3 font-medium uppercase">
                {{ bank }}
              </p>
            </div>
          </div>
          <div class="text-center">
            <button
              class="
                bg-btn-bg-primary
                focus:outline-none
                text-btn-text-primary
                p-4
                w-full
                rounded
                text-center text-sm
                font-bold
                my-6
              "
              @click="verificationResponse.isSuccess = false"
            >
              Confirm
            </button>
            <button
              class="
                focus:outline-none
                rounded
                text-center text-sm
                font-semibold
                mx-auto
              "
              @click="closeVerificationModal"
            >
              Change Bank Details
            </button>
          </div>
        </div>
      </BModal>
      <BModal
        @close="bvnResponse.isFailure = false"
        :isOpen="bvnResponse.isFailure"
      >
        <div class="text-center flex flex-col items-center">
          <FailureIcon class="my-4 mx-auto" />
          <h2 class="text-xl text-text-primary font-semibold my-4">Sorry!</h2>
          <p class="text-xs text-text-alternate-3">
            The BVN does not match your bank details, please try again
          </p>
          <button
            class="
              bg-btn-bg-primary
              max-w-sm
              text-btn-text-primary
              rounded
              w-full
              mt-8
              py-4
              font-bold
              text-sm
              focus:outline-none
            "
            @click="bvnResponse.isFailure = false"
          >
            Retry
          </button>
        </div>
      </BModal>

      <BSpinner v-if="isLoading" text="Signing you up" />

      <BModal @close="handleLogin" :isOpen="isOpen">
        <div class="text-center flex flex-col items-center pb-6">
          <SuccessIcon class="mb-8 mt-4 mx-auto" />
          <p class="text-lg max-w-sm text-text-primary font-semibold my-4">
            Registration complete.
          </p>
          <p class="text-xs text-text-alternate-3 font-medium">
            Please check your email to verify your account
          </p>

          <button
            class="
              bg-btn-bg-primary
              max-w-sm
              text-btn-text-primary
              rounded
              w-full
              mt-8
              py-4
              font-bold
              text-sm
              focus:outline-none
            "
            @click="handleLogin"
          >
            Done
          </button>
        </div>
      </BModal>
    </div>
    <div class="lg:flex mt-2 justify-center hidden">
      <div class="b-card p-6">
        <div class="text-sm">
          <div class="flex mb-8">
            <button @click="handlePrevious">
              <LeftArrowIcon class="h-6 fill-current" />
            </button>
            <p class="px-6 text-lg font-semibold text-text-primary">
              Sign up now as an Organisation.
            </p>
          </div>
          <BStepperOrgLG :step="2" />
        </div>
        <div class="px-26">
          <form @submit.prevent="handleSubmit">
            <div
              class="flex flex-col mt-8 text-text-primary font-semibold text-sm"
            >
              <!-- {{bvn}} -->
              <label>BVN*</label>

              <input
                class="
                  border
                  rounded
                  border-border-primary
                  mt-2
                  mb-1
                  p-3.75
                  text-sm
                  font-medium
                  focus:outline-none
                  input-shadow
                "
                maxlength="11"
                v-model="bvn"
                type="number"
              />
              <span
                v-for="error in v$.bvn.$errors"
                :key="error.$uid"
                class="text-xs text-text-danger"
                >{{ error.$message }}</span
              >
            </div>

            <div
              class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
            >
              <label>Corporate Bank Account Number*</label>
              <input
                maxlength="10"
                class="
                  border
                  rounded
                  border-border-primary
                  mt-2
                  mb-1
                  p-3.75
                  text-sm
                  font-medium
                  focus:outline-none
                  input-shadow
                "
                v-model="banknumber"
                type=""
              />
              <span
                v-for="error in v$.banknumber.$errors"
                :key="error.$uid"
                class="text-xs text-text-danger"
                >{{ error.$message }}</span
              >
            </div>

            <div
              class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
            >
              <label class="mb-2">Bank*</label>
              <BSelectInput
                class="hidden lg:block"
                v-model="bank"
                :options="bankNames"
              />
              <span
                v-for="error in v$.bank.$errors"
                :key="error.$uid"
                class="text-xs text-text-danger"
                >{{ error.$message }}</span
              >
            </div>

            <div class="grid grid-cols-3 items-center">
              <div class="col-span-1 pt-4">
                <a
                  href="javascript:void(0)"
                  @click="handlePrevious"
                  class="font-bold text-text-primary"
                  >Previous</a
                >
              </div>
              <div class="col-span-2">
                <div class="flex flex-col pt-6">
                  <button
                    class="
                      bg-btn-bg-primary
                      text-btn-text-primary
                      my-2
                      py-4
                      rounded
                      text-sm
                      font-bold
                    "
                    type="submit"
                  >
                    Create Account
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        <BSpinner v-if="verificationResponse.isLoading" text="Verifying" />

        <BSpinner v-else-if="bvnResponse.isLoading" text="Verifying BVN" />

        <BModal
          class="lg:hidden"
          @close="closeVerificationModal"
          :isOpen="
            verificationResponse.isFailure || verificationResponse.isSuccess
          "
        >
          <div class="text-center flex flex-col items-center" v-if="isFailure">
            <FailureIcon class="my-4 mx-auto" />
            <h2 class="text-xl text-text-primary font-semibold my-4">Sorry!</h2>
            <p class="text-xs text-text-alternate-3">
              We couldn’t verify your bank details, please try again
            </p>
            <button
              class="
                bg-btn-bg-primary
                max-w-sm
                text-btn-text-primary
                rounded
                w-full
                mt-8
                py-4
                font-bold
                text-sm
                focus:outline-none
              "
              @click="closeVerificationModal"
            >
              Retry
            </button>
          </div>
          <div v-else-if="verificationResponse.isSuccess">
            <p class="text-text-alternate-3 text-xs font-medium my-4">
              Please confirm that this is your withdrawal account details
            </p>
            <div class="border rounded border-border-primary p-4 text-xs flex">
              <BankIcon class="h-6 fill-current" style="fill: #323232" />
              <div class="flex flex-col justify-between pl-3">
                <p class="font-semibold text-text-primary truncate uppercase">
                  {{ verificationResponse.data.account_name }}
                </p>
                <p class="text-text-alternate-3 font-semibold my-1">
                  {{ verificationResponse.data.account_number }}
                </p>
                <p class="text-text-alternate-3 font-medium uppercase">
                  {{ bank }}
                </p>
              </div>
            </div>
            <div class="text-center">
              <button
                class="
                  bg-btn-bg-primary
                  focus:outline-none
                  text-btn-text-primary
                  p-4
                  w-full
                  rounded
                  text-center text-sm
                  font-bold
                  my-6
                "
                @click="verificationResponse.isSuccess = false"
              >
                Confirm
              </button>
              <button
                class="
                  focus:outline-none
                  rounded
                  text-center text-sm
                  font-semibold
                  mx-auto
                "
                @click="closeVerificationModal"
              >
                Change Bank Details
              </button>
            </div>
          </div>
        </BModal>
        <BModal
          @close="bvnResponse.isFailure = false"
          :isOpen="bvnResponse.isFailure"
        >
          <div class="text-center flex flex-col items-center">
            <FailureIcon class="my-4 mx-auto" />
            <h2 class="text-xl text-text-primary font-semibold my-4">Sorry!</h2>
            <p class="text-xs text-text-alternate-3">
              The BVN does not match your bank details, please try again
            </p>
            <button
              class="
                bg-btn-bg-primary
                max-w-sm
                text-btn-text-primary
                rounded
                w-full
                mt-8
                py-4
                font-bold
                text-sm
                focus:outline-none
              "
              @click="bvnResponse.isFailure = false"
            >
              Retry
            </button>
          </div>
        </BModal>

        <BSpinner v-if="isLoading" text="Signing you up" />

        <BModal @close="handleLogin" :isOpen="isOpen">
          <div class="text-center flex flex-col items-center pb-6">
            <SuccessIcon class="mb-8 mt-4 mx-auto" />
            <p class="text-lg max-w-sm text-text-primary font-semibold my-4">
              Registration complete.
            </p>
            <p class="text-xs text-text-alternate-3 font-medium">
              Please check your email to verify your account
            </p>

            <button
              class="
                bg-btn-bg-primary
                max-w-sm
                text-btn-text-primary
                rounded
                w-full
                mt-8
                py-4
                font-bold
                text-sm
                focus:outline-none
              "
              @click="handleLogin"
            >
              Done
            </button>
          </div>
        </BModal>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, reactive, computed, watch } from 'vue';

// import BSpinner from '@/components/ui/BSpinner';

import BModalSelect from '../../../components/ui/BModalSelect.vue';
import { banks } from '@/data/banks';
import { useRouter } from 'vue-router';
import { useApi } from '@/cmp-functions/useApi';
import {
  verifyAccount,
  verifyBVN,
  registerOrganisation,
  registerBank,
} from '@/services/api';
import BSpinner from '../../../components/ui/BSpinner.vue';
import BModal from '../../../components/ui/BModal.vue';

import BStepperOrgLG from '../../../components/ui/BStepperOrgLG.vue';
import BSelectInput from '../../../components/ui/BSelectInput.vue';
import BStepperOrg from '../../../components/ui/BStepperOrg.vue';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

export default {
  name: 'StepOne',

  components: {
    BModalSelect,
    BSpinner,
    BModal,
    BStepperOrgLG,
    BSelectInput,
    BStepperOrg,
  },

  props: ['user'],

  emits: ['next', 'reset'],

  setup(props, { emit }) {
    const steps = ref([{}]);
    const show = ref(false);
    const show2 = ref(false);
    const store = useStore();
    const authUser = computed(() => store.state.auth.authData);
    const bankNames = computed(() => banks.map(({ name }) => name));
    const router = useRouter();
    const signingUp = ref(false);
    const required$ = helpers.withMessage('This field is required', required);
    const formInput = reactive({
      bank: '',
      banknumber: '',
      bvn: '',
    });
    const rules = {
      bank: { required$ },
      banknumber: { required$ },
      bvn: { required$ },
    };
    const v$ = useVuelidate(rules, formInput);
    const toast = useToast();
    const isOpen = computed(() => {
      return !signupResponse.isLoading && signupResponse.isSuccess;
    });

    const isFilled = computed(() => {
      if (
        formInput.username &&
        formInput.email &&
        formInput.password &&
        formInput.password == formInput.confirm_password
      ) {
        return true;
      } else {
        return false;
      }
    });

    const isMobile = () => {
      if (screen.width <= 768) {
        return true;
      } else {
        return false;
      }
    };
    const handleContinue = () => {
      emit('next');
    };

    const handlePrevious = () => {
      emit('previous');
    };

    watch(formInput, ({ bank, banknumber }) => {
      if (banknumber.length === 10 && bank) {
        handleVerification();
      } else {
        closeVerificationModal();
      }
    });
    const bankDetails = computed(() =>
      banks.find(({ name }) => name === formInput.bank)
    );

    const handleSubmit = async () => {
      const result = await v$.value.$validate();
      if (!result) {
        document.getElementById('form').scrollIntoView({
          behavior: 'smooth',
        });
        return;
      }
      const { firstname, lastname } = props.user;
      const { code: bankcode } = bankDetails.value;
      const payload = {
        bvn: formInput.bvn,
        accountNumber: verificationResponse.data.account_number,
        firstName: firstname,
        lastName: lastname,
        bankCode: bankcode,
      };
      verifyUserBVN(payload);
    };

    const closeVerificationModal = () => {
      verificationResponse.data = null;
      verificationResponse.isFailure = false;
      verificationResponse.isSuccess = false;
    };
    const [bvnResponse, verifyUserBVN] = useApi(verifyBVN);
    const [
      verificationResponse,
      verifyBankAccount,
      { setIsFailure: setIsVerificationFailure },
    ] = useApi(verifyAccount);
    watch(bvnResponse, ({ isSuccess }) => {
      if (isSuccess) {
        const { code: bankcode, name: bankname } = bankDetails.value;
        const payload = {
          bvn: formInput.bvn,
          bankcode,
          bankname,
          accountnumber: verificationResponse.data.account_number,
          accountname: verificationResponse.data.account_name,
        };
        console.log(props.user);
        console.log(payload);
        signingUp.value = true;

        let phonenumber = props.user.phoneNumber;
        phonenumber =
          phonenumber[0] === '+'
            ? phonenumber
            : phonenumber[0] === '0'
            ? `+234${phonenumber.slice(1)}`
            : `+234${phonenumber}`;
        signupUser({
          // ...props.user,
          ...formInput,
          phonenumber,
          email: props.user.email,
          password: props.user.password,
          accounttype: 'CORPORATE',
          username: props.user.username,
          firstname: props.user.nameoforg,
          rcnumber: props.user.rcnumber,
          contactperson: props.user.contactperson,
          address: props.user.address,
          state: props.user.state,
          idType: props.user.idType,
          idFile: props.user.idFile,
          profilePhoto: props.user.logo,
          twitter: props.user.twitter,
          facebook: props.user.facebook,
          instagram: props.user.instagram,
          tiktok: props.user.tiktok,
          website: props.user.website,
          cacFile: props.user.cacCertificate,
        });
      }
    });

    const [signupResponse, signupUser] = useApi(registerOrganisation);
    watch(signupResponse, ({ isFailure, isSuccess, error }) => {
      if (isFailure) {
        toast.error(error);
      }
      if (isSuccess) {
        const { code: bankcode } = bankDetails.value;
        setupBank({
          userid: authUser.value.id,
          accountnumber: formInput.banknumber,
          accountname: verificationResponse.data.account_name,
          bankname: formInput.bank,
          bankcode: bankcode,
          bvn: formInput.bvn,
        });
      }
    });
    const closeModal = () => {
      signupResponse.isSuccess = false;
      isOpen.value = true;
      emit('reset');
    };
    const handleLogin = () => {
      closeModal();
      router.push({ name: 'Feeds' });
    };

    const [bankSetupResponse, setupBank] = useApi(registerBank);
    const canGoNext = computed(
      () => verificationResponse.data && formInput.bvn
    );

    const handleVerification = () => {
      setIsVerificationFailure(false);
      const { code: bankcode } = bankDetails.value;
      verifyBankAccount({
        account_bank: bankcode,
        account_number: formInput.banknumber,
      });
    };
    watch(bankSetupResponse, ({ isFailure, error }) => {
      if (isFailure) {
        toast.error(error);
      }
    });

    return {
      steps,
      ...toRefs(formInput),
      // ...toRefs(verificationResponse),
      ...toRefs(signupResponse),
      verificationResponse,
      bvnResponse,
      bankSetupResponse,
      isFilled,
      isOpen,
      show,
      show2,
      bankNames,
      handleContinue,
      handleSubmit,
      closeVerificationModal,
      canGoNext,
      handleLogin,
      signingUp,
      handlePrevious,
      isMobile,
      v$,
    };
  },
};
</script>
<style lang="scss" scoped>
.decorated {
  &::after {
    background: #000;
    width: 100px;
  }
  // after:block
  //           after:bg-text-primary
  //           after:w-1
  //           after:h-10
  //           after:mx-auto
  //           after:my-2
}
</style>
