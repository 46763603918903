<template>
  <div
    class="fixed top-0 left-0 flex items-center justify-center w-screen h-screen spinner"
  >
    <div class="rounded py-4 px-16 flex flex-col items-center justify-center">
      <div
        class="h-16 w-16 border-8 border-border-secondary rounded-full loader"
      ></div>
      <p class="text-sm font-semibold mt-4 text-text-secondary">{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BSpinner',

  props: {
    text: {
      default: 'Processing',
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  z-index: 9999;
  background-color: rgba(33, 31, 31, 0.5);
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loader {
    border-top-color: transparent;
    animation: spinner 1.2s linear infinite;
  }
}
</style>
