<template>
  <div>
    <div class="flex lg:hidden justify-between items-center px-6 h-16">
      <button
        class="focus:outline-none"
        @click="isSideMenuOpen = !isSideMenuOpen"
      >
        <div
          v-if="authUser"
          class="
            rounded-full
            font-bold
            text-xs
            xs:text-sm
            h-8
            w-8
            flex flex-shrink-0
            justify-center
            items-center
            autenticated
            border border-border-secondary
          "
          :style="{
            backgroundImage: `linear-gradient(144.46deg, ${randomColor({
              luminosity: 'light',
              seed: `${authUser.firstname[0]}${authUser.lastname[0]}`,
            })} 17.06%, ${randomColor({
              luminosity: 'dark',
              seed: `${authUser.firstname[0]}${authUser.lastname[0]}`,
            })} 103.78%)`,
            color: '#fff',
          }"
        >
          <img
            class="rounded-full"
            v-if="authUser.profilePhoto"
            :src="authUser.profilePhoto"
            alt="profile_image"
          />
          <span v-else>
            {{ authUser.firstname[0] }}{{ authUser.lastname[0] }}
          </span>
        </div>
        <MenuIcon class="h-6 mr-4" v-else />
      </button>

      <router-link class="mx-auto" :to="{ name: 'Home' }">
        <BrandIcon class="h-5 sm:h-6" />
      </router-link>

      <span v-if="$route.name === 'CreateProject'" class="px-4" />

      <router-link :to="{ name: 'CreateProject' }" v-else>
        <AddIcon class="h-6 mx-1" v-if="authUser" />
      </router-link>

      <router-link v-if="!authUser" :to="{ name: 'Login' }">
        <span class="font-bold text-xs text-text-primary">Sign In</span>
      </router-link>
    </div>
    <div
      class="
        hidden
        lg:flex
        z-10
        bg-text-secondary
        justify-between
        items-center
        px-6
        h-20
        top-0
      "
      :class="
        isHomePage
          ? 'w-full'
          : 'm-max-width fixed border-b border-border-alternate-6'
      "
    >
      <router-link class="w-52" :to="{ name: 'Home' }">
        <BrandIconLg />
      </router-link>
      <!-- <Brand -->
      <div class="flex justify-between items-center">
        <div class="mr-6">
          <div class="dropdowns inline-block relative">
            <button
              class="
                text-text-primary
                mr-
                text-sm
                font-bold
                rounded
                inline-flex
                items-center
              "
            >
              <span class="mr-1">About us</span>
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </button>
            <ul class="dropdown-menus absolute hidden p-6">
              <li
                class="
                  text-text-primary
                  mr-6
                  text-sm
                  hover:text-text-alternate-6
                  font-semibold
                "
              >
                <router-link :to="{ name: 'About' }">About us</router-link>
              </li>
              <li
                class="
                  mt-4
                  hover:text-text-alternate-6
                  text-text-primary
                  mr-6
                  text-sm
                  font-semibold
                "
              >
                <router-link :to="{ name: 'Advisory' }"
                  >Advisory Board</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <router-link to="/explore">
          <p class="text-text-primary mr-6 text-sm font-bold">Fundraisers</p>
        </router-link>
        <router-link to="/settings/faqs">
          <p class="text-text-primary mr-6 text-sm font-bold">FAQs</p>
        </router-link>
        <router-link to="/fundraiser/filter">
          <p class="text-text-primary text-sm font-bold">Search</p>
        </router-link>
      </div>

      <div class="flex justify-between items-center">
        <BToolTip :content="'Start a Fundraiser'" placement="">
          <router-link v-if="authUser" :to="{ name: 'CreateProject' }">
            <button
              class="
                ml-3
                w-12
                mr-6
                py-2
                text-sm
                font-bold
                rounded
                bg-btn-bg-primary
                text-btn-text-primary
              "
            >
              +
            </button>
          </router-link>
        </BToolTip>
        <div class="dropdown inline-block relative">
          <button
            class="focus:outline-none"
            @mouseout="showSubMenu = showSubMenu2 = showSubMenu3 = false"
          >
            <div
              v-if="authUser"
              class="
                rounded-full
                font-bold
                text-xs
                xs:text-sm
                h-12
                w-12
                flex flex-shrink-0
                justify-center
                items-center
                authenticated
                border border-border-secondary
              "
              :style="{
                backgroundImage: `linear-gradient(144.46deg, ${randomColor({
                  luminosity: 'light',
                  seed: `${authUser.firstname[0]}${authUser.lastname[0]}`,
                })} 17.06%, ${randomColor({
                  luminosity: 'dark',
                  seed: `${authUser.firstname[0]}${authUser.lastname[0]}`,
                })} 103.78%)`,
                color: '#fff',
              }"
            >
              <img
                class="rounded-full"
                v-if="authUser.profilePhoto"
                :src="authUser.profilePhoto"
                alt="profile_image"
              />
              <span v-else>
                {{ authUser.firstname[0] }}{{ authUser.lastname[0] }}
              </span>
            </div>
            <!-- <MenuIcon class="h-6 mr-4" v-else /> -->
          </button>

          <div
            v-if="authUser"
            @mouseout="showSubmenu = false"
            class="dropdown-menu absolute hidden"
          >
            <div class="dropdown-head p-6 h-20 rounded-t-lg">
              <p class="text-sm text-text-primary font-semibold">
                {{ authUser.firstname }} {{ authUser.lastname }}
              </p>
              <p class="text-xs text-text-alternate-3 mt-2">
                @{{ authUser.username }}
              </p>
            </div>

            <div class="p-6">
              <ul class="font-medium">
                <!-- <li class="-6 py-0.5 flex items-center">
                  <OverviewIcon class="h-4" />
                  <router-link
                    class="flex-grow text-text-alternate-1 pl-4"
                    :class="
                      $router.currentRoute.value.path == '/overview'
                        ? 'font-bold'
                        : null
                    "
                    to="/overview"
                    >Overview
                  </router-link>
                  
                </li> -->
                <li class="py-0.5 flex items-center">
                  <Fundraiser2Icon
                    class="h-5 stroke-2 stroke-current text-text-alternate-4"
                  />
                  <router-link
                    class="flex-grow text-text-alternate-1 pl-3.5"
                    :class="
                      $router.currentRoute.value.path == '/account-fundraiser'
                        ? 'font-bold'
                        : null
                    "
                    to="/account-fundraiser"
                    >My Fundraisers</router-link
                  >
                  <!-- <ChevronDownIcon class="h-6 fill-current transform -rotate-90" /> -->
                </li>
                <li class="my-6 py-0.5 flex items-center">
                  <ProfileIcon
                    class="h-5 stroke-current stroke-2 text-text-alternate-4"
                  />
                  <router-link
                    class="flex-grow text-text-alternate-1 pl-3.5"
                    :class="
                      $router.currentRoute.value.name == 'Profile'
                        ? 'font-bold'
                        : null
                    "
                    :to="{
                      name: 'Profile',
                      params: { username: authUser.username },
                    }"
                  >
                    Profile
                  </router-link>
                  <!-- <ChevronDownIcon class="h-6 fill-current transform -rotate-90" /> -->
                </li>
                <!-- <li class="my-6 py-0.5 flex items-center">
                  <Wallet2Icon
                    class="h-5 stroke-current stroke-2 text-text-alternate-4"
                  />
                  <router-link
                    class="flex-grow text-text-alternate-1 pl-3.5"
                    :class="
                      $router.currentRoute.value.path == '/wallet'
                        ? 'font-bold'
                        : null
                    "
                    to="/wallet"
                    >Wallet</router-link
                  >
                  
                </li> -->
                <li class="my-6 py-1">
                  <div class="flex justify-between dropstart">
                    <div
                      class="flex"
                      @click="(showSubMenu3 = true), (showSubMenu2 = false), showSubMenu = false"
                      @blur="showSubMenu2 = showSubMenu = showSubMenu3 = false"
                    >
                      <Wallet2Icon
                      class="h-5 stroke-current stroke-2 text-text-alternate-4"
                        
                      />
                      <button
                        :class="subMenuType == 'wallet' ? 'font-bold' : null"
                        class="
                          flex-grow
                          text-left
                          font-medium
                          text-text-alternate-1
                          pl-3.5
                          focus:outline-none
                        "
                        @click="
                          subMenuType == 'wallet'
                            ? showSubMenu('')
                            : showSubMenu('wallet')
                        "
                      >
                        Wallet
                      </button>
                    </div>
                    <ChevronDownIcon
                      class="h-6 fill-current transform -rotate-90"
                    />
                    <ul
                      class="
                        font-medium
                        w-64
                        ml-8
                        p-4
                        absolute
                        dropright
                        right-72
                        float-right
                        bg-text-secondary
                        rounded-lg
                      "
                      :class="showSubMenu3 ? 'block' : 'hidden'"
                    >
                      <!-- <li class="my-6 py-1 flex items-center">
                        <ProfileIcon
                    class="h-5 stroke-current stroke-2 text-text-alternate-4"
                  />
                        <router-link
                          class="flex-grow text-text-alternate-1 pl-3.5"
                          :to="{ name: 'TopBlessors' }"
                          >Account Wallet</router-link
                        >
                      </li> -->
                      <li class="my-6 py-1 flex items-center">
                        <Fundraiser2Icon
                          class="
                            h-5
                            stroke-2 stroke-current
                            text-text-alternate-4
                          "
                        />
                        <router-link
                          class="flex-grow text-text-alternate-1 pl-3.5"
                          :to="{ name: 'Wallet' }"
                          >Fundraisers Wallet</router-link
                        >
                      </li>
                      <li class="my-6 py-1 flex items-center">
                        <MegaPhoneIcon
                          class="
                            h-5
                            stroke-2 stroke-current
                            text-text-alternate-4
                          "
                        />
                        <router-link
                          class="flex-grow text-text-alternate-1 pl-3.5"
                          :to="{ name: 'Refer' }"
                          >Refer & Earn Wallet</router-link
                        >
                      </li>
                      <li class="my-6 py-1 flex items-center">
                        <PaymentIcon
                          class="
                            h-5
                            stroke-2 stroke-current
                            text-text-alternate-4
                          "
                        />
                        <router-link
                          class="flex-grow text-text-alternate-1 pl-3.5"
                          :to="{ name: 'PromoteAndEarn' }"
                          >Promote & Earn Wallet</router-link
                        >
                      </li>
                    </ul>
                  </div>
                  <transition
                    enter-active-class="ease-linear duration-300 transition-all"
                    enter-from-class="-bottom-40 opacity-0"
                    leave-active-class="ease-linear duration-300 transition-all"
                    leave-to-class="-bottom-40 opacity-0"
                    leave-class="bottom-0 opacity-100"
                    appear
                  >
                    <ul
                      class="font-medium ml-8 w-64"
                      v-if="subMenuType === 'wallet'"
                    >
                      <li class="my-6 py-1 flex items-center">
                        <AddGroupIcon
                          class="mx-auto h-5 fill-current"
                          style="fill: #577387"
                        />
                        <router-link
                          class="flex-grow text-text-alternate-1 pl-3.5"
                          :to="{ name: 'TopBlessors' }"
                          >Top Blessors</router-link
                        >
                      </li>
                      <li class="my-6 py-1 flex items-center">
                        <Fundraiser2Icon
                          class="
                            h-5
                            stroke-2 stroke-current
                            text-text-alternate-4
                          "
                        />
                        <router-link
                          class="flex-grow text-text-alternate-1 pl-3.5"
                          :to="{ name: 'TopFundraisers' }"
                          >Top Fundraisers</router-link
                        >
                      </li>
                    </ul>
                  </transition>
                </li>
                <li class="my-6 py-0.5 flex items-center">
                  <PaymentIcon
                    class="h-5 stroke-current stroke-1 text-text-alternate-4"
                  />
                  <router-link
                    class="flex-grow text-text-alternate-1 pl-3.5"
                    to="/payment"
                    :class="
                      $router.currentRoute.value.path == '/payment'
                        ? 'font-bold'
                        : null
                    "
                    >Payment</router-link
                  >
                  <!-- <ChevronDownIcon class="h-6 fill-current transform -rotate-90" /> -->
                </li>
                <li class="my-6 py-0.5 flex items-center">
                  <MessageIcon class="h-5 fill-current text-text-alternate-4" />
                  <router-link
                    class="flex-grow text-text-alternate-1 pl-3.5"
                    :class="
                      $router.currentRoute.value.path == '/messages'
                        ? 'font-bold'
                        : null
                    "
                    to="/messages"
                    >Messages</router-link
                  >
                  <!-- <ChevronDownIcon class="h-6 fill-current transform -rotate-90" /> -->
                </li>
                <li class="my-6 py-0.5">
                  <div
                    class="
                      flex
                      justify-between
                      leading-tight
                      dropstart
                      relative
                      transition
                      duration-150
                      ease-in-out
                    "
                  >
                    <div
                      class="flex"
                      @click="(showSubMenu = true), (showSubMenu2 = false), showSubMenu3 = false"
                      @blur="showSubMenu = showSubMenu2 = showSubMenu3 = false"
                    >
                      <Setting2Icon
                        class="h-5 stroke-current text-text-alternate-4"
                      />
                      <button
                        :class="subMenuType == 'settings' ? 'font-bold' : null"
                        class="
                          flex-grow
                          text-left
                          font-medium
                          text-text-alternate-1
                          pl-3.5
                          focus:outline-none
                        "
                      >
                        Settings
                      </button>
                    </div>
                    <ChevronDownIcon
                      class="h-6 fill-current -rotate-90 transform"
                    />
                    <ul
                      class="
                        font-medium
                        w-64
                        ml-8
                        p-4
                        absolute
                        dropright
                        right-72
                        float-right
                        bg-text-secondary
                        rounded-lg
                      "
                      :class="showSubMenu ? 'block' : 'hidden'"
                    >
                      <li class="my-6 py-1 flex items-center">
                        <Lock2Icon
                          class="mx-auto h-5 stroke-2 stroke-current"
                          style="stroke: #577387"
                        />
                        <router-link
                          class="flex-grow text-text-alternate-1 pl-3.5"
                          :to="{ name: 'Security' }"
                          >Security</router-link
                        >
                      </li>
                      <li class="my-6 py-1 flex items-center">
                        <NotificationIcon
                          class="mx-auto h-5 stroke-2 stroke-current"
                          style="stroke: #577387"
                        />
                        <router-link
                          class="flex-grow text-text-alternate-1 pl-3.5"
                          :to="{ name: 'NotificationSetting' }"
                          >Notifications</router-link
                        >
                      </li>
                      <li class="my-6 py-1 flex items-center">
                        <MegaPhoneIcon
                          class="
                            h-5
                            stroke-current stroke-2
                            text-text-alternate-4
                          "
                          style="stroke: #577387"
                        />
                        <router-link
                          class="flex-grow text-text-alternate-1 pl-3.5"
                          :to="{ name: 'Refer' }"
                          >Refer & Earn</router-link
                        >
                      </li>

                      <li class="my-6 py-1 flex items-center">
                        <PaymentIcon
                          class="
                            h-5
                            stroke-current stroke-2
                            text-text-alternate-4
                          "
                          style="stroke: #577387"
                        />
                        <router-link
                          class="flex-grow text-text-alternate-1 pl-3.5"
                          :to="{ name: 'PromoteAndEarn' }"
                          >Promote & Earn</router-link
                        >
                      </li>

                      <li class="my-6 py-1 flex items-center">
                        <InfoIcon
                          class="
                            h-5
                            stroke-current stroke-2
                            text-text-alternate-4
                          "
                          style="stroke: #577387"
                        />
                        <router-link
                          class="flex-grow text-text-alternate-1 pl-3.5"
                          :to="{ name: 'FAQs' }"
                          >FAQs</router-link
                        >
                      </li>
                      <li class="my-6 py-1 flex items-center">
                        <PhoneIcon
                          class="mx-auto h-5 stroke-2 stroke-current"
                          style="stroke: #577387"
                        />
                        <router-link
                          class="flex-grow text-text-alternate-1 pl-3.5"
                          :to="{ name: 'ComposeMessage' }"
                          >Contact Support</router-link
                        >
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="my-6 py-1">
                  <div class="flex justify-between dropstart">
                    <div
                      class="flex"
                      @click="(showSubMenu2 = true), (showSubMenu = false), showSubMenu3 = false"
                      @blur="showSubMenu2 = showSubMenu = showSubMenu3 = false"
                    >
                      <StatIcon
                        class="h-5 stroke-current text-text-alternate-4"
                        style="fill: #577387"
                      />
                      <button
                        :class="subMenuType == 'stats' ? 'font-bold' : null"
                        class="
                          flex-grow
                          text-left
                          font-medium
                          text-text-alternate-1
                          pl-3.5
                          focus:outline-none
                        "
                        @click="
                          subMenuType == 'stats'
                            ? showSubMenu('')
                            : showSubMenu('stats')
                        "
                      >
                        Stats
                      </button>
                    </div>
                    <ChevronDownIcon
                      class="h-6 fill-current transform -rotate-90"
                    />
                    <ul
                      class="
                        font-medium
                        w-64
                        ml-8
                        p-4
                        absolute
                        dropright
                        right-72
                        float-right
                        bg-text-secondary
                        rounded-lg
                      "
                      :class="showSubMenu2 ? 'block' : 'hidden'"
                    >
                      <li class="my-6 py-1 flex items-center">
                        <AddGroupIcon
                          class="mx-auto h-5 fill-current"
                          style="fill: #577387"
                        />
                        <router-link
                          class="flex-grow text-text-alternate-1 pl-3.5"
                          :to="{ name: 'TopBlessors' }"
                          >Top Blessors</router-link
                        >
                      </li>
                      <li class="my-6 py-1 flex items-center">
                        <Fundraiser2Icon
                          class="
                            h-5
                            stroke-2 stroke-current
                            text-text-alternate-4
                          "
                        />
                        <router-link
                          class="flex-grow text-text-alternate-1 pl-3.5"
                          :to="{ name: 'TopFundraisers' }"
                          >Top Fundraisers</router-link
                        >
                      </li>
                    </ul>
                  </div>
                  <transition
                    enter-active-class="ease-linear duration-300 transition-all"
                    enter-from-class="-bottom-40 opacity-0"
                    leave-active-class="ease-linear duration-300 transition-all"
                    leave-to-class="-bottom-40 opacity-0"
                    leave-class="bottom-0 opacity-100"
                    appear
                  >
                    <ul
                      class="font-medium ml-8 w-64"
                      v-if="subMenuType === 'stats'"
                    >
                      <li class="my-6 py-1 flex items-center">
                        <AddGroupIcon
                          class="mx-auto h-5 fill-current"
                          style="fill: #577387"
                        />
                        <router-link
                          class="flex-grow text-text-alternate-1 pl-3.5"
                          :to="{ name: 'TopBlessors' }"
                          >Top Blessors</router-link
                        >
                      </li>
                      <li class="my-6 py-1 flex items-center">
                        <Fundraiser2Icon
                          class="
                            h-5
                            stroke-2 stroke-current
                            text-text-alternate-4
                          "
                        />
                        <router-link
                          class="flex-grow text-text-alternate-1 pl-3.5"
                          :to="{ name: 'TopFundraisers' }"
                          >Top Fundraisers</router-link
                        >
                      </li>
                    </ul>
                  </transition>
                </li>
                <hr class="text-text-alternate-8" />
                <li class="my-6 py-0.5 flex items-center">
                  <LogOutIcon
                    class="h-5 stroke-current text-text-alternate-4"
                  />
                  <button
                    class="
                      flex-grow
                      text-left
                      font-semibold
                      text-text-alternate-1
                      pl-3.5
                    "
                    @click="logout"
                  >
                    Log Out
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <router-link :to="{ name: 'Login' }">
          <span
            class="font-bold text-sm mr-6 text-text-primary"
            v-if="!authUser"
            >Sign In</span
          >
        </router-link>

        <span v-if="$route.name === 'CreateProject'" class="px-" />

        <router-link
          v-if="!authUser"
          :to="{ name: 'Signup' }"
          class="
            ml-3
            px-6
            py-2
            text-sm
            font-bold
            rounded
            bg-btn-bg-primary
            text-btn-text-primary
          "
          >Start a Fundraiser
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, computed, ref } from 'vue';
import useSideMenuState from '@/cmp-functions/useSideMenuState.js';
import { useStore } from 'vuex';
import randomColor from 'randomcolor';
import BToolTip from './BToolTip.vue';

export default {
  name: 'BHeader',
  data() {
    return {
      showSubMenu: false,
      showSubMenu2: false,
      showSubMenu3: false,
    };
  },
  props: ['isHomePage'],
  setup() {
    const store = useStore();
    const authUser = computed(() => store.state.auth.userDetails);
    const { state } = useSideMenuState();
    const subMenuType = ref('');
    const logout = () => {
      store.commit('auth/UNSET_AUTH');
    };
    return { ...toRefs(state), randomColor, authUser, logout, subMenuType };
  },
  components: { BToolTip },
};
</script>

<style lang="scss" scoped>
.autenticated {
  @apply relative;
  &::before {
    content: '';
    position: absolute;
    background: #57b894;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    bottom: 0;
    right: 0;
    border: 1px solid #e0f2ff;
  }
}
.authenticated {
  @apply relative;
  &::before {
    content: '';
    position: absolute;
    background: #57b894;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    bottom: 0;
    right: 0;
    border: 1px solid #e0f2ff;
  }
}
.hheader {
  border-bottom-color: #e2e8f0;
}
.dropdown:hover .dropdown-menu {
  display: block;
  margin-left: -265px;
}
.dropdowns:hover .dropdown-menus {
  display: block;
}
.dropdown-menu {
  width: 312px;
  max-height: 552px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  .dropdown-head {
    background: #f8f7f7;
    border: 1px solid #e2e8f0;
  }
}
.dropdown-menus {
  width: 190px;
  max-height: 552px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px;
  .dropdown-head {
    background: #f8f7f7;
    border: 1px solid #e2e8f0;
  }
}
// .dropstart:hover .dropright {
//   display: block;
// }
.dropright {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}
</style>
