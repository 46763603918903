<template>
  <div class="lg:flex gap-6 justify-center grid-cols-3">
    <div class="col-span-2">
      <div class="lg:rounded-lg lg:border lg:border-text-alternate-14 w-fff">
        <div class="relative hidden lg:block">
          <div class="h-48">
            <img
              :src="fundraiser.coverImage"
              class="object-cover h-48 w-full rounded-t-lg"
              alt=""
            />
          </div>
          <router-link
            v-if="authUser?.id === fundraiser.userdetail.userid"
            class=""
            :class="[
              'absolute right-4 top-4 bg-bg-primary px-5 py-2.5 text-center rounded text-text-alternate-1 font-semibold text-sm',
              { 'link-disabled': isOneTime && !hasNotExpired },
            ]"
            :to="{ name: 'EditFundraiser', params: { id: fundraiser.id } }"
          >
            Edit
          </router-link>
          <div
            class="
              flex
              items-center
              justify-between
              px-6
              bg-bg-alternate-69
              text-text-alternate-1
              font-semibold
              text-xs
              xs:text-sm
              py-2
            "
          >
            <div
              class="
                rounded-full
                border-2 border-border-secondary
                w-14
                xs:w-36
                h-14
                xs:h-36
                absolute
                bottom-
                left-6
                overflow-hidden
                flex
                items-center
                justify-center
                text-xl
                xs:text-2xl
                font-bold
                z-1
              "
              :style="{
                backgroundImage: `linear-gradient(144.46deg, ${randomColor({
                  luminosity: 'light',
                  seed: `${fundraiser.userdetail.firstname[0]}${fundraiser.userdetail.lastname[0]}`,
                })} 17.06%, ${randomColor({
                  luminosity: 'dark',
                  seed: `${fundraiser.userdetail.firstname[0]}${fundraiser.userdetail.lastname[0]}`,
                })} 103.78%)`,
                color: '#fff',
              }"
            >
              <img
                v-if="fundraiser.userdetail.profilePhoto"
                :src="fundraiser.userdetail.profilePhoto"
                alt="profile_image"
                class="rounded-full h-36 w-36"
              />
              <p v-else>
                {{ fundraiser.userdetail.firstname[0]
                }}{{ fundraiser.userdetail.lastname[0] }}
              </p>
            </div>
          </div>
          <div
            class="
              text-center
              bg-bg-alternate-7
              text-text-alternate-1
              font-semibold
              text-2xs
              xs:text-xs
              py-2
            "
            v-if="fundraiser.status === 'PENDING'"
          >
            Pending admin review
          </div>
          <!-- {{ fundraiser.organisationWebsite }} -->
          <div class="text-right flex justify-end mr-2">
            <a
              v-for="social in socialmedialinks"
              :key="social.id"
              :href="`//${social.link.replace(/^https?:\/\//, '')}`"
              target="_blank"
              class="mr-3"
            >
              <div
                class="
                  h-10
                  w-10
                  bg-bg-alternate-3
                  rounded-full
                  flex
                  justify-center
                  items-center
                "
              >
                <FBIcon class="h-6 w-6" v-if="social.smname === 'Facebook'" />
                <IGIcon class="h-6 w-6" v-if="social.smname === 'Instagram'" />
                <TWIcon class="h-6 w-6" v-if="social.smname === 'Twitter'" />
                <YTIcon class="h-6 w-6" v-if="social.smname === 'YouTube'" />
              </div>
            </a>
            <a
              :href="`//${fundraiser.organisationWebsite.replace(
                /^https?:\/\//,
                ''
              )}`"
              target="_blank"
              class="mr-3"
            >
              <div
                class="
                  h-10
                  w-10
                  bg-bg-alternate-3
                  rounded-full
                  flex
                  justify-center
                  items-center
                "
              >
                <WEBIcon class="h-6 w-6" />
              </div>
            </a>
          </div>
        </div>
        <section class="mt-2 lg:mt-8">
          <div class="text-text-alternate-1 py-3">
            <div class="flex items-center mb-2 px-6">
              <h3 class="pb- text-lg sm:text-xl font-semibold">
                {{ fundraiser.title }}
              </h3>
              <VerifiedIcon
                class="h-3 pl-1 flex-shrink-0"
                v-if="fundraiser.userdetail.verificationStatus === 'VERIFIED'"
              />
              <VIPIcon
                class="h-4 pl-1 pr-5 flex-shrink-0"
                v-else-if="fundraiser.verificationStatus === 'VIP'"
              />
            </div>
            <div class="flex px-6">
              <p class="text-sm font-semibold text-text-alternate-1">
                @{{ fundraiser.userdetail.username }}
              </p>
              <p class="text-sm font-semibold text-text-alternate-1 ml-4">
                RC {{ fundraiser.userdetail.rcnumber?.replace('RC') }}
              </p>
            </div>

            <header
              class="overflow-scroll bg-bg-alternate-3 px-6 mt-8"
              v-if="!isModal"
            >
              <ul class="inline-flex font-semibold text-xs xs:text-sm w-full">
                <li v-for="(nav, index) in sections" :key="index">
                  <router-link
                    :class="[
                      'px-4 py-3 nav-border inline-block whitespace-nowrap',
                      $route.name === nav
                        ? 'nav-active text-text-alternate-1'
                        : 'text-text-alternate-10',
                    ]"
                    :to="{
                      name: nav,
                      query: isWishList
                        ? { wishlist_id: wishListId }
                        : isPromoteAndEarn
                        ? { promotion_id: promoteAndEarnId }
                        : null,
                    }"
                  >
                    {{ nav === 'Overview' ? 'About Us' : nav }}
                  </router-link>
                </li>
              </ul>
            </header>
            <router-view
              class="px-6"
              :fundraiser="fundraiser"
              :authUser="authUser"
              :isExpired="isExpired"
              :description="description"
              :isLenghtExceeded="isLenghtExceeded"
              :shouldTruncateDesc="shouldTruncateDesc"
              :isDescTruncated="isDescTruncated"
              @shouldTruncate="handleTruncate"
            />
          </div>

          <div class="lg:hidden px-6">
            <hr class="border-t border-border-primary" />
            <div
              class="
                h-20
                bg-bg-alternate-9
                rounded
                mb-4
                mt-4
                text-center
                flex flex-col
                items-center
                justify-center
              "
            >
              <p class="font-extrabold text-xl">
                ₦{{ fundraiser.raisedAmount.toLocaleString() }}
              </p>
              <p class="text-sm font-medium text-text-alternate-3 mt-2">
                Total Donations
              </p>
            </div>
            <div
              class="py-3 border-t border-border-primary"
              v-if="
                !fundraiser.hideCycleTargetAmount &&
                fundraiser.components.includes('2')
              "
            >
              <p
                class="
                  flex
                  justify-between
                  font-semibold
                  text-xs
                  xs:text-sm
                  py-1
                "
              >
                <span>₦{{ fundraiser.raisedAmount.toLocaleString() }}</span>
                <span>₦{{ fundraiser.targetAmount.toLocaleString() }}</span>
              </p>
              <div
                class="bg-bg-alternate-3 h-2 rounded-full my-2 overflow-hidden"
              >
                <div
                  class="bg-bg-alternate-8 rounded-full h-2"
                  :style="{ width: targetCoverage }"
                ></div>
              </div>
              <p
                class="
                  flex
                  justify-between
                  font-medium
                  text-xs text-text-alternate-3
                  pb-1
                "
              >
                <span>Raised</span>
                <span class="capitalize">
                  {{ fundraiser.fundraiserCycle.toLowerCase() }} Target
                </span>
              </p>
            </div>
            <template v-if="!isModal">
              <hr class="border-t border-border-primary mb-5" />
              <div class="flex gap-3 mb-5">
                <button
                  @click="setDonationType(true)"
                  class="h-12 rounded w-1/2 text-xs font-bold"
                  :class="
                    isDonateOnce
                      ? 'bg-btn-bg-primary text-text-secondary'
                      : 'bg-btn-bg-secondary border border-border-alternate-2 text-text-primary'
                  "
                >
                  Donate Once
                </button>
                <button
                  @click="setDonationType(false)"
                  class="h-12 rounded w-1/2 text-xs font-bold"
                  :class="
                    !isDonateOnce
                      ? 'bg-btn-bg-primary text-text-secondary'
                      : 'bg-btn-bg-secondary border border-border-alternate-2 text-text-primary'
                  "
                >
                  Donate Monthly
                </button>
              </div>
            </template>
            <div
              v-if="isDonateOnce"
              class="border-border-primary py-4"
              :class="fundraiser.components.includes('2') ? 'border-t' : null"
            >
              <p class="font-medium text-xs">
                You can support
                <span class="font-bold"
                  >@{{ fundraiser.userdetail.username }}</span
                >
                by making a payment using Bank Transfer, Card or USSD.
              </p>
              <div
                v-if="fundraiser.supportedPayments.split(',').includes('1')"
                class="
                  rounded
                  bg-bg-alternate-8
                  my-2
                  mb-0
                  flex flex-col
                  justify-center
                  items-center
                  py-4
                  text-text-secondary
                  font-semibold
                "
              >
                <h3 class="uppercase text-text-alternate-9 text-xs">
                  <span v-if="isWishList">
                    {{ wishListAccounts[0].bankName }}
                  </span>
                  <span v-else-if="isPromoteAndEarn">
                    {{ promoteAndEarnAccounts[0].bankName }}
                  </span>
                  <span v-else>
                    {{ accounts[0].bankName }}
                  </span>
                </h3>
                <p class="text-lg my-2 flex">
                  <span class="mr-2">
                    <span v-if="isWishList">
                      {{ wishListAccounts[0].accountNumber }}
                    </span>
                    <span v-else-if="isPromoteAndEarn">
                      {{ promoteAndEarnAccounts[0].accountNumber }}
                    </span>
                    <span v-else>
                      {{ accounts[0].accountNumber }}
                    </span>
                  </span>
                  <button
                    @click="
                      handleCopy(
                        isWishList
                          ? wishListAccounts[0].accountNumber
                          : isPromoteAndEarn
                          ? promoteAndEarnAccounts[0].accountNumber
                          : accounts[0].accountNumber
                      )
                    "
                  >
                    <CopyIcon
                      class="h-4 stroke-0 stroke-current fill-current"
                    />
                  </button>
                </p>
                <p class="text-xs">{{ accounts[0].accountName }}</p>
              </div>
              <div
                v-if="fundraiser.supportedPayments.split(',').includes('8')"
                class="
                  mt-4
                  py-4
                  border border-border-primary
                  flex
                  justify-center
                  rounded-md
                  flex-col
                  items-center
                "
              >
                <p class="text-xs mb-2 font-semibold text-text-primary">
                  NQR - Scan to pay
                </p>
                <qrcode-vue
                  foreground="#000000"
                  :value="fundraiser.emvcoCode"
                  :size="200"
                  level="H"
                />
              </div>
            </div>
            <template v-if="!isModal">
              <!-- <p class="decorated">Or</p> -->
              <div v-if="isDonateOnce" class="pb-2 pt-0">
                <!-- <p class="font-medium text-xs my-2">
              You can also support
              <span class="font-bold">@{{ fundraiser.userdetail.username }}</span>
              from anywhere in the world using a Credit/Debit Card or ApplePay.
            </p> -->
                <div class="flex flex-col items-center gap-4 my-2 mt-0">
                  <button
                    v-if="
                      fundraiser.supportedPayments.split(',').includes('3') ||
                      fundraiser.supportedPayments.split(',').includes('10') ||
                      fundraiser.supportedPayments.split(',').includes('12')
                    "
                    class="
                      border border-btn-border-alternate-1
                      btn-shadow
                      w-full
                      py-4
                      flex
                      justify-center
                      rounded
                    "
                    @click="$emit('bless', { option: 'PAYPAL' })"
                  >
                    <ApplePayIcon class="h-10" />
                  </button>
                  <button
                    v-if="
                      fundraiser.supportedPayments.split(',').includes('2') ||
                      fundraiser.supportedPayments.split(',').includes('5') ||
                      fundraiser.supportedPayments.split(',').includes('9')
                    "
                    class="
                      border border-btn-border-alternate-1
                      btn-shadow
                      w-full
                      py-6
                      flex
                      justify-center
                      gap-3
                      rounded
                    "
                    @click="
                      fundraiser.supportedPayments.split(',').includes('2') ||
                      fundraiser.supportedPayments.split(',').includes('9')
                        ? (showCurrencyModal = true)
                        : $emit('bless', { option: 'CARD' })
                    "
                  >
                    <MasterCardIcon class="h-6" />
                    <VisaIcon class="h-6" />
                    <VerveIcon class="h-6" />
                    <!-- <UnionPayIcon class="h-6" /> -->
                  </button>
                  <button
                    v-if="
                      fundraiser.supportedPayments.split(',').includes('6') ||
                      fundraiser.supportedPayments.split(',').includes('12')
                    "
                    class="
                      border border-btn-border-alternate-1
                      btn-shadow
                      w-full
                      py-4
                      btn-ussd
                      flex
                      justify-center
                      rounded
                      btn-ussd
                    "
                    @click="isOpen = true"
                  >
                    <p class="ussd-text">*USSD#</p>
                  </button>
                </div>
              </div>
              <!-- <p class="decorated">Or</p> -->
              <div v-if="!isDonateOnce" class="pt-4 pb-2">
                <p class="font-medium text-xs my-2">
                  Support
                  <span class="font-bold"
                    >@{{ fundraiser.userdetail.username }}</span
                  >
                  monthly by choosing one of the options below
                </p>
                <div class="flex gap-2 xs:gap-3 justify-between pb-2">
                  <label
                    :class="[
                      'flex flex-grow flex-col items-center border rounded relative bg-bg-alternate-3 p-3',
                      recurAmount === id
                        ? 'border-first'
                        : 'border-border-transparent',
                    ]"
                    v-for="{ amount, id, donationtype } in donations"
                    :key="id"
                  >
                    <span
                      :class="[
                        'text-text-alternate-1 font-bold',
                        donationtype.toLowerCase(),
                      ]"
                    >
                      ${{ amount.toLocaleString() }}
                    </span>
                    <span
                      class="text-text-alternate-1 font-semibold text-sm mt-1"
                    >
                      /Month
                    </span>
                    <TickIcon
                      v-if="recurAmount === amount"
                      class="h-3.5 absolute right-1 top-1 z-10 fill-current"
                      style="fill: #017622"
                    />
                    <input
                      type="radio"
                      :value="amount"
                      hidden
                      v-model="recurAmount"
                    />
                  </label>
                </div>

                <button
                  class="
                    bg-btn-bg-primary
                    mt-4
                    mb-2
                    text-btn-text-primary
                    px-4
                    py-4
                    w-full
                    rounded
                    text-center text-sm
                    xs:text-base
                    font-bold
                  "
                  :disabled="!recurAmount"
                  @click="
                    $emit('bless', { amount: recurAmount, option: 'CARD' })
                  "
                >
                  Pledge
                </button>
                <button
                  class="
                    bg-btn-bg-alternate-1
                    my-2
                    text-btn-text-alternate-1
                    px-4
                    py-4
                    w-full
                    rounded
                    text-center text-sm
                    xs:text-base
                    font-bold
                  "
                  @click="shouldCancelPledge = true"
                >
                  Cancel Pledge
                </button>
              </div>
              <div class="border- border-border-primary pb-4" v-if="!isModal">
                <button
                  class="
                    bg-btn-bg-primary
                    text-btn-text-primary
                    px-4
                    py-4
                    w-full
                    rounded
                    text-center text-sm
                    xs:text-base
                    font-bold
                  "
                  @click="$emit('share')"
                >
                  Share this Fundraiser
                </button>
              </div>
            </template>
            <div
              class="border-t border-border-primary py-3"
              v-if="fundraiser.blessings?.length"
            >
              <div
                class="
                  flex
                  justify-between
                  font-semibold
                  text-lg
                  py-3
                  text-text-alternate-1
                "
              >
                <h2>Top Blessors</h2>
                <span v-if="isModal" class="underline"
                  >({{ fundraiser.totalBlessingsCount }})</span
                >
                <button
                  v-else
                  class="underline font-semibold"
                  @click="isBlessModalOpen = true"
                >
                  ({{ fundraiser.totalBlessingsCount }})
                </button>
              </div>

              <BBlessor
                v-for="blessor in fundraiser.blessings"
                :key="blessor.id"
                :blessor="blessor"
              />

              <template v-if="!isModal">
                <button
                  class="
                    border border-btn-border-secondary
                    items-center
                    w-full
                    px-4
                    py-3.75
                    text-center
                    rounded
                    text-text-alternate-1
                    font-bold
                    text-sm
                    mt-4
                    mb-2
                  "
                  @click="isBlessModalOpen = true"
                >
                  Show more
                </button>
                <button
                  class="
                    bg-btn-bg-alternate-1
                    text-btn-text-alternate-1
                    w-full
                    py-4
                    rounded
                    text-center text-sm
                    font-bold
                    mt-2
                  "
                  @click="verify = true"
                >
                  Verify Your Donation
                </button>
              </template>
            </div>
          </div>

          <!-- Rewards section hidden for Permanent Fundraiser -->
          <div
            class="py-2 hidden"
            v-if="!isModal && fundraiser.rewards?.length"
          >
            <h3 class="font-semibold text-text-alternate-1 text-lg">Rewards</h3>
            <div class="font-semibold text-text-alternate-4">
              <div
                class="rounded border border-border-primary p-4 my-4 relative"
                v-for="reward in fundraiser.rewards"
                :key="reward.id"
              >
                <div class="flex flex-wrap justify-between items-center mb-2">
                  <p>
                    <span class="text-sm"
                      >₦{{ reward.blessingAmount.toLocaleString() }}</span
                    >
                    <span class="text-xs text-text-primary"> or more</span>
                  </p>
                </div>
                <h3 class="text-text-primary text-xs my-2">Reward</h3>
                <p class="text-xs">
                  {{ reward.reward }}
                </p>
              </div>
            </div>
          </div>
          <!-- Rewards ends here -->
          <!-- This section is hidden for Permanent fundraiser -->
          <div
            class="
              py-2
              text-text-alternate-1
              border-t border-border-primary
              hidden
            "
            v-if="!isModal && socialmedialinks?.length"
          >
            <header class="py-2 font-semibold">
              <h2 class="text-lg">On Social Media</h2>
            </header>
            <div class="py-2 text-xs flex">
              <a
                v-for="social in socialmedialinks"
                :key="social.id"
                :href="`//${social.link.replace(/^https?:\/\//, '')}`"
                target="_blank"
              >
                <FBIcon
                  v-if="social.smname === 'Facebook'"
                  class="h-6 stroke-0 stroke-current fill-current mr-5"
                />
                <IGIcon
                  v-else-if="social.smname === 'Instagram'"
                  class="h-6 stroke-0 stroke-current fill-current mr-5"
                />
                <TWIcon
                  v-else-if="social.smname === 'Twitter'"
                  class="h-6 stroke-0 stroke-current fill-current mr-5"
                />
                <YTIcon
                  v-else-if="social.smname === 'YouTube'"
                  class="h-6 stroke-0 stroke-current fill-current mr-5"
                />
                <TKKIcon
                  v-else-if="social.smname === 'TikTok'"
                  class="h-6 stroke-0 stroke-current fill-current mr-5"
                />
              </a>
            </div>
          </div>
          <FundraiserBottom
            :isModal="isModal"
            :fundraiser="fundraiser"
            @setSection="handleSetSection"
            @makeawish="$emit('makeawish')"
            @updateLikeData="updateLike"
            @promoteFundraiser="$emit('promoteFundraiser')"
            :authUser="authUser"
            :isOwner="isOwner"
            :searchResponse="searchResponse"
          />
        </section>
      </div>
    </div>
    <div class="hidden col-span-1 lg:block" id="right-panel">
      <div class="h-full w-ff">
        <div
          class="px-6 py-4 border rounded-lg border-text-alternate-14 w-full"
        >
          <div
            class="
              h-20
              bg-bg-alternate-9
              rounded
              mb-6
              mt-1
              text-center
              flex flex-col
              items-center
              justify-center
            "
          >
            <p class="font-extrabold text-xl">
              ₦{{ fundraiser.raisedAmount.toLocaleString() }}
            </p>
            <p class="text-sm font-medium text-text-alternate-3 mt-2">
              Total Donations
            </p>
          </div>
          <div
            class=""
            v-if="
              !fundraiser.hideCycleTargetAmount &&
              fundraiser.components.includes('2')
            "
          >
            <p
              class="flex justify-between font-semibold text-xs xs:text-sm pb-1"
            >
              <span>₦{{ fundraiser.raisedAmount.toLocaleString() }}</span>
              <span>₦{{ fundraiser.targetAmount.toLocaleString() }}</span>
            </p>
            <div
              class="bg-bg-alternate-3 h-2 rounded-full my-2 overflow-hidden"
            >
              <div
                class="bg-bg-alternate-8 rounded-full h-2"
                :style="{ width: targetCoverage }"
              ></div>
            </div>
            <p
              class="
                flex
                justify-between
                font-medium
                text-xs text-text-alternate-3
                pb-1
              "
            >
              <span>Raised</span>
              <span class="capitalize">
                {{ fundraiser.fundraiserCycle.toLowerCase() }} Target
              </span>
            </p>
          </div>
          <hr class="border-t border-border-primary my-4" />
          <div class="border- border-border-primary pb-4">
            <p class="font-medium text-xs">
              You can support
              <span class="font-bold"
                >@{{ fundraiser.userdetail.username }}</span
              >
              by making a payment using Bank Transfer, Card or USSD.
            </p>
            <div
              v-if="fundraiser.supportedPayments.split(',').includes('1')"
              class="
                rounded
                bg-bg-alternate-8
                my-2
                mb-0
                flex flex-col
                justify-center
                items-center
                py-4
                text-text-secondary
                font-semibold
              "
            >
              <h3 class="uppercase text-text-alternate-9 text-xs">
                <span v-if="isWishList">
                  {{ wishListAccounts[0].bankName }}
                </span>
                <span v-else-if="isPromoteAndEarn">
                  {{ promoteAndEarnAccounts[0].bankName }}
                </span>
                <span v-else>
                  {{ accounts[0].bankName }}
                </span>
              </h3>
              <p class="text-lg my-2 flex">
                <span class="mr-2">
                  <span v-if="isWishList">
                    {{ wishListAccounts[0].accountNumber }}
                  </span>
                  <span v-else-if="isPromoteAndEarn">
                    {{ promoteAndEarnAccounts[0].accountNumber }}
                  </span>
                  <span v-else>
                    {{ accounts[0].accountNumber }}
                  </span>
                </span>
                <button
                  @click="
                    handleCopy(
                      isWishList
                        ? wishListAccounts[0].accountNumber
                        : isPromoteAndEarn
                        ? promoteAndEarnAccounts[0].accountNumber
                        : accounts[0].accountNumber
                    )
                  "
                >
                  <CopyIcon class="h-4 stroke-0 stroke-current fill-current" />
                </button>
              </p>
              <p class="text-xs">{{ accounts[0].accountName }}</p>
            </div>
            <div
              v-if="fundraiser.supportedPayments.split(',').includes('8')"
              class="
                mt-4
                py-4
                border border-border-primary
                flex
                justify-center
                rounded-md
                flex-col
                items-center
              "
            >
              <p class="text-xs mb-2 font-semibold text-text-primary">
                NQR - Scan to pay
              </p>
              <qrcode-vue
                foreground="#000000"
                :value="fundraiser.emvcoCode"
                :size="200"
                level="H"
              />
            </div>
          </div>
          <template v-if="!isModal">
            <!-- <p class="decorated">Or</p> -->
            <div class="py-4 pt-0">
              <!-- <p class="font-medium text-xs my-2">
            You can also support
            <span class="font-bold">@{{ fundraiser.userdetail.username }}</span>
            from anywhere in the world using a Credit/Debit Card or ApplePay.
          </p> -->
              <div class="flex flex-col items-center gap-4 my-2 mt-0">
                <button
                  v-if="
                    fundraiser.supportedPayments.split(',').includes('3') ||
                    fundraiser.supportedPayments.split(',').includes('10') ||
                    fundraiser.supportedPayments.split(',').includes('12')
                  "
                  class="
                    border border-btn-border-alternate-1
                    btn-shadow
                    w-full
                    py-4
                    flex
                    justify-center
                    rounded
                  "
                  @click="$emit('bless', { option: 'PAYPAL' })"
                >
                  <ApplePayIcon class="h-10" />
                </button>
                <button
                  v-if="
                    fundraiser.supportedPayments.split(',').includes('2') ||
                    fundraiser.supportedPayments.split(',').includes('5') ||
                    fundraiser.supportedPayments.split(',').includes('9')
                  "
                  class="
                    border border-btn-border-alternate-1
                    btn-shadow
                    w-full
                    py-6
                    flex
                    justify-center
                    gap-3
                    rounded
                  "
                  @click="
                    fundraiser.supportedPayments.split(',').includes('2') ||
                    fundraiser.supportedPayments.split(',').includes('9')
                      ? (showCurrencyModal = true)
                      : $emit('bless', { option: 'CARD' })
                  "
                >
                  <MasterCardIcon class="h-6" />
                  <VisaIcon class="h-6" />
                  <VerveIcon class="h-6" />
                  <!-- <UnionPayIcon class="h-6" /> -->
                </button>
                <button
                  v-if="
                    fundraiser.supportedPayments.split(',').includes('6') ||
                    fundraiser.supportedPayments.split(',').includes('12')
                  "
                  class="
                    border border-btn-border-alternate-1
                    btn-shadow
                    w-full
                    py-4
                    btn-ussd
                    flex
                    justify-center
                    rounded
                    btn-ussd
                  "
                  @click="isOpen = true"
                >
                  <p class="ussd-text">*USSD#</p>
                </button>

                <button
                  class="
                    bg-btn-bg-primary
                    text-btn-text-primary
                    px-4
                    py-4
                    w-full
                    rounded
                    text-center text-sm
                    xs:text-base
                    font-bold
                  "
                  @click="$emit('share')"
                >
                  Share this Fundraiser
                </button>
              </div>
            </div>
            <p class="decorated">Or</p>
            <div class="py-4">
              <p class="font-medium text-xs my-2">
                Support
                <span class="font-bold"
                  >@{{ fundraiser.userdetail.username }}</span
                >
                monthly by choosing one of the options below
              </p>
              <div class="flex gap-2 xs:gap-3 justify-between pb-2">
                <label
                  :class="[
                    'flex flex-grow flex-col items-center border rounded relative bg-bg-alternate-3 p-3',
                    recurAmount === id
                      ? 'border-first'
                      : 'border-border-transparent',
                  ]"
                  v-for="{ amount, id, donationtype, currency } in donations"
                  :key="id"
                >
                  <span
                    :class="[
                      'text-text-alternate-1 font-bold',
                      donationtype.toLowerCase(),
                    ]"
                  >
                    {{currency == 'NGN' ? '₦' : '$'}}{{ amount.toLocaleString() }}
                  </span>
                  <span
                    class="text-text-alternate-1 font-semibold text-sm mt-1"
                  >
                    /Month
                  </span>
                  <TickIcon
                    v-if="recurAmount === amount"
                    class="h-3.5 absolute right-1 top-1 z-10 fill-current"
                    style="fill: #017622"
                  />
                  <input
                    type="radio"
                    :value="amount"
                    hidden
                    v-model="recurAmount"
                  />
                </label>
              </div>

              <button
                class="
                  bg-btn-bg-primary
                  mt-4
                  mb-2
                  text-btn-text-primary
                  px-4
                  py-4
                  w-full
                  rounded
                  text-center text-sm
                  xs:text-base
                  font-bold
                "
                :disabled="!recurAmount"
                @click="$emit('bless', { amount: recurAmount, option: 'CARD', currency: 'USD' })"
              >
                Pledge
              </button>
              <button
                class="
                  bg-btn-bg-alternate-1
                  my-2
                  text-btn-text-alternate-1
                  px-4
                  py-4
                  w-full
                  rounded
                  text-center text-sm
                  xs:text-base
                  font-bold
                "
                @click="shouldCancelPledge = true"
              >
                Cancel Pledge
              </button>
            </div>
          </template>
          <div
            class="border-t border-border-primary py-3"
            v-if="fundraiser.blessings?.length"
          >
            <div
              v-if="fundraiser.components.includes('3')"
              class="
                flex
                justify-between
                font-semibold
                text-lg
                py-3
                text-text-alternate-1
              "
            >
              <h2>Top Blessors</h2>
              <span v-if="isModal" class="underline"
                >({{ fundraiser.totalBlessingsCount }})</span
              >
              <button
                v-else
                class="underline font-semibold"
                @click="isBlessModalOpen = true"
              >
                ({{ fundraiser.totalBlessingsCount }})
              </button>
            </div>
            <span v-if="fundraiser.components.includes('3')">
              <BBlessor
                v-for="blessor in fundraiser.blessings"
                :key="blessor.id"
                :blessor="blessor"
              />
            </span>

            <template v-if="!isModal">
              <button
                v-if="fundraiser.components.includes('3')"
                class="
                  border border-btn-border-secondary
                  items-center
                  w-full
                  px-4
                  py-3.75
                  text-center
                  rounded
                  text-text-alternate-1
                  font-bold
                  text-sm
                  mt-4
                  mb-2
                "
                @click="isBlessModalOpen = true"
              >
                Show more
              </button>
              <button
                class="
                  bg-btn-bg-alternate-1
                  text-btn-text-alternate-1
                  w-full
                  py-4
                  rounded
                  text-center text-sm
                  font-bold
                  mt-2
                "
                @click="verify = true"
              >
                Verify Your Donation
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <BModal :isOpen="showCurrencyModal" @close="showCurrencyModal = false">
    <p class="text-text-alternate-7 text-sm my-8">Select Currency Below</p>

    <form
      @submit.prevent="
        $emit('bless', { option: 'CARD', currency }),
          (showCurrencyModal = false)
      "
      class="lg:px-28"
    >
      <label
        :class="[
          'block mt-4 border-text-alternate-12 rounded h-28 bg-bg-alternate-6 p-4 xs:p-5 my-4 xs:my-5 relative border-box cursor-pointer',
          currency === 'NGN' ? ' border-2' : '',
        ]"
      >
        <TickIcon
          v-if="currency == 'NGN'"
          class="h-5 xs:h-6 absolute right-4 top-4 z-10 fill-current"
          style="fill: #017622"
        />

        <p class="text-4xl font-bold text-center mt-4">NGN (&#8358;)</p>

        <input
          type="radio"
          name="Naira"
          value="NGN"
          hidden
          v-model="currency"
        />
      </label>
      <label
        :class="[
          'block border-text-alternate-12 rounded h-28 bg-bg-alternate-9 p-4 xs:p-5 mt-8 mb-4 xs:my-5 relative border-box cursor-pointer',
          currency === 'USD' ? ' border-2' : '',
        ]"
      >
        <TickIcon
          v-if="currency == 'USD'"
          class="h-5 xs:h-6 absolute right-4 top-4 z-10 fill-current"
          style="fill: #017622"
        />

        <p class="text-4xl font-bold text-center mt-4">USD ($)</p>

        <input
          type="radio"
          name="Dollar"
          value="USD"
          hidden
          v-model="currency"
        />
      </label>
      <button
        class="
          bg-btn-bg-primary
          text-btn-text-primary
          rounded
          w-full
          py-4
          my-4
          mt-10
          font-bold
          text-sm
          xs:text-base
        "
        type="submit"
        :disabled="!currency"
      >
        Proceed
      </button>
    </form>
  </BModal>

  <BlesserChecker
    :fundraiserId="fundraiser.id"
    v-model="verify"
    @bless="$emit('bless')"
  />

  <CancelPledge
    v-model="shouldCancelPledge"
    :authUser="authUser"
    :fundraiserId="fundraiser.id"
  />

  <BlessorModal
    :isOpen="isBlessModalOpen"
    :fundraiserId="fundraiser.id"
    @close="isBlessModalOpen = false"
  />

  <BModal :isOpen="showCode" @close="showCode = false">
    <p class="form-label">
      The USSD code generated below is only valid for the previously selected
      bank.
    </p>
    <div class="ussd-card">
      <p class="font-bold text-center text-text-primary">
        {{ ussdData.paymentCode }}
      </p>
      <p class="copy-text text-center flex mt-2">
        Tap to copy code
        <CopyIcon
          @click="handleCopy(ussdData.paymentCode)"
          class="h-5 ml-2 stroke-0 stroke-current fill-current"
          style="fill: #577387"
        />
      </p>
    </div>
    <div
      class="
        px-6
        py-4
        flex
        gap-4
        z-10
        bg-bg-alternate-3
        absolute
        left-0
        w-full
        bottom-13
      "
    >
      <a
        :href="'tel:' + ussdData.paymentCode"
        class="
          bg-btn-bg-primary
          text-btn-text-primary
          px-4
          py-3.5
          xs:py-4
          w-full
          rounded
          text-center text-sm
          xs:text-base
          font-bold
        "
      >
        Dial Code
      </a>
    </div>
  </BModal>

  <BModal
    :heading="'Bless Fundraiser using USSD'"
    :isOpen="isOpen"
    @close="isOpen = showCode = false"
  >
    <form @submit.prevent="generateCode">
      <div class="lg:hidden">
        <div>
          <p class="form-label">
            Use the form below to generate a USSD code for your bank to bless
            this fundraiser
          </p>
        </div>
        <!-- {{ bankLists }} -->
        <div class="flex flex-col mt-2 text-text-primary font-semibold text-sm">
          <label>Amount (Naira)*</label>
          <!-- <input
            class="
              border
              rounded
              border-border-primary
              mt-2
              mb-1
              p-3.75
              text-sm
              font-medium
              focus:outline-none
              input-shadow
            "
            type="number"
            v-model.number="amount"
            pattern="\d*"
            inputmode="decimal"
            min="0"
          /> -->
          <BCurrencyInput
          name="amount"
                    placeholder="0.00"
                    @input="handleAmountInput"
                      />
        </div>
        <template v-if="!authUser">
          <div
            class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
          >
            <label>First Name*</label>
            <input
              class="
                border
                rounded
                border-border-primary
                mt-2
                mb-1
                p-3.75
                text-sm
                font-medium
                focus:outline-none
                input-shadow
              "
              type="text"
              v-model.trim="firstname"
            />
          </div>
          <div
            class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
          >
            <label>Last Name*</label>
            <input
              class="
                border
                rounded
                border-border-primary
                mt-2
                mb-1
                p-3.75
                text-sm
                font-medium
                focus:outline-none
                input-shadow
              "
              type="text"
              v-model.trim="lastname"
            />
          </div>
        </template>
        <div class="flex flex-col mt-5 text-text-primary font-semibold text-sm">
          <label>Choose Your Bank *</label>
          <BModalSelect
            class="my-2 pb-5"
            :options="bankLists"
            v-model="bank"
          ></BModalSelect>
        </div>
        <div class="mb-24 flex items-center mt-4">
          <input type="checkbox" v-model="anonymous" />
          <label class="font-medium text-xs ml-2">
            Bless this fundraiser anonymously.
          </label>
        </div>
        <div
          class="
            px-6
            py-4
            flex
            gap-4
            z-10
            bg-bg-alternate-3
            absolute
            left-0
            w-full
            bottom-13
          "
        >
          <button
            class="
              bg-btn-bg-primary
              text-btn-text-primary
              px-4
              py-3.5
              xs:py-4
              w-full
              rounded
              text-center text-sm
              xs:text-base
              font-bold
            "
            :disabled="!isFilled"
            type="submit"
          >
            Generate Code
          </button>
        </div>
      </div>
      <div class="hidden lg:block mt-6">
        <p class="text-sm text-text-primary font-medium">
          Use the form below to generate a USSD code for your bank to bless this
          fundraiser
        </p>
        <div class="grid grid-cols-2 mt-6 gap-6">
          <div class="col-span-1">
            <div
              class="flex flex-col mt-2 text-text-primary font-semibold text-sm"
            >
              <label>Amount (Naira)*</label>
              <!-- <input
                class="
                  border
                  rounded
                  border-border-primary
                  mt-2
                  mb-1
                  p-3.75
                  text-sm
                  font-medium
                  focus:outline-none
                  input-shadow
                "
                type="number"
                v-model.number="amount"
                pattern="\d*"
                inputmode="decimal"
                min="0"
              /> -->
              <BCurrencyInput
              name="amount"
                    placeholder="0.00"
                    @input="handleAmountInput"
                      />
            </div>
            <template v-if="!authUser">
              <div
                class="
                  flex flex-col
                  mt-5
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <label>First Name*</label>
                <input
                  class="
                    border
                    rounded
                    border-border-primary
                    mt-2
                    mb-1
                    p-3.75
                    text-sm
                    font-medium
                    focus:outline-none
                    input-shadow
                  "
                  type="text"
                  v-model.trim="firstname"
                />
              </div>
            </template>
            <div class="mb-8 flex items-center mt-4">
              <input type="checkbox" v-model="anonymous" />
              <label class="font-medium text-xs ml-2">
                Bless this fundraiser anonymously.
              </label>
            </div>
            <button
              class="
                bg-btn-bg-primary
                text-btn-text-primary
                px-4
                py-3.5
                xs:py-4
                w-full
                h-14
                rounded
                text-center text-sm
                xs:text-base
                font-bold
              "
              :disabled="!isFilled"
              type="submit"
            >
              Generate Code
            </button>
          </div>
          <div class="col-span-1">
            <div
              class="flex flex-col mt-2 text-text-primary font-semibold text-sm"
            >
              <label>Choose Your Bank *</label>

              <BSelectInput
                :options="bankLists"
                v-model="bank"
                class="mt-2 input-shadow focus:outline-none"
              />
            </div>

            <div
              class="flex flex-col mt-6 text-text-primary font-semibold text-sm"
            >
              <label>Last Name*</label>
              <input
                class="
                  border
                  rounded
                  border-border-primary
                  mt-2
                  mb-1
                  p-3.75
                  text-sm
                  font-medium
                  focus:outline-none
                  input-shadow
                "
                type="text"
                v-model.trim="lastname"
              />
            </div>

            <div
              v-if="showCode"
              class="
                flex flex-col
                items-center
                justify-center
                h-14
                bg-text-alternate-9
                rounded-md
                mt-16
                text-center
              "
            >
              <p class="text-sm text-border-alternate-4">
                Dial the code below on your phone
              </p>
              <p class="text-base mt-1 font-bold text-text-primary">
                {{ ussdData.paymentCode }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </form>
  </BModal>
  <BSpinner
    class="flex-grow"
    v-if="ussdResponse.isLoading"
    text="Generating code..."
  ></BSpinner>
</template>
<script>
import QrcodeVue from 'qrcode.vue';
import BBlessor from '@/components/ui/BBlessor';
import randomColor from 'randomcolor';
import { reactive, ref, toRefs, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { formatNumber } from '@/utils';
import BlesserChecker from './BlesserChecker';
import CancelPledge from './CancelPledge';
import { useUtils } from '@/cmp-functions/useUtils';
import BlessorModal from '@/components/ui/BlessorModal';
import BModalSelect from '@/components/ui/BModalSelect.vue';
import { useApi } from '@/cmp-functions/useApi';
import { getUssdBanks, generateUssdCode } from '@/services/api';
import BSpinner from '@/components/ui/BSpinner';
import BModal from '@/components/ui/BModal';
import { useToast } from 'vue-toastification';
import 'vue3-carousel/dist/carousel.css';
// import { Carousel, Slide, Pagination } from 'vue3-carousel';
import BSelectInput from '../../components/ui/BSelectInput.vue';
import { getYouTubeVideoId } from '@/utils';
import FundraiserBottom from './FundraiserBottom.vue';
import BCurrencyInput from '../../components/ui/BCurrencyInput.vue';

export default {
  name: 'RecurringFundraiser',

  components: {
    BlesserChecker,
    CancelPledge,
    BlessorModal,
    BBlessor,
    BModal,
    BModalSelect,
    BSpinner,
    // Carousel,
    // Slide,
    // Pagination,
    BSelectInput,
    QrcodeVue,
    FundraiserBottom,
    BCurrencyInput,
  },

  props: [
    'fundraiser',
    'donations',
    'accounts',
    'targetCoverage',
    'authUser',
    'isModal',
    'isWishList',
    'wishListId',
    'isPromoteAndEarn',
    'promoteAndEarnId',
    'searchResponse',
  ],

  emits: ['share', 'bless', 'makeawish', 'updateLikeData'],

  setup(props, context) {
    const { handleCopy } = useUtils();
    const verify = ref(false);
    const isOpen = ref(false);
    const showCode = ref(false);
    const isModalShare = ref(false);
    const shouldCancelPledge = ref(false);
    const isBlessModalOpen = ref(false);
    const shouldTruncateDesc = ref(true);
    const showCurrencyModal = ref(false);
    const currency = ref('NGN');
    const store = useStore();
    const authUser = computed(() => store.state.auth.authData);
    const { firstname = '', lastname = '', bank = '' } = authUser.value || {};
    const ussdData = ref({});
    const isDonateOnce = ref(true);

    const recurAmount = ref('');

    const userDetails = reactive({
      firstname,
      lastname,
      bank,
      amount: '',
      bankCode: '0',
      anonymous: false,
      isPromoteAndEarn: props.isPromoteAndEarn,
      promoteAndEarnId: props.promoteAndEarnId,
    });

    const embededYoutubeId = computed(() =>
      getYouTubeVideoId(props.fundraiser?.youTubeLink)
    );

    const slides = computed(() => {
      const { images, youTubeLink } = props.fundraiser;
      return youTubeLink
        ? [
            ...images.slice().reverse(),
            { id: embededYoutubeId.value, youTubeLink },
          ]
        : images.slice().reverse();
    });

    const isFilled = computed(() =>
      Object.values(userDetails).every(
        (value) => typeof value === 'boolean' || value
      )
    );

    const isLenghtExceeded = computed(
      () => props.fundraiser.fundraiserDesc.length > 280
    );

    const isDescTruncated = computed(
      () => shouldTruncateDesc.value && isLenghtExceeded.value
    );

    const [bankListResponse, fetchUssdBanks] = useApi(getUssdBanks);
    const [ussdResponse, generateUssd] = useApi(generateUssdCode);
    fetchUssdBanks();
    const bankList = computed(() => bankListResponse.data);
    const bankLists = computed(
      () =>
        bankListResponse.data
          ?.filter((item) => item.baseUssdCode)
          .map(({ name }) => name) || []
    );
    const isMobile = () => {
      if (screen.width <= 768) {
        return true;
      } else {
        return false;
      }
    };

    const generateCode = () => {
      const payload = {
        ...userDetails,
        fundraiserId: props.fundraiser.id,
      };
      let obj = bankList.value.filter((item) => item.name === userDetails.bank);
      let code = obj[0].baseUssdCode;
      let ussdCode = code.substring(1, code.length - 1);
      payload.bankCode = ussdCode;
      delete payload.bank;
      // delete payload.anonymous;
      generateUssd(payload);
    };

    watch(ussdResponse, ({ data, error, isSuccess, isFailure }) => {
      if (isSuccess) {
        isMobile() ? (isOpen.value = false) : null;
        showCode.value = true;
        ussdData.value = data;
      }
      if (isFailure) {
        const toast = useToast();
        toast.error(error);
      }
    });

    const updateLike = (value) => {
      context.emit('updateLikeData', value)
    }

    const handleSetSection = (value) => {
      context.emit('setSection', value);
    };

    const handleAmountInput = (value) => {
      console.log(value);
      userDetails.amount = value
    }

    const description = computed(() => {
      if (isDescTruncated.value)
        return props.fundraiser.fundraiserDesc.slice(
          0,
          isMobile() ? 180 : 1200
        );
      return props.fundraiser.fundraiserDesc;
    });

    const socialmedialinks = computed(() =>
      props.fundraiser.socialmedialinks.filter(({ link }) => !!link?.trim())
    );

    const wishListAccounts = computed(() => {
      if (props.isWishList) {
        const account = props.accounts.filter(
          (item) => item.wishListId === props.wishListId
        );
        return account;
      } else {
        return null;
      }
    });

    const promoteAndEarnAccounts = computed(() => {
      if (props.isPromoteAndEarn) {
        const account = props.accounts.filter(
          (item) => item.promoteAndEarnId === props.promoteAndEarnId
        );
        return account;
      } else {
        return null;
      }
    });

    const handleTruncate = (value) => {
      shouldTruncateDesc.value = value;
    };

    const setDonationType = (value) => {
      isDonateOnce.value = value;
    };

    // const wishListBlessor = (wishListId) => {
    //   fundraiser
    // }

    const sections = computed(() => {
      const sectionList = ['Overview'];
      if (props.fundraiser.impactstories?.length) {
        sectionList.push('Impact Stories');
      }

      if (props.fundraiser.teammembers?.length) {
        sectionList.push('Team');
      }

      if (props.fundraiser.images?.length) {
        sectionList.push('Gallery');
      }

      if (props.fundraiser.contactAddress) {
        sectionList.push('Contact');
      }

      return sectionList;
    });

    return {
      recurAmount,
      generateCode,
      randomColor,
      ...toRefs(userDetails),
      isOpen,
      sections,
      isBlessModalOpen,
      socialmedialinks,
      wishListAccounts,
      formatNumber,
      isModalShare,
      shouldCancelPledge,
      handleCopy,
      shouldTruncateDesc,
      isDescTruncated,
      isLenghtExceeded,
      description,
      verify,
      bankLists,
      isFilled,
      ussdResponse,
      bankList,
      ussdData,
      showCode,
      slides,
      embededYoutubeId,
      promoteAndEarnAccounts,
      isMobile,
      handleTruncate,
      showCurrencyModal,
      currency,
      isDonateOnce,
      setDonationType,
      handleSetSection,
      handleAmountInput,
      updateLike,
    };
  },
};
</script>

<style lang="scss" scoped>
.border-first {
  border-color: #567286;
}

.silver {
  color: #567286;
}

.gold {
  color: #9f771f;
}

.diamond {
  color: #d15520;
}

.decorated {
  @apply relative overflow-hidden text-center text-text-alternate-3 font-semibold;
  &::before,
  &::after {
    content: '\a0';
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 46%;
    height: 1px;
    background-color: #e5e5e5;
  }
  &::before {
    margin-left: -48%;
    text-align: right;
  }
  &::after {
    margin-left: 2%;
  }
}
</style>
