<template>
  <transition
    enter-active-class="ease-linear duration-300 transition-all"
    enter-from-class="opacity-0"
    leave-active-class="ease-linear duration-300 transition-all"
    leave-to-class="opacity-0"
    leave-class="opacity-100"
    appear
  >
    <div
      class="fixed z-10 top-0 left-0 w-screen h-screen flex justify-center"
      style="background: rgba(22, 23, 34, 0.6)"
      v-if="isOpen"
    ></div>
  </transition>
  <transition
    enter-active-class="ease-linear duration-300 transition-all"
    enter-from-class="top-1/2 opacity-0"
    leave-active-class="ease-linear duration-300 transition-all"
    leave-to-class="top-1/2 opacity-0"
    leave-class="top-0 opacity-100"
    appear
  >
    <div
      id="containerDiv"
      class="
        scroll
        bg-bg-primary
        z-50
        fixed
        left-0
        right-0
        top-0
        lg:top-1/4
        bottom-0
        overflow-y-scroll
        m-max-width
        w-modal
        lg:rounded-md
        to
        lg:h-150
        mx-auto
        pb-10
      "
      v-if="isOpen"
    >
      <div
        class="
          px-6
          pt-4
          h-10
          w-full
          flex
          justify-between
          lg:border-b lg:pb-8
          border-bg-alternate-1
          lg:mb-8
        "
      >
        <p class="font-semibold text-text-primary text-base hidden lg:block">
          Blessors
        </p>
        <div class="flex items-center pt-2">
          <BSelectInput
            class="hidden lg:block mr-6"
            v-model="filter"
            :options="['Highest', 'Lowest', 'Newest', 'Oldest', 'A - Z']"
            :dense="true"
          />
          <button
            class="
              text-4xl
              h-5
              hidden
              sticky
              lg:flex
              focus:outline-none
              overflow-hidden
              items-center
            "
            @click="$emit('close')"
          >
            &times;
          </button>
        </div>
        <div class="flex fixed w-full pr-10 justify-between">
          <p></p>
          <button
            class="
              focus:outline-none
              text-2xl
              top-4
              left-6
              bg-bg-primary
              rounded-full
              w-6
              lg:hidden
              h-6
              flex
              items-center
              justify-center
              z-30
            "
            @click="$emit('close')"
            style="box-shadow: 0 0 5px #888"
          >
            &times;
          </button>
        </div>
      </div>
      <div class="px- pb-8">
        <h4
          class="
            font-semibold
            px-6
            lg:hidden
            mt-2
            text-lg
            py-2
            flex
            justify-between
          "
        >
          <span>Blessors</span>
          <span>({{ data.length }})</span>
        </h4>
        <div
          class="
            bg-text-alternate-9
            flex
            justify-between
            items-center
            lg:hidden
            my-2
            mb-4
            px-6
            h-9
          "
        >
          <p
            @click="filter = 'Highest'"
            class="text-2xs cursor-pointer"
            :class="
              filter == 'Highest'
                ? 'text-text-alternate-1 font-bold'
                : 'text-text-alternate-10'
            "
          >
            Highest
          </p>

          <p
            @click="filter = 'Lowest'"
            class="text-2xs cursor-pointer"
            :class="
              filter == 'Lowest'
                ? 'text-text-alternate-1 font-bold'
                : 'text-text-alternate-10'
            "
          >
            Lowest
          </p>

          <p
            @click="filter = 'Newest'"
            class="text-2xs cursor-pointer"
            :class="
              filter == 'Newest'
                ? 'text-text-alternate-1 font-bold'
                : 'text-text-alternate-10'
            "
          >
            Newest
          </p>

          <p
            @click="filter = 'Oldest'"
            class="text-2xs cursor-pointer"
            :class="
              filter == 'Oldest'
                ? 'text-text-alternate-1 font-bold'
                : 'text-text-alternate-10'
            "
          >
            Oldest
          </p>

          <p
            @click="filter = 'A - Z'"
            class="text-2xs cursor-pointer"
            :class="
              filter == 'A - Z'
                ? 'text-text-alternate-1 font-bold'
                : 'text-text-alternate-10'
            "
          >
            A - Z
          </p>
        </div>
        <BPageLoader v-if="isLoading" />
        <template v-else>
          <div class="px-6 bg-text-primar">
            <BBlessor
              v-for="blessor in blessors"
              :key="blessor.id"
              :blessor="blessor"
            />
            <div class="fixed bottom-24 right-0 pr-4">
              <LeftArrowIcon
                
                @click="scrollUp"
                class="h-6 rotate fill-current"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
  </transition>
</template>

<script>
import BBlessor from '@/components/ui/BBlessor';
import { watch, onBeforeUnmount, toRefs, ref, computed } from 'vue';
import { useApi } from '@/cmp-functions/useApi';
import { getAllBlessors } from '@/services/api';
import BSelectInput from './BSelectInput.vue';

export default {
  name: 'BlessorModal',

  components: { BBlessor, BSelectInput },

  emits: ['close'],

  props: ['isOpen', 'fundraiserId'],

  setup(props) {
    const filter = ref('Highest');
    const blessors = ref('');
    const [blessorResponse, fetchAllBlessors] = useApi(getAllBlessors);
    fetchAllBlessors(props.fundraiserId);

    onBeforeUnmount(() => {
      document.body.classList.remove('modal-open');
    });

    const scrollUp = () => {
      var myDiv = document.getElementById('containerDiv');
      // myDiv.innerHTML = variableLongText;
      myDiv.scrollTop = 0;
    };

    // const isScrolled = () => {
    //   var myDiv = document.getElementById('containerDiv');
    //   if (myDiv)
    //     if (myDiv.scrollTop > 100) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    // };

    const isScrolled = computed(() => {
      return document.getElementById('containerDiv').scrollTop;
    });

    watch(blessorResponse, ({ data }) => {
      blessors.value = data;
    });

    watch(props, ({ isOpen }) => {
      if (isOpen) document.body.classList.add('modal-open');
      else document.body.classList.remove('modal-open');
    });

    watch(filter, () => {
      if (filter.value == 'Newest') {
        blessors.value.sort(function (a, b) {
          var keyA = new Date(a.createdAt),
            keyB = new Date(b.createdAt);
          // Compare the 2 dates
          if (keyA > keyB) return -1;
          if (keyA < keyB) return 1;
          return 0;
        });
      }
      if (filter.value == 'Oldest') {
        blessors.value.sort(function (a, b) {
          var keyA = new Date(a.createdAt),
            keyB = new Date(b.createdAt);
          // Compare the 2 dates
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
      }
      if (filter.value == 'Highest') {
        blessors.value.sort(function (a, b) {
          var keyA = a.amount,
            keyB = b.amount;

          if (keyA > keyB) return -1;
          if (keyA < keyB) return 1;
          return 0;
        });
      }
      if (filter.value == 'Lowest') {
        blessors.value.sort(function (a, b) {
          var keyA = a.amount,
            keyB = b.amount;

          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
      }

      if (filter.value == 'A - Z') {
        blessors.value.sort(function (a, b) {
          if (a.firstname.toLowerCase() < b.firstname.toLowerCase()) {
            return -1;
          }
          if (a.firstname.toLowerCase() > b.firstname.toLowerCase()) {
            return 1;
          }
          return 0;
        });
      }
    });

    return {
      ...toRefs(blessorResponse),
      filter,
      blessors,
      scrollUp,
      isScrolled,
    };
  },
};
</script>
<style scoped>
.rotate {
  transform: rotate(90deg);
}
@media only screen and (min-width: 1024px) {
  .w-modal {
    width: 696px !important;
  }
}
.scroll {
  scroll-behavior: smooth;
}
</style>
