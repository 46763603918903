import { useToast } from 'vue-toastification';

export const useUtils = () => {
  const toast = useToast();

  const handleCopy = async (value) => {
    try {
      navigator.clipboard.writeText(value);
      toast.success('Copied to clipboard!');
    } catch (error) {
      console.log(error);
    }
  };

  return { handleCopy };
};
