<template>
  <BModal
    :heading="'Make-A-Wish'"
    :isOpen="learnMore"
    @close="learnMore = !learnMore"
  >
    <div class="lg:p-8">
      <h3 class="lg:hidden text2xl text-text-primary font-bold mb-4">
        Make A Wish
      </h3>
      This feature allows you to help a fundraiser meet its target by adopting this fundraiser to mark a special occasion such as your birthday, anniversary, or memorial. You will be given a unique url link to share with your friends and family, and all donations from your network will be acknowledged as your effort.
    </div>
  </BModal>

  <BModal
    :heading="'Make-A-Wish'"
    :isOpen="learnMorePnE"
    @close="learnMorePnE = !learnMorePnE"
  >
    <div class="lg:p-8">
      <h3 class="lg:hidden text2xl text-text-primary font-bold mb-4">
        Promote and Earn
      </h3>
      Promote & Earn is a feature that allows other people help promote your
      fundraiser in return for a commission, by generating their unique link
      which they can share with their friends and family network. Promoters are
      then rewarded based on the total funds they were able to help you generate
      via their link. You are require to set the commission percentage with
      which your promoters will be rewarded.
    </div>
  </BModal>

  <div class="p-6 pt-0" v-if="!isModal">
    <hr class="border-t border-border-primary mb-4 mt-1" />
    <div class="bg-bg-alternate-3 rounded lg:h-56 mb-4 w-full make-wish-bg p-4" v-if="fundraiser.allowMakeAWish">
      <p class="font-semibold text-text-alternate-1 text-lg">Make A Wish</p>
      <p class="text-sm font-normal mt-3">
        Adopt this fundraisers for special occasions such as your birthday,
        Anniversary, Memorial, etc.<span class="font-semibold">
          <a href="javascript:void(0)" @click="learnMore = true">
            Learn more
          </a>
        </span>
      </p>

      <div class="flex items-center my-5">
        <div
          class="
            rounded-full
            h-10
            w-10
            bg-bg-alternate-8
            text-text-secondary
            flex
            justify-center
            items-center
          "
        >
          <PersonIcon class="text-text-secondary fill-current" />
        </div>
        <a href="javascript:void(0)" @click="emit('makeawish')">
        <p class="underline font-medium text-sm text-text-alternate-1 ml-2">
          {{ fundraiser.wishlists?.length ? 'Join others' : 'Be the first' }}
        </p>
      </a>
      </div>
      <button
        class="
          h-9
          bg-btn-bg-primary
          w-44
          text-text-secondary text-sm
          font-bold
          rounded
        "
        @click="emit('makeawish')"
      >
        Make a Wish
      </button>
    </div>

    <div
      class="bg-bg-alternate-3 rounded mb-4 w-full make-wish-bg p-4"
      style="background-color: #fde0d4"
      v-if="fundraiser.allowPromoteAndEarn"
    >
      <p class="font-semibold text-text-alternate-1 text-lg">Promote & Earn</p>
      <p class="text-sm font-normal mt-3">
        Promote this fundraiser to your friends and family and earn a commission
        on all donations that come through your effort.<span
          class="font-semibold"
        >
          <a href="javascript:void(0)" @click="learnMorePnE = true">
            Learn more
          </a>
        </span>
      </p>
      <p class="font-bold mt-4">
        <span class="text-2xl" style="color: #e20010"
          >{{ fundraiser.promoteAndEarnCommPercent }}%</span
        >
        Commission!
      </p>
      <button
        class="
          h-9
          bg-btn-bg-primary
          w-44
          mt-8
          mb-2
          text-text-secondary text-sm
          font-bold
          rounded
        "
        @click="handlePromoteBtnClick"
      >
        {{ authUser ? 'Promote Fundraiser' : 'Login to Promote' }}
      </button>
    </div>

    <hr class="border-t border-border-primary mb-6" />
    <div class="flex justify-between">
      <p class="text-xs text-text-alternate-3 mb-4 font-semibold">
        Fundraiser ID:
        <span class="text-text-alternate-1">{{ props.fundraiser.id }}</span>
      </p>
      <p class="text-xs text-text-alternate-3 mb-4 font-semibold">
        Created:
        <span class="text-text-alternate-1">{{
          props.fundraiser.createdAt.substr(0, 10)
        }}</span>
      </p>
    </div>
    <div class="flex text-sm justify-between">
      <button class="flex items-center" @click="emit('setSection', 'REPORT')">
        <FlagIcon class="mx-auto h-4 stroke-0 stroke-current fill-current" />
        <span class="underline ml-1">Report Fundraiser</span>
      </button>
      <BButtonLoader class="h-5 w-5" v-if="likeResponse.isLoading" />
      <button
        class="flex items-end focus:outline-none"
        v-else-if="canLike"
        @click="handleLike"
      >
        <Heart2Icon class="mx-auto h-5" v-if="props.fundraiser.likes.isLiked" />
        <HeartIcon class="mx-auto h-5 fill-current" v-else />
        <span
          :class="[
            'ml-1 font-medium text-sm',
            { liked: props.fundraiser.likes.isLiked },
          ]"
        >
          {{ formatNumber(props.fundraiser.likes.totalLikes) }}
        </span>
      </button>
      <div class="flex items-end focus:outline-none" v-else>
        <HeartIcon class="mx-auto h-5 fill-current" />
        <span :class="['ml-1 font-medium text-sm']">
          {{ formatNumber(props.fundraiser.likes.totalLikes) }}
        </span>
      </div>
    </div>
    <aside
      class="text-text-alternate-1 mt-4 border-t border-border-primary"
      v-if="
        props.searchResponse?.length > 1 &&
        props.fundraiser.components.includes('4')
      "
    >
      <h4 class="font-semibold text-xs my-5">You may also be interested in:</h4>

      <div class="grid grid-cols-2 sm:grid-cols-3 gap-4">
        <template
          v-for="fundraiser in isMobile()
            ? props.searchResponse.slice(0, 2)
            : props.searchResponse.slice(0, 3)"
          :key="fundraiser.id"
        >
          <BFundraiserCardSM
            v-if="fundraiser.id !== props.fundraiser.id"
            :fundraiser="fundraiser"
            @like="fundraiser.likes = $event"
          />
        </template>
      </div>
    </aside>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref, watch } from 'vue';
import { formatNumber } from '@/utils';
import { likeFundraiser } from '@/services/api';
import { useApi } from '@/cmp-functions/useApi';
import BFundraiserCardSM from '../../components/ui/BFundraiserCardSM.vue';
import BModal from '../../components/ui/BModal.vue';

import { useRouter } from 'vue-router';

const props = defineProps({
  fundraiser: {
    type: Object,
  },
  authUser: {
    type: Object,
  },
  isOwner: {
    type: Boolean,
  },
  isModal: {
    type: Boolean,
  },
  searchResponse: {
    type: Object,
  },
});
const emit = defineEmits(['setSection', 'makeawish', 'updateLikeData']);
const learnMore = ref(false);
const learnMorePnE = ref(false);
const canLike = computed(() => props.authUser && !props.isOwner);
const router = useRouter();

const [likeResponse, postLikeFundraiser] = useApi(likeFundraiser);

const handleLike = () => {
  if (!props.authUser) return;
  postLikeFundraiser({
    userId: props.authUser.id,
    id: props.fundraiser.id,
  });
};

// const handleLike = () => {
//   emit('likeButtonClicked')
// }
watch(likeResponse, ({data, isSuccess}) => {
  if(isSuccess){
    emit('updateLikeData', data)
  }
})

const isMobile = () => {
  return window.matchMedia('only screen and (max-width: 768px)').matches;
};

const handlePromoteBtnClick = () => {
  if (props.authUser) {
    emit('promoteFundraiser');
  } else {
    router.push({
      name: 'Login',
    });
  }
};
</script>

<style></style>
