import { getUserDetails, getAccountWallet } from '@/services/api';

const state = () => {
  return {
    authData: null,
    userDetails: null,
    userWallet: null,
  };
};

const actions = {
  async fetchUser({ commit }, userId) {
    try {
      const { data } = await getUserDetails(userId);
      if (data.valid) {
        commit('SET_USER_DETAILS', data.data);
      }
      // eslint-disable-next-line no-empty
    } catch {}
  },
  async fetchUserWallet({ commit }, userId) {
    try {
      const { data } = await getAccountWallet(userId);
      if (data.valid) {
        commit('SET_USER_WALLET', data.data);
      }
      // eslint-disable-next-line no-empty
    } catch {}
  },
};

const mutations = {
  SET_AUTH(state, authData) {
    state.authData = { ...authData };
  },

  SET_USER_DETAILS(state, user) {
    state.userDetails = { ...user };
  },

  SET_USER_WALLET(state, wallet) {
    state.userWallet = { ...wallet };
  },

  TOGGLE_PERSONALISE(state) {
    const { token, ispersonalised, hasUserActedOnRegBankSetup } = state.authData;
    localStorage.setItem(
      'authData',
      JSON.stringify({
        token,
        hasUserActedOnRegBankSetup,
        ispersonalised: !ispersonalised,
      })
    );
    state.authData.ispersonalised = true;
  },

  TOGGLE_BANK_SETUP(state) {
    const { token, ispersonalised, hasUserActedOnRegBankSetup } = state.authData;
    localStorage.setItem(
      'authData',
      JSON.stringify({
        token,
        ispersonalised,
        hasUserActedOnRegBankSetup: !hasUserActedOnRegBankSetup,
      })
    );
    state.authData.ispersonalised = true;
  },

  UNSET_AUTH(state) {
    localStorage.removeItem('authData');
    state.authData = null;
    state.userDetails = null;
  },
};

const auth = {
  namespaced: true,
  actions,
  state,
  mutations,
};

export default auth;
