import { reactive, watch } from 'vue';

const state = reactive({
  isSideMenuOpen: false,
  isSubMenuOpen: false,
  isLoginOpen: false,
  isTermsOpen: false,
  isPrivacyOpen: false,
  isOrgRegOpen: false,
  isAboutUsOpen: false,
});

watch(
  () => state.isSideMenuOpen,
  (value) => {
    if (value) document.body.classList.add('modal-open');
    else document.body.classList.remove('modal-open');
  }
);

const closeSideMenu = () => {
  state.isSubMenuOpen = false;
  state.isSideMenuOpen = false;
};

export default () => ({ state, closeSideMenu });
