<template>
  <div class="">
    <div class="text-sm">
      <p class="px-6 text-lg font-semibold text-text-primary mb-5">
        Sign up now as an Individual.
      </p>
      <BStepper :step="1" />
    </div>
    <div class="px-6" id="form">
      <form @submit.prevent="handleContinue">
        <div class="flex flex-col mt-5 text-text-primary font-semibold text-sm">
          <label>First Name*</label>

          <input
            class="
              border
              rounded
              border-border-primary
              mt-2
              mb-1
              p-3.75
              text-sm
              font-medium
              focus:outline-none
              input-shadow
            "
            v-model="firstname"
            type="text"
          />
          <span
            v-for="error in v$.firstname.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
        </div>

        <div class="flex flex-col mt-5 text-text-primary font-semibold text-sm">
          <label>Last Name*</label>
          <input
            class="
              border
              rounded
              border-border-primary
              mt-2
              mb-1
              p-3.75
              text-sm
              font-medium
              focus:outline-none
              input-shadow
            "
            type="text"
            v-model="lastname"
          />
          <span
            v-for="error in v$.lastname.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
        </div>

        <div class="flex flex-col mt-5 text-text-primary font-semibold text-sm">
          <label>Gender*</label>

          <div
            class="
              border
              rounded
              border-border-primary
              mt-2
              mb-1
              text-sm
              font-medium
              focus:outline-none
              input-shadow
            "
          >
            <ul class="grid grid-cols-2 max-w-md mx-auto">
              <li class="relative">
                <label
                  :class="
                    gender == 'Male' ? 'bg-text-alternate-9 border-r' : ''
                  "
                  class="
                    flex
                    p-3.75
                    bg-white
                    border-text-alternate-8
                    rounded-md
                    cursor-pointer
                    focus:outline-none
                    text-xs text-center
                    pl-12
                    font-bold
                  "
                >
                  <RadioCheckedIcon v-if="gender == 'Male'" class="mr-3" />
                  <RadioUncheckedIcon v-else class="mr-3" />

                  Male
                  <input
                    hidden
                    name="Male"
                    value="Male"
                    v-model="gender"
                    type="radio"
                /></label>
              </li>

              <li class="relative">
                <label
                  :class="
                    gender == 'Female' ? 'bg-text-alternate-9 border-l' : ''
                  "
                  class="
                    flex
                    p-3.75
                    text-center
                    bg-white
                    border-text-alternate-8
                    rounded-md
                    cursor-pointer
                    pl-11
                    focus:outline-none
                    text-xs
                    font-bold
                  "
                >
                  <RadioCheckedIcon v-if="gender == 'Female'" class="mr-3" />
                  <RadioUncheckedIcon v-else class="mr-3" />
                  Female
                  <input
                    v-model="gender"
                    hidden
                    type="radio"
                    value="Female"
                    name="Female"
                /></label>
              </li>
            </ul>
          </div>
          <span
            v-for="error in v$.gender.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
        </div>

        <div
          class="
            flex
            relative
            flex-col
            mt-5
            text-text-primary
            font-semibold
            text-sm
          "
        >
          <label class="">Date of birth*</label>

         <!--- <input
            class="
              border
              rounded
              border-border-primary
              mt-2
              mb-1
              p-3.75
              text-sm
              font-medium
              focus:outline-none
              input-shadow
            "
            v-model="dob"
            type="date"
          />--->

          <VueDatePicker :enable-time-picker="false" :format="'dd/MM/yyyy'" v-model="dob" 
            class="
                border
                rounded
                border-border-primary
                mt-2 outline-none
                mb-1 
                w-full h-12
                text-sm
                font-medium
                focus:outline-none
                input-shadow
              "></VueDatePicker>

          <span
            v-for="error in v$.dob.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
        </div>

        <div class="flex flex-col mt-5 text-text-primary font-semibold text-sm">
          <label>Phone Number*</label>

          <input
            class="
              border
              rounded
              border-border-primary
              mt-2
              mb-1
              p-3.75
              text-sm
              font-medium
              focus:outline-none
              input-shadow
            "
            v-model="phoneNumber"
            type="number"
          />
          <span
            v-for="error in v$.phoneNumber.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
        </div>

        <div class="flex flex-col pt-6">
          <button
            class="
              bg-btn-bg-primary
              text-btn-text-primary
              my-2
              py-4
              rounded
              text-sm
              font-bold
            "
            type="submit"
            
          >
            Continue
          </button>
        </div>
      </form>
    </div>

    <!-- <BSpinner v-if="isLoading" text="Verifying" />

    <BSpinner v-else-if="bvnResponse.isLoading" text="Verifying BVN" /> -->
  </div>
</template>

<script>
import { ref, toRefs, reactive, computed } from 'vue';

// import BSpinner from '@/components/ui/BSpinner';

import BStepper from '../../../components/ui/BStepper.vue';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';


export default {
  name: 'StepOne',

  components: {
    // BSpinner,
    BStepper,
  },

  emits: ['next', 'reset'],

  setup(props, { emit }) {
    const steps = ref([{}]);
    const required$ = helpers.withMessage('This field is required', required);
    const formInput = reactive({
      firstname: '',
      lastname: '',
      gender: 'Male',
      dob: '',
      phoneNumber: '',
    });

    const rules = {
      firstname: { required$ },
      lastname: { required$ },
      gender: { required$ },
      dob: { required$ },
      phoneNumber: { required$ },
    };

    const v$ = useVuelidate(rules, formInput);

    const isFilled = computed(() => {
      let istrue = Object.values(formInput).every((value) => value);
      console.log(istrue);
      return istrue;
    });

    const handleContinue = async () => {
      const result = await v$.value.$validate();
      if (!result) {
        document.getElementById('form').scrollIntoView({
          behavior: 'smooth',
        });
        return;
      }
      emit('next', formInput);
    };

  

    return {
      steps,
      ...toRefs(formInput),
      isFilled,
      handleContinue,
      v$,
    };
  },
};
</script>
<style lang="scss" scoped>
.decorated {
  &::after {
    background: #000;
    width: 100px;
  }
  // after:block
  //           after:bg-text-primary
  //           after:w-1
  //           after:h-10
  //           after:mx-auto
  //           after:my-2
}
input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

</style>
