<template>
  <!-- <transition
    enter-active-class="ease-linear duration-300 transition-all"
    enter-from-class="opacity-0"
    leave-active-class="ease-linear duration-300 transition-all"
    leave-to-class="opacity-0"
    leave-class="opacity-100"
    appear
  >
    <div
      class="fixed z-10 top-0 left-0 w-screen h-screen flex justify-center"
      style="background: rgba(22, 23, 34, 0.6)"
      v-if="isOpen"
    ></div>
  </transition> -->
  <transition
    enter-active-class="ease-linear duration-300 transition-all"
    enter-from-class="top-1/2 opacity-0"
    leave-active-class="ease-linear duration-300 transition-all"
    leave-to-class="top-1/2 opacity-0"
    leave-class="top-0 opacity-100"
    appear
  >
    <div
      class="bg-bg-primary z-10 fixed left-0 right-0 top-0 bottom-0 overflow-y-scroll m-max-width mx-auto"
      v-if="isOpen"
    >
      <div class="pl-6 pt-4 pb-10 h-10">
        <button
          class="
            text-4xl
            h-5
            focus:outline-none
            overflow-hidden
            flex
            items-center
          "
          @click="$emit('close')"
        >
          &times;
        </button>
      </div>
      <div class="px-6 pb-8">
        <div class="border-b border-border-primary pb-6">
          <h4 class="font-semibold text-lg">Terms and Conditions</h4>
        </div>
        <BPageLoader v-if="isLoading" />
        <div
          class="text-sm text-text-alternate-1 py-4"
          v-html="data.content"
          v-else
        />
      </div>
    </div>
  </transition>
</template>

<script>
import { watch, onBeforeUnmount, toRefs } from 'vue';
import { useApi } from '@/cmp-functions/useApi';
import { getTerms } from '@/services/api';

export default {
  name: 'TermsModal',

  emits: ['close'],

  props: ['isOpen'],

  setup(props) {
    const [response, fetchTerms] = useApi(getTerms);
    fetchTerms();

    onBeforeUnmount(() => {
      document.body.classList.remove('modal-open');
    });

    watch(props, ({ isOpen }) => {
      if (isOpen) document.body.classList.add('modal-open');
      else document.body.classList.remove('modal-open');
    });

    return {
      ...toRefs(response),
    };
  },
};
</script>
