<template>
  <div>
    <div
      class="w-full h-full border-2 border-border-alternate-3 rounded-full loader"
    />
  </div>
</template>

<script>
export default {
  name: 'BButtonLoader',
};
</script>

<style lang="scss" scoped>
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border-top-color: transparent;
  animation: spinner 0.8s linear infinite;
}
</style>
