import Axios from 'axios';
import { useToast } from 'vue-toastification';

import { getAuthToken, setAuth, clearAuth } from '../utils';
import useSideMenuState from '@/cmp-functions/useSideMenuState.js';
const { state } = useSideMenuState();
const toast = useToast();

const baseURL = process.env.VUE_APP_BLESS_SERVER_BASE_URL;

const axiosInstance = Axios.create({
  baseURL: baseURL,
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const authToken = getAuthToken();
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }

    return config;
  },

  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (!response?.data?.valid) throw response;

    const { data } = response.data;

    if (data?.token) {
      setAuth(data);
    }

    return response;
  },

  (error) => {
    if (error.response?.status === 401) {
      clearAuth();
      state.isLoginOpen = true;
    } else if (error.response?.status === 500) {
      toast.error(error.response.data.message);
    }

    return Promise.reject(error?.response || { data: error });
  }
);

export default axiosInstance;
