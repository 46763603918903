import { createRouter, createWebHistory } from 'vue-router';
import { getAuthData, getUserDetails } from '@/utils';
import Home from '../views/Home.vue';
import BaseLayout from '@/layouts/BaseLayout.vue';
import Signup from '@/views/Signup/index.vue';
import SignupIndividual from '@/views/Signup/Individual/index.vue';
import SignupOrganisation from '@/views/Signup/Organisation/index.vue';
import SignupIndividualLG from '@/views/Signup/IndividualLG/index.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import TermsAndConditions from '@/views/TermsAndConditions.vue';
import Feeds from '@/views/Feeds.vue';
import Filter from '@/views/Filter.vue';
import useSideMenuState from '@/cmp-functions/useSideMenuState.js';
import { useAppState } from '@/cmp-functions/appState.js';
import Swal from 'sweetalert2';

const { state } = useSideMenuState();
const { isNative } = useAppState();

let isMobile = window.matchMedia('only screen and (max-width: 768px)').matches;

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      isLarge: true,
      noAuth: true,
    },
  },

  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'Privacy',
    component: () => import('@/views/PrivacyPolicy.vue'),
  },
  {
    path: '/advisory-board',
    name: 'Advisory',
    component: () => import('@/views/AdvisoryBoard.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      noAuth: true,
      // layout: BaseLayout,
    },
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    meta: {
      noAuth: true,
    },
  },
  {
    path: '/signup/individual',
    name: 'SignupIndividual',
    component: isMobile ? SignupIndividual : SignupIndividualLG,
    meta: {
      noAuth: true,
    },
  },

  {
    path: '/signup/personal',
    name: 'SignupIndividualLG',
    component: SignupIndividualLG,
    meta: {
      noAuth: true,
    },
  },
  {
    path: '/signup/organisation',
    name: 'Signup Organisation',
    component: SignupOrganisation,
    meta: {
      noAuth: true,
    },
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      noAuth: true,
      layout: BaseLayout,
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      noAuth: true,
      layout: BaseLayout,
    },
  },
  {
    path: '/verify-email',
    name: 'EmailVerification',
    component: () => import('@/views/EmailVerification.vue'),
    meta: {
      layout: BaseLayout,
    },
  },
  {
    path: '/make-a-wish/approval/:wishListId/:ref',
    name: 'MakeAWishVerification',
    component: () => import('@/views/MakeAWishApproval.vue'),
    meta: {
      layout: BaseLayout,
    },
  },
  {
    path: '/promote-and-earn/approval/:promoteAndEarnId/:ref',
    name: 'PromoteAndEarnVerification',
    component: () => import('@/views/PromoteAndEarnApproval.vue'),
    meta: {
      layout: BaseLayout,
    },
  },
  // {
  //   path: '/contact',
  //   name: 'Contact',
  //   component: () => import('@/views/Contact.vue'),
  // },
  {
    path: '/top-blessors',
    name: 'TopBlessors',
    component: () => import('@/views/TopBlessors.vue'),
    meta: { layout: BaseLayout },
  },
  {
    path: '/top-fundraisers',
    name: 'TopFundraisers',
    component: () => import('@/views/TopFundraisers.vue'),
    meta: { layout: BaseLayout },
  },
  {
    path: '/explore/:filter?',
    name: 'Feeds',
    component: Feeds,
    meta: { layout: BaseLayout },
  },
  {
    path: '/fundraiser/filter',
    name: 'Filter',
    component: Filter,
    meta: { layout: BaseLayout },
  },
  {
    path: '/fundraiser/payment_status',
    name: 'FundraiserPaymentStatus',
    component: () => import('@/views/FundraiserPaymentStatus.vue'),
    meta: { layout: BaseLayout },
  },
  {
    path: '/company-registration',
    name: 'OrganizationRegistration',
    component: () => import('@/views/OrganizationRegistration.vue'),
    meta: {},
  },
  {
    path: '/account',
    name: 'Accounts',
    component: () => import('@/views/accounts/Account.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account-fundraiser',
    name: 'AccountsFundraiser',
    component: () => import('@/views/accounts/MyFundraiser.vue'),
    meta: {
      requiresAuth: true,
      layout: BaseLayout,
    },
  },
  {
    path: '/overview',
    name: 'AccountOverview',
    component: () => import('@/views/accounts/Overview.vue'),
    meta: {
      requiresAuth: true,
      layout: BaseLayout,
    },
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import('@/views/Notifications.vue'),
    meta: {
      requiresAuth: true,
      layout: BaseLayout,
    },
  },
  {
    path: '/messages',
    name: 'Messages',
    component: () => import('@/views/message/Messages.vue'),
    meta: {
      requiresAuth: true,
      layout: BaseLayout,
    },
  },
  {
    path: '/messages/compose',
    name: 'ComposeMessage',
    component: () => import('@/views/message/ComposeMessage.vue'),
    meta: {
      requiresAuth: true,
      layout: BaseLayout,
    },
  },
  {
    path: '/messages/:id',
    name: 'MessageChat',
    component: () => import('@/views/message/MessageChat.vue'),
    meta: {
      requiresAuth: true,
      layout: BaseLayout,
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/accounts/setting/index.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/settings/personalisation',
    name: 'Personalisation',
    component: () => import('@/views/accounts/setting/Personalisation.vue'),
    meta: {
      requiresAuth: true,
      layout: BaseLayout,
    },
  },
  {
    path: '/settings/refer',
    name: 'Refer',
    component: () => import('@/views/accounts/setting/Refer.vue'),
    meta: {
      requiresAuth: true,
      layout: BaseLayout,
    },
  },
  {
    path: '/settings/promote',
    name: 'PromoteAndEarn',
    component: () => import('@/views/accounts/setting/PromoteAndEarn.vue'),
    meta: {
      requiresAuth: true,
      layout: BaseLayout,
    },
  },
  {
    path: '/settings/faqs',
    name: 'FAQs',
    component: () => import('@/views/accounts/setting/FAQs.vue'),
    meta: {
      layout: BaseLayout,
    },
  },
  {
    path: '/settings/notifications',
    name: 'NotificationSetting',
    component: () => import('@/views/accounts/setting/NotificationSetting.vue'),
    meta: {
      requiresAuth: true,
      layout: BaseLayout,
    },
  },
  {
    path: '/settings/faqs/:slug',
    name: 'FAQDetails',
    component: () => import('@/views/accounts/setting/FAQDetails.vue'),
    meta: {
      layout: BaseLayout,
    },
  },
  {
    path: '/settings/security',
    name: 'Security',
    component: () => import('@/views/accounts/setting/Security.vue'),
    meta: {
      requiresAuth: true,
      layout: BaseLayout,
    },
  },
  {
    path: '/settings/security/question',
    name: 'SecurityQuestions',
    component: () => import('@/views/accounts/setting/Questions.vue'),
    meta: {
      requiresAuth: true,
      layout: BaseLayout,
    },
  },
  {
    path: '/settings/security/change-password',
    name: 'UpdatePassword',
    component: () => import('@/views/accounts/setting/UpdatePassword.vue'),
    meta: {
      requiresAuth: true,
      layout: BaseLayout,
    },
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () => import('@/views/accounts/payment/index.vue'),
    meta: {
      requiresAuth: true,
      layout: BaseLayout,
    },
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: () => import('@/views/accounts/wallet/index.vue'),
    meta: {
      requiresAuth: true,
      layout: BaseLayout,
    },
  },
  {
    path: '/wallet/fundraiser/:id',
    name: 'AccountFundraiser',
    component: () => import('@/views/accounts/wallet/AccountFundraiser.vue'),
    meta: {
      requiresAuth: true,
      layout: BaseLayout,
    },
  },
  {
    path: '/promotion/fundraiser/:id',
    name: 'PromotedFundraiser',
    component: () =>
      import('@/views/accounts/promotion/PromotedFundraiser.vue'),
    meta: {
      requiresAuth: true,
      layout: BaseLayout,
    },
  },
  {
    path: '/promotion/wallet/:userId',
    name: 'PromoteAndEarnWallet',
    component: () =>
      import('@/views/accounts/setting/PromoteAndEarnWallet.vue'),
    meta: {
      requiresAuth: true,
      layout: BaseLayout,
    },
  },
  // {
  //   path: '/fundraiser/:slug/embed',
  //   component: () => import('@/views/FundraiserEmbed.vue'),
  // },
  {
    path: '/fundraiser/:slug',
    component: () => import('@/layouts/FundraiserLayout.vue'),
    children: [
      {
        path: '',
        name: 'Overview',
        alias: 'overview',
        component: () => import('@/views/fundraiser/Overview.vue'),
      },
      {
        path: 'blessors',
        name: 'Blessors',
        component: () => import('@/views/fundraiser/Blessors.vue'),
      },
      {
        path: 'rewards',
        name: 'Rewards',
        component: () => import('@/views/fundraiser/Rewards.vue'),
      },
      {
        path: 'comments',
        name: 'Comments',
        component: () => import('@/views/fundraiser/Comments.vue'),
      },
      {
        path: 'impact-stories',
        name: 'Impact Stories',
        component: () => import('@/views/fundraiser/ImpactStories.vue'),
      },
      {
        path: 'team-members',
        name: 'Team',
        component: () => import('@/views/fundraiser/TeamMembers.vue'),
      },
      {
        path: 'gallery',
        name: 'Gallery',
        component: () => import('@/views/fundraiser/Gallery.vue'),
      },
      {
        path: 'contacts',
        name: 'Contact',
        component: () => import('@/views/fundraiser/Contacts.vue'),
      },
      // {
      //   path: 'wishlist/:wishId',
      //   alias: [
      //     'contacts/wishlist/:wishId',
      //     'impact-stories/wishlist/:wishId',
      //     'team-members/wishlist/:wishId',
      //     'gallery/wishlist/:wishId',
      //     'contacts/wishlist/:wishId',
      //   ],
      //   name: 'WishList',
      //   component: () => import('@/views/fundraiser/WishList.vue'),
      // },
    ],
  },
  {
    path: '/fundraiser/:id/history',
    name: 'fundraiserHistory',
    component: () => import('@/views/fundraiser/FundraiserHistory.vue'),
    meta: {
      requiresAuth: true,
      layout: BaseLayout,
    },
  },
  // {
  //   path: '/feeds',
  //   component: () => import('@/layouts/FeedLayout.vue'),
  //   children: [
  //     {
  //       path: '',
  //       name: 'Feeds',
  //       component: () => import('@/views/Feeds.vue'),
  //     },
  //     {
  //       path: 'my-fundraiser',
  //       name: 'MyFundraiser',
  //       component: () => import('@/views/MyFundraiser.vue'),
  //       meta: {
  //         requiresAuth: true,
  //       },
  //     },
  //   ],
  // },
  {
    path: '/bank',
    name: 'Bank',
    component: () => import('@/views/Bank.vue'),
    meta: {
      requiresAuth: true,
      isBank: true,
      layout: BaseLayout,
    },
  },
  {
    path: '/create-fundraiser',
    name: 'CreateProject',
    component: () => import('@/views/createProject/index.vue'),
    meta: {
      requiresAuth: true,
      layout: BaseLayout,
    },
  },
  {
    path: '/fundraiser/:id/edit',
    name: 'EditFundraiser',
    component: () => import('@/views/editFundraiser/index.vue'),
    meta: {
      requiresAuth: true,
      layout: BaseLayout,
    },
    beforeEnter: (to, from, next) => {
      Swal.fire({
        title: `<p class="text-lg">Edit Fundraiser?</p>`,
        text: `Your fundraiser will be temporarily suspended and will require re-approval. Proceed with edit?`,
        // icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, proceed!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          next(false);
        }
      });
    },
  },
  {
    path: '/start-screen',
    name: 'StartScreen',
    component: () => import('@/views/StartScreen.vue'),
    meta: {
      isLarge: true,
      noAuth: true,
    },
  },
  {
    path: '/personalization',
    name: 'Personalization',
    component: () => import('@/views/Category.vue'),
    meta: {
      requiresAuth: true,
      isCategory: true,
      layout: BaseLayout,
    },
  },
  {
    path: '/profile/:username/edit',
    name: 'EditProfile',
    component: () => import('@/views/accounts/profile/EditProfile.vue'),
    meta: {
      requiresAuth: true,
      layout: BaseLayout,
    },
  },
  {
    path: '/:username',
    name: 'Profile',
    component: () => import('@/views/accounts/profile/index.vue'),
    meta: { layout: BaseLayout },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: () => {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const customNext = (arg) => {
    document.body.classList.remove('modal-open');
    next(arg);
  };

  const authUser = getAuthData();
  const userDetails = getUserDetails();
  const { requiresAuth, noAuth, isCategory, isBank } = to.meta;

  if (to.name === 'Home' && isNative) {
    return customNext({ name: 'StartScreen' });
  }

  if (to.name === 'StartScreen' && !isNative) {
    return customNext({ name: 'Home' });
  }

  if (noAuth && authUser) {
    return customNext('/explore');
  }

  if (requiresAuth && !authUser) {
    state.isLoginOpen = true;
    return from.name ? next(false) : customNext('/explore');
  }

  if (isCategory && authUser.ispersonalised) {
    return customNext('/bank');
  }

  if (isBank && authUser.hasUserActedOnRegBankSetup) {
    return customNext('/explore');
  }

  if (
    to.name === 'EditProfile' &&
    (from.name !== 'Profile' || to.params.username !== userDetails?.username)
  ) {
    return customNext({
      name: 'Profile',
      params: { username: to.params.username },
    });
  }

  customNext();
});

export default router;
