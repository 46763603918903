<template>
  <transition
    enter-active-class="ease-in-out duration-200 transition-all"
    enter-to-class="ml-0 opacity-100"
    enter-from-class="-ml-76 opacity-0"
    leave-active-class="ease-in-out duration-200 transition-all"
    leave-to-class="-ml-76 opacity-0"
    leave-from-class="ml-0 opacity-100"
  >
    <div
      v-if="isSideMenuOpen"
      class="bg-bg-primary z-10 flex-shrink-0 overflow-x-hidden w-76 h-screen"
    >
    <div v-if="authUser" class="flex min-h-screen">
        <div class="px-6 py-4 order-2 side-shadow">
          <header class="pt-1 text-lg flex text-text-alternate-1">
            <button @click="isSubMenuOpen = false">
              <LeftArrowIcon class="h-6 fill-current" />
            </button>
            <h2 class="mx-auto font-semibold text-sm capitalize">
              {{ subMenuType }}
            </h2>
            <button
              class="
                text-4xl
                h-5
                focus:outline-none
                overflow-hidden
                flex
                items-center
              "
              @click="closeSideMenu"
            >
              &times;
            </button>
          </header>
          <ul class="font-semibold w-64" v-if="subMenuType === 'settings'">
            <li class="my-6 py-1 flex items-center">
              <Lock2Icon
                class="mx-auto h-5 stroke-2 stroke-current"
                style="stroke: #577387"
              />
              <router-link
                class="flex-grow text-text-alternate-1 pl-3.5"
                :to="{ name: 'Security' }"
                >Security</router-link
              >
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
            <li class="my-6 py-1 flex items-center">
              <NotificationIcon
                class="mx-auto h-5 stroke-2 stroke-current"
                style="stroke: #577387"
              />
              <router-link
                class="flex-grow text-text-alternate-1 pl-3.5"
                :to="{ name: 'NotificationSetting' }"
                >Notifications</router-link
              >
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
            <li class="my-6 py-1 flex items-center">
              <MegaPhoneIcon
                class="h-5 stroke-current stroke-2 text-text-alternate-4"
                style="stroke: #577387"
              />
              <router-link
                class="flex-grow text-text-alternate-1 pl-3.5"
                :to="{ name: 'Refer' }"
                >Refer & Earn</router-link
              >
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
            <li class="my-6 py-1 flex items-center">
              <PaymentIcon
                class="h-5 stroke-current stroke-2 text-text-alternate-4"
                style="stroke: #577387"
              />
              <router-link
                class="flex-grow text-text-alternate-1 pl-3.5"
                :to="{ name: 'PromoteAndEarn' }"
                >Promote & Earn</router-link
              >
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
            <li class="my-6 py-1 flex items-center">
              <InfoIcon
                class="h-5 stroke-current stroke-2 text-text-alternate-4"
                style="stroke: #577387"
              />
              <router-link
                class="flex-grow text-text-alternate-1 pl-3.5"
                :to="{ name: 'FAQs' }"
                >FAQs</router-link
              >
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
            <li class="my-6 py-1 flex items-center">
              <PhoneIcon
                class="mx-auto h-5 stroke-2 stroke-current"
                style="stroke: #577387"
              />
              <router-link
                class="flex-grow text-text-alternate-1 pl-3.5"
                :to="{ name: 'ComposeMessage' }"
                >Contact Support</router-link
              >
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
          </ul>
          <ul class="font-semibold w-64" v-else-if="subMenuType === 'stats'">
            <li class="my-6 py-1 flex items-center">
              <AddGroupIcon
                class="mx-auto h-5 fill-current"
                style="fill: #577387"
              />
              <router-link
                class="flex-grow text-text-alternate-1 pl-3.5"
                :to="{ name: 'TopBlessors' }"
                >Top Blessors</router-link
              >
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
            <li class="my-6 py-1 flex items-center">
              <Fundraiser2Icon
                class="h-5 stroke-2 stroke-current text-text-alternate-4"
              />
              <router-link
                class="flex-grow text-text-alternate-1 pl-3.5"
                :to="{ name: 'TopFundraisers' }"
                >Top Fundraisers</router-link
              >
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
          </ul>
          <ul class="font-semibold w-64" v-else-if="subMenuType === 'wallet'">
            <li class="my-6 py-1 flex items-center">
              <Fundraiser2Icon
              class="h-5 stroke-2 stroke-current text-text-alternate-4"
              />
              <router-link
                class="flex-grow text-text-alternate-1 pl-3.5"
                :to="{ name: 'Wallet' }"
                >Fundraisers Wallet</router-link
              >
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
            <li class="my-6 py-1 flex items-center">
              <MegaPhoneIcon
                class="h-5 stroke-2 stroke-current text-text-alternate-4"
              />
              <router-link
                class="flex-grow text-text-alternate-1 pl-3.5"
                :to="{ name: 'Refer' }"
                >Refer & Earn Wallet</router-link
              >
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
            <li class="my-6 py-1 flex items-center">
              <MegaPhoneIcon
                class="h-5 stroke-2 stroke-current text-text-alternate-4"
              />
              <router-link
                class="flex-grow text-text-alternate-1 pl-3.5"
                :to="{ name: 'PromoteAndEarn' }"
                >Promote & Earn Wallet</router-link
              >
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
          </ul>
        </div>
        <div
          :class="[
            'p-6 order-1 side-shadow transition-all duration-200',
            { '-ml-76': isSubMenuOpen },
          ]"
        >
          <button
            class="
              text-4xl
              h-5
              focus:outline-none
              overflow-hidden
              flex
              items-center
            "
            @click="closeSideMenu"
          >
            &times;
          </button>
          <ul class="font-semibold w-64">
            <li class="my-6 py-0.5 flex items-center">
              <OverviewIcon class="h-4" />
              <router-link
                class="flex-grow text-text-alternate-1 pl-4"
                to="/overview"
                >Overview</router-link
              >
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
            <li class="my-6 py-0.5 flex items-center">
              <Fundraiser2Icon
                class="h-5 stroke-2 stroke-current text-text-alternate-4"
              />
              <router-link
                class="flex-grow text-text-alternate-1 pl-3.5"
                to="/account-fundraiser"
                >My Fundraisers</router-link
              >
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
            <li class="my-6 py-0.5 flex items-center">
              <ProfileIcon
                class="h-5 stroke-current stroke-2 text-text-alternate-4"
              />
              <router-link
                class="flex-grow text-text-alternate-1 pl-3.5"
                :to="{
                  name: 'Profile',
                  params: { username: authUser.username },
                }"
              >
                Profile
              </router-link>
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
            <!-- <li class="my-6 py-0.5 flex items-center">
              <Wallet2Icon
                class="h-5 stroke-current stroke-2 text-text-alternate-4"
              />
              <router-link
                class="flex-grow text-text-alternate-1 pl-3.5"
                to="/wallet"
                >Wallet</router-link
              >
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li> -->

            <li class="my-6 py-1 flex items-center">
              <Wallet2Icon
                class="h-5 stroke-current stroke-2 text-text-alternate-4"
              />
              <button
                class="
                  flex-grow
                  text-left
                  font-semibold
                  text-text-alternate-1
                  pl-3.5
                  focus:outline-none
                "
                @click="showSubMenu('wallet')"
              >
                Wallet
              </button>
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
            
            <li class="my-6 py-0.5 flex items-center">
              <PaymentIcon
                class="h-5 stroke-current stroke-1 text-text-alternate-4"
              />
              <router-link
                class="flex-grow text-text-alternate-1 pl-3.5"
                to="/payment"
                >Payment</router-link
              >
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
            <li class="my-6 py-0.5 flex items-center">
              <MessageIcon class="h-5 fill-current text-text-alternate-4" />
              <router-link
                class="flex-grow text-text-alternate-1 pl-3.5"
                to="/messages"
                >Messages</router-link
              >
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
            <li class="my-6 py-0.5 flex items-center">
              <Setting2Icon class="h-5 stroke-current text-text-alternate-4" />
              <button
                class="
                  flex-grow
                  text-left
                  font-semibold
                  text-text-alternate-1
                  pl-3.5
                  focus:outline-none
                "
                @click="showSubMenu('settings')"
              >
                Settings
              </button>
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
            <li class="my-6 py-1 flex items-center">
              <StatIcon
                class="h-5 stroke-current text-text-alternate-4"
                style="fill: #577387"
              />
              <button
                class="
                  flex-grow
                  text-left
                  font-semibold
                  text-text-alternate-1
                  pl-3.5
                  focus:outline-none
                "
                @click="showSubMenu('stats')"
              >
                Stats
              </button>
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
            <hr class="text-text-alternate-8" />
            <li class="my-6 py-0.5 flex items-center">
              <LogOutIcon class="h-5 stroke-current text-text-alternate-4" />
              <button
                class="
                  flex-grow
                  text-left
                  font-semibold
                  text-text-alternate-1
                  pl-3.5
                "
                @click="logout"
              >
                Log Out
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="min-h-screen flex" v-else>
        <div v-if="sideShow == 'about'" class="px-6 py-4 order-2 side-shadow">
          <header class="pt-1 text-lg flex text-text-alternate-1">
            <button @click="(isSubMenuOpen = false), (sideShow = '')">
              <LeftArrowIcon class="h-6 fill-current" />
            </button>
            <h2 class="mx-auto font-semibold text-sm">About us</h2>
            <button
              class="
                text-4xl
                h-5
                focus:outline-none
                overflow-hidden
                flex
                items-center
              "
              @click="closeSideMenu(), (sideShow = '')"
            >
              &times;
            </button>
          </header>
          <ul class="font-semibold w-64">
            <li class="my-6 py-1 flex items-center">
              <InfoIcon
                class="h-5 stroke-current stroke-2 text-text-alternate-4"
                style="stroke: #577387"
              />
              <router-link
                class="flex-grow text-text-alternate-1 pl-3.5"
                :to="{ name: 'About' }"
                >About us</router-link
              >
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
            <li class="my-6 py-1 flex items-center">
              <AddGroupIcon
                class="mx-auto h-5 fill-current"
                style="fill: #577387"
              />
              <router-link
                class="flex-grow text-text-alternate-1 pl-3.5"
                :to="{ name: 'Advisory' }"
                >Advisory Board</router-link
              >
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
          </ul>
        </div>
        <div v-else class="px-6 py-4 order-2 side-shadow">
          <header class="pt-1 text-lg flex text-text-alternate-1">
            <button @click="(isSubMenuOpen = false), (sideShow = '')">
              <LeftArrowIcon class="h-6 fill-current" />
            </button>
            <h2 class="mx-auto font-semibold text-sm">Stats</h2>
            <button
              class="
                text-4xl
                h-5
                focus:outline-none
                overflow-hidden
                flex
                items-center
              "
              @click="closeSideMenu(), (sideShow = '')"
            >
              &times;
            </button>
          </header>
          <ul class="font-semibold w-64">
            <li class="my-6 py-1 flex items-center">
              <AddGroupIcon
                class="mx-auto h-5 fill-current"
                style="fill: #577387"
              />
              <router-link
                class="flex-grow text-text-alternate-1 pl-3.5"
                :to="{ name: 'TopBlessors' }"
                >Top Blessors</router-link
              >
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
            <li class="my-6 py-1 flex items-center">
              <Fundraiser2Icon
                class="h-5 stroke-2 stroke-current text-text-alternate-4"
              />
              <router-link
                class="flex-grow text-text-alternate-1 pl-3.5"
                :to="{ name: 'TopFundraisers' }"
                >Top Fundraisers</router-link
              >
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
          </ul>
        </div>

        <div
          :class="[
            'p-6 order-1 side-shadow transition-all duration-200',
            { '-ml-76': isSubMenuOpen },
          ]"
        >
          <button
            class="
              text-4xl
              h-5
              focus:outline-none
              overflow-hidden
              flex
              items-center
            "
            @click="closeSideMenu(), (sideShow = '')"
          >
            &times;
          </button>
          <ul class="font-semibold w-64">
            <!-- <ul class="font-semibold w-full"> -->
            <li class="my-6 py-0.5 flex items-center">
              <Fundraiser2Icon
                class="h-5 stroke-2 stroke-current text-text-alternate-4"
              />
              <router-link
                class="flex-grow text-text-alternate-1 pl-3.5"
                to="/explore"
                >Fundraisers</router-link
              >
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
            <li class="my-6 py-1 flex items-center">
              <DiscoverIcon
                class="h-5 stroke-current stroke-2 text-text-alternate-4"
                style="stroke: #577387"
              />
              <router-link
                class="flex-grow text-text-alternate-1 pl-3.5"
                :to="{ name: 'Filter' }"
              >
                Discover
              </router-link>
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
            <li class="my-6 py-1 flex items-center">
              <InfoIcon
                class="h-5 stroke-current stroke-2 text-text-alternate-4"
                style="stroke: #577387"
              />
              <router-link
                class="flex-grow text-text-alternate-1 pl-3.5"
                :to="{ name: 'FAQs' }"
                >FAQs</router-link
              >
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>

            <li class="my-6 py-1 flex items-center">
              <InfoIcon
                class="h-5 stroke-current stroke-2 text-text-alternate-4"
                style="stroke: #577387"
              />
              <button
                class="
                  flex-grow
                  text-left
                  font-semibold
                  text-text-alternate-1
                  pl-3.5
                  focus:outline-none
                "
                @click="(isSubMenuOpen = true), (sideShow = 'about')"
              >
                About us
              </button>
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
            <li class="my-6 py-1 flex items-center">
              <StatIcon
                class="h-5 stroke-current text-text-alternate-4"
                style="fill: #577387"
              />
              <button
                class="
                  flex-grow
                  text-left
                  font-semibold
                  text-text-alternate-1
                  pl-3.5
                  focus:outline-none
                "
                @click="isSubMenuOpen = true"
              >
                Stats
              </button>
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </li>
            <hr class="text-text-alternate-8" />
            <li class="my-6 py-0.5 flex items-center">
              <LogOutIcon class="h-5 stroke-current text-text-alternate-4" />
              <button
                class="
                  focus:outline-none
                  text-left
                  font-semibold
                  flex-grow
                  text-text-alternate-1
                  pl-3.5
                "
                @click="handleSignIn"
              >
                Sign In
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { toRefs, watch, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import useSideMenuState from '@/cmp-functions/useSideMenuState.js';

export default {
  name: 'BSideMenu',

  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const authUser = computed(() => store.state.auth.userDetails);
    const { state, closeSideMenu } = useSideMenuState();
    const subMenuType = ref('');
    const sideShow = ref('');

    watch(() => route?.name, closeSideMenu);
    watch(() => route?.params, closeSideMenu);

    watch(authUser, (newValue) => {
      if (!newValue && route.meta?.requiresAuth) {
        router.push({ name: 'Feeds' });
        handleSignIn();
      }
    });

    const showSubMenu = (value) => {
      subMenuType.value = value;
      state.isSubMenuOpen = true;
    };

    const logout = () => {
      store.commit('auth/UNSET_AUTH');
    };

    const handleAboutUs = () => {
      closeSideMenu();
      state.isAboutUsOpen = true;
    };

    const handleSignIn = () => {
      closeSideMenu();
      // state.isLoginOpen = true;
      router.push('/login');
    };

    return {
      authUser,
      subMenuType,
      showSubMenu,
      ...toRefs(state),
      handleSignIn,
      handleAboutUs,
      closeSideMenu,
      logout,
      sideShow,
    };
  },
};
</script>

<style scoped>
.side-shadow {
  box-shadow: inset rgb(0 0 0 / 19%) -21px -17px 12px -23px;
  /* box-shadow: 0px 8px 24px 0px rgba(77, 31, 31, 0.16); */
}
</style>
