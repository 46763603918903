<template>
  <div class="" id="form">
    <div class="text-sm">
      <BStepperLG class="mt-5 mb-8" />
    </div>
    <div class="px-26">
      <form @submit.prevent="handleContinue">
        <div class="flex flex-col mt-5 text-text-primary font-semibold text-sm">
          <label class="text-sm font-bold">First Name*</label>

          <input
            class="
              border
              rounded
              border-border-primary
              mt-2
              mb-1
              p-3.75
              text-sm
              font-medium
              focus:outline-none
              input-shadow
            "
            v-model="firstname"
            type="text"
          />
          <span
            v-for="error in v$.firstname.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
        </div>

        <div class="flex flex-col mt-5 text-text-primary font-semibold text-sm">
          <label class="text-sm font-bold">Last Name*</label>
          <input
            class="
              border
              rounded
              border-border-primary
              mt-2
              mb-1
              p-3.75
              text-sm
              font-medium
              focus:outline-none
              input-shadow
            "
            type="text"
            v-model="lastname"
          />
          <span
            v-for="error in v$.lastname.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
        </div>

        <div class="grid grid-cols-2 gap-4">
          <div
            class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
          >
            <label class="text-sm font-bold">Gender*</label>

            <div
              class="
                border
                rounded
                border-border-primary
                mt-2
                mb-1
                text-sm
                font-medium
                focus:outline-none
                input-shadow
              "
            >
              <ul class="grid grid-cols-2 max-w-md mx-auto">
                <li class="relative">
                  <label
                    :class="
                      gender == 'Male' ? 'bg-text-alternate-9 border-r' : ''
                    "
                    class="
                      flex
                      p-3.75
                      bg-white
                      border-text-alternate-8
                      rounded-md
                      cursor-pointer
                      focus:outline-none
                      text-xs text-center
                      font-bold
                    "
                  >
                    <RadioCheckedIcon v-if="gender == 'Male'" class="mr-3" />
                    <RadioUncheckedIcon v-else class="mr-3" />

                    Male
                    <input
                      hidden
                      name="Male"
                      value="Male"
                      v-model="gender"
                      type="radio"
                  /></label>
                </li>

                <li class="relative">
                  <label
                    :class="
                      gender == 'Female' ? 'bg-text-alternate-9 border-l' : ''
                    "
                    class="
                      flex
                      p-3.75
                      text-center
                      bg-white
                      border-text-alternate-8
                      rounded-md
                      cursor-pointer
                      focus:outline-none
                      text-xs
                      font-bold
                    "
                  >
                    <RadioCheckedIcon v-if="gender == 'Female'" class="mr-3" />
                    <RadioUncheckedIcon v-else class="mr-3" />
                    Female
                    <input
                      v-model="gender"
                      hidden
                      type="radio"
                      value="Female"
                      name="Female"
                  /></label>
                </li>
              </ul>
            </div>
            <span
              v-for="error in v$.gender.$errors"
              :key="error.$uid"
              class="text-xs text-text-danger"
              >{{ error.$message }}</span
            >
          </div>

          <div
            class="
              flex
              relative
              flex-col
              mt-5
              text-text-primary
              font-semibold
              text-sm
            "
          >
            <label class="text-sm font-bold">Date of birth*</label>

            <!-- <input
              class="
                border
                rounded
                border-border-primary
                mt-2
                mb-1
                p-3.75
                text-sm
                font-medium
                focus:outline-none
                input-shadow
              "
              v-model="dob"
              type="date"
            /> -->
            <VueDatePicker :enable-time-picker="false" :format="'dd/MM/yyyy'" v-model="dob" 
            class="
                border
                rounded
                border-border-primary
                mt-2 outline-none
                mb-1 
                w-full h-12
                text-sm
                font-medium
                focus:outline-none
                input-shadow
              "></VueDatePicker>
            <span
              v-for="error in v$.dob.$errors"
              :key="error.$uid"
              class="text-xs text-text-danger"
              >{{ error.$message }}</span
            >
          </div>
        </div>

        <div class="flex flex-col mt-5 text-text-primary font-semibold text-sm">
          <label class="text-sm font-bold">Phone Number*</label>

          <input
            class="
              border
              rounded
              border-border-primary
              mt-2
              mb-1
              p-3.75
              text-sm
              font-medium
              focus:outline-none
              input-shadow
            "
            placeholder="+234"
            maxlength="11"
            v-model="phoneNumber"
            type=""
          />
          <span
            v-for="error in v$.phoneNumber.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
        </div>

        <p class="text-text-alternate-3 font-bold text-sm mt-8">
          Login information
        </p>
        <div class="flex flex-col mt-5 text-text-primary font-semibold text-sm">
          <label class="text-sm font-bold">Username/Nickname*</label>

          <input
            class="
              border
              rounded
              border-border-primary
              mt-2
              mb-1
              p-3.75
              text-sm
              font-medium
              focus:outline-none
              input-shadow
            "
            v-model="username"
            type="text"
          />
          <span
            v-for="error in v$.username.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
        </div>

        <div class="flex flex-col mt-5 text-text-primary font-semibold text-sm">
          <label class="text-sm font-bold">Email*</label>
          <input
            class="
              border
              rounded
              border-border-primary
              mt-2
              mb-1
              p-3.75
              text-sm
              font-medium
              focus:outline-none
              input-shadow
            "
            type="email"
            v-model="email"
          />
          <span
            v-for="error in v$.email.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
        </div>

        <div class="flex flex-col mt-5 text-text-primary font-semibold text-sm">
          <label class="text-sm font-bold">Password*</label>
          <div class="w-full relative">
            <input
              class="
                w-full
                relative
                border
                rounded
                border-border-primary
                mt-2
                mb-1
                p-3.75
                text-sm
                font-medium
                focus:outline-none
                input-shadow
              "
              v-model="password"
              :type="show ? 'text' : 'password'"
            />

            <PasswordHiddenIcon
              v-if="!show"
              @click="show = true"
              class="absolute right-4 bottom-5"
            />
            <PasswordShownIcon
              v-else
              @click="show = false"
              class="absolute right-4 bottom-5"
            />
          </div>
          <span
            v-for="error in v$.password.$errors"
            :key="error.$uid"
            class="text-xs text-text-danger"
            >{{ error.$message }}</span
          >
        </div>

        <div class="flex flex-col mt-5 text-text-primary font-semibold text-sm">
          <label class="text-sm font-bold">Confirm Password*</label>
          <div class="w-full relative">
            <input
              class="
                w-full
                relative
                border
                rounded
                border-border-primary
                mt-2
                mb-1
                p-3.75
                text-sm
                font-medium
                focus:outline-none
                input-shadow
              "
              v-model="confirm_password"
              :type="show2 ? 'text' : 'password'"
            />
            <PasswordHiddenIcon
              v-if="!show2"
              @click="show2 = true"
              class="absolute right-4 bottom-5"
            />
            <PasswordShownIcon
              v-else
              @click="show2 = false"
              class="absolute right-4 bottom-5"
            />
          </div>
        </div>
        <p
          v-for="error in v$.confirm_password.$errors"
          :key="error.$uid"
          class="text-xs text-text-danger font-semibold"
          >{{ error.$message }}</p
        >

        <div class="flex flex-col mt-5 text-text-primary font-semibold text-sm">
          <label class="text-sm font-bold">Referral Code (optional)</label>

          <input
            class="
              border
              rounded
              border-border-primary
              mt-2
              mb-1
              p-3.75
              text-sm
              font-medium
              focus:outline-none
              input-shadow
            "
            v-model="referralCode"
            type="number"
          />
        </div>
        <div class="grid grid-cols-3 items-center">
          <div class="col-span-1 pt-4">
            <a
              href="javascript:void(0)"
              @click="handlePrevious"
              class="font-bold text-text-primary"
              >Previous</a
            >
          </div>
          <div class="col-span-2">
            <div class="flex flex-col pt-6">
              <button
                class="
                  bg-btn-bg-primary
                  text-btn-text-primary
                  my-2
                  py-4
                  rounded
                  text-sm
                  font-bold
                "
                type="submit"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, reactive, computed } from 'vue';
import BStepperLG from '../../../components/ui/BStepperLG.vue';
import useVuelidate from '@vuelidate/core';
import {
  required,
  helpers,
  email,
  minLength,
  sameAs,
} from '@vuelidate/validators';

export default {
  name: 'StepOne',

  components: {
    BStepperLG,
  },

  emits: ['next', 'reset'],

  setup(props, { emit }) {
    const steps = ref([{}]);
    const show = ref(false);
    const show2 = ref(false);
    const required$ = helpers.withMessage('This field is required', required);
    const formInput = reactive({
      firstname: '',
      lastname: '',
      gender: 'Male',
      dob: '',
      phoneNumber: '',
      username: '',
      email: '',
      password: '',
      confirm_password: '',
      referralCode: '',
    });

    const rules = {
      firstname: { required$ },
      lastname: { required$ },
      gender: { required$ },
      dob: { required$ },
      phoneNumber: { required$ },
      username: { required$ },
      email: { required$, email },
      password: { required$, minLength: minLength(8) },
      confirm_password: {
        required$,
        sameAsPassword: sameAs(computed(() => formInput.password)),
      },
    };

    const v$ = useVuelidate(rules, formInput);

    const isFilled = computed(() => {
      const data = JSON.parse(JSON.stringify(formInput));
      delete data.referralCode;
      let istrue =
        Object.values(data).every((value) => value) &&
        formInput.password == formInput.confirm_password;

      return istrue;
    });

    const handleContinue = async () => {
      const result = await v$.value.$validate();
      if (!result) {
        document.getElementById('form').scrollIntoView({
          behavior: 'smooth',
        });
        return;
      }
      emit('next', formInput);
    };


    const handlePrevious = () => {
      emit('previous');
    };

    return {
      steps,
      ...toRefs(formInput),
      isFilled,
      show,
      show2,
      handleContinue,
      handlePrevious,
      v$,
    };
  },
};
</script>
<style lang="scss" scoped>
.decorated {
  &::after {
    background: #000;
    width: 100px;
  }
  // after:block
  //           after:bg-text-primary
  //           after:w-1
  //           after:h-10
  //           after:mx-auto
  //           after:my-2
}

input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

</style>
