<template>
  <slot v-bind="exec(data)" />
</template>

<script>
export default {
  name: 'BFunctional',

  props: ['exec', 'data'],
};
</script>
