<template>
  <div class="py-2 relative flex items-center gap-4">
    <span
      class="
        rounded-full
        border border-border-primary
        font-bold
        bg-bg-alternate-3
        text-xs
        xs:text-sm
        h-10
        w-10
        flex flex-shrink-0
        justify-center
        items-center
      "
      :style="{
        backgroundColor: randomColor({
          luminosity: 'light',
          seed: `${
            blessorDetail.anonymous
              ? 'A'
              : (blessorDetail.firstname ? blessorDetail.firstname[0] : '') +
                (blessorDetail.lastname ? blessorDetail.lastname[0] : '')
          }`,
          format: 'rgba',
          alpha: 0.3,
        }),
        color: randomColor({
          luminosity: 'bright',
          seed: `${
            blessorDetail.anonymous
              ? 'A'
              : (blessorDetail.firstname ? blessorDetail.firstname[0] : '') +
                (blessorDetail.lastname ? blessorDetail.lastname[0] : '')
          }`,
        }),
      }"
    >
      <img
        class="rounded-full"
        v-if="isCoporate"
        :src="blessorDetail.profilePhoto"
        alt="profile_image"
      />
      <span v-else>
        {{
          blessorDetail.anonymous
            ? 'A'
            : (blessorDetail.firstname ? blessorDetail.firstname[0] : '') +
              (blessorDetail.lastname ? blessorDetail.lastname[0] : '')
        }}
      </span>
    </span>
    <div class="text-text-alternate-1">
      <h2 class="text-sm font-medium flex items-center" v-if="isCoporate">
        {{ blessorDetail.username }}
        <VerifiedIcon
          class="h-3 pl-1 mr-auto flex-shrink-0"
          v-if="blessorDetail.verificationStatus === 'VERIFIED'"
        />
      </h2>
      <h2 class="text-sm font-medium" v-else-if="blessorDetail.anonymous">
        Anonymous
        <span v-if="blessor.wishlist">
          (via
          {{ blessor.wishlist?.firstname }} {{ blessor.wishlist?.lastname }})
        </span>
        <span v-else-if="blessor.fundraiserpromotion">
          (via
          {{ blessor.fundraiserpromotion?.firstname }}
          {{ blessor.fundraiserpromotion?.lastname }})
        </span>
      </h2>
      <h2 class="text-sm font-medium" v-else>
        {{ blessor.firstname }} {{ blessor.lastname }}
        <span v-if="blessor.wishlist">
          (via
          {{ blessor.wishlist?.firstname }} {{ blessor.wishlist?.lastname }})
        </span>
        <span v-else-if="blessor.fundraiserpromotion">
          (via
          {{ blessor.fundraiserpromotion?.firstname }}
          {{ blessor.fundraiserpromotion?.lastname }})
        </span>
      </h2>
      <p
        v-if="isPledge"
        class="text-text-alternate-3 text-xs font-medium mt-px"
      >
        <!-- {{ dayjs(blessorDetail.createdAt).format('DD/MM/YYYY') }} -->
      </p>
      <p v-else>
        <span class="text-xs font-bold pr-2">
          {{ blessorDetail.dollarAmount ? '$' : '₦'
          }}{{
            blessorDetail.dollarAmount || blessorDetail.amount.toLocaleString()
          }}
        </span>
        <span class="text-text-alternate-3 text-xs font-medium">
          {{ dayjs().to(new Date(blessorDetail.createdAt).toISOString()) }}
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import randomColor from 'randomcolor';
dayjs.extend(relativeTime);
const flatObject = (object) => {
  let result = {};

  for (let key in object) {
    // eslint-disable-next-line no-prototype-builtins
    if (object.hasOwnProperty(key)) {
      if (object[key]?.constructor === Object) {
        result = { ...result, ...flatObject(object[key]) };
      } else {
        result[key] = object[key];
      }
    }
  }

  return result;
};

export default {
  name: 'BBlessor',

  props: {
    blessor: {
      required: true,
    },
    isPledge: {
      default: false,
    },
  },

  setup(props) {
    // const blessorWishList = props.blessor.wishlist;
    // delete props.blessor.wishlist;
    const blessorDetail = flatObject(props.blessor);
    const isCoporate =
      blessorDetail.accounttype === 'CORPORATE' ||
      blessorDetail.blessorType === 'CORPORATE';

    const addHours = (date, hours) => {
      date.setHours(date.getHours() + hours);
      return date;
    };

    return {
      dayjs,
      randomColor,
      blessorDetail,
      // blessorWishList,
      isCoporate,
      addHours,
    };
  },
};
</script>
