import { reactive } from 'vue';

const defaultDataFormatter = (pre, { data }) => {
  if (data?.data) return data.data;
  return pre;
};

export const useApi = (
  apiMethod,
  initData = null,
  dataFormatter = defaultDataFormatter
) => {
  const apiResult = reactive({
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    error: null,
    data: initData,
  });

  const setError = (error) => (apiResult.error = error);
  const setData = (data) => (apiResult.data = data);
  const setIsSuccess = (value) => (apiResult.isSuccess = value);
  const setIsFailure = (value) => (apiResult.isFailure = value);
  const toggleIsLoading = () => (apiResult.isLoading = !apiResult.isLoading);
  const callApi = async (...args) => {
    try {
      setIsSuccess(false);
      setIsFailure(false);
      toggleIsLoading();
      const response = await apiMethod(...args);
      setData(dataFormatter(apiResult.data, response));
      setError(null);
      setIsSuccess(true);
    } catch ({ data }) {
      setError(data.message);
      // setData(initData);
      setIsFailure(true);
    } finally {
      toggleIsLoading();
    }
  };

  return [apiResult, callApi, { setIsSuccess, setIsFailure }];
};
