import { onMounted, onUnmounted } from 'vue';

export const useInfiniteScroll = (callback, loadInitial) => {
  loadInitial && callback();

  const handleScroll = () => {
    if ((window.innerHeight + window.scrollY + 25) >= document.body.offsetHeight) {
      callback();
    }
  };

  onMounted(() => {
    window.addEventListener('scroll', handleScroll);
  });

  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
  });

  return { handleScroll };
};
