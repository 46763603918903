F<template>
  <div>
    <header class="mx-auto w-full bg-text-secondary z-40">
      <div class="max-w-xl mx-auto lg:px-20 md:max-w-screen-2xl">
        <b-header :isHomePage="true" />
      </div>
    </header>
  </div>
  <div class="bg-home pt-1">
    <!-- <header
      class="
        flex
        items-center
        p-6
        lg:px-24
        justify-between
        max-w-screen-2xl
        mx-auto
      "
    >
      <div class="mx-auto md:m-0">
        <router-link to="/">
          <BrandIcon class="h-6 sm:h-8" />
        </router-link>
      </div>
      <nav class="hidden md:flex ml-auto font-bold text-sm items-center">
        <router-link :to="{ name: 'Login' }">
          <button
            class="mx-5 text-text-primary focus:outline-none font-bold"
            @click="isLoginOpen = true"
          >
            Sign In
          </button>
        </router-link>
        <router-link
          class="mx-3 px-6 py-2 rounded bg-btn-bg-primary text-btn-text-primary"
          :to="{ name: 'Signup' }"
          >Start a Fundraiser
        </router-link>
      </nav>
    </header> -->

    <section
      class="text-text-alternate-1 px-6 md:px-10 lg:px-26 max-w-xl md:max-w-screen-2xl mx-auto mt-6 mb-6 md:my-16"
    >
      <div class="md:grid grid-cols-5 gap-10 justify-center lg:px-16 mt-14">
        <div class="md:col-span-3 text-center md:text-left">
          <h1
            class="text-2xl xs:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold mt-4 w-full max-w-2xl leading-10"
          >
            <span class="mr-2 sm:mr-3">For</span>
            <span
              style="color: #56b893"
              :class="['cursor', { 'blink-animation': shouldBlink }]"
              >{{ name }}</span
            >
          </h1>
          <h2
            class="text-2xl leading-10 xs:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold mb-6 xl:mb-8 w-full max-w-2xl "
          >
            A new way to receive financial support.
          </h2>
          <p
            class="text-sm md:text-lg lg:text-2xl font-normal my-4 mb-12 md:my-6 md:my-8 font-semibold"
          >
            Sign up in 2 minutes to get started
          </p>
          <div
            class="mt-6 mb-10 md:mt-14 md:mb-16 flex flex-col md:flex-row gap-4"
          >
            <router-link :to="{ name: 'Login' }">
              <button
                class="font-bold px-14 py-3.5 sm:py-5 w-full xl:py-6 text-center text-btn-text-primary bg-btn-bg-primary inline-block leading-5 rounded"
                @click="isLoginOpen = true"
              >
                Get Started
              </button>
            </router-link>
            <router-link
              class="font-bold px-5 py-3.5 sm:py-5 xl:py-6 text-center bg-btn-bg-secondary border border-border-alternate-2 inline-block leading-5 rounded box-border"
              to="/explore"
              >Browse Fundraisers</router-link
            >
          </div>
          <!-- <div class="hidden md:flex gap-4">
            <AppleStoreIcon class="h-10 2xs:h-12" />
            <PlayStoreIcon class="h-10 2xs:h-12" />
          </div> -->
          <h4 class="lg:font-semibold hidden lg:block font-bold text-2xl">
            Receive donations for your fundraisers <br />
            from all over the world
          </h4>
        </div>
        <img
          class="w-full inline col-span-2 ml-auto"
          src="@/assets/images/mobile_showcase.png"
          alt="image"
        />
      </div>

      <h4 class="mt-12 text-center lg:hidden font-bold text-2xl md:text-4xl">
        Receive donations for your projects from all over the world
      </h4>

      <div class="text-center my-16 mt-16 mx-auto w-full max-w-3xl">
        <ul class="flex gap-4 md:gap-8 mt-10 mb-6 justify-center">
          <li><MasterCardIcon class="h-4 2xs:h-5 md:h-14" /></li>
          <li><VisaIcon class="h-4 2xs:h-5 md:h-14" /></li>
          <li><UnionPayIcon class="h-4 2xs:h-5 md:h-14" /></li>
          <li><BankTransferIcon class="h-4 2xs:h-5 md:h-14" /></li>
          <li><ApplePayIcon class="h-4 2xs:h-5 md:h-14" /></li>
        </ul>
      </div>
    </section>

    <section class="bg-bg-alternate-3 py-10 md:py-18">
      <div class="px-6 lg:px-26 max-w-xl md:max-w-screen-2xl mx-auto">
        <div class="text-text-alternate-1">
          <h3 class="font-bold text-2xl md:text-4xl text-center lg:text-left">
            How does it work?
          </h3>
        </div>
        <div
          class="grid text-center lg:text-left md:grid-cols-3 pt-4 md:pt-8 gap-6 lg:gap-8 text-text-alternate-1"
        >
          <div class="lg:max-w-90 pt-4">
            <div class="flex justify-center lg:justify-start py-7">
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M34.6665 21.3334C34.6665 15.44 29.8932 10.6667 23.9998 10.6667C18.1065 10.6667 13.3332 15.44 13.3332 21.3334C13.3332 27.2267 18.1065 32 23.9998 32C29.8932 32 34.6665 27.2267 34.6665 21.3334ZM29.3332 21.3334C29.3332 24.2667 26.9332 26.6667 23.9998 26.6667C21.0665 26.6667 18.6665 24.2667 18.6665 21.3334C18.6665 18.4 21.0665 16 23.9998 16C26.9332 16 29.3332 18.4 29.3332 21.3334ZM2.6665 48V53.3334H45.3332V48C45.3332 40.9067 31.1198 37.3334 23.9998 37.3334C16.8798 37.3334 2.6665 40.9067 2.6665 48ZM7.99984 48C8.53317 46.1067 16.7998 42.6667 23.9998 42.6667C31.1732 42.6667 39.4132 46.08 39.9998 48H7.99984ZM53.3332 40V32H61.3332V26.6667H53.3332V18.6667H47.9998V26.6667H39.9998V32H47.9998V40H53.3332Z"
                  fill="#577387"
                />
              </svg>
            </div>
            <h4 class="text-2xl font-bold">Sign up</h4>
            <p class="mt-4 text-base font-medium">
              Create a Blessmi account in 2 minutes and setup your withdrawal
              bank account.
            </p>
          </div>
          <div class=" lg:max-w-90 pt-4">
            <div class="flex justify-center lg:justify-start py-7">
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M56 19.4133V13.3333C56 10.4 53.6 8 50.6667 8H13.3333C10.3733 8 8 10.4 8 13.3333V50.6667C8 53.6 10.3733 56 13.3333 56H50.6667C53.6 56 56 53.6 56 50.6667V44.5867C57.5733 43.6533 58.6667 41.9733 58.6667 40V24C58.6667 22.0267 57.5733 20.3467 56 19.4133ZM53.3333 24V40H34.6667V24H53.3333ZM13.3333 50.6667V13.3333H50.6667V18.6667H34.6667C31.7333 18.6667 29.3333 21.0667 29.3333 24V40C29.3333 42.9333 31.7333 45.3333 34.6667 45.3333H50.6667V50.6667H13.3333Z"
                  fill="#577387"
                />
                <path
                  d="M42.6665 36C44.8756 36 46.6665 34.2091 46.6665 32C46.6665 29.7909 44.8756 28 42.6665 28C40.4574 28 38.6665 29.7909 38.6665 32C38.6665 34.2091 40.4574 36 42.6665 36Z"
                  fill="#577387"
                />
              </svg>
            </div>
            <h4 class="text-2xl font-bold">
              Create a Fundraiser
            </h4>
            <p class="mt-4 text-base font-medium">
              Create a one-time or permanent fundraiser and set your target amount and duration.
            </p>
          </div>
          <div class=" lg:max-w-90 pt-4">
            <div class="flex justify-center lg:justify-start py-7">
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.3335 26.6667H12.0002V45.3334H17.3335V26.6667ZM33.3335 26.6667H28.0002V45.3334H33.3335V26.6667ZM56.0002 50.6667H5.3335V56H56.0002V50.6667ZM49.3335 26.6667H44.0002V45.3334H49.3335V26.6667ZM30.6668 8.69335L44.5602 16H16.7735L30.6668 8.69335ZM30.6668 2.66669L5.3335 16V21.3334H56.0002V16L30.6668 2.66669Z"
                  fill="#577387"
                />
              </svg>
            </div>
            <h4 class="text-2xl font-bold px-">Get paid automatically</h4>
            <p class="mt-4 text-base font-medium">
              Receive donations from all over the world, paid automatically and directly into your bank account.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="py-10 mt-5 md:py-18">
      <div class="px-6 lg:px-26 max-w-xl md:max-w-screen-2xl mx-auto">
        <div class="text-text-alternate-1">
          <h3 class="font-bold text-2xl md:text-4xl">Do more with Blessmi</h3>
        </div>
        <div
          class="grid md:grid-cols-3 pt-4 md:pt-8 gap-6 text-text-alternate-1"
        >
          <div class="lg:max-w-90 pt-7">
            <div
              class="bg-bg-alternate-11 flex justify-end items-end h-60 md:w-full lg:w-90 w-full rounded-lg"
            >
              <DonateIcon />
            </div>
            <h4 class="text-2xl font-bold mt-8">Donate & Share</h4>
            <p class="mt-4 text-base leading-6 font-medium">
              Donate to good causes and spread the word about fundraisers and good causes to help them achieve their goals.
            </p>
            <router-link :to="{ name: 'Feeds' }">
              <p class="font-bold mt-4 text-text-alternate-12 cursor-pointer">
                Browse Fundraisers
              </p>
            </router-link>
          </div>
          <div class="lg:max-w-90 pt-7">
            <div
              class="bg-bg-alternate-11 flex justify-end items-end h-60 lg:w-90 md:w-full w-full rounded-lg"
            >
              <CharitiesIcon />
            </div>
            <h4 class="text-2xl font-bold mt-8">Institutions & Charities</h4>
            <p class="mt-4 text-base leading-6 font-medium">
              Create permanent fundraiser profile pages for your charity, institution, or nonprofit organisation.
            </p>
            <router-link :to="{ name: 'Feeds' }">
              <p class="font-bold mt-4 text-text-alternate-12 cursor-pointer">
                Browse Charities
              </p>
            </router-link>
          </div>
          <div class="lg:max-w-90 pt-7">
            <div
              class="bg-bg-alternate-11 flex justify-end items-end h-60 lg:w-90 md:w-full w-full rounded-lg"
            >
              <MakeWishIcon />
            </div>
            <h4 class="text-2xl font-bold mt-8">Make A Wish</h4>
            <p class="mt-4 text-base leading-6 font-medium">
              Adopt a fundraiser and help raise money to commemorate a special occassion or milestone in your life. 
            </p>
            <router-link :to="{ name: 'Signup' }">
              <p class="font-bold mt-4 text-text-alternate-12 cursor-pointer">
                Start a Fundraisers
              </p>
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="py-10 md:py-18 bg-bg-alternate-3 bless-impact">
      <div class="py-4 px-6 lg:px-24 max-w-xl md:max-w-screen-2xl mx-auto">
        <h3 class="font-bold text-2xl md:text-4xl text-center lg:text-left">
          Blessmi Impact
        </h3>
        <p
          class="font-bold lg:text-2xl text-xl text-center lg:text-left text-text-alternate-1 mt-12"
        >
          Over 150 million naira has <br class="hidden lg:block" />
          been raised on Blessmi
        </p>
        <p class="text mt-4 text-text-alternate-1 text-center lg:text-left">
          At Blessmi, we believe that society must harness the power
          <br class="hidden lg:block" />
          of technology to do good things.
        </p>
        <div
          class="mt-12 flex flex-col lg:flex-row items-center text-center lg:text-left"
        >
          <div>
            <span
              class="text-text-alternate-10 font-bold lg:text-3.5xl text-2.5xl"
              >2k+</span
            >
            <p class="text-text-alternate-1 font-medium text-xl">Fundraisers</p>
          </div>
          <div class="lg:ml-12 mt-6 lg:mt-0">
            <span
              class="text-text-alternate-10 font-bold lg:text-3.5xl text-2.5xl"
              >150m+</span
            >
            <p class="text-text-alternate-1 font-medium text-xl">Donated</p>
          </div>
          <div class="lg:ml-16 mt-6 lg:mt-0">
            <span
              class="text-text-alternate-10 font-bold lg:text-3.5xl text-2.5xl"
              >20k+</span
            >
            <p class="text-text-alternate-1 font-medium text-xl">Donors</p>
          </div>
        </div>
      </div>
    </section>

    <section class="py-10 mt-10 md:py-18">
      <div class="px-6 lg:px-24 max-w-xl md:max-w-screen-2xl mx-auto">
        <div
          class="bg-bg-alternate-11 rounded-lg cta-section flex flex-col lg:pl-20 p-6 justify-center"
        >
          <h3 class="font-bold lg:text-2.5xl text-2xl text-text-alternate-1">
            Want to give back to the society?
          </h3>
          <p class="text-text-alternate-1 font-medium mt-5">
            Create a Blessmi account in 2 minutes. Create a fundraiser <br class="hidden lg:block" />for a cause. Share the Fundraiser.
          </p>
          <button
            class="h-12 w-52 rounded-md bg-btn-bg-primary text-btn-text-primary font-bold text-sm mt-8 text-white"
          >
            Get Started
          </button>
        </div>
      </div>
    </section>

    <section class>
      <div class="px-6  pb-20 lg:px-24 max-w-xl md:max-w-screen-2xl mx-auto">
        <h3
          class="font-bold lg:text-2.5xl text-2xl text-text-alternate-1 text-center"
        >
          Join our 500+ Partners
        </h3>
        <div
          class="lg:flex hidden lg:justify-between justify-center items-center lg:px-20 mt-16 gap-6 lg:gap-0"
        >
          <div>
            <QoreIdIcon class="" />
          </div>
          <div>
            <WemaBankIcon style="margin-top: -15px" />
          </div>
          <div>
            <img src="../assets/images/giplc.png" draggable="false" class=" h-16" alt="" />
          </div>
          <div>
            <img src="../assets/images/illmi.png" alt="" />
          </div>
          <div>
            <MoniePointIcon />
          </div>
        </div>

        <div
          class="flex lg:hidden lg:justify-between items-center justify-center lg:px-20 mt-6 lg:mt-16 gap-3 lg:gap-0"
        >
          <div>
            <img src="../assets/images/qoreid.png" alt="">
          </div>
          <div>
            <img src="../assets/images/wema-logo.png" alt="">
          </div>
          <div>
            <img src="../assets/images/giplc.png" class=" w-20" alt="" />
          </div>
          <div>
            <img src="../assets/images/illmi.png" alt="" />
          </div>
          <div>
            <img src="../assets/images/moniepoint.png" alt="">
          </div>
        </div>
      </div>
    </section>

    <footer
      class="bg-btn-text-primary text-text-secondary pt-10 pb-8 md:pt-18 md:pb-10 px-6 sm:px-8 md:px-10 lg:px-0 text-sm"
    >
      <div
        class="lg:grid lg:grid-cols-12 items-center md:items-start md:flex-row gap-6 max-w-xl md:max-w-screen-2xl xl:px-24 mx-auto"
      >
        <div class="pt-1 md:pr-4 lg:col-span-4 md:col-span-3 col-span-12">
          <router-link to="/">
            <Brand2Icon class="h-12" />
          </router-link>
          <p class="text-text-primary font-medium mt-4">
            Blessmi is a platform designed and <br class="hidden lg:block" /> built to enable organisations and <br class="hidden lg:block" /> individuals raise money for good  <br class="hidden lg:block" />causes
          </p>
        </div>

        <div class="md:col-span-3 lg:col-span-4 col-span-12 mt-10 lg:mt-0">
          <h4 class="font-bold text-sm text-text-primary">COMPANY</h4>
          <router-link :to="{ name: 'About' }">
            <p class="text-text-primary my-4 font-medium">About Us</p>
          </router-link>
          <router-link :to="{ name: 'TermsAndConditions' }">
            <p class="text-text-primary my-4 font-medium">Terms & Conditions</p>
          </router-link>
          <router-link :to="{ name: 'Privacy' }">
            <p class="text-text-primary my-4 font-medium">Privacy Policy</p>
          </router-link>
          <router-link :to="{ name: 'FAQs' }">
            <p class="text-text-primary my-4 font-medium">FAQs</p>
          </router-link>
        </div>

        <div class="md:col-span-3  col-span-12 mt-10 lg:mt-0">
          <h4 class="font-bold text-sm text-text-primary">CONTACT US</h4>
          <a href="tel:+2347033237954">
            <p class="text-text-primary my-4 font-medium">+234 703 323 7954</p>
          </a>
          <a href="mailto:hello@blessmi.com">
            <p class="text-text-primary my-4 font-medium">hello@blessmi.com</p>
          </a>
        </div>

        <div class="md:col-span-3 lg:col-span-2 col-span-12 mt-10 lg:mt-0">
          <h4 class="font-bold text-sm text-text-primary">CONNECT WITH US</h4>
          <ul class="flex py-4 gap-6">
            <li>
              <a
                href="https://www.youtube.com/channel/UC3ImUaab7xA66fBPF6JUAqA"
                target="_blank"
              >
                <YouTubeIcon class="h-5" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/_blessmi" target="_blank">
                <TWIcon class="h-5" />
              </a>
            </li>
            <li>
              <a href="https://www.tiktok.com/@_blessmi_" target="_blank">
                <TKKI2con class="h-5" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/_blessmi/" target="_blank">
                <InstagramIcon class="h-5" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex flex-col lg:flex-row justify-center mt-10 lg:mt-0">
        <p
          class="text-center font-medium mt-4 md:mt-7 text-sm text-text-primary"
        >
          &copy;{{ new Date().getFullYear() }} Blessmi. GXT Blessmi Ltd. All
          Rights Reserved
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import { toRefs, ref, onBeforeUnmount, onMounted, computed } from "vue";
import useSideMenuState from "@/cmp-functions/useSideMenuState.js";
import BHeader from "../components/ui/BHeader.vue";
import AOS from "aos";

const lists = [
  "Charities",
  "Good Causes",
  "Medical",
  "Creatives",
  "Civil Societies",
  "Nonprofits",
  "Communities",
  "Education",
  "Everyone",
];

let pointer = 0;
let isForward = false;
let time = 50;
let timerId;

export default {
  components: { BHeader },
  name: "Home",

  setup() {
    const { state } = useSideMenuState();
    const name = ref(lists[0]);

    const updateText = () => {
      if (!name.value.length) {
        name.value = "";
        pointer = (pointer + 1) % lists.length;
        isForward = true;
      }
      if (isForward) {
        name.value = lists[pointer].slice(0, name.value.length + 1);
      } else {
        name.value = lists[pointer].slice(0, name.value.length - 1);
      }
      if (name.value.length === lists[pointer].length) {
        isForward = false;
        time = 2000;
      } else {
        time = 80;
      }
      timerId = setTimeout(updateText, time);
    };

    onMounted(() => {
      // timerId = setInterval(updateText, 100);
      updateText();
    });
    onMounted(() => {
      AOS.init();
    });

    const shouldBlink = computed(
      () => name.value.length === lists[pointer].length
    );

    onBeforeUnmount(() => clearTimeout(timerId));

    return {
      ...toRefs(state),
      name,
      shouldBlink,
    };
  },
};
</script>

<style lang="scss" scoped>
.bg-home {
  background-color: #f3f7ff;
}

.cursor {
  border-right: 3px solid;
}
.blink-animation {
  animation: blink 0.5s step-end infinite alternate;
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
@media (min-width: 1024px) {
  .bless-impact {
    background-image: url("../assets/images/MapBackground.png");
    background-position: center right;
    background-repeat: no-repeat;
  }
  .cta-section {
    background-image: url("../assets/images/supporting-business-person-diagonal-svgrepo-com 1.png");
    height: 357px;
    background-position: right bottom;
    background-repeat: no-repeat;
  }
}

</style>
