<template>
  <div class="px-6 border-b pb-4 border-text-alternate-8">
    <!-- {{step}} -->
    <div class="flex justify-center">
      <div class="flex sm:w-3/4 w-4/5 justify-between items-center text-center">
        <span
          class="
            h-4
            w-4
            rounded-full
            bg-text-primary
            flex
            items-center
            justify-center
            font-semibold
            text-text-secondary text-2xs
          "
          >{{ step > 1 ? '&#10003;' : '1' }}</span
        >
        <div class="flex-grow border-t mx-2 border-text-alternate-8"></div>
        <span
          class="
            h-4
            w-4
            rounded-full
            flex
            items-center
            justify-center
            font-semibold
            text-text-secondary text-2xs
          "
          :class="step >= 2 ? 'bg-text-primary' : 'bg-text-alternate-3'"
          >{{ step > 2 ? '&#10003;' : '2' }}</span
        >
        <div class="flex-grow border-t mx-2 border-text-alternate-8"></div>
        <span
          :class="step >= 3 ? 'bg-text-primary' : 'bg-text-alternate-3'"
          class="
            h-4
            w-4
            rounded-full
            flex
            items-center
            justify-center
            font-semibold
            text-text-secondary text-2xs
          "
          >{{ step > 3 ? '&#10003;' : '3' }}</span
        >
      </div>
    </div>
    <div class="flex w-full justify-between text-center">
      <div class="flex w-1/4 flex-col items-center">
        <p class="text-2xs mt-3 font-semibold text-text-primary">
          Organisation <br />Information
        </p>
      </div>
      <div class="flex w-1/4 flex-col items-center">
        <p
          class="text-2xs mt-3 font-semibold"
          :class="step >= 2 ? 'text-text-primary' : 'text-text-alternate-3'"
        >
          Contact <br />Information
        </p>
      </div>
      <div class="flex w-1/4 flex-col items-center">
        <p
          class="text-2xs font-semibold mt-3"
          :class="step >= 3 ? 'text-text-primary' : 'text-text-alternate-3'"
        >
          Bank <br />Information
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['step'],
};
</script>

<style></style>
