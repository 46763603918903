<template>
  <div class="splash">
    <BrandIcon id="brand" class="h-12" />
  </div>
</template>
<script>
export default {
  name: 'BSplashScreen',
}
</script>

<style lang="scss" scoped>
.splash {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  & > #brand {
    animation: 2s ease-in-out 0s infinite running pulse;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.5);
  }
}
</style>
