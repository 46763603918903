<template>
  <div class="px-6 pt-4 pb-8 lg:mt-28 lg:min-h-50vh lg:w-full round lg:p-6 lg:rounded-xl">
    
    <BPageLoader v-if="categoryResponse.isLoading" class="flex-grow" />
    <template v-else>
      <div
        class="
          border
          rounded
          border-border-primary
          flex
          items-center
          overflow-hidden
          w-full
          input-shadow
        "
      >
        <input
          class="p-4 focus:outline-none w-full text-sm font-semibold"
          type="text"
          placeholder="Search"
          v-model.trim="searchText"
        />
        <router-link :to="{ name: 'Filter' }">
          <SettingIcon class="mx-3 h-5" />
        </router-link>
      </div>
      <div class="flex my-4 gap-2 overflow-x-auto">
        <router-link
          :class="[
            'font-semibold cursor-pointer h-8 text-xs rounded px-4 flex items-center whitespace-nowrap',
            $route.params.filter === 'latest' || $route.fullPath === '/explore'
              ? 'bg-btn-bg-primary text-text-secondary'
              : 'text-text-primary',
          ]"
          :to="{ name: 'Feeds', params: { filter: 'latest' } }"
        >
          Latest
        </router-link>
        <router-link
          :class="[
            'font-semibold cursor-pointer h-8 text-xs rounded px-4 flex items-center whitespace-nowrap',
            $route.params.filter === 'trending'
              ? 'bg-btn-bg-primary text-text-secondary'
              : 'text-text-primary',
          ]"
          :to="{ name: 'Feeds', params: { filter: 'trending' } }"
        >
          Trending
        </router-link>
        <router-link
          :class="[
            'font-semibold cursor-pointer h-8 text-xs rounded px-4 flex items-center whitespace-nowrap',
            $route.params.filter === 'top-donated'
              ? 'bg-btn-bg-primary text-text-secondary'
              : 'text-text-primary',
          ]"
          :to="{ name: 'Feeds', params: { filter: 'top-donated' } }"
        >
          Top Donated
        </router-link>
        <router-link
          :class="[
            'font-semibold cursor-pointer h-8 text-xs rounded px-4 flex items-center whitespace-nowrap',
            id == $route.query.category
              ? 'bg-btn-bg-primary text-text-secondary'
              : 'text-text-primary',
          ]"
          v-for="{ categoryname, id } in categoryResponse.data"
          :key="id"
          :to="{ name: 'Feeds', query: { ...$route.query, category: id } }"
        >
          {{ categoryname }}
        </router-link>
      </div>
      <BPageLoader
        v-if="isLoading && !data.fundraisers?.length"
        class="flex-grow"
      />
      <template v-else-if="data.fundraisers.length">
        <div class="grid grid-cols-2 lg:gap-6 sm:grid-cols-4 gap-4">
          <BFundraiserCardSM
            v-for="fundraiser in data.fundraisers"
            :key="fundraiser.id"
            :fundraiser="fundraiser"
            @like="fundraiser.likes = $event"
          />
        </div>
        <BPageLoader class="my-2" v-if="isLoading" />
      </template>
      <div class="flex pt-20 flex-col items-center justify-center" v-else>
        <span class="p-6 bg-bg-alternate-3 rounded-full">
          <ResultEmptyIcon class="fill-current h-10" />
        </span>
        <h4 class="font-medium text-md mt-8">No Fundraiser Found</h4>
      </div>
    </template>
  </div>
</template>

<script>
import { ref, watch, toRefs, computed } from 'vue';
import BFundraiserCardSM from '@/components/ui/BFundraiserCardSM';
import { useApi } from '@/cmp-functions/useApi';
import { useInfiniteScroll } from '@/cmp-functions/useInfiniteScroll';
import { useRoute } from 'vue-router';
import {
  searchFundraiser,
  getTopDonatedFundraiser,
  getTrendingFundraiser,
} from '@/services/api';
import { useStore } from 'vuex';
import debounce from 'lodash.debounce';
import { useAppState } from '@/cmp-functions/appState.js';
const typeMaper = {
  'One-Time': 'ONEOFF',
  Recurring: 'RECURRING',
};

const limit = 20;

const getFundraiserFeeds = (filter, payload) => {
  switch (filter) {
    case 'top-donated':
      return getTopDonatedFundraiser(payload);
    case 'trending':
      return getTrendingFundraiser(payload);
    default:
      return searchFundraiser(payload);
  }
};

const handlePaginationResponse = (pre, { data }) => {
  if (!data?.data) return pre;
  const { data: fundraisers, metadata } = data;

  return {
    fundraisers: [...pre.fundraisers, ...fundraisers],
    metaData: {
      offset: fundraisers.length + pre.fundraisers.length,
      ...metadata,
    },
  };
};

const initialStructure = {
  fundraisers: [],
  metaData: { offset: 0, isLastPage: false },
};

export default {
  name: 'Feeds',

  components: { BFundraiserCardSM },

  setup() {
    const searchText = ref('');
    const route = useRoute();
    const store = useStore();

    const { categories: categoryResponse } = useAppState();

    const authUser = computed(() => store.state.auth.authData);

    const [response, searchForFundraiser] = useApi(
      getFundraiserFeeds,
      { ...initialStructure },
      handlePaginationResponse
    );

    const getPayload = (routeQuery) => {
      let {
        category: categoryId,
        amount: targetAmount,
        type,
        country,
      } = routeQuery;

      const fundraiserType = typeMaper[type];

      return {
        targetAmount,
        categoryId,
        fundraiserType,
        country,
        userId: authUser.value?.id,
      };
    };

    const requestForFeeds = (query) => {
      const payload = { ...getPayload(route.query), limit, offset: 0, query };
      response.data.fundraisers = [];
      searchForFundraiser(route.params.filter, payload);
    };

    watch(
      () => route.fullPath,
      () => {
        if (route.name !== 'Feeds') return;
        requestForFeeds();
      }
    );

    watch(searchText, debounce(requestForFeeds, 350));

    const fetchFundraiserData = () => {
      if (response.data.metaData.isLastPage || response.isLoading) return;
      searchForFundraiser(route.params.filter, {
        ...getPayload(route.query),
        limit,
        offset: response.data.metaData.offset,
        query: searchText.value || undefined,
      });
    };

    useInfiniteScroll(fetchFundraiserData, true);

    return {
      ...toRefs(response),
      categoryResponse,
      searchText,
    };
  },
};
</script>
<style scoped>
@media only screen and (min-width: 1024px) {
  .round {
    border: 1px solid #e2e8f0 !important;
  }
}
</style>
